import {
    GET_TICKET_CANCELLATION_REQUEST_BY_ID,
    COMMENT_TICKET
 } from "../actions/types";

const initialState = {
  ticketCancelationRequest: {},
};

const ticketCancellationRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TICKET_CANCELLATION_REQUEST_BY_ID:
      return {
        ...state,
        ticketCancelationRequest: action.payload,
      };

    case COMMENT_TICKET:
      const { comment, ticketNumber } = action.payload;

      if (state.ticketCancelationRequest.ticket.number === ticketNumber) {
        return {
          ...state,
          ticketCancelationRequest: {
            ...state.ticketCancelationRequest,
            ticket: {
              ...state.ticketCancelationRequest.ticket,
              comment: comment,
            },
          },
        };
      }

    default:
      return state;
  }
};

export default ticketCancellationRequestsReducer;
