import {
  CANCEL_TICKET,
  DELETE_TICKET,
  GENERATE_DOCUMENTATION,
  GET_ALL_VIOLATIONS,
  CHANGE_TICKET_LEGAL_STATUS,
  MANUALLY_PAY,
  ARCHIVED_TICKETS,
  SET_LOADER,
  COMMENT_TICKET,
  MODIFY_TICKET,
  OWNERS_PARKING_ZONES_FOR_EDITING_TICKET,
  DONE_TICKET,
  GET_ALL_TICKET_AUDIT,
  CANCEL_TICKET_FEE,
  OWNERS_GAS_STATION_ZONES_FOR_EDITING_TICKET,
  CLEAR_OWNERS_LIST_FOR_GS_AND_PZ_EDIT,
  GET_TICKET_FOR_EDIT,
  CLEAR_TICKET_FOR_EDIT, CHANGE_TICKET_DOCUMENT_LANGUAGE,
} from "./types";
import { apiService } from "../utils/serviceApi";
import { zurichZipCodes } from "../columns/violationsColumns";

export const getAllViolations =
  (showArchivedTickets, setArchivedCellStyle, filter, ourRequest) =>
  async (dispatch) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });

    apiService
      .getWithCancelToken(
        `${
          process.env.REACT_APP_SERVICE_URL
        }/api/report/getTickets?filter=${encodeURI(JSON.stringify(filter))}`,
        ourRequest
      )
      .then((res) => {
        dispatch({
          type: GET_ALL_VIOLATIONS,
          payload: res?.data?.content,
          totalElements: res?.data?.totalElements,
        });
      })
      .then(() => {
        setArchivedCellStyle(showArchivedTickets);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };

export const getAllDriveOffs =
  (showArchivedTickets, setArchivedCellStyle, filter, ourRequest) =>
  async (dispatch) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });

    apiService
      .getWithCancelToken(
        `${
          process.env.REACT_APP_SERVICE_URL
        }/api/report/getTickets?filter=${encodeURI(JSON.stringify(filter))}`,
        ourRequest
      )
      .then((res) => {
        dispatch({
          type: GET_ALL_VIOLATIONS,
          payload: res?.data?.content,
          totalElements: res?.data?.totalElements,
        });
      })
      .then(() => {
        setArchivedCellStyle(showArchivedTickets);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };

export const deleteTicket = (id) => async (dispatch) => {
  apiService
    .delete(`${process.env.REACT_APP_SERVICE_URL}/api/report/archive/${id}`)
    .then(() => {
      dispatch({
        type: DELETE_TICKET,
        payload: id,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const downloadZipDocumentation = async (id) => {
  apiService
    .getDocument(`${process.env.REACT_APP_SERVICE_URL}/api/report/zip/${id}`)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "multiCompressed.zip");
      document.body.appendChild(link);
      link.click();
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getAuditTickets = (referenceNumber) => async (dispatch) => {
  apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/report/audit/${referenceNumber}`
    )
    .then((res) => {
      dispatch({
        type: GET_ALL_TICKET_AUDIT,
        payload: res.data,
      });
    })

    .catch((e) => {
      console.log(e);
    });
};

export const generateSueDocumentation =
  (ticketId, licencePlate, postalCode) => async (dispatch) => {
    apiService
      .getDocument(
        `${process.env.REACT_APP_SERVICE_URL}/api/report/${ticketId}/${licencePlate}/generateSueDocs`
      )
      .then((response) => {
        dispatch({
          type: GENERATE_DOCUMENTATION,
          payload: ticketId,
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          postalCode.startsWith("80") ||
            postalCode.startsWith("8910") ||
            zurichZipCodes.includes(parseInt(postalCode)) ||
            postalCode.startsWith("8307")
            ? response.headers['content-disposition'].split("filename=")[1]
            : "documentation.zip"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.log(e);
      });
  };

export const createOperationalDoc =
  (ticketNumber, licencePlate) => async (dispatch) => {
    apiService
      .getDocument(
        `${process.env.REACT_APP_SERVICE_URL}/api/report/${ticketNumber}/${licencePlate}/printoutOperationalDocument`
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.headers['content-disposition'].split("filename=")[1]);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.log(e);
      });
  };

export const generatePaymentSlipReminder =
  (ticketNumber, licencePlate, selectedLanguage) => async (dispatch) => {
    apiService
      .getDocument(
        `${process.env.REACT_APP_SERVICE_URL}/api/tickets/${ticketNumber}/${licencePlate}/${selectedLanguage}/printoutReminder`
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.headers['content-disposition'].split("filename=")[1]);
        document.body.appendChild(link);
        link.click();
        //this is waiting for automatic changing legal status
        // dispatch({
        //   type: GENERATE_REMINDER_PAYMENT_SLIP,
        //   payload: ticketNumber,
        // });
      })
      .catch((e) => {
        console.log(e);
      });
  };

export const generatePaymentSlipWithPhotos =
  (ticketNumber, licencePlate, selectedLanguage) => async () => {
    apiService
      .getDocument(
        `${process.env.REACT_APP_SERVICE_URL}/api/report/${ticketNumber}/${licencePlate}/${selectedLanguage}/paymentSlipWithPhotos`
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.headers['content-disposition'].split("filename=")[1]);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.log(e);
      });
  };

export const cancelTicket = (id) => async (dispatch) => {
  apiService
    .put(`${process.env.REACT_APP_SERVICE_URL}/api/report/cancel/${id}`)
    .then((res) => {
      dispatch({
        type: CANCEL_TICKET,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const manuallyPayTicketBySysadmin =
  (manualPayment) => async (dispatch) => {
    apiService
      .put(
        `${process.env.REACT_APP_SERVICE_URL}/api/report/manuallyPayTicketBySysadmin`,
        manualPayment
      )
      .then((res) => {
        dispatch({
          type: MANUALLY_PAY,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

export const cancelTicketFee = (id) => async (dispatch) => {
  apiService
    .put(`${process.env.REACT_APP_SERVICE_URL}/api/report/cancelFee/${id}`)
    .then((res) => {
      dispatch({
        type: CANCEL_TICKET_FEE,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const changeTicketLegalStatusByPZAdminAndSysAdmin =
  (id, status) => async (dispatch) => {
    apiService
      .put(
        `${process.env.REACT_APP_SERVICE_URL}/api/report/changeTicketLegalStatus/${id}/${status}`
      )
      .then((res) => {
        dispatch({
          type: CHANGE_TICKET_LEGAL_STATUS,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };


export const changeTicketDocumentLanguageByNumber =
    (number,language) => async (dispatch) => {
      apiService
          .post(
              `${process.env.REACT_APP_SERVICE_URL}/api/report/editDocumentLanguage/${number}/${language}`
          )
          .then((res) => {
            dispatch({
              type: CHANGE_TICKET_DOCUMENT_LANGUAGE,
              payload: res.data,
            });
          })
          .catch((e) => {
            console.log(e);
          });
    };

export const changeTicketArchivedStatus = (number) => async (dispatch) => {
  apiService
    .put(`${process.env.REACT_APP_SERVICE_URL}/api/report/${number}`)
    .then((res) => {
      dispatch({
        type: ARCHIVED_TICKETS,
        payload: number,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};
export const commentTicket = (data) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .put(`${process.env.REACT_APP_SERVICE_URL}/api/report/commentTicket`, data)
    .then((res) => {
      dispatch({
        type: COMMENT_TICKET,
        payload: { comment: data.comment, ticketNumber: data.ticketNumber },
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};
export const modifyTicket = (data, setEditTicketModal) => async (dispatch) => {
  apiService
    .put(`${process.env.REACT_APP_SERVICE_URL}/api/report/editTicket`, data)
    .then((res) => {
      dispatch({
        type: MODIFY_TICKET,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      setEditTicketModal(false);
      dispatch({
        type: CLEAR_TICKET_FOR_EDIT,
      });
    });
};

export const clearOwnersForGSAndPZEdit = () => async (dispatch) => {
  dispatch({
    type: CLEAR_OWNERS_LIST_FOR_GS_AND_PZ_EDIT,
  });
};

export const getBeppolicemanParkingZones = (email) => async (dispatch) => {
  apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/parking-zone/getOwnersParkingZones/${email}`,
      email
    )
    .then((res) => {
      dispatch({
        type: OWNERS_PARKING_ZONES_FOR_EDITING_TICKET,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {});
};
export const getBeppolicemanGasStationZones = (email) => async (dispatch) => {
  apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/gas-station/getOwnersGasStationZones/${email}`,
      email
    )
    .then((res) => {
      dispatch({
        type: OWNERS_GAS_STATION_ZONES_FOR_EDITING_TICKET,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {});
};

export const editLockedViolatorAddress = (data) => async (dispatch) => {
  apiService
    .put(
      `${process.env.REACT_APP_SERVICE_URL}/api/report/editViolatorAddress`,
      data
    )
    .then((res) => {
      dispatch({
        type: MODIFY_TICKET,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {});
};
export const changeTicketToDoneStatus = (id) => async (dispatch) => {
  apiService
    .put(`${process.env.REACT_APP_SERVICE_URL}/api/report/done/${id}`)
    .then((res) => {
      dispatch({
        type: DONE_TICKET,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getTicketForEdit = (ticketNumber) => async (dispatch) => {
  apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/report/getTicketForEdit/${ticketNumber}`
    )
    .then((res) => {
      dispatch({
        type: GET_TICKET_FOR_EDIT,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {});
};
