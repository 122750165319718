import {
  cellStyle,
  headerStyle,
  cellStyleDisabled,
  filterStyle,
} from "./columnsGeneral";
import Dropdown from "react-bootstrap/Dropdown";
import React from "react";

const dropdownItem = (onClickAction, translation) => {
  return (
    <Dropdown.Item
      style={{
        color: "white",
      }}
      onClick={() => onClickAction()}
    >
      {translation}
    </Dropdown.Item>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/#"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <div style={{ fontWeight: "bolder", fontSize: "x-large" }}>&#xFE19;</div>
  </a>
));

export function UsersColumns(
  t,
  setConfirmationDisableUser,
  setUserIDForArchive,
  inactiveListVisible,
  inactiveCellStyle,
    setConfirmationResetPassword,
    setUserEmailForPasswordReset
) {
  return [
    {
      title: t("Columns.First name"),
      field: "firstName",
      cellStyle: inactiveCellStyle ? cellStyleDisabled : cellStyle,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
    },

    {
      title: t("Columns.Last name"),
      field: "lastName",
      filterCellStyle: filterStyle,
      cellStyle: inactiveCellStyle ? cellStyleDisabled : cellStyle,
      headerStyle: headerStyle,
    },

    {
      title: t("Columns.Email"),
      field: "emailAddress",
      filterCellStyle: filterStyle,
      cellStyle: inactiveCellStyle ? cellStyleDisabled : cellStyle,
      headerStyle: headerStyle,
    },

    {
      title: t("Columns.Parking zone Admin"),
      field: "administeredParkingZones",
      filterCellStyle: filterStyle,
      cellStyle: inactiveCellStyle ? cellStyleDisabled : cellStyle,
      headerStyle: headerStyle,
      render: (row) => {
        if (row.administeredParkingZones.length === 0) {
          return "-";
        }
        return row.administeredParkingZones.map((pz, index) => {
          return (
            <p key={index} style={{ margin: 0 }}>
              {pz}
            </p>
          );
        });
      },
    },
    {
      title: t("Columns.Parking managers"),
      field: "managerParkingZones",
      filterCellStyle: filterStyle,
      cellStyle: inactiveCellStyle ? cellStyleDisabled : cellStyle,
      headerStyle: headerStyle,
      render: (row) => {
        if (row.managerParkingZones.length === 0) {
          return "-";
        }
        return row.managerParkingZones.map((manager, index) => {
          return (
            <p key={index} style={{ margin: 0 }}>
              {manager}
            </p>
          );
        });
      },
    },
    {
      title: t("Owners.owners"),
      field: "ownershipParkingZones",
      filterCellStyle: filterStyle,
      cellStyle: inactiveCellStyle ? cellStyleDisabled : cellStyle,
      headerStyle: headerStyle,
      render: (row) => {
        if (row.ownershipParkingZones.length === 0) {
          return "-";
        }
        return row.ownershipParkingZones.map((owner, index) => {
          return (
            <p key={index} style={{ margin: 0 }}>
              {owner}
            </p>
          );
        });
      },
    },
    {
      title: t("Columns.License Plate Number"),
      field: "licensePlate",
      filterCellStyle: filterStyle,
      cellStyle: inactiveCellStyle ? cellStyleDisabled : cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Actions"),
      filterPlaceholder: "eingeben",
      headerStyle: headerStyle,
      field: "actions",
      filterComponent: () => null,
      cellStyle: inactiveCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          {row.licensePlate !== "-" ||
          row.administeredParkingZones.length > 0 ||
          row.managerParkingZones.length > 0 ||
          row.ownershipParkingZones > 0 ? null : (
            <Dropdown.Menu
              size="sm"
              style={{
                color: "white",
                backgroundColor: "rgb(0,90,140)",
              }}
              popperConfig={{ strategy: "fixed" }}
            >
              {inactiveListVisible
                ? dropdownItem(() => {
                    setConfirmationDisableUser(true);
                    setUserIDForArchive(row.id);
                  }, t("Users.Active"))
                : dropdownItem(() => {
                    setConfirmationDisableUser(true);
                    setUserIDForArchive(row.id);
                  }, t("Users.Deactive"))}
              {dropdownItem(() => {
                setConfirmationResetPassword(true);
                setUserEmailForPasswordReset(row.emailAddress);
              }, t("Users.ResetPassword"))}
            </Dropdown.Menu>
          )}
        </Dropdown>
      ),
    },
  ];
}
