import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useTranslation } from "react-i18next";
import Style from "./violationsDetailsModal.module.css";
import { newModalStyle } from "../../reusable/styles";
import EntityDetail from "../../common/entityDetail/entityDetail";
import moment from "moment";

const ViolationsDetailsModal = (props) => {
  const { selectedTicket } = props;
  const { t } = useTranslation();

  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={props.onClose}
      center
      styles={newModalStyle({ width: "539px" })}
    >
      <div className="modal__headerLine" />
      <div className="modal__title">{t("ViolationsDetailsModal.title")}</div>

      <div className={Style.body}>
        <EntityDetail
          label={t("ViolationsDetailsModal.referenceNumber")}
          value={selectedTicket.number}
        />
        <EntityDetail
          label={t("ViolationsDetailsModal.licensePlate")}
          value={selectedTicket.licensePlate}
        />
        <EntityDetail
          label={t("ViolationsDetailsModal.date")}
          value={moment(selectedTicket.date).format("DD.MM.YYYY")}
        />
        <EntityDetail
          label={t("ViolationsDetailsModal.parkingZoneName")}
          value={selectedTicket.parkingZone.parkingZoneName}
        />
      </div>
    </Modal>
  );
};
export default ViolationsDetailsModal;
