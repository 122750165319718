import Modal from "react-responsive-modal";
import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { useTranslation } from "react-i18next";
import { newModalStyle } from "../../reusable/styles";
import { useDispatch, useSelector } from "react-redux";
import { getZendeskTicket } from "../../actions/ticket";
import moment from "moment";
import Style from "./modalZendeskDetails.module.css";
import EntityDetail from "../requests/entityDetail";
import ModalLoadingIndicator from "../../reusable/modalLoadingIndicator";

const ModalZendeskDetails = (props) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.loader);
  const { zendeskTicket } = useSelector((state) => state.violations);
  useEffect(() => {
    dispatch(getZendeskTicket(props.zendeskId));
  }, [props.zendeskId]);

  const { t } = useTranslation();

  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={props.onClose}
      center
      styles={newModalStyle({ width: "539px" })}
    >
      {isLoading && <ModalLoadingIndicator />}
      <div className="modal__headerLine" />
      <div className="modal__title">{zendeskTicket?.subject}</div>

      <div className={Style.body}>
        <EntityDetail
          label={t("ZendeskModal.status")}
          value={zendeskTicket?.status}
        />
        <EntityDetail
          label={t("ZendeskModal.name")}
          value={zendeskTicket?.name}
        />
        <EntityDetail
          label={t("ZendeskModal.lastChange")}
          value={
            zendeskTicket?.updated_at &&
            moment(zendeskTicket?.updated_at).format("DD.MM.YYYY")
          }
        />

        <EntityDetail
          label={t("ZendeskModal.channel")}
          value={
            zendeskTicket?.fields?.find(
              (element) => element.id === 8723246970641
            ).value
          }
        />
        <EntityDetail
          label={t("ZendeskModal.referenceNumber")}
          value={
            zendeskTicket?.fields?.find(
              (element) => element.id === 7609355724433
            ).value
          }
        />
        <EntityDetail
          label={t("ZendeskModal.employee")}
          value={
            zendeskTicket?.fields?.find(
              (element) => element.id === 8768472469777
            ).value
          }
        />
        <EntityDetail
          label={t("ZendeskModal.category")}
          value={
            zendeskTicket?.fields?.find(
              (element) => element.id === 7599841125265
            ).value
          }
        />

        {zendeskTicket?.comments?.map((comment) => (
          <div
            className={Style.textOnProhibitionSign}
            style={
              !comment?.public
                ? { fontStyle: "italic", background: "#fdfa72" }
                : null
            }
            dangerouslySetInnerHTML={{ __html: comment?.html_body }}
          />
        ))}
      </div>
    </Modal>
  );
};
export default ModalZendeskDetails;
