import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Sidebar from "../../sidebar/sidebar";
import MaterialTable from "@material-table/core";
import {
  useStyles,
  useStylesForMaterial,
  materialTableStyle,
  paginationStyle,
} from "../../reusable/styles";
import { useTranslation } from "react-i18next";
import Pagination from "../../reusable/pagination";
import { useDispatch, useSelector } from "react-redux";
import { optionsGeneral } from "../../reusable/optionsForTables";
import LoadingIndicator from "../../reusable/loadingIndicator";
import keycloak from "../../keycloak";
import { ROLES } from "../../consts";
import Style from "./whitelist.module.css";
import { BlacklistColumns } from "../../columns/blacklistColumns";
import {
  closeDuplicateBlacklistVehicleModal,
  getAllBlacklistForParkingZone,
  removeVehicleFromParkingZoneBlacklist,
} from "../../actions/blacklist";
import { getPaprkingZone } from "../../actions/parkingZone";
import { useState } from "react";
import AddVehicleToBlacklistModal from "./addVehicleToBlacklistModal";
import ErrorModal from "../../common/modal/errorModal";
import ConfirmationModal from "../../common/modal/confirmationModal";
import MagnifyingGlass from "../../common/assets/magnifying-glass.svg";
import {useAuth} from "react-oidc-context";

export default function Blacklist(props) {
  const auth = useAuth();
  const { t } = useTranslation();
  const classes = useStyles();
  const classesMaterial = useStylesForMaterial();
  const dispatch = useDispatch();
  const { blacklist, duplicateBlacklistVehicle } = useSelector(
    (state) => state.blacklist
  );
  const { parkingZone } = useSelector((state) => state.parkingZone);
  const { isLoading } = useSelector((state) => state.loader);
  const [height, setHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setHeight(window.innerHeight);
  };

  const [addVehicleToBlacklistModalOpen, setAddVehicleToBlacklistModalOpen] =
    useState(false);

  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);

  const [blacklistVehicleForDeleteId, setBlacklistVehicleForDeleteId] =
    useState(null);

  const deleteVehicleFromParkingZoneBlacklist = (id) => {
    dispatch(
      removeVehicleFromParkingZoneBlacklist(id, () =>
        setDeleteConfirmationModalOpen(false)
      )
    );
  };

  const openDeleteConfirmationModal = (id) => {
    setDeleteConfirmationModalOpen(true);
    setBlacklistVehicleForDeleteId(id);
  };

  const closeModalDuplicatedBlacklistVehicle = () => {
    dispatch(closeDuplicateBlacklistVehicleModal());
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  useEffect(() => {
    const parkingZoneId = props?.match?.params?.id;
    dispatch(getAllBlacklistForParkingZone(parkingZoneId));
    dispatch(getPaprkingZone(parkingZoneId));
  }, [dispatch, props?.match?.params?.id]);

  function toolbar() {
    return (
      <div className={Style.toolbar}>
        <div className={Style.toolbar__titleAndInfo}>
          <div className={Style.toolbar__titleAndInfo__title}>
            {t("Blacklist.title")}
          </div>
          <div className={Style.pzInfo}>
            <div className={Style.pzInfo__label}>
              {t("Blacklist.parkingZoneNumber")}
            </div>
            <div className={Style.pzInfo__value}>
              {parkingZone?.parkingZoneNumber}
            </div>
          </div>
          <div className={Style.pzInfo}>
            <div className={Style.pzInfo__label}>
              {t("Blacklist.parkingZoneName")}
            </div>
            <div className={Style.pzInfo__value}>
              {parkingZone?.parkingZoneName}
            </div>
          </div>
        </div>

        {(keycloak.hasRealmRole(ROLES.SYSADMIN, auth) ||
          keycloak.hasRealmRole(ROLES.PARKING_ADMIN, auth) ||
          keycloak.hasRealmRole(ROLES.PARKING_MANAGER, auth)) && (
          <div
            className={Style.addVehicle}
            onClick={() => setAddVehicleToBlacklistModalOpen(true)}
          >
            {t("Blacklist.addVehicle")}
          </div>
        )}
      </div>
    );
  }
  function pagination(props) {
    return (
      <td style={paginationStyle}>
        <Pagination translate={t} {...props} classes={{ ...classesMaterial }} />
      </td>
    );
  }

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {isLoading && <LoadingIndicator />}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="text-center">
                <div className="row content">
                  <div className="col-sm-12">
                    <div className={classes.paper}>
                      <MaterialTable
                        icons={{
                          Filter: () => (
                            <img src={MagnifyingGlass} alt="search" />
                          ),
                        }}
                        searchPlaceholder="Test"
                        title={t("ParkingZones.ParkingZones")}
                        style={materialTableStyle}
                        data={blacklist}
                        columns={BlacklistColumns(
                          t,
                          openDeleteConfirmationModal
                        )}
                        options={optionsGeneral(height - 240, 44)}
                        components={{
                          Toolbar: (props) => toolbar(props),
                          Pagination: (props) => pagination(props),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </main>

      <AddVehicleToBlacklistModal
        open={addVehicleToBlacklistModalOpen}
        setModalOpen={setAddVehicleToBlacklistModalOpen}
        parkingZone={parkingZone}
      />

      <ErrorModal
        title={t("DulicateWhitelistWehicle.title")}
        errorMessage={t("DulicateWhitelistWehicle.message")}
        open={duplicateBlacklistVehicle}
        onClose={() => closeModalDuplicatedBlacklistVehicle()}
        width="400px"
      />

      <ConfirmationModal
        title={t("BlacklistDeleteConfirmationModal.title")}
        message={t("BlacklistDeleteConfirmationModal.message")}
        confirmationButtonText={t(
          "BlacklistDeleteConfirmationModal.buttonDelete"
        )}
        open={deleteConfirmationModalOpen}
        onClose={() => setDeleteConfirmationModalOpen(false)}
        confirmationAction={() =>
          deleteVehicleFromParkingZoneBlacklist(blacklistVehicleForDeleteId)
        }
        width="450px"
      />
    </div>
  );
}
