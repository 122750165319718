import {
  GET_ALL_PARKING_ZONES,
  GET_ALL_GAS_STATION_ZONES,
  GET_ALL_PARKING_ZONES_FOR_PZ_ADMIN_OR_PZ_MANAGER,
  GET_ALL_GAS_STATIONS_FOR_GS_ADMIN_OR_GS_MANAGER,
  GET_CHART_DATA,
  RESET_CHART_DATA,
} from "../actions/types";

const initialState = {
  parkingZones: [],
  gsZones: [],
  chartData: {},
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PARKING_ZONES:
      return {
        ...state,
        parkingZones: action.payload,
      };
    case GET_ALL_PARKING_ZONES_FOR_PZ_ADMIN_OR_PZ_MANAGER:
      return {
        ...state,
        parkingZones: action.payload,
      };
    case GET_ALL_GAS_STATION_ZONES:
          return {
            ...state,
            gsZones: action.payload,
          };
    case GET_ALL_GAS_STATIONS_FOR_GS_ADMIN_OR_GS_MANAGER:
      return {
        ...state,
        gsZones: action.payload,
      };
    case GET_CHART_DATA:
      return {
          ...state,
          chartData: action.payload,
      };
    case RESET_CHART_DATA:
      return {
        ...state,
        chartData: initialState.chartData
      };
    default:
      return state;
  }
};

export default reportReducer;
