import {
  DUPLICATE_MANAGER,
  GET_ALL_MANAGERS_BY_ADMIN_ID,
  ADD_MANAGER,
  CLOSE_DUPLICATE_MANAGER_MODAL,
  USER_MANAGER_WITH_GIVEN_EMAIL_NOT_FOUND,
  CLEAR_MANAGER_DATA,
  CHECK_MANAGERS_EMAIL,
  GET_ALL_MANAGERS_FOR_SYSADMIN,
  EDIT_MANAGER,
  DISABLE_MANAGER,
  REMOVE_MANAGER_FROM_PZ_ADMINS_MANAGER_LIST,
} from "../actions/types";

const initialState = {
  managers: [],
  duplicateManager: false,
  manager: null,
};

const managerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_MANAGERS_BY_ADMIN_ID:
      return {
        ...state,
        managers: action.payload,
        manager: null,
      };
    case GET_ALL_MANAGERS_FOR_SYSADMIN:
      return {
        ...state,
        managers: action.payload,
        manager: null,
      };
    case ADD_MANAGER:
      return {
        ...state,
        managers: state.managers.reverse().concat(action.payload).reverse(),
        manager: null,
      };

    case DUPLICATE_MANAGER:
      return {
        ...state,
        duplicateManager: true,
        manager: null,
      };
    case CLOSE_DUPLICATE_MANAGER_MODAL:
      return {
        ...state,
        duplicateManager: false,
        manager: null,
      };
    case CHECK_MANAGERS_EMAIL:
      return {
        ...state,
        manager: action.payload,
        duplicateManager: false,
      };
    case USER_MANAGER_WITH_GIVEN_EMAIL_NOT_FOUND:
      return {
        ...state,
        manager: null,
        duplicateManager: false,
      };
    case CLEAR_MANAGER_DATA:
      return {
        ...state,
        manager: null,
        duplicateManager: false,
      };
    case EDIT_MANAGER:
      return {
        ...state,
        managers: state.managers.map((a) =>
          a.id === action.payload.id ? action.payload : a
        ),
      };
    case DISABLE_MANAGER:
      return {
        ...state,
        managers: state.managers.filter(
          (m) => m.emailAddress !== action.payload
        ),
      };
    case REMOVE_MANAGER_FROM_PZ_ADMINS_MANAGER_LIST:
      return {
        ...state,
        managers: state.managers.filter(
          (m) => m.emailAddress !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default managerReducer;
