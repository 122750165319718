import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {
  cellStyle,
  CustomToggle,
  dropdownItem,
  filterStyle,
  headerStyle,
} from "./columnsGeneral";

export function ViewerColumns(
  setOpenEditOwnerModal,
  setSelectedViewer,
  t,
  disableViewer,
  removeViewerFromPZAdminsManagerList
) {
  const columns = useState([
    {
      title: t("Columns.First name"),
      field: "firstName",
      cellStyle: cellStyle,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Last name"),
      filterCellStyle: filterStyle,
      field: "lastName",
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.PZ number"),
      filterCellStyle: filterStyle,
      field: "administeredParkingZones",
      cellStyle: {
        ...cellStyle,
        width: "12%",
      },
      headerStyle: headerStyle,
      render: (row) => (
        <div>
          {row.administeredParkingZones?.map((a, index) => {
            if (index === 0) {
              return a;
            }
            return " " + a;
          })}
        </div>
      ),
    },
    {
      title: t("Columns.GS numbers"),
      filterCellStyle: filterStyle,
      field: "administeredGasStations",
      cellStyle: {
        ...cellStyle,
        width: "12%",
      },
      headerStyle: headerStyle,
      render: (row) => (
        <div>
          {row.administeredGasStations?.map((a, index) => {
            if (index === 0) {
              return a;
            }
            return " " + a;
          })}
        </div>
      ),
    },
    {
      title: t("Columns.Email"),
      filterCellStyle: filterStyle,
      field: "emailAddress",
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Phone admins page"),
      filterCellStyle: filterStyle,
      field: "phoneNumber",
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Company"),
      field: "companyName",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Actions"),
      headerStyle: {
        paddingTop: "10px",
        fontWeight: "bold",
        paddingBottom: "10px",
        textAlign: "center",
        backgroundColor: "#C7C7C7",
      },
      field: "actions",
      filterComponent: () => null,
      cellStyle: {
        width: "10%",
        textAlign: "center",
        paddingRight: "60px",
        paddingTop: "10px",
        paddingBottom: "10px",
      },
      render: (row) => (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu
            size="sm"
            style={{
              color: "white",
              backgroundColor: "rgb(0,90,140)",
            }}
            popperConfig={{ strategy: "fixed" }}
          >
            {dropdownItem(() => {
              setOpenEditOwnerModal(true);
              setSelectedViewer(row);
            }, t("Admins.change"))}

            {row.administeredParkingZones &&
              row.administeredParkingZones.length === 0 &&
                row.administeredGasStations &&
                row.administeredGasStations.length === 0  &&
              dropdownItem(
                () => removeViewerFromPZAdminsManagerList(row.emailAddress),
                t("Admins.delete")
              )}
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ]);
  return columns[0];
}
