import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { modalCloseButtonStyle } from "../reusable/styles";
import ReactPlayer from "react-player";

const ModalForVideoPlaying = (props) => {
  const packVideos = () => {
    let videos = [];
    props.videos?.map((image) => {
      const video = {
        original: image,
        renderItem: () => {
          return (
            <ReactPlayer url={image} controls width="100%" height="100%" />
          );
        },
      };

      return videos.push(video);
    });

    return videos;
  };

  return (
    <Modal
      open={props.showVideos}
      closeOnEsc
      onClose={props.closeVideos}
      center
      animationDuration={500}
      styles={{
        modal: {
          maxWidth: "25%",
        },
        closeButton: modalCloseButtonStyle,
      }}
      showCloseIcon={false}
    >
      <ImageGallery
        items={packVideos()}
        showPlayButton={false}
        showFullscreenButton={false}
      />
    </Modal>
  );
};
export default ModalForVideoPlaying;
