import { apiService } from "../utils/serviceApi";
import {
  ADD_VIEWER,
  CHECK_VIEWERS_EMAIL,
  CLEAR_VIEWER_DATA,
  CLOSE_DUPLICATE_VIEWER_MODAL,
  DISABLE_VIEWER,
  DUPLICATE_VIEWER,
  EDIT_VIEWER,
  GET_ALL_VIEWERS_BY_ADMIN_ID, GET_ALL_VIEWERS_FOR_SYSADMIN, REMOVE_VIEWER_FROM_PZ_ADMINS_VIEWER_LIST,
  SET_LOADER,
  USER_VIEWER_WITH_GIVEN_EMAIL_NOT_FOUND,
} from "./types";

export const getViewersByAdminId = () => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/accounts/getViewersByAdmin`
    )
    .then((res) => {
      dispatch({
        type: GET_ALL_VIEWERS_BY_ADMIN_ID,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const getAllViewers = () => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/accounts/getAllViewersBySysAdmin`
    )
    .then((res) => {
      dispatch({
        type: GET_ALL_VIEWERS_FOR_SYSADMIN,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};


export const addViewer = (data) => async (dispatch) => {
  apiService
    .post(`${process.env.REACT_APP_SERVICE_URL}/api/accounts/viewer`, data)
    .then((res) => {
      if (res.status === 409) {
        return dispatch({
          type: DUPLICATE_VIEWER,
        });
      }
      dispatch({
        type: ADD_VIEWER,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const closeDuplicateViewerModal = () => async (dispatch) => {
  dispatch({
    type: CLOSE_DUPLICATE_VIEWER_MODAL,
  });
};

export const checkViewerEmail = (email) => async (dispatch) => {
  apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/accounts/checkAdminEmail/${email}`
    )
    .then((res) => {
      if (res.status === 404) {
        return dispatch({
          type: USER_VIEWER_WITH_GIVEN_EMAIL_NOT_FOUND,
        });
      }
      dispatch({
        type: CHECK_VIEWERS_EMAIL,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const clearViewerData = () => async (dispatch) => {
  dispatch({
    type: CLEAR_VIEWER_DATA,
  });
};

export const editViewer = (data) => async (dispatch) => {
  apiService
    .put(
      `${process.env.REACT_APP_SERVICE_URL}/api/accounts/user-data/sysadmin`,
      data
    )
    .then((res) => {
      dispatch({
        type: EDIT_VIEWER,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const disableViewer = (email) => async (dispatch) => {
  apiService
    .put(
      `${process.env.REACT_APP_SERVICE_URL}/api/accounts/disableViewerRole/${email}`,
      email
    )
    .then((res) => {
      dispatch({
        type: DISABLE_VIEWER,
        payload: email,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const removeViewerFromPZAdminsViewerList =
  (email) => async (dispatch) => {
    apiService
      .put(
        `${process.env.REACT_APP_SERVICE_URL}/api/accounts/removeViewerFromParkingZoneAdminsViewerList/${email}`,
        email
      )
      .then((res) => {
        dispatch({
          type: REMOVE_VIEWER_FROM_PZ_ADMINS_VIEWER_LIST,
          payload: email,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
