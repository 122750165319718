import { Avatar } from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import moment from "moment";
import React, { useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Objection from "../common/assets/objection.svg";
import Comment from "../common/assets/comment.svg";
import DropdownItem from "../common/dropdownItem/DropdownItem";
import Style from "./violationsColumns.module.css";
import { ticketLegalStatus, ticketStatus } from "../globals";
import { switchLegalStatus, switchStatus, switchUserStatus } from "../utils/ticketStatusesHelper";
import {
  cellStyle,
  cellStyleDisabled,
  CustomToggle,
  filterStyle,
  headerStyle,
} from "./columnsGeneral";
import { useTranslation } from "react-i18next";
import SearchIcon from "../reusable/searchIcon";
import CloseIcon from "../reusable/closeIcon";
import { changeTicketToDoneStatus } from "../actions/violations";
import { useDispatch } from "react-redux";
import {ROLES} from "../consts";
import { colors } from '../globals';
import {useAuth} from "react-oidc-context";

const violationsActionsCellStyle = {
  width: "10%",
  textAlign: "right",
  paddingRight: "60px",
  paddingTop: "10px",
  paddingBottom: "10px",
};
const violationsActionsCellStyleDisabled = {
  width: "10%",
  textAlign: "right",
  paddingRight: "60px",
  paddingTop: "10px",
  paddingBottom: "10px",
  backgroundColor: "#C7C7C7",
};

const parkingZonesEnabledDocumentation = [
  405670288, 412365991, 414274541, 800565758, 804870647, 604852739, 999932221,
  870624258,
];

export const luzernPostalCodes = [
  6000, 6003, 6004, 6005, 6005, 6006, 6014, 6015, 6016, 6010, 6012, 6013, 6020,
  6023, 6026, 6027, 6028, 6030, 6032, 6033, 6034, 6035, 6036, 6037, 6038, 6038,
  6039, 6043, 6044, 6045, 6047, 6048, 6102, 6103, 6274, 6275, 6276, 6277, 6277,
  6280, 6280, 6283, 6284, 6284, 6285, 6285, 6286, 6287, 6288, 6289, 6289, 6294,
  6295, 6344, 6353, 6354, 6356, 6404, 4806, 4915, 5735, 6017, 6018, 6019, 6022,
  6024, 6025, 6105, 6106, 6110, 6110, 6112, 6113, 6114, 6122, 6123, 6125, 6126,
  6130, 6132, 6133, 6142, 6143, 6144, 6145, 6146, 6147, 6152, 6153, 6154, 6156,
  6156, 6162, 6162, 6162, 6163, 6166, 6167, 6170, 6173, 6174, 6182, 6192, 6196,
  6203, 6204, 6205, 6206, 6207, 6208, 6210, 6211, 6212, 6212, 6213, 6214, 6215,
  6215, 6216, 6217, 6218, 6221, 6222, 6231, 6232, 6233, 6234, 6234, 6235, 6236,
  6242, 6243, 6244, 6245, 6246, 6247, 6248, 6252, 6253, 6260, 6260, 6260, 6260,
  6262, 6263, 6264, 6265,
];

export const zurichZipCodes = [
  8152, 8306, 8172, 8317, 8404, 8184, 8304, 8180, 8197, 8424, 4800, 5400, 5412,
  5430, 5432, 5600, 6052, 6430, 6440, 8157, 8212, 8280, 8302, 8305, 8317, 8400,
  8408, 8500, 8580, 8600, 8706, 8808, 8953, 9000, 9400, 8910, 8307, 8424, 8152,
];

const vaudPostalCodes = [
  1000, 1003, 1004, 1005, 1006, 1007, 1008, 1008, 1009, 1010, 1011, 1012, 1015,
  1018, 1020, 1022, 1023, 1024, 1025, 1026, 1026, 1027, 1028, 1029, 1030, 1031,
  1032, 1033, 1034, 1035, 1036, 1037, 1038, 1040, 1040, 1040, 1041, 1041, 1041,
  1041, 1041, 1041, 1042, 1042, 1042, 1043, 1044, 1045, 1046, 1047, 1052, 1053,
  1053, 1054, 1055, 1058, 1059, 1061, 1062, 1063, 1063, 1063, 1063, 1066, 1068,
  1070, 1071, 1071, 1071, 1072, 1073, 1073, 1076, 1077, 1078, 1080, 1081, 1082,
  1083, 1084, 1085, 1088, 1090, 1091, 1091, 1091, 1092, 1093, 1094, 1095, 1096,
  1096, 1097, 1098, 1110, 1112, 1113, 1114, 1115, 1116, 1117, 1121, 1122, 1123,
  1124, 1125, 1126, 1127, 1128, 1131, 1132, 1134, 1134, 1135, 1136, 1141, 1142,
  1143, 1144, 1145, 1146, 1147, 1148, 1148, 1148, 1148, 1148, 1148, 1148, 1148,
  1148, 1149, 1162, 1163, 1164, 1165, 1166, 1167, 1168, 1169, 1170, 1172, 1173,
  1174, 1174, 1175, 1176, 1180, 1180, 1182, 1183, 1184, 1184, 1185, 1186, 1187,
  1188, 1188, 1189, 1195, 1195, 1196, 1197, 1260, 1261, 1261, 1261, 1262, 1263,
  1264, 1265, 1266, 1267, 1267, 1268, 1268, 1269, 1270, 1271, 1272, 1273, 1273,
  1274, 1274, 1275, 1276, 1277, 1277, 1278, 1279, 1279, 1290, 1291, 1295, 1295,
  1296, 1297, 1299, 1302, 1303, 1304, 1304, 1304, 1304, 1305, 1306, 1307, 1308,
  1312, 1313, 1315, 1316, 1317, 1318, 1321, 1322, 1323, 1324, 1325, 1326, 1329,
  1337, 1338, 1341, 1342, 1343, 1344, 1345, 1346, 1347, 1347, 1348, 1350, 1352,
  1353, 1354, 1355, 1355, 1356, 1356, 1357, 1358, 1372, 1373, 1374, 1375, 1376,
  1376, 1376, 1377, 1400, 1400, 1404, 1404, 1405, 1406, 1407, 1407, 1407, 1407,
  1408, 1409, 1410, 1410, 1410, 1410, 1412, 1412, 1413, 1415, 1415, 1416, 1417,
  1417, 1418, 1420, 1421, 1421, 1422, 1423, 1423, 1423, 1423, 1424, 1425, 1426,
  1426, 1427, 1428, 1428, 1429, 1430, 1431, 1431, 1432, 1432, 1433, 1434, 1435,
  1436, 1436, 1437, 1438, 1439, 1441, 1442, 1443, 1443, 1443, 1445, 1446, 1450,
  1450, 1450, 1452, 1453, 1453, 1454, 1454, 1462, 1463, 1464, 1464, 1509, 1510,
  1510, 1512, 1513, 1513, 1514, 1515, 1515, 1521, 1522, 1522, 1523, 1524, 1525,
  1525, 1526, 1526, 1530, 1534, 1535, 1536, 1537, 1538, 1543, 1545, 1551, 1552,
  1554, 1554, 1555, 1562, 1565, 1580, 1580, 1582, 1584, 1585, 1585, 1585, 1586,
  1587, 1587, 1588, 1589, 1595, 1607, 1607, 1607, 1607, 1608, 1608, 1608, 1610,
  1610, 1610, 1612, 1613, 1658, 1658, 1659, 1659, 1660, 1660, 1660, 1660, 1682,
  1682, 1682, 1682, 1682, 1683, 1683, 1683, 1787, 1800, 1801, 1802, 1803, 1804,
  1805, 1806, 1807, 1808, 1809, 1814, 1815, 1816, 1817, 1820, 1820, 1820, 1822,
  1823, 1824, 1832, 1832, 1833, 1844, 1845, 1846, 1847, 1852, 1853, 1854, 1856,
  1860, 1862, 1862, 1863, 1864, 1865, 1866, 1867, 1867, 1867, 1880, 1880, 1880,
  1880, 1882, 1882, 1884, 1884, 1884, 1885, 1892, 1892, 1892,
];

const aargauPostalCodes = [
  4303, 4305, 4310, 4312, 4313, 4314, 4315, 4316, 4317, 4322, 4323, 4324, 4325,
  4332, 4333, 4334, 4663, 4665, 4800, 4802, 4803, 4805, 4812, 4813, 4814, 4852,
  4853, 4853, 4856, 5000, 5004, 5017, 5018, 5022, 5023, 5024, 5025, 5026, 5027,
  5028, 5032, 5033, 5034, 5035, 5036, 5037, 5040, 5042, 5043, 5044, 5046, 5046,
  5046, 5053, 5053, 5054, 5054, 5056, 5057, 5058, 5062, 5063, 5064, 5070, 5072,
  5073, 5074, 5075, 5076, 5077, 5078, 5079, 5080, 5082, 5083, 5084, 5085, 5102,
  5103, 5103, 5105, 5106, 5107, 5108, 5112, 5113, 5116, 5200, 5210, 5212, 5213,
  5222, 5223, 5224, 5224, 5224, 5225, 5233, 5234, 5235, 5236, 5237, 5242, 5242,
  5243, 5244, 5245, 5246, 5272, 5273, 5274, 5275, 5276, 5277, 5300, 5301, 5303,
  5304, 5305, 5306, 5312, 5313, 5314, 5315, 5316, 5316, 5317, 5318, 5322, 5323,
  5324, 5325, 5326, 5330, 5332, 5333, 5334, 5400, 5404, 5405, 5406, 5408, 5412,
  5413, 5415, 5415, 5415, 5416, 5417, 5420, 5423, 5425, 5426, 5430, 5432, 5436,
  5442, 5443, 5444, 5445, 5452, 5453, 5454, 5462, 5463, 5464, 5465, 5466, 5467,
  5502, 5503, 5504, 5505, 5506, 5507, 5512, 5522, 5524, 5524, 5525, 5600, 5600,
  5603, 5604, 5605, 5606, 5607, 5608, 5610, 5611, 5612, 5613, 5614, 5615, 5616,
  5617, 5618, 5619, 5619, 5620, 5621, 5622, 5623, 5624, 5624, 5625, 5626, 5627,
  5628, 5630, 5632, 5634, 5636, 5637, 5637, 5642, 5643, 5643, 5643, 5644, 5645,
  5645, 5646, 5647, 5702, 5703, 5704, 5705, 5706, 5707, 5708, 5712, 5722, 5723,
  5724, 5725, 5726, 5727, 5728, 5732, 5733, 5734, 5736, 5737, 5742, 5745, 6042,
  8109, 8905, 8905, 8916, 8917, 8918, 8919, 8956, 8957, 8962, 8964, 8965, 8966,
  8967,
];

const checkAccessDocumentation = (postalCode, parkingZoneNumber) => {
  if (
    postalCode?.startsWith("80") ||
    parkingZonesEnabledDocumentation?.includes(parkingZoneNumber) ||
    luzernPostalCodes.includes(parseInt(postalCode)) ||
    zurichZipCodes.includes(parseInt(postalCode)) ||
    vaudPostalCodes.includes(parseInt(postalCode)) ||
    aargauPostalCodes.includes(parseInt(postalCode))
  ) {
    return true;
  }
  return false;
};

export function ViolationsColumns({
  t,
  setShowPictures,
  setImages,
  setObjectionOpen,
  setObjection,
  generateDocumentation,
  cancelViolation,
  changeTicketLegalStatus,
  changeTicketDocumentLanguage,
  keycloak,
  manuallyPayTicket,
  generateReminderPaymentSlip,
  archiveTicket,
  archivedCellStyle,
  generatePaymentSlip,
  toogleModalForComment,
  setTicketNumberInCommentModal,
  setCommentDefaultValue,
  setEditTicketModal,
  setTicketForEdit,
  setOpenModalParkingZoneDetails,
  setSelectedParkingZoneId,
  setOpenModalOwnerDetails,
  setSelectedOwner,
  setFilter,
  filter,
  and,
  setZendeskDetailsModalOpen,
  setZendeskId,
  setDateModalOpen,
  refDate,
  setOpenLicensePlateDetails,
  setViolatorDetails,
  setOpenViolatorsAddressLocked,
  doneTicketManually,
  createOperationalDocument,
  setTicketAuditModal,
  setTicketForAudit,
  setOpenManualPaymentModal,
}) {
  const auth = useAuth();
  const [localArray, setLocalArray] = useState([...filter.filter.and]);
  const dispatch = useDispatch();
  const isSysAdmin = keycloak.hasRealmRole(ROLES.SYSADMIN, auth);
  const isViewer = keycloak.hasRealmRole(ROLES.PARKING_VIEWER, auth);


  const dropdownItemFinancialStatus = (row) => {
    switch (row.status) {
      case ticketStatus.PENDING:
        return (
            <>
              <DropdownItem
                  onClickAction={() => cancelViolation(row.id)}
                  translation={t("Violations.Menu.Cancel")}
              />
              {isSysAdmin && (
                  <DropdownItem
                      onClickAction={() => dispatch(changeTicketToDoneStatus(row.id))}
                      translation={t("Violations.Menu.Done")}
                  />
              )}
              {isSysAdmin && (
                  <DropdownItem
                      onClickAction={() => {
                        setTicketForEdit(row);
                        setOpenManualPaymentModal(true);
                      }}
                      translation={t("Violations.Menu.PayManually")}
                  />
              )}
            </>
        );
      case ticketStatus.ISSUED:
        return (
          <>
            <DropdownItem
              onClickAction={() => cancelViolation(row.id)}
              translation={t("Violations.Menu.Cancel")}
            />
            {isSysAdmin && (
                <DropdownItem
                    onClickAction={() => dispatch(changeTicketToDoneStatus(row.id))}
                    translation={t("Violations.Menu.Done")}
                />
            )}
            {isSysAdmin && (
              <DropdownItem
                onClickAction={() => {
                  setTicketForEdit(row);
                  setOpenManualPaymentModal(true);
                }}
                translation={t("Violations.Menu.PayManually")}
              />
            )}
          </>
        );
      case ticketStatus.PAID:
        // arhiviranje dodati na neki drugi nacin
        break;
      case ticketStatus.OVERDUE:
        return (
          <>
            <DropdownItem
              onClickAction={() => cancelViolation(row.id)}
              translation={t("Violations.Menu.Cancel")}
            />
            {isSysAdmin && (
              <DropdownItem
                onClickAction={() => dispatch(changeTicketToDoneStatus(row.id))}
                translation={t("Violations.Menu.Done")}
              />
            )}

            {isSysAdmin && (
              <DropdownItem
                onClickAction={() => {
                  setTicketForEdit(row);
                  setOpenManualPaymentModal(true);
                }}
                translation={t("Violations.Menu.PayManually")}
              />
            )}
          </>
        );
      case ticketStatus.DONE:
        return (
          <>
            <DropdownItem
              onClickAction={() => cancelViolation(row.id)}
              translation={t("Violations.Menu.Cancel")}
            />
            <DropdownItem
              onClickAction={() => dispatch(changeTicketToDoneStatus(row.id))}
              translation={t("Violations.Menu.Done")}
            />

            {isSysAdmin && (
              <DropdownItem
                onClickAction={() => {
                  setTicketForEdit(row);
                  setOpenManualPaymentModal(true);
                }}
                translation={t("Violations.Menu.PayManually")}
              />
            )}
          </>
        );
      case ticketStatus.STOPPED:
        // arhiviranje dodati na neki drugi nacin
        break;
      default:
        return null;
    }
  };
  const modifyArray = (array, name, value) => {
    const newArray = array.map((element) => {
      if (element.path === name) {
        element.value = value;
      }
      return element;
    });
    return newArray;
  };

  const paymanetPeriodExpired = (date, paymentPeriod) => {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + parseInt(paymentPeriod));
    if (newDate > new Date()) {
      return false;
    } else {
      return true;
    }
  };
  const reminderPeriodExpired = (reminderDeadline) => {
    if (!reminderDeadline) {
      return false;
    }
    const newDate = new Date();
    let remider = new Date(reminderDeadline);
    if (newDate < remider) {
      return false;
    }
    return true;
  };

  const modifyArrayForStatus = (array, name, value) => {
    const newArray = array.map((element) => {
      if (element.path === name) {
        if (value === "DEFAULT") {
          element.value = "";
          element.filter = "notnull";
        } else {
          element.value = value;
          element.filter = "eq";
        }
      }
      return element;
    });

    return newArray;
  };

  const modifyArrayForViolatorAddressStatus = (array, name, value) => {
    const newArray = array.map((element) => {
      if (element.path.startsWith("violatorAddress") || element.path==="id") {
        if (value === "DEFAULT") {
          element.value = "";
          element.path = "id";
          element.filter = "notnull";
        } else if (value === "NEW") {
          element.value = "";
          element.filter = "isnull";
          element.path = "violatorAddress";
        } else {
          element.value = value;
          element.filter = "eq";
          element.path = "violatorAddress.status";
        }
      }
        return element;
    });

    return newArray;
  };

  const { i18n } = useTranslation();


  const findEnumViolatorAddressStatusForLanguage = (value) => {
    if (i18n.language === "en") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("foreign".startsWith((value + "").toLowerCase())) {
        return "FOREIGN";
      }
      if ("determined".startsWith((value + "").toLowerCase())) {
        return "DETERMINED";
      }
      if ("undetermined".startsWith((value + "").toLowerCase())) {
        return "UNDETERMINED";
      }
      if ("unknown".startsWith((value + "").toLowerCase())) {
        return "UNKNOWN";
      }
      if ("new".startsWith((value + "").toLowerCase())) {
        return "NEW";
      }
      if ("requested".startsWith((value + "").toLowerCase())) {
        return "REQUESTED";
      }
      return "DEFAULT";
    }
    if (i18n.language === "de") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("ausländisch".startsWith((value + "").toLowerCase())) {
        return "FOREIGN";
      }
      if ("bestimmt".startsWith((value + "").toLowerCase())) {
        return "DETERMINED";
      }
      if ("unbestimmt".startsWith((value + "").toLowerCase())) {
        return "UNDETERMINED";
      }
      if ("unbekannt".startsWith((value + "").toLowerCase())) {
        return "UNKNOWN";
      }
      if ("neu".startsWith((value + "").toLowerCase())) {
        return "NEW";
      }
      if ("angefordert".startsWith((value + "").toLowerCase())) {
        return "REQUESTED";
      }
      return "DEFAULT";
    }
    if (i18n.language === "it") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("staniero".startsWith((value + "").toLowerCase())) {
        return "FOREIGN";
      }
      if ("determinato".startsWith((value + "").toLowerCase())) {
        return "DETERMINED";
      }
      if ("indeterminato".startsWith((value + "").toLowerCase())) {
        return "UNDETERMINED";
      }
      if ("sconosciuto".startsWith((value + "").toLowerCase())) {
        return "UNKNOWN";
      }
      if ("nuovo".startsWith((value + "").toLowerCase())) {
        return "NEW";
      }
      if ("richiesto".startsWith((value + "").toLowerCase())) {
        return "REQUESTED";
      }
      return "DEFAULT";
    }
    if (i18n.language === "fr") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("étranger".startsWith((value + "").toLowerCase())) {
        return "FOREIGN";
      }
      if ("déterminé".startsWith((value + "").toLowerCase())) {
        return "DETERMINED";
      }
      if ("indéterminé".startsWith((value + "").toLowerCase())) {
        return "UNDETERMINED";
      }
      if ("inconnu".startsWith((value + "").toLowerCase())) {
        return "UNKNOWN";
      }
      if ("nouveau".startsWith((value + "").toLowerCase())) {
        return "NEW";
      }
      if ("demandé".startsWith((value + "").toLowerCase())) {
        return "REQUESTED";
      }
      return "DEFAULT";
    }
  }
  const findEnumValueForLanguage = (value) => {
    if (i18n.language === "en") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("paid".startsWith((value + "").toLowerCase())) {
        return "PAID";
      }
      if ("overrdue".startsWith((value + "").toLowerCase())) {
        return "OVERDUE";
      }
      if ("canceled".startsWith((value + "").toLowerCase())) {
        return "STOPPED";
      }
      if ("due".startsWith((value + "").toLowerCase())) {
        return "ISSUED";
      }
      if ("downpaid".startsWith((value + "").toLowerCase())) {
        return "DOWNPAID";
      }
      if ("complited".startsWith((value + "").toLowerCase())) {
        return "DONE";
      }
      if ("new".startsWith((value + "").toLowerCase())) {
        return "PENDING";
      }
      return "DEFAULT";
    }
    if (i18n.language === "de") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("bezahlt".startsWith((value + "").toLowerCase())) {
        return "PAID";
      }
      if ("überfällig".startsWith((value + "").toLowerCase())) {
        return "OVERDUE";
      }
      if ("storniert".startsWith((value + "").toLowerCase())) {
        return "STOPPED";
      }
      if ("fällig".startsWith((value + "").toLowerCase())) {
        return "ISSUED";
      }
      if ("angezahlt".startsWith((value + "").toLowerCase())) {
        return "DOWNPAID";
      }
      if ("erledigt".startsWith((value + "").toLowerCase())) {
        return "DONE";
      }
      if ("neu".startsWith((value + "").toLowerCase())) {
        return "PENDING";
      }
      return "DEFAULT";
    }
    if (i18n.language === "fr") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("payée".startsWith((value + "").toLowerCase())) {
        return "PAID";
      }
      if ("überfällig".startsWith((value + "").toLowerCase())) {
        return "OVERDUE";
      }
      if ("annulée".startsWith((value + "").toLowerCase())) {
        return "STOPPED";
      }
      if ("échue".startsWith((value + "").toLowerCase())) {
        return "ISSUED";
      }
      if ("acompte".startsWith((value + "").toLowerCase())) {
        return "DOWNPAID";
      }
      if ("faite".startsWith((value + "").toLowerCase())) {
        return "DONE";
      }
      if ("nouveau".startsWith((value + "").toLowerCase())) {
        return "PENDING";
      }
      return "DEFAULT";
    }
    if (i18n.language === "it") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("pagato".startsWith((value + "").toLowerCase())) {
        return "PAID";
      }
      if ("scaduto".startsWith((value + "").toLowerCase())) {
        return "OVERDUE";
      }
      if ("annullato".startsWith((value + "").toLowerCase())) {
        return "STOPPED";
      }
      if ("dovuto".startsWith((value + "").toLowerCase())) {
        return "ISSUED";
      }
      if ("acconto pagato".startsWith((value + "").toLowerCase())) {
        return "DOWNPAID";
      }
      if ("evaso".startsWith((value + "").toLowerCase())) {
        return "DONE";
      }
      if ("nuovo".startsWith((value + "").toLowerCase())) {
        return "PENDING";
      }
      return "DEFAULT";
    }
  };

  const findEnumLegalStatusValueForLanguage = (value) => {
    if(value === "-"){
      return "NONE";
    }
    if (i18n.language === "en") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("reminded".startsWith((value + "").toLowerCase())) {
        return "REMINDED";
      }
      if ("sued".startsWith((value + "").toLowerCase())) {
        return "SUED";
      }
      if ("terminated".startsWith((value + "").toLowerCase())) {
        return "TERMINATED";
      }
      if ("invoice sent".startsWith((value + "").toLowerCase())) {
        return "INVOICE_SENT";
      }

      if ("Enforced".startsWith((value + "").toLowerCase())) {
        return "OPERATION_DOC_SENT";
      }
      if ("to sue".startsWith((value + "").toLowerCase())) {
        return "TO_SUE";
      }
      if ("to enforce".startsWith((value + "").toLowerCase())) {
        return "TO_BE_ENFORCED";
      }
      if ("penalty fee demanded".startsWith((value + "").toLowerCase())) {
        return "PENALTY_FEE_DEMANDED";
      }

      return "DEFAULT";
    }

    if (i18n.language === "de") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("gemahnt".startsWith((value + "").toLowerCase())) {
        return "REMINDED";
      }
      if ("verzeigt".startsWith((value + "").toLowerCase())) {
        return "SUED";
      }
      if ("abgeschlossen".startsWith((value + "").toLowerCase())) {
        return "TERMINATED";
      }
      if ("rg versendet".startsWith((value + "").toLowerCase())) {
        return "INVOICE_SENT";
      }

      if ("betrieben".startsWith((value + "").toLowerCase())) {
        return "OPERATION_DOC_SENT";
      }
      if ("zu verzeigen".startsWith((value + "").toLowerCase())) {
        return "TO_SUE";
      }
      if ("zu betreiben".startsWith((value + "").toLowerCase())) {
        return "TO_BE_ENFORCED";
      }
      if ("strafgebühr eingefordert".startsWith((value + "").toLowerCase())) {
        return "PENALTY_FEE_DEMANDED";
      }

      return "DEFAULT";
    }
    if (i18n.language === "fr") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("rappelée".startsWith((value + "").toLowerCase())) {
        return "REMINDED";
      }
      if ("dénoncée".startsWith((value + "").toLowerCase())) {
        return "SUED";
      }
      if ("fermé".startsWith((value + "").toLowerCase())) {
        return "TERMINATED";
      }
      if ("facture envoyée".startsWith((value + "").toLowerCase())) {
        return "INVOICE_SENT";
      }

      if (
        "poursuite en cours".startsWith((value + "").toLowerCase())
      ) {
        return "OPERATION_DOC_SENT";
      }
      if ("à dénoncer".startsWith((value + "").toLowerCase())) {
        return "TO_SUE";
      }
      if ("introduire poursuite".startsWith((value + "").toLowerCase())) {
        return "TO_BE_ENFORCED";
      }
      if ("pénalité demandée".startsWith((value + "").toLowerCase())) {
        return "PENALTY_FEE_DEMANDED";
      }

      return "DEFAULT";
    }
  };

  const findUserStatusEnumValueForLanguage = (value) => {
    if (i18n.language === "en") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("paid".startsWith((value + "").toLowerCase())) {
        return "PAID";
      }
      if ("open".startsWith((value + "").toLowerCase())) {
        return "OPEN";
      }
      if ("cancelled".startsWith((value + "").toLowerCase())) {
        return "CANCELLED";
      }
      if ("closed".startsWith((value + "").toLowerCase())) {
        return "CLOSED";
      }

      return "DEFAULT";
    }
    if (i18n.language === "de") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("bezahlt".startsWith((value + "").toLowerCase())) {
        return "PAID";
      }
      if ("offen".startsWith((value + "").toLowerCase())) {
        return "OPEN";
      }
      if ("storniert".startsWith((value + "").toLowerCase())) {
        return "CANCELLED";
      }
      if ("geschlossen".startsWith((value + "").toLowerCase())) {
        return "CLOSED";
      }

      return "DEFAULT";
    }
    if (i18n.language === "fr") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("paye".startsWith((value + "").toLowerCase())) {
        return "PAID";
      }
      if ("ouvert".startsWith((value + "").toLowerCase())) {
        return "OPEN";
      }
      if ("annule".startsWith((value + "").toLowerCase())) {
        return "CANCELLED";
      }
      if ("ferme".startsWith((value + "").toLowerCase())) {
        return "CLOSED";
      }

      return "DEFAULT";
    }
    if (i18n.language === "it") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("pagato".startsWith((value + "").toLowerCase())) {
        return "PAID";
      }
      if ("aperto".startsWith((value + "").toLowerCase())) {
        return "OPEN";
      }
      if ("annullato".startsWith((value + "").toLowerCase())) {
        return "CANCELLED";
      }
      if ("chiuso".startsWith((value + "").toLowerCase())) {
        return "CLOSED";
      }

      return "DEFAULT";
    }
  };

  const showLicensePlate = (row) => {
    if (row?.violatorAddress?.street) {
      return (
          <div
              onClick={isViewer? null : () => {
                setOpenViolatorsAddressLocked(true);
                setViolatorDetails(row);
              }}
              style={{
                color: "green",
                cursor: "pointer",
                fontWeight: "bold",
              }}
          >
            {row.licensePlate}
          </div>
      );
    }
    if (row?.violatorAddress?.locked) {
      return (
        <div
          onClick={isViewer? null : () => {
            setOpenViolatorsAddressLocked(true);
            setViolatorDetails(row);
          }}
          style={{
            color: "red",
            cursor: "pointer",
            fontWeight: "bold",
          }}
        >
          {row.licensePlate}
        </div>
      );
    }
    return (
      <div
        onClick={isViewer? null : () => {
          setOpenViolatorsAddressLocked(true);
          setViolatorDetails(row);
        }}
      >
        {row.licensePlate}
      </div>
    );
  };

  const refViolationNumber = useRef(null);
  const refParkingZone = useRef(null);
  const refLicensePlate = useRef(null);
  const refBeppoliceman = useRef(null);
  const refViolatorAddress = useRef(null);
  const refStatus = useRef(null);
  const refLegalStatus = useRef(null);
  const refUserStatus = useRef(null);
  const refPZName = useRef(null);


  const closeFilter = (ref, name) => {
    ref.current.value = "";

    const newArray = modifyArray([...filter.filter.and], name, "");
    setFilter({ ...filter, filter: { and: newArray } });
  };

  const closeFilterForStatus = (ref, name) => {
    ref.current.value = "";
    const enumValue = findEnumValueForLanguage("");

    const newArray = modifyArrayForStatus(localArray, name, enumValue);

    setFilter({ ...filter, filter: { and: newArray } });
  };
  const [columns, setColumns] = useState([
    {
      title: isViewer ? t("Columns.Viewer PZ number") : t("Columns.ParkingZone"),
      field: "parkingZone.parkingZoneNumber",
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      filterComponent: () => (
        <div style={{ marginTop: "12px" }} class="input-group">
          <SearchIcon closeFilter={closeFilter} />
          {refParkingZone?.current?.value !== "" && (
            <CloseIcon
              closeFilter={closeFilter}
              reference={refParkingZone}
              name={"parkingZone.parkingZoneNumber"}
            />
          )}

          <input
            ref={refParkingZone}
            name="parkingZone.parkingZoneNumber"
            className={Style.inputField}
            onChange={(e) => {
              const newArray = modifyArray(
                [...filter.filter.and],
                e.target.name,
                e.target.value
              );
              setFilter({ ...filter, filter: { and: newArray } });
            }}
          />
        </div>
      ),
      render: (row) => {
        return (
          <div
            style={{ color: isViewer ? "#000000" : "#005a92", cursor: isViewer ? "default" : "pointer" }}
            onClick={isViewer ? null : () => {
              setSelectedParkingZoneId(row?.parkingZone?.id);
              setOpenModalParkingZoneDetails(true);
            }}
          >
            {row.parkingZone?.parkingZoneNumber}
          </div>
        );
      },
    },
    {
      title: t("Columns.Viewer PZ name"),
      field: "pzName",
      hidden: isViewer? false : true,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      filterComponent: () => (
        <div style={{ marginTop: "12px" }} class="input-group">
          <SearchIcon closeFilter={closeFilter} />
          {refPZName?.current?.value !== "" && (
            <CloseIcon
              closeFilter={closeFilter}
              reference={refPZName}
              name={"pzName"}
            />
          )}
          <input
            name="pzName"
            ref={refPZName}
            className={Style.inputField}
            onChange={(e) => {
              const newArray = modifyArray(
                [...filter.filter.and],
                e.target.name,
                e.target.value.toUpperCase()
              );
              setFilter({ ...filter, filter: { and: newArray } });
            }}
          />
        </div>
      ),
      render: (row) => {
        return row.parkingZone?.parkingZoneName;
      },
    },
    {
      title: isViewer? t("Columns.Reference") : t("Columns.Violation Number"),
      field: "number",
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      filterComponent: () => (
        <div style={{ marginTop: "12px" }} class="input-group">
          <SearchIcon closeFilter={closeFilter} />
          {refViolationNumber?.current?.value !== "" && (
            <CloseIcon
              closeFilter={closeFilter}
              reference={refViolationNumber}
              name={"number"}
            />
          )}

          <input
            name="number"
            ref={refViolationNumber}
            className={Style.inputField}
            onChange={(e) => {
              const newArray = modifyArray(
                [...filter.filter.and],
                e.target.name,
                e.target.value
              );
              setFilter({ ...filter, filter: { and: newArray } });
            }}
          />
        </div>
      ),
    },
    {
      title: t("Columns.License Plate Number"),
      field: "licensePlate",
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      filterComponent: () => (
        <div style={{ marginTop: "12px" }} class="input-group">
          <SearchIcon closeFilter={closeFilter} />
          {refLicensePlate?.current?.value !== "" && (
            <CloseIcon
              closeFilter={closeFilter}
              reference={refLicensePlate}
              name={"licensePlate"}
            />
          )}
          <input
            name="licensePlate"
            ref={refLicensePlate}
            className={Style.inputField}
            onChange={(e) => {
              const newArray = modifyArray(
                [...filter.filter.and],
                e.target.name,
                e.target.value.toUpperCase()
              );
              setFilter({ ...filter, filter: { and: newArray } });
            }}
          />
        </div>
      ),
      render: (row) => {
        return showLicensePlate(row);
      },
    },
    {
      title: t("Columns.Address"),
      field: "violatorAddress.status",
      hidden: isViewer? true : false,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      filterComponent: () => (
          <div style={{ marginTop: "12px" }} class="input-group">
            <SearchIcon closeFilter={closeFilter} />
            {refViolatorAddress?.current?.value !== "" && (
                <CloseIcon
                    closeFilter={(ref,name)=>{
                        ref.current.value = "";
                        const array = [...filter.filter.and];
                        const newArray = array.map((element) => {
                          if (element.path.startsWith("violatorAddress") || element.path === "id") {
                            element.value = "";
                            element.filter= "notnull";
                            element.path = "id";
                          }
                          return element;
                        });
                        setFilter({ ...filter, filter: { and: newArray } });
                    }}
                    reference={refViolatorAddress}
                    name={"violatorAddress.status"}
                />
            )}
            <input
                name="violatorAddress.status"
                ref={refViolatorAddress}
                className={Style.inputField}
                onChange={(e) => {

                  const enumValue = findEnumViolatorAddressStatusForLanguage(e.target.value);
                  const newArray = modifyArrayForViolatorAddressStatus(
                      [...filter.filter.and],
                      e.target.name,
                      enumValue
                  );
                  setFilter({ ...filter, filter: { and: newArray } });
                }}
            />
          </div>
      ),
      render: (row) => {
        let value = "NEW";
        switch(row.violatorAddress?.status) {
          case "FOREIGN":
          case "UNDETERMINED":
          case "REQUESTED":
          case "UNKNOWN":
          case "DETERMINED":
            value = row.violatorAddress?.status;
            break;
          default:
            break;
        }
        return  <div
            style={{
              color: "gray",
              fontSize:"x-small"
            }}
        >
          {
            t(`ViolatorsAddressModal.Statuses.${value.toLowerCase()}`)
          }
        </div>
      },
    },
    {
      title: isViewer? t("Columns.Beppoliceman") : t("Columns.Beppo Officer"),
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      field: "reporter",
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,

      filterComponent: () => (
        <div style={{ marginTop: "12px" }} class="input-group">
          <SearchIcon closeFilter={closeFilter} />
          {refBeppoliceman?.current?.value !== "" && (
            <CloseIcon
              closeFilter={closeFilter}
              reference={refBeppoliceman}
              name={"reporterName"}
            />
          )}
          <input
            name="reporterName"
            ref={refBeppoliceman}
            className={Style.inputField}
            onChange={(e) => {
              const newArray = modifyArray(
                [...filter.filter.and],
                e.target.name,
                e.target.value
              );
              setFilter({ ...filter, filter: { and: newArray } });
            }}
          />
        </div>
      ),
      render: (row) => {
        return row.reporter?.beppoOfficerData ? (
          <div
            style={{ color: "#005a92", cursor: "pointer" }}
            onClick={() => {
              setOpenModalOwnerDetails(true);
              setSelectedOwner(row.reporter);
            }}
          >
            {row.reporter?.beppoOfficerData}
          </div>
        ) : (
          <div>{row.sharePUser}</div>
        );
      },
    },
    {
      title: t("Columns.Objections"),
      field: "complaintText",
      hidden: isViewer? true : false,
      headerStyle: headerStyle,
      filterComponent: () => null,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => {
        if (row.appeal) {
          return (
            <img
              src={Objection}
              style={{ cursor: "pointer", paddingLeft: 30 }}
              id="objection"
              alt="objection"
              height={20}
              onClick={() => {
                setObjectionOpen(true);
                setObjection(row.appeal);
              }}
            />
          );
        }
        return null;
      },
    },
    {
      title: t("Columns.Zendesk"),
      field: "zendeskIds",
      headerStyle: headerStyle,
      hidden: isSysAdmin ? false : true,
      filterComponent: () => null,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => {
        if (row.zendeskIds) {
          return row.zendeskIds.map((element) => {
            return (
              <div
                style={{ color: "#005a92", cursor: "pointer" }}
                onClick={() => {
                  setZendeskDetailsModalOpen(true);
                  setZendeskId(element);
                }}
              >
                {element}
              </div>
            );
          });
        }
        return null;
      },
    },
    {
      title: t("Columns.Comment"),
      field: "comment",
      headerStyle: headerStyle,
      hidden: isViewer? true : false,
      filterComponent: () => null,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => {
        if (row.comment) {
          return (
            <img
              src={Comment}
              style={{ cursor: "pointer", paddingLeft: 30 }}
              id="comment"
              alt="comment"
              height={20}
              onClick={() => {
                toogleModalForComment();
                setCommentDefaultValue(row.comment);
                setTicketNumberInCommentModal(row.number);
              }}
            />
          );
        }
        return null;
      },
    },
    {
      title: t("Columns.Images"),
      field: "scaledImageUrls",
      filterComponent: () => null,
      headerStyle: headerStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => {
        return (
          <AvatarGroup style={{ justifyContent: "left", cursor: "pointer" }}>
            {
              <Avatar
                alt="img"
                onClick={(e) => {
                  setShowPictures(true);
                  setImages(row.imageUrls);
                }}
                src={row.scaledImageUrls && row.scaledImageUrls[0]}
              ></Avatar>
            }
          </AvatarGroup>
        );
      },
    },
    ... keycloak.hasRealmRole(ROLES.PARKING_VIEWER, auth)?
    [{
          title: t("Columns.UserStatus"),

          field: "userStatus",
          headerStyle: headerStyle,
          filterCellStyle: filterStyle,
          cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
          render: (row) => (
            <div
              style={{ cursor: isViewer? "default" : "pointer" }}
              onClick={isViewer? null : () => {
                setTicketAuditModal(true);
                setTicketForAudit(row.number);
              }}
            >
              {switchUserStatus(t, row)}
            </div>
          ),

          filterComponent: () => (
            <div style={{ marginTop: "12px" }} class="input-group">
              <SearchIcon closeFilter={closeFilterForStatus} />
              {refUserStatus?.current?.value !== "" && (
                <CloseIcon
                  closeFilter={closeFilterForStatus}
                  reference={refUserStatus}
                  name={"userStatus"}
                />
              )}
              <input
                name="userStatus"
                ref={refUserStatus}
                className={Style.inputField}
                onChange={(e) => {
                  const enumValue = findUserStatusEnumValueForLanguage(
                    e.target.value
                  );

                  const newArray = modifyArrayForStatus(
                    localArray,
                    e.target.name,
                    enumValue
                  );
                  setFilter({ ...filter, filter: { and: newArray } });
                }}
              />
            </div>
          ),
        }]:[],

    ... !keycloak.hasRealmRole(ROLES.PARKING_VIEWER, auth)?
    [{
      title: t("Columns.Status"),
      field: "status",
      headerStyle: headerStyle,
      filterCellStyle: filterStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => switchStatus(t, row),
      filterComponent: () => (
        <div style={{ marginTop: "12px" }} class="input-group">
          <SearchIcon closeFilter={closeFilterForStatus} />
          {refStatus?.current?.value !== "" && (
            <CloseIcon
              closeFilter={closeFilterForStatus}
              reference={refStatus}
              name={"status"}
            />
          )}
          <input
            name="status"
            ref={refStatus}
            className={Style.inputField}
            onChange={(e) => {
              const enumValue = findEnumValueForLanguage(e.target.value);

              const newArray = modifyArrayForStatus(
                localArray,
                e.target.name,
                enumValue
              );

              setFilter({ ...filter, filter: { and: newArray } });
            }}
          />
        </div>
      ),
    }] : [],
    ... !keycloak.hasRealmRole(ROLES.PARKING_VIEWER, auth)?
    [{
      title: t("Columns.LegalStatus"),

      field: "legalStatus",
      headerStyle: headerStyle,
      filterCellStyle: filterStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setTicketAuditModal(true);
            setTicketForAudit(row.number);
          }}
        >
          {switchLegalStatus(t, row.legalStatus)}
        </div>
      ),

      filterComponent: () => (
        <div style={{ marginTop: "12px" }} class="input-group">
          <SearchIcon closeFilter={closeFilterForStatus} />
          {refLegalStatus?.current?.value !== "" && (
            <CloseIcon
              closeFilter={closeFilterForStatus}
              reference={refLegalStatus}
              name={"legalStatus"}
            />
          )}
          <input
            name="legalStatus"
            ref={refLegalStatus}
            className={Style.inputField}
            onChange={(e) => {
              const enumValue = findEnumLegalStatusValueForLanguage(
                e.target.value
              );

              const newArray = modifyArrayForStatus(
                localArray,
                e.target.name,
                enumValue
              );
              setFilter({ ...filter, filter: { and: newArray } });
            }}
          />
        </div>
      ),
    }]:[],
    {
      title: t("Columns.Due"),
      field: "date",
      headerStyle: headerStyle,
      filterCellStyle: filterStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => {
        return moment(row.date).format("DD.MM.YYYY HH:mm");
      },
      filterComponent: () => (
        <div style={{ marginTop: "12px" }} class="input-group">
          <SearchIcon closeFilter={closeFilter} />
          {refDate?.current?.value && (
            <CloseIcon
              closeFilter={closeFilter}
              reference={refDate}
              name={"date"}
            />
          )}
          <div
            name="date"
            ref={refDate}
            className={Style.inputField}
            onClick={() => {
              setDateModalOpen(true);
            }}
          >
            <p>{refDate?.current?.value && refDate?.current?.value}</p>
          </div>
        </div>
      ),
    },
    {
      title: t("Columns.IssueDate"),
      field: "documentSent",
      hidden: isViewer? true : false,
      headerStyle: headerStyle,
      filterCellStyle: filterStyle,
      filterComponent: () => null,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => {
        if (row.documentSentDate) {
          return (
            <span>{moment(row.documentSentDate).format("DD.MM.YYYY")}</span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: t("Columns.Actions"),
      headerStyle: headerStyle,
      hidden: isViewer? true : false,
      filterComponent: () => null,
      field: "actions",
      cellStyle: archivedCellStyle
        ? violationsActionsCellStyleDisabled
        : violationsActionsCellStyle,
      render: (row) => (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu
            size="sm"
            style={{ color: "white", backgroundColor: "rgb(0, 90, 140)", overflowY:"scroll", maxHeight:"65vh" }}
            popperConfig={{
              strategy: "fixed",
              modifiers:[
                {
                  name: 'preventOverflow',
                  enabled: true,
                  options: {
                    altAxis: true,
                    altBoundary: true,
                    tether: true,
                    rootBoundary: 'document',
                    padding: 0,
                  },
                },
              ]
            }}
          >
            {!row.archived ? (
              <>
                <DropdownItem
                  onClickAction={() => {
                    setTicketAuditModal(true);
                    setTicketForAudit(row.number);
                  }}
                  translation={t("AuditTicket.History")}
                />

                <Dropdown.Item disabled>
                  {t("Violations.Menu.Status")}
                </Dropdown.Item>
                {dropdownItemFinancialStatus(row)}
                <Dropdown.Divider />
                <Dropdown.Item disabled>
                  {t("Violations.Menu.LegalStatusMenuLabel")}
                </Dropdown.Item>
                {row.status === ticketStatus.STOPPED ? (
                  <Dropdown.Item disabled>-</Dropdown.Item>
                ) : (
                  <DropdownItem
                    onClickAction={() =>
                      changeTicketLegalStatus(row.id, ticketLegalStatus.NONE)
                    }
                    translation="-"
                  />
                )}

                {row.status === ticketStatus.STOPPED ? (
                  <Dropdown.Item disabled>
                    {t("Violations.Menu.InvoiceSent")}
                  </Dropdown.Item>
                ) : (
                  <DropdownItem
                    onClickAction={() =>
                      changeTicketLegalStatus(
                        row.id,
                        ticketLegalStatus.INVOICE_SENT
                      )
                    }
                    translation={t("Violations.Menu.InvoiceSent")}
                  />
                )}

                {row.status === ticketStatus.STOPPED ? (
                  <Dropdown.Item disabled>
                    {t("Violations.Menu.Remind")}
                  </Dropdown.Item>
                ) : (
                  <DropdownItem
                    onClickAction={() =>
                      changeTicketLegalStatus(
                        row.id,
                        ticketLegalStatus.REMINDED
                      )
                    }
                    translation={t("Violations.Menu.Remind")}
                  />
                )}

                {row.status === ticketStatus.STOPPED ? (
                  <Dropdown.Item disabled>
                    {t("Violations.Menu.ToSue")}
                  </Dropdown.Item>
                ) : (
                  <DropdownItem
                    onClickAction={() =>
                      changeTicketLegalStatus(row.id, ticketLegalStatus.TO_SUE)
                    }
                    translation={t("Violations.Menu.ToSue")}
                  />
                )}

                {row.status === ticketStatus.STOPPED ? (
                  <Dropdown.Item disabled>
                    {t("Violations.Menu.Sued")}
                  </Dropdown.Item>
                ) : (
                  <DropdownItem
                    onClickAction={() =>
                      changeTicketLegalStatus(row.id, ticketLegalStatus.SUED)
                    }
                    translation={t("Violations.Menu.Sued")}
                  />
                )}
                <DropdownItem
                  disabled={row.status === ticketStatus.STOPPED}
                  onClickAction={() =>
                    changeTicketLegalStatus(
                      row.id,
                      ticketLegalStatus.TO_BE_ENFORCED
                    )
                  }
                  translation={t("Violations.Menu.ToBeEnforced")}
                />
                {row.status === ticketStatus.STOPPED ? (
                  <Dropdown.Item disabled>
                    {t("Violations.Menu.OperationDocSent")}
                  </Dropdown.Item>
                ) : (
                  <DropdownItem
                    onClickAction={() =>
                      changeTicketLegalStatus(
                        row.id,
                        ticketLegalStatus.OPERATION_DOC_SENT
                      )
                    }
                    translation={t("Violations.Menu.OperationDocSent")}
                  />
                )}
                <DropdownItem
                  disabled={row.status === ticketStatus.STOPPED}
                  onClickAction={() =>
                    changeTicketLegalStatus(
                      row.id,
                      ticketLegalStatus.PENALTY_FEE_DEMANDED
                    )
                  }
                  translation={t("Violations.Menu.PenaltyFeeDemanded")}
                />

                <DropdownItem
                  disabled={row.status == ticketStatus.STOPPED}
                  onClickAction={() =>
                    changeTicketLegalStatus(
                      row.id,
                      ticketLegalStatus.TERMINATED
                    )
                  }
                  translation={t("Violations.Menu.Close")}
                />
                {((row.status === ticketStatus.OVERDUE ||
                  row.status === ticketStatus.DONE ||
                    row.status === ticketStatus.CHECK &&
                    paymanetPeriodExpired(row.date, row.paymentPeriod))
                  || row.status === ticketStatus.DOWNPAID
                ) && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.ItemText
                      style={{
                        color: "white",
                      }}
                    >
                      {t("Violations.Menu.DocumentLanguage")}
                      <select
                        type="text"
                        name={`docLang`}
                        style={{ marginLeft: "10px", borderRadius: "5px" }}
                        onChange={(e) => {
                          changeTicketDocumentLanguage(
                            row.number,
                            e.target.value
                          );
                        }}
                        value={
                          row.documentLanguage ? row.documentLanguage : "de"
                        }
                        defaultValue={"de"}
                      >
                        <option value="de" key={1}>
                          {"DE"}
                        </option>
                        <option value="fr" key={2}>
                          {"FR"}
                        </option>
                        <option value="it" key={3}>
                          {"IT"}
                        </option>
                      </select>
                    </Dropdown.ItemText>
                    <Dropdown.Divider style={{ opacity: 0.2 }} />
                    <DropdownItem
                      onClickAction={() => {
                        generateReminderPaymentSlip(
                          row.number,
                          row.licensePlate
                        );
                      }}
                      translation={t("Violations.Menu.CreateReminder")}
                    />
                  </>
                )}
                {(row.status === ticketStatus.ISSUED ||
                  row.status === ticketStatus.PENDING) && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.ItemText
                      style={{
                        color: "white",
                      }}
                    >
                      {t("Violations.Menu.DocumentLanguage")}
                      <select
                        type="text"
                        name={`docLang`}
                        style={{ marginLeft: "10px", borderRadius: "5px" }}
                        onChange={(e) => {
                          changeTicketDocumentLanguage(
                            row.number,
                            e.target.value
                          );
                        }}
                        value={
                          row.documentLanguage ? row.documentLanguage : "de"
                        }
                        defaultValue={"de"}
                      >
                        <option value="de" key={1}>
                          {"DE"}
                        </option>
                        <option value="fr" key={2}>
                          {"FR"}
                        </option>
                        <option value="it" key={3}>
                          {"IT"}
                        </option>
                      </select>
                    </Dropdown.ItemText>
                    <Dropdown.Divider style={{ opacity: 0.2 }} />
                    <DropdownItem
                      onClickAction={() => {
                        generatePaymentSlip(row.number, row.licensePlate);
                      }}
                      translation={t("Violations.Menu.CreatePaymentSlip")}
                    />
                  </>
                )}

                {((row.status === ticketStatus.OVERDUE ||
                  row.status === ticketStatus.DONE ||
                  ((row.status === ticketStatus.DOWNPAID ||
                    row.status === ticketStatus.CHECK) &&
                    reminderPeriodExpired(row.reminderDeadline)))
                || row.status === ticketStatus.PENDING) && (
                  <>
                    {checkAccessDocumentation(
                      row.parkingZone?.postalCode,
                      row.parkingZone?.parkingZoneNumber
                    ) && (
                      <>
                        <DropdownItem
                          onClickAction={() => {
                            generateDocumentation(
                              row.number,
                              row.licensePlate,
                              row.parkingZone?.postalCode
                            );
                          }}
                          translation={t("Violations.Menu.CreateDocumentation")}
                        />
                      </>
                    )}
                    <DropdownItem
                        onClickAction={() => {
                          createOperationalDocument(
                              row.number,
                              row.licensePlate
                          );
                        }}
                        translation={t(
                            "Violations.Menu.CreateOperationalDocument"
                        )}
                    />
                  </>
                )}
                <Dropdown.Divider />
                <DropdownItem
                  onClickAction={() => {
                    toogleModalForComment();
                    setCommentDefaultValue(row.comment);
                    setTicketNumberInCommentModal(row.number);
                  }}
                  translation={t("Violations.Menu.Comment")}
                />
                {((row.legalStatus === ticketLegalStatus.TERMINATED &&
                  row.status === ticketStatus.PAID) ||
                  (row.legalStatus === ticketLegalStatus.TERMINATED &&
                    row.status === ticketStatus.STOPPED)) && (
                  <>
                    <Dropdown.Divider />
                    <DropdownItem
                      onClickAction={() => {
                        archiveTicket(row.number);
                      }}
                      translation={t("Violations.archive")}
                    />
                  </>
                )}
                {isSysAdmin && (
                  <>
                    <Dropdown.Divider />
                    <DropdownItem
                      onClickAction={() => {
                        setEditTicketModal(true);
                        setTicketForEdit(row);
                      }}
                      translation={t("Violations.editTicket")}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {((row.legalStatus === ticketLegalStatus.TERMINATED &&
                  row.status === ticketStatus.PAID) ||
                  (row.legalStatus === ticketLegalStatus.TERMINATED &&
                    row.status === ticketStatus.STOPPED)) && (
                  <>
                    <DropdownItem
                      onClickAction={() => {
                        archiveTicket(row.number);
                      }}
                      translation={t("Violations.unarchive")}
                    />
                  </>
                )}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ]);

  return columns;
}
