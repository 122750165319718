import {useEffect} from "react";
import {isAllowed} from "./isAllowed";
import {useAuth} from "react-oidc-context";

export function AuthorizedElement(
    {
        roles,
        children,
    }
) {
    const auth = useAuth();
    const { isLoading, user,isAuthenticated, signinRedirect } = useAuth();

    useEffect(() => {
        if (!isLoading && !isAuthenticated && user === null) {
            signinRedirect({redirect_uri: window.location.href});
        }
    }, [isLoading, isAuthenticated, signinRedirect, user])

    return isAllowed(roles, auth) ? children : null;
}
