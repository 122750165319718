import {
  ADD_VIEWER,
  CHECK_VIEWERS_EMAIL,
  CLEAR_VIEWER_DATA,
  CLOSE_DUPLICATE_VIEWER_MODAL,
  DISABLE_VIEWER,
  DUPLICATE_VIEWER,
  EDIT_VIEWER,
  GET_ALL_VIEWERS_BY_ADMIN_ID,
  GET_ALL_VIEWERS_FOR_SYSADMIN,
  REMOVE_VIEWER_FROM_PZ_ADMINS_VIEWER_LIST,
  USER_VIEWER_WITH_GIVEN_EMAIL_NOT_FOUND,
} from "../actions/types";

const initialState = {
  viewers: [],
  duplicateViewer: false,
  viewer: null,
};

const viewerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_VIEWERS_BY_ADMIN_ID:
      return {
        ...state,
        viewers: action.payload,
        viewer: null,
      };
    case GET_ALL_VIEWERS_FOR_SYSADMIN:
      return {
        ...state,
        viewers: action.payload,
        viewer: null,
      };
    case ADD_VIEWER:
      return {
        ...state,
        viewers: state.viewers.reverse().concat(action.payload).reverse(),
        viewer: null,
      };

    case DUPLICATE_VIEWER:
      return {
        ...state,
        duplicateViewer: true,
        viewer: null,
      };
    case CLOSE_DUPLICATE_VIEWER_MODAL:
      return {
        ...state,
        duplicateViewer: false,
        viewer: null,
      };
    case CHECK_VIEWERS_EMAIL:
      return {
        ...state,
        viewer: action.payload,
        duplicateViewer: false,
      };
    case USER_VIEWER_WITH_GIVEN_EMAIL_NOT_FOUND:
      return {
        ...state,
        viewer: null,
        duplicateViewer: false,
      };
    case CLEAR_VIEWER_DATA:
      return {
        ...state,
        viewer: null,
        duplicateViewer: false,
      };
    case EDIT_VIEWER:
      return {
        ...state,
        viewers: state.viewers.map((a) =>
          a.id === action.payload.id ? action.payload : a
        ),
      };
    case DISABLE_VIEWER:
      return {
        ...state,
        viewers: state.viewers.filter(
          (m) => m.emailAddress !== action.payload
        ),
      };
    case REMOVE_VIEWER_FROM_PZ_ADMINS_VIEWER_LIST:
      return {
        ...state,
        viewers: state.viewers.filter(
          (m) => m.emailAddress !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default viewerReducer;
