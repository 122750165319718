import {
  APPROVE_GAS_STATION_OWNER,
  APPROVE_OWNER,
  ARCHIVE_OWNER,
  GET_ALL_OWNERS,
  LAST_DATE_REQUEST,
} from "../actions/types";

const initialState = {
  owners: [],
  gasStationOwners: [],
  lastDate: "",
  refreshPage: false,
};

const ownerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_OWNERS:
      return {
        ...state,
        owners: action.payload,
      };
    case APPROVE_OWNER:
      return {
        ...state,
        owners: state.owners,
      };
    case APPROVE_GAS_STATION_OWNER:
      return {
        ...state,
        gasStationOwners: state.owners,
      };

    case LAST_DATE_REQUEST:
      return {
        ...state,
        lastDate: action.payload,
        refreshPage:
          state.lastDate !== "" && state.lastDate !== action.payload
            ? true
            : false,
      };
    case ARCHIVE_OWNER:
      return {
        ...state,
        owners: state.owners.filter((a) => a.id !== action.payload),
      };
    default:
      return state;
  }
};

export default ownerReducer;
