import { GET_GAS_STATION_OWNERSHIO_REQUEST_BY_ID } from "../actions/types";

const initialState = {
  gasStationOwnershipRequest: {},
};

const gasStationOwnershipRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GAS_STATION_OWNERSHIO_REQUEST_BY_ID:
      return {
        ...state,
        gasStationOwnershipRequest: action.payload,
      };

    default:
      return state;
  }
};

export default gasStationOwnershipRequestsReducer;
