import MaterialTable from "@material-table/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAuth} from "react-oidc-context";
import {useDispatch, useSelector} from "react-redux";
import {getAllClients,} from "../../actions/client";
import {ClientColumns} from "../../columns/clientColumns";
import MagnifyingGlass from "../../common/assets/magnifying-glass.svg";
import {ROLES} from "../../consts";

import keycloak from "../../keycloak";
import LoadingIndicator from "../../reusable/loadingIndicator";
import {optionsGeneral} from "../../reusable/optionsForTables";
import Pagination from "../../reusable/pagination";

import {
    materialTableStyle,
    paginationStyle,
    toolbarStyles,
    useStyles,
    useStylesForMaterial,
} from "../../reusable/styles";
import Sidebar from "../../sidebar/sidebar";
import AddClientModal from "./addClientModal"
import Style from "./client.module.css";
import ClientDetailsModal from "./clientDetailsModal"
import EditClientModal from "./editClientModal"


export default function Client() {
    const auth = useAuth();
    const classesForm = toolbarStyles();
    const { t } = useTranslation();
    const classesMaterial = useStylesForMaterial();
    const classes = useStyles();
    const { isLoading } = useSelector((state) => state.loader);
    const [height, setHeight] = useState(window.innerHeight);
    const dispatch = useDispatch();
    const handleResize = () => {
        setHeight(window.innerHeight);
    };
    const { clients, client } = useSelector((state) => state.client);
    const [clientAddModalOpen, setClientAddModalOpen] = useState(false);
    const [clientEditModalOpen, setClientEditModalOpen] = useState(false);
    const [clientDetailsModalOpen, setClientDetailsModalOpen] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState("");


    useEffect(() => {
        if (keycloak.hasRealmRole(ROLES.SYSADMIN, auth)) {
          dispatch(getAllClients());
        }
    }, [dispatch]);

    useEffect(() => {
      window.addEventListener("resize", handleResize, false);
    }, []);

    const toolbar = (props) => {
      return (
            <div className={classesForm.root}>
              <Grid container direction="row" className={Style.header}>
                <p className={Style.header__text}>{t("Clients.TableTitle")}</p>

                {(keycloak.hasRealmRole(ROLES.SYSADMIN, auth)) && (
                  <button
                    className="addEntityButton"
                    onClick={() => {
                      setClientAddModalOpen(true);
                    }}
                  >
                    <p className={Style.header__addButtonText}>
                      {t("Clients.AddNewClient")}
                    </p>
                  </button>
                )}
              </Grid>
            </div>
          );
    };
    const pagination = (props) => {
        return (
          <td style={paginationStyle}>
            <Pagination translate={t} {...props} classes={{ ...classesMaterial }} />
          </td>
        );
    };

    const openClientEditModal = () => {
        setClientEditModalOpen(true);
        setClientDetailsModalOpen(false);
    };

    return (
        <div className={classes.root}>
            <Sidebar />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth={false} className={classes.container}>
                    {isLoading && <LoadingIndicator />}
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div className="text-center">
                                <div className="row content">
                                    <div className={classes.paper}>
                                        <MaterialTable
                                            icons={{
                                              Filter: () => (
                                                <img src={MagnifyingGlass} alt="search" />
                                              ),
                                            }}
                                            searchPlaceholder="Test"
                                            title={t("Clients.TableTitle")}
                                            style={materialTableStyle}
                                            data={clients?clients:[]}
                                            columns={ClientColumns({
                                              t,
                                              setSelectedClientId,
                                              setClientDetailsModalOpen
                                            })}
                                            options={optionsGeneral(height - 240, 44)}
                                            components={{
                                              Toolbar: (props) => toolbar(props),
                                              Pagination: (props) => pagination(props),
                                            }}
                                          />
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </main>

            {clientDetailsModalOpen && (
                    <ClientDetailsModal
                      open={clientDetailsModalOpen}
                      onClose={() => setClientDetailsModalOpen(false)}
                      clientId={selectedClientId}
                      openClientEditModal={openClientEditModal}
                    />
                  )}

            {clientAddModalOpen && (
                <AddClientModal
                  open={clientAddModalOpen}
                  closeOnEsc
                  onClose={() => setClientAddModalOpen(false)}
                  title={t("Clients.CreateClient")}
                />
            )}

            {clientEditModalOpen && (
                    <EditClientModal
                      open={clientEditModalOpen}
                      closeOnEsc
                      onClose={() => setClientEditModalOpen(false)}
                    />
                  )}
        </div>

    );

}
