import { SET_LOADER, GET_PARKING_ZONE_OWNERSHIO_REQUEST_BY_ID } from "./types";
import { apiService } from "../utils/serviceApi";

export const getParkingZoneOwnershipRequestById =
  (requestId) => async (dispatch) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    apiService
      .get(
        `${process.env.REACT_APP_SERVICE_URL}/api/parking-zone-ownership/${requestId}`
      )
      .then((res) => {
        dispatch({
          type: GET_PARKING_ZONE_OWNERSHIO_REQUEST_BY_ID,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };
