
const keycloak = {
    hasRealmRole: (role, auth) => {
        if(!auth.isAuthenticated) {
            return false;
        }
        if(auth.user) {
            if(auth.user?.profile?.roles){
                return auth.user?.profile?.roles.includes(role);
            } else {
                return false;
            }
        }
        else {
            return false;
    }
}}
export default keycloak;
