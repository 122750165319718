import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { getUserDeleteRequestById } from "../actions/userDeleteRequest";
import BlueBeppolicemanRequest from "../common/assets/blueBeppolicemanRequest.svg";
import RedTCR from "../common/assets/notSeenTicketCancellationRequest.svg";
import RedBeppolicemanRequest from "../common/assets/redBeppolicemanRequest.svg";
import Trash from "../common/assets/icons8-trash.svg";
import BlueTCR from "../common/assets/seenTicketCancellationRequest.svg";
import { requestType } from "../globals";
import { cellStyle, filterStyle, headerStyle } from "./columnsGeneral";

export function RequestsColumns(
  t,
  setTicketCancelationRequestViewModalOpen,
  setTicketFeeCancelationRequestViewModalOpen,
  markRequestAsSeen,
  getTicketCancellationRequestById,
  setParkingZoneOwnershipRequestViewModalOpen,
  getParkingZoneOwnershipRequestById,
  setDeleteUserRequestViewModalOpen,
  setGasStationOwnershipRequestViewModalOpen,
  getGasStationOwnershipRequestById,
  getTicketFeeCancellationRequestById
) {
  const dispatch = useDispatch();

  const viewRequest = async (row) => {
    switch (row.type) {
      case requestType.TICKET_CANCELLATION_REQUEST:
        await dispatch(getTicketCancellationRequestById(row.id));
        await dispatch(markRequestAsSeen(row.id, row.type));
        setTicketCancelationRequestViewModalOpen(true);
        break;
      case requestType.TICKET_FEE_CANCELLATION_REQUEST:
        await dispatch(getTicketFeeCancellationRequestById(row.id));
        await dispatch(markRequestAsSeen(row.id, row.type));
        setTicketFeeCancelationRequestViewModalOpen(true);
        break;
      case requestType.PARKING_ZONE_OWNERSHIP_REQUEST:
        await dispatch(getParkingZoneOwnershipRequestById(row.id));
        await dispatch(markRequestAsSeen(row.id, row.type));
        setParkingZoneOwnershipRequestViewModalOpen(true);
        break;
      case requestType.DELETE_USER_REQUEST:
        await dispatch(getUserDeleteRequestById(row.id));
        await dispatch(markRequestAsSeen(row.id, row.type));
        setDeleteUserRequestViewModalOpen(true);
        break;
      case requestType.GAS_STATION_OWNERSHIP_REQUEST:
        await dispatch(getGasStationOwnershipRequestById(row.id));
        await dispatch(markRequestAsSeen(row.id, row.type));
        setGasStationOwnershipRequestViewModalOpen(true);
        break;
      default:
        break;
    }
  };

  const newRequestStyle = {
    cursor: "pointer",
    fontWeight: "bold",
  };
  const seenRequestStyle = {
    cursor: "pointer",
  };

  return [
    {
      title: t("Columns.RequestType"),
      cellStyle: { ...cellStyle },
      width: "3%",
      headerStyle: headerStyle,
      render: (row) => {
        switch (row.type) {
          case requestType.TICKET_CANCELLATION_REQUEST:
            return row.seen ? (
              <img src={BlueTCR} alt="ticketCancellationBlue" />
            ) : (
              <img src={RedTCR} alt="ticketCancellationRed" />
            );
          case requestType.TICKET_FEE_CANCELLATION_REQUEST:
            return row.seen ? (
              <img src={BlueTCR} alt="ticketCancellationBlue" />
            ) : (
              <img src={RedTCR} alt="ticketCancellationRed" />
            );
          case requestType.PARKING_ZONE_OWNERSHIP_REQUEST:
            return row.seen ? (
              <img src={BlueBeppolicemanRequest} alt="beppolicemanBlue" />
            ) : (
              <img src={RedBeppolicemanRequest} alt="beppolicemanBlueRed" />
            );
          case requestType.GAS_STATION_OWNERSHIP_REQUEST:
            return row.seen ? (
              <img src={BlueBeppolicemanRequest} alt="beppolicemanBlue" />
            ) : (
              <img src={RedBeppolicemanRequest} alt="beppolicemanBlueRed" />
            );
          case requestType.DELETE_USER_REQUEST:
            return row.seen ? (
              <img style={{ color: "blue" }} src={Trash} alt="trash" />
            ) : (
              <img style={{ color: "blue" }} src={Trash} alt="trash" />
            );
          default:
            return null;
        }
      },
    },
    {
      title: t("Columns.RequestText"),
      field: "message",
      filterCellStyle: filterStyle,
      cellStyle: {
        ...cellStyle,
      },
      width: "70%",
      headerStyle: headerStyle,
      render: (row) => (
        <div
          onClick={() => viewRequest(row)}
          style={row.seen ? seenRequestStyle : newRequestStyle}
        >
          {row.type === requestType.TICKET_CANCELLATION_REQUEST &&
            row.parkingZoneNumber != 0 &&
            t("Requests.ticketCancelationMessagePZ", {
              fullname: row.fullName,
              ticketNumber: row.ticketNumber,
            })}
          {row.type === requestType.TICKET_CANCELLATION_REQUEST &&
            row.gasStationNumber != 0 &&
            t("Requests.ticketCancelationMessageGS", {
              fullname: row.fullName,
              ticketNumber: row.ticketNumber,
            })}
          {row.type === requestType.TICKET_FEE_CANCELLATION_REQUEST &&
            t("Requests.ticketFeeCancelationMessage", {
              fullname: row.fullName,
              ticketNumber: row.ticketNumber,
            })}
          {row.type === requestType.DELETE_USER_REQUEST &&
            t("Requests.deleteUserMessage", {
              fullname: row.fullName,
            })}
          {row.type === requestType.PARKING_ZONE_OWNERSHIP_REQUEST &&
            t("Requests.parkingZoneOwnershipMessage", {
              fullname: row.fullName,
              parkingZoneNumber: row.parkingZoneNumber,
            })}

          {row.type === requestType.GAS_STATION_OWNERSHIP_REQUEST &&
            t("Requests.gasStationOwnershipMessage", {
              fullname: row.fullName,
              parkingZoneNumber: row.gasStationNumber,
            })}
        </div>
      ),
    },

    {
      title: t("Columns.RequestDate"),
      field: "createdOn",
      filterCellStyle: filterStyle,
      cellStyle: { ...cellStyle },
      width: "10%",
      headerStyle: headerStyle,
      render: (row) => {
        return moment(row.createdOn).format("DD.MM.YYYY");
      },
      customFilterAndSearch: (searchValue, row) => {
        if (
          moment(row.createdOn)
            .format("DD.MM.YYYY")
            .toString()
            .includes(searchValue)
        ) {
          return true;
        }
      },
    },

    {
      title: t("Columns.RequestTime"),
      field: "createdOn",
      cellStyle: { ...cellStyle },
      filterCellStyle: filterStyle,
      width: "10%",
      headerStyle: headerStyle,
      render: (row) => {
        return moment(row.createdOn).format("HH:mm");
      },
      customFilterAndSearch: (searchValue, row) => {
        if (
          moment(row.createdOn).format("HH:mm").toString().includes(searchValue)
        ) {
          return true;
        }
      },
    },
  ];
}
