import React from "react";
import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import { wideModalStyle } from "../../reusable/styles";
import DatePicker, { registerLocale } from "react-datepicker";
import { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Style from "./dateModal.module.css";
import de from "date-fns/locale/de";
registerLocale("de", de);

export default function DateModal(props) {
  const [startDate, setStartDate] = useState(new Date());
  const { t } = useTranslation();

  const modifyArray = (array, name, value) => {
    const newArray = array.map((element) => {
      if (element.path === name) {
        element.value = value;
      }
      return element;
    });
    return newArray;
  };
  return (
    <Modal
      open={props.open}
      closeOnEs={true}
      onClose={props.close}
      center
      styles={wideModalStyle}
    >
      <form>
        <div className={Style.formFilter}>
          <div className={Style.filterWrapper}>
            <p className={Style.title}>{t("DateModal.FilterByDate")}</p>
            <div className={Style.datePickerPosition}>
              <DatePicker
                selected={startDate}
                locale="de"
                onChange={(date) => {
                  const newArray = modifyArray(
                    [...props.filter.filter.and],
                    "date",
                    moment(date).format("yyyy-MM-DD")
                  );
                  props.setFilter({
                    ...props.filter,
                    filter: { and: newArray },
                  });

                  props.close();
                  props.refDate.current.value =
                    moment(date).format("yyyy-MM-DD");
                }}
              />
            </div>
          </div>
          <div className={Style.filterWrapper}>
            <p className={Style.title}>{t("DateModal.FilterByMonth")}</p>
            <div className={Style.datePickerPosition}>
              <DatePicker
                selected={startDate}
                locale="de"
                onChange={(date) => {
                  const newArray = modifyArray(
                    [...props.filter.filter.and],
                    "date",
                    moment(date).format("yyyy-MM")
                  );
                  props.setFilter({
                    ...props.filter,
                    filter: { and: newArray },
                  });

                  props.close();
                  props.refDate.current.value = moment(date).format("yyyy-MM");
                }}
                dateFormat="yyyy-MM"
                showMonthYearPicker
                showFullMonthYearPicker
                showTwoColumnMonthYearPicker
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
