import {
  createErrorNotification,
  createSimpleSuccessNotification,
  createWarningNotification
} from "../common/notifications/notification";
import {apiService} from "../utils/serviceApi";
import {
  ADD_CLIENT,
  CLEAR_CLIENT_FOR_DETAILS,
  CLEAR_CLIENT_FOR_UPDATE,
  GET_ALL_CLIENTS,
  GET_CLIENT_FOR_UPDATE,
  SET_LOADER,
  UPDATE_CLIENT,
} from "./types";


export const getAllClients = () => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(`${process.env.REACT_APP_SERVICE_URL}/api/clients`)
    .then((res) => {
      dispatch({
        type: GET_ALL_CLIENTS,
        payload: res?.data?.content,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const addClient = (data, closeModal) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .post(`${process.env.REACT_APP_SERVICE_URL}/api/clients`, data)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: ADD_CLIENT,
          payload: res.data,
        });
        createSimpleSuccessNotification();
        closeModal();
      } else {
        createWarningNotification("Technical message: " + res?.data?.errors.map(JSON.stringify), "Error while adding client!")
      }
    })
    .catch((e) => {
      console.error(e);
      createErrorNotification("Technical message: " + e?.response?.data?.error, "Error while adding client!")
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const getClient = (id) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(`${process.env.REACT_APP_SERVICE_URL}/api/clients/${id}`)
    .then((res) => {
      dispatch({
        type: GET_CLIENT_FOR_UPDATE,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const editClient = (clientId, client, closeModal) => (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .put(
      `${process.env.REACT_APP_SERVICE_URL}/api/clients/${clientId}`,
      client
    )
    .then((res) => {
      dispatch({
        type: UPDATE_CLIENT,
        payload: res.data,
      });
      createSimpleSuccessNotification();
      closeModal();
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const clearClientForUpdate = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CLIENT_FOR_UPDATE,
  });
};

export const clearClientForDetails = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CLIENT_FOR_DETAILS,
  });
};