import { NewCloseIconButton } from "../common/closeIconButton/newCloseIcon";
import { cellStyle, filterStyle, headerStyle } from "./columnsGeneral";

export function WhitelistColumns(t, openDeleteConfirmationModal) {
  return [
    {
      title: t("Columns.License Plate"),
      field: "plateNumber",
      cellStyle: cellStyle,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Name"),
      field: "name",
      cellStyle: cellStyle,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Company"),
      field: "company",
      cellStyle: cellStyle,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Phone"),
      field: "phoneNumber",
      cellStyle: cellStyle,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
    },

    {
      title: t("Columns.Delete"),
      headerStyle: {
        ...headerStyle,
        textAlign: "center",
      },
      filterComponent: () => null,
      cellStyle: {
        ...cellStyle,
      },

      render: (row) => (
        <NewCloseIconButton
          onClick={() => {
            openDeleteConfirmationModal(row.id);
          }}
        />
      ),
    },
  ];
}
