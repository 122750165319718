import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Sidebar from "../../sidebar/sidebar";
import MaterialTable from "@material-table/core";
import {
  useStyles,
  useStylesForMaterial,
  toolbarStyles,
  materialTableStyle,
  paginationStyle,
} from "../../reusable/styles";
import { useTranslation } from "react-i18next";
import Pagination from "../../reusable/pagination";
import { useDispatch, useSelector } from "react-redux";
import { optionsGeneral } from "../../reusable/optionsForTables";
import LoadingIndicator from "../../reusable/loadingIndicator";
import {
  disableUserBySysadmin,
  enableUserBySysadmin,
  getAllDisabledUsers,
  getAllUsers, requestPasswordResetBySysadmin,
} from "../../actions/users";
import { changeTabInSidebar } from "../../actions/sidebar";
import Style from "./users.module.css";
import { UsersColumns } from "../../columns/userColumns";
import MagnifyingGlass from "../../common/assets/magnifying-glass.svg";
import ConfirmationModal from "../../common/modal/confirmationModal";
import {apiService} from "../../utils/serviceApi";

export default function Users() {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesMaterial = useStylesForMaterial();

  const classesForm = toolbarStyles();
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  const { isLoading } = useSelector((state) => state.loader);
  const [height, setHeight] = useState(window.innerHeight);
  const [confirmationDisableUser, setConfirmationDisableUser] = useState(false);
    const [confirmationResetPassword, setConfirmationResetPassword] = useState(false);
  const [inactiveListVisible, setInactiveListVisible] = useState(false);
  const [inactiveCellStyle, setInactiveCellStyle] = useState(false);
  const [userIDForArchive, setUserIDForArchive] = useState("");
    const [userEmailForPasswordReset, setUserEmailForPasswordReset] = useState("");

  const handleResize = () => {
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  useEffect(() => {
    if (inactiveListVisible) {
      dispatch(getAllDisabledUsers(setInactiveCellStyle));
    } else {
      dispatch(getAllUsers(setInactiveCellStyle));
    }
  }, [inactiveListVisible, dispatch]);

  useEffect(() => {
    dispatch(changeTabInSidebar(5));
  }, [dispatch]);

  const disableUser = (id) => {
    dispatch(
      disableUserBySysadmin(id, setConfirmationDisableUser, setUserIDForArchive)
    );
  };

  const enableUser = (id) => {
    dispatch(
      enableUserBySysadmin(id, setConfirmationDisableUser, setUserIDForArchive)
    );
  };

  const resetPassword = (email) => {
    dispatch(
        requestPasswordResetBySysadmin(email, setConfirmationResetPassword, setUserEmailForPasswordReset)
    );
  }

  function toolbar(props) {
    return (
      <div className={classesForm.root}>
        <div className={Style.header}>
          <div className={Style.header__text}>{t("Sidebar.Users")}</div>
          <button
            className="addEntityButton"
            onClick={() => {
              setInactiveListVisible(!inactiveListVisible);
            }}
          >
            <p className={Style.header__addButtonText}>
              {inactiveListVisible
                ? t("Users.ActiveUsers")
                : t("Users.InactiveUsers")}
            </p>
          </button>
        </div>
      </div>
    );
  }
  function pagination(props) {
    return (
      <td style={paginationStyle}>
        <Pagination translate={t} {...props} classes={{ ...classesMaterial }} />
      </td>
    );
  }

  return (
    <div className={classes.root}>
      <>
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />

          <Container maxWidth={false} className={classes.container}>
            {isLoading && <LoadingIndicator />}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className="text-center">
                  <div className="row content">
                    <div className="col-sm-12">
                      <div className={classes.paper}>
                        <MaterialTable
                          style={materialTableStyle}
                          icons={{
                            Filter: () => (
                              <img src={MagnifyingGlass} alt="search" />
                            ),
                          }}
                          data={users}
                          columns={UsersColumns(
                            t,
                            setConfirmationDisableUser,
                            setUserIDForArchive,
                            inactiveListVisible,
                            inactiveCellStyle,
                            setConfirmationResetPassword,
                            setUserEmailForPasswordReset
                          )}
                          options={optionsGeneral(height - 240, 44)}
                          components={{
                            Toolbar: (props) => toolbar(props),
                            Pagination: (props) => pagination(props),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </main>
      </>
      {confirmationDisableUser && (
        <ConfirmationModal
          title={
            inactiveListVisible
              ? t("Users.ActivateUsers")
              : t("Users.DeactivateUsers")
          }
          message={
            inactiveListVisible
              ? t("Users.ActivateUserMessage")
              : t("Users.DeactivateUserMessage")
          }
          confirmationButtonText={
            inactiveListVisible ? t("Users.Active") : t("Users.Deactive")
          }
          open={confirmationDisableUser}
          onClose={() => {
            setConfirmationDisableUser(false);
            setUserIDForArchive("");
          }}
          confirmationAction={() => {
            inactiveListVisible
              ? enableUser(userIDForArchive)
              : disableUser(userIDForArchive);
          }}
          width="450px"
        />
      )}
      {confirmationResetPassword && (
        <ConfirmationModal
          title={t("Users.ResetPassword")}
          message={t("Users.ResetPasswordMessage")}
          confirmationButtonText={t("Users.Reset")}
          open={confirmationResetPassword}

          onClose={() => {
            setConfirmationResetPassword(false);
            setUserEmailForPasswordReset("");
          }}
          confirmationAction={() => {
            resetPassword(userEmailForPasswordReset);
            console.log("Reset password for user with email: ", userEmailForPasswordReset);
          }}
          width="450px"
        />
        )}
    </div>
  );
}
