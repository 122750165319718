import {
  SET_LOADER,
  GET_TICKET_CANCELLATION_REQUEST_BY_ID,
  REMOVE_TICKET_CANCELLATION_REQUEST_FROM_REQUESTS,
  GET_TICKET_FEE_CANCELLATION_REQUEST_BY_ID,
  REMOVE_TICKET_FEE_CANCELLATION_REQUEST_FROM_REQUESTS,
} from "./types";
import { apiService } from "../utils/serviceApi";

export const getTicketCancellationRequestById =
  (requestId) => async (dispatch) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    apiService
      .get(
        `${process.env.REACT_APP_SERVICE_URL}/api/ticketCancellation/${requestId}`
      )
      .then((res) => {
        dispatch({
          type: GET_TICKET_CANCELLATION_REQUEST_BY_ID,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };

export const getTicketFeeCancellationRequestById =
  (requestId) => async (dispatch) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    apiService
      .get(
        `${process.env.REACT_APP_SERVICE_URL}/api/ticketFeeCancellation/${requestId}`
      )
      .then((res) => {
        dispatch({
          type: GET_TICKET_FEE_CANCELLATION_REQUEST_BY_ID,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };

export const denyTicketCancellationRequest =
  (id, setErrorModalOpen) => async (dispatch) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    apiService
      .put(
        `${process.env.REACT_APP_SERVICE_URL}/api/ticketCancellation/deny/${id}`
      )
      .then((res) => {
        if (res.status === 400) {
          setErrorModalOpen(true);
        }
        dispatch({
          type: REMOVE_TICKET_CANCELLATION_REQUEST_FROM_REQUESTS,
          payload: id,
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };

export const approveTicketCancellationRequest =
  (id, setErrorModalOpen) => async (dispatch) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    apiService
      .put(
        `${process.env.REACT_APP_SERVICE_URL}/api/ticketCancellation/approve/${id}`
      )
      .then((res) => {
        if (res.status === 400) {
          setErrorModalOpen(true);
        }
        dispatch({
          type: REMOVE_TICKET_CANCELLATION_REQUEST_FROM_REQUESTS,
          payload: id,
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };

export const approveTicketFeeCancellationRequest =
  (id, setErrorModalOpen) => async (dispatch) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    apiService
      .put(
        `${process.env.REACT_APP_SERVICE_URL}/api/ticketFeeCancellation/approve/${id}`
      )
      .then((res) => {
        if (res.status === 400) {
          setErrorModalOpen(true);
        }
        dispatch({
          type: REMOVE_TICKET_FEE_CANCELLATION_REQUEST_FROM_REQUESTS,
          payload: id,
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };

export const denyTicketFeeCancellationRequest =
  (id, setErrorModalOpen) => async (dispatch) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    apiService
      .put(
        `${process.env.REACT_APP_SERVICE_URL}/api/ticketFeeCancellation/deny/${id}`
      )
      .then((res) => {
        if (res.status === 400) {
          setErrorModalOpen(true);
        }
        dispatch({
          type: REMOVE_TICKET_FEE_CANCELLATION_REQUEST_FROM_REQUESTS,
          payload: id,
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };
