import { NewCloseIconButton } from "../common/closeIconButton/newCloseIcon";
import { cellStyle, headerStyle } from "./columnsGeneral";

export function BlacklistColumns(t, openDeleteConfirmationModal) {
  return [
    {
      title: t("Columns.License Plate"),
      field: "plateNumber",
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Name"),
      field: "name",
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Delete"),
      headerStyle: {
        ...headerStyle,
        textAlign: "center",
      },
      filterComponent: () => null,
      cellStyle: {
        ...cellStyle,
      },

      render: (row) => (
        <NewCloseIconButton
          onClick={() => {
            openDeleteConfirmationModal(row.id);
          }}
        />
      ),
    },
  ];
}
