import * as PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import InputFieldWithLabel from "../../common/input/inputFieldWithLabel";
import SelectWithLabel from "../../common/input/selectWithLabel";
import {supportedCountries} from "../../globals";
import Button from "../button/button";
import Style from "./AddressesComponent.module.css";


const AddressesComponent = (props) => {
    const {register, watch, initialAddress, useGeocode} = props;
    const {t} = useTranslation();

    const [initialCountry, setInitialCountry] = useState(initialAddress?.countryCode || "0");
    const [address, setAddress] = useState(null);

    const streetWatch = watch("street", initialAddress?.street);
    const zipCodeWatch = watch("zipCode", initialAddress?.zipCode);
    const cityWatch = watch("city", initialAddress?.city);
    const countryWatch = watch("country", initialCountry);

    const addressIsInvalid =
        !countryWatch || countryWatch === "0"|| !cityWatch || !zipCodeWatch || zipCodeWatch.length < 4 || !streetWatch

    const onAddressPartChanged = () => {
        const address = {
            street: streetWatch,
            zipCode: zipCodeWatch,
            city: cityWatch,
            countryCode: countryWatch,
        };
        setAddress(address);

        if (addressIsInvalid) {
            props.onAddressAdded(null);
        } else {
            props.onAddressAdded(address);
        }
    }

    /*
     * This has to be useEffect for several reasons:
     * * if we pass onAddressPartChanged to react-hook-form's register({ onChange: onAddressPartChanged } ),
     * watched values will have values from previous render, as RHF populates them after onChange
     * * if we use watch() directly in render, we will get infinite loop
     * * we need to call onAddressPartChanged() only when watched values change, not on every render
     */
    useEffect(onAddressPartChanged, [streetWatch, zipCodeWatch, cityWatch, countryWatch]);

    return <div className={Style.addressContainer}>
        <div className={Style.section__title}>
            {t("Addresses.address")}
        </div>
        <SelectWithLabel
            style={{gridColumn: "span 2"}}
            label={t("Addresses.country")}
            name="country"
            registerName="country"
            register={register}
            required={true}
            whiteBackground
            defaultOption={t("Addresses.chooseCountry")}
            hidden={initialCountry !== "0"}
            defaultValue={initialCountry}
            options={[...supportedCountries.keys()].map((country) => {
                return (
                    <option key={country} value={country}>
                        {country}
                    </option>
                );
            })}
        />
        <InputFieldWithLabel
            style={{gridColumn: "span 3"}}
            label={t("Addresses.city")}
            name="city"
            registerName="city"
            register={register}
            required={true}
            defaultValue={initialAddress?.city}
        />
        <InputFieldWithLabel
            style={{gridColumn: "span 2"}}
            label={t("Addresses.postalCode")}
            name="zipCode"
            registerName="zipCode"
            register={register}
            required={true}
            type="number"
            defaultValue={initialAddress?.zipCode}
            validationProps={{minLength: 5}}
        />
        <InputFieldWithLabel
            style={{gridColumn: useGeocode? "span 5" : "span 7"}}
            label={t("Addresses.streetAndNumber")}
            name="street"
            registerName="street"
            register={register}
            required={true}
            defaultValue={initialAddress?.street}
        />

        {useGeocode && <Button
            type={"button"}
            style={{gridColumn: "span 2", display: "flex", alignItems: "end", marginBottom: "16px"}}
            buttonStyle={{width: "100%"}}
            onClick={() => props.onGeocodeClicked(address)}
            disabled={addressIsInvalid}
            text={t("ParkingZones.geocode")}
        />}
    </div>

};

export const formatAddress = (address) => {
    return `${address.street}, ${address.zipCode} ${address.city}, ${address.countryCode}`;
}

AddressesComponent.propTypes = {
    onAddressAdded: PropTypes.func.isRequired,
    useGeocode: PropTypes.bool,
    onGeocodeClicked: PropTypes.func,
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    initialAddress: PropTypes.exact({
        street: PropTypes.string,
        zipCode: PropTypes.string,
        city: PropTypes.string,
        countryCode: PropTypes.string,
    })
};

export default AddressesComponent;