import {ROLES} from "../consts";
import {isAllowed} from "./isAllowed";

export function resolveRedirectLocation(auth) {
    if (isAllowed([ROLES.SYSADMIN], auth)) {
        console.log("redirecting to sysadmin");
        return "/admins";
    }
    if (
        isAllowed([ROLES.PARKING_ADMIN], auth) ||
        isAllowed([ROLES.GAS_STATION_ADMIN], auth)
    ) {
        console.log("redirecting to admin");
        return "/owners";
    }

    if (isAllowed([ROLES.PARKING_MANAGER, ROLES.PARKING_VIEWER], auth)) {
        console.log("redirecting to admin");
        return "/parkingZones";
    }

    if (isAllowed([ROLES.GAS_STATION_MANAGER, ROLES.GAS_STATION_VIEWER], auth)) {
        console.log("redirecting to admin");
        return "/gasStations";
    }

    if (isAllowed([ROLES.SYSADMIN], auth)) {
        console.log("redirecting to ticket series");
        return "/ticketSeries";
    }

    if (isAllowed([ROLES.VISITOR], auth)) {
        console.log("redirecting to bye bye");
        auth.signoutSilent({post_logout_redirect_uri: process.env.REACT_APP_FRONTEND_URL});
        return "/signout";
    }


    return "/unauthorized"
}