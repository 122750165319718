import { Avatar, Tooltip } from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import moment from "moment";
import React, { useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Objection from "../common/assets/objection.svg";
import Play from "../common/assets/play-button.svg";
import Comment from "../common/assets/comment.svg";
import Approved from "../common/assets/approved.svg";
import DropdownItem from "../common/dropdownItem/DropdownItem";
import Style from "./violationsColumns.module.css";
import { switchLegalStatus, switchStatus, switchUserStatus } from "../utils/ticketStatusesHelper";
import {
  gasStationTicketCategory,
  ticketLegalStatus,
  ticketStatus,
} from "../globals";
import {
  cellStyle,
  cellStyleDisabled,
  CustomToggle,
  filterStyle,
  headerStyle,
} from "./columnsGeneral";
import { useTranslation } from "react-i18next";
import SearchIcon from "../reusable/searchIcon";
import CloseIcon from "../reusable/closeIcon";
import { changeTicketToDoneStatus } from "../actions/violations";
import { useDispatch } from "react-redux";
import {ROLES} from "../consts";
import { colors } from '../globals';
import {useAuth} from "react-oidc-context";

const violationsActionsCellStyle = {
  width: "10%",
  textAlign: "right",
  paddingRight: "60px",
  paddingTop: "10px",
  paddingBottom: "10px",
};
const violationsActionsCellStyleDisabled = {
  width: "10%",
  textAlign: "right",
  paddingRight: "60px",
  paddingTop: "10px",
  paddingBottom: "10px",
  backgroundColor: "#C7C7C7",
};

export function DriveOffColumns({
  t,
  setShowPictures,
  setImages,
  setShowVideos,
  setVideos,
  setObjectionOpen,
  setObjection,
  generateDocumentation,
  cancelViolation,
  cancelViolationFee,
  changeTicketLegalStatus,
  changeTicketDocumentLanguage,
  keycloak,
  manuallyPayTicket,
  generateReminderPaymentSlip,
  archiveTicket,
  archivedCellStyle,
  generatePaymentSlip,
  toogleModalForComment,
  setTicketNumberInCommentModal,
  setCommentDefaultValue,
  setEditTicketModal,
  setTicketForEdit,
  setOpenModalOwnerDetails,
  setSelectedOwner,
  setFilter,
  filter,
  setZendeskDetailsModalOpen,
  setZendeskId,
  setDateModalOpen,
  refDate,
  setViolatorDetails,
  setOpenViolatorsAddressLocked,
  createOperationalDocument,
  setTicketAuditModal,
  setTicketForAudit,
  setOpenManualPaymentModal,
  setOpenModalGasStationZoneDetails,
  setSelectedGasStationZoneId,
}) {
  const auth = useAuth();
  const [localArray, setLocalArray] = useState([...filter.filter.and]);
  const dispatch = useDispatch();
  const isSysAdmin = keycloak.hasRealmRole(ROLES.SYSADMIN, auth);
  const isViewer = keycloak.hasRealmRole(ROLES.PARKING_VIEWER, auth);

  const dropdownItemFinancialStatus = (row) => {
    switch (row.status) {
      case ticketStatus.PENDING:
        return (
            <>
              <DropdownItem
                  onClickAction={() => cancelViolation(row.id)}
                  translation={t("Violations.Menu.Cancel")}
              />
              {isSysAdmin && (
                  <DropdownItem
                      onClickAction={() => cancelViolationFee(row.id)}
                      translation={t("Violations.Menu.CancelFee")}
                  />
              )}
              {isSysAdmin && (
                  <DropdownItem
                      onClickAction={() => dispatch(changeTicketToDoneStatus(row.id))}
                      translation={t("Violations.Menu.Done")}
                  />
              )}
              {isSysAdmin && (
                  <DropdownItem
                      onClickAction={() => {
                        setTicketForEdit(row);
                        setOpenManualPaymentModal(true);
                      }}
                      translation={t("Violations.Menu.PayManually")}
                  />
              )}
            </>
        );
      case ticketStatus.ISSUED:
        return (
          <>
            <DropdownItem
              onClickAction={() => cancelViolation(row.id)}
              translation={t("Violations.Menu.Cancel")}
            />
            {isSysAdmin && (
              <DropdownItem
                onClickAction={() => cancelViolationFee(row.id)}
                translation={t("Violations.Menu.CancelFee")}
              />
            )}
            {isSysAdmin && (
                <DropdownItem
                    onClickAction={() => dispatch(changeTicketToDoneStatus(row.id))}
                    translation={t("Violations.Menu.Done")}
                />
            )}
            {isSysAdmin && (
              <DropdownItem
                onClickAction={() => {
                  setTicketForEdit(row);
                  setOpenManualPaymentModal(true);
                }}
                translation={t("Violations.Menu.PayManually")}
              />
            )}
          </>
        );
      case ticketStatus.PAID:
        // arhiviranje dodati na neki drugi nacin
        break;
      case ticketStatus.OVERDUE:
        return (
          <>
            <DropdownItem
              onClickAction={() => cancelViolation(row.id)}
              translation={t("Violations.Menu.Cancel")}
            />
            {isSysAdmin && (
              <DropdownItem
                onClickAction={() => cancelViolationFee(row.id)}
                translation={t("Violations.Menu.CancelFee")}
              />
            )}
            {isSysAdmin && (
              <DropdownItem
                onClickAction={() => dispatch(changeTicketToDoneStatus(row.id))}
                translation={t("Violations.Menu.Done")}
              />
            )}

            {isSysAdmin && (
              <DropdownItem
                onClickAction={() => {
                  setTicketForEdit(row);
                  setOpenManualPaymentModal(true);
                }}
                translation={t("Violations.Menu.PayManually")}
              />
            )}
          </>
        );
      case ticketStatus.DONE:
        return (
          <>
            <DropdownItem
              onClickAction={() => cancelViolation(row.id)}
              translation={t("Violations.Menu.Cancel")}
            />
            {isSysAdmin && (
              <DropdownItem
                onClickAction={() => cancelViolationFee(row.id)}
                translation={t("Violations.Menu.CancelFee")}
              />
            )}
            <DropdownItem
              onClickAction={() => dispatch(changeTicketToDoneStatus(row.id))}
              translation={t("Violations.Menu.Done")}
            />

            {isSysAdmin && (
              <DropdownItem
                onClickAction={() => {
                  setTicketForEdit(row);
                  setOpenManualPaymentModal(true);
                }}
                translation={t("Violations.Menu.PayManually")}
              />
            )}
          </>
        );
      case ticketStatus.DOWNPAID:
        return (<>
            {isSysAdmin && (
                <DropdownItem
                    onClickAction={() => {
                      setTicketForEdit(row);
                      setOpenManualPaymentModal(true);
                    }}
                    translation={t("Violations.Menu.PayManually")}
                />
            )}
            </>
        )
      case ticketStatus.STOPPED:
        // arhiviranje dodati na neki drugi nacin
        break;
      default:
        return null;
    }
  };
  const modifyArray = (array, name, value) => {
    const newArray = array.map((element) => {
      if (element.path === name) {
        element.value = value;
      }
      return element;
    });
    return newArray;
  };

  const paymanetPeriodExpired = (date, paymentPeriod) => {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + parseInt(paymentPeriod));
    if (newDate > new Date()) {
      return false;
    } else {
      return true;
    }
  };
  const reminderPeriodExpired = (reminderDeadline) => {
    if (!reminderDeadline) {
      return false;
    }
    const newDate = new Date();
    let remider = new Date(reminderDeadline);
    if (newDate < remider) {
      return false;
    }
    return true;
  };

  const modifyArrayForStatus = (array, name, value) => {
    const newArray = array.map((element) => {
      if (element.path === name) {
        if (value === "DEFAULT") {
          element.value = "";
          element.filter = "notnull";
        } else {
          element.value = value;
          element.filter = "eq";
        }
      }
      return element;
    });

    return newArray;
  };

  const modifyArrayForViolatorAddressStatus = (array, name, value) => {
    const newArray = array.map((element) => {
      if (element.path.startsWith("violatorAddress") || element.path==="id") {
        if (value === "DEFAULT") {
          element.value = "";
          element.path = "id";
          element.filter = "notnull";
        } else if (value === "NEW") {
          element.value = "";
          element.filter = "isnull";
          element.path = "violatorAddress";
        } else {
          element.value = value;
          element.filter = "eq";
          element.path = "violatorAddress.status";
        }
      }
      return element;
    });

    return newArray;
  };

  const { i18n } = useTranslation();


  const findEnumViolatorAddressStatusForLanguage = (value) => {
    if (i18n.language === "en") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("foreign".startsWith((value + "").toLowerCase())) {
        return "FOREIGN";
      }
      if ("determined".startsWith((value + "").toLowerCase())) {
        return "DETERMINED";
      }
      if ("undetermined".startsWith((value + "").toLowerCase())) {
        return "UNDETERMINED";
      }
      if ("unknown".startsWith((value + "").toLowerCase())) {
        return "UNKNOWN";
      }
      if ("new".startsWith((value + "").toLowerCase())) {
        return "NEW";
      }
      if ("requested".startsWith((value + "").toLowerCase())) {
        return "REQUESTED";
      }
      return "DEFAULT";
    }
    if (i18n.language === "de") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("ausländisch".startsWith((value + "").toLowerCase())) {
        return "FOREIGN";
      }
      if ("bestimmt".startsWith((value + "").toLowerCase())) {
        return "DETERMINED";
      }
      if ("unbestimmt".startsWith((value + "").toLowerCase())) {
        return "UNDETERMINED";
      }
      if ("unbekannt".startsWith((value + "").toLowerCase())) {
        return "UNKNOWN";
      }
      if ("neu".startsWith((value + "").toLowerCase())) {
        return "NEW";
      }
      if ("angefordert".startsWith((value + "").toLowerCase())) {
        return "REQUESTED";
      }
      return "DEFAULT";
    }
    if (i18n.language === "it") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("staniero".startsWith((value + "").toLowerCase())) {
        return "FOREIGN";
      }
      if ("determinato".startsWith((value + "").toLowerCase())) {
        return "DETERMINED";
      }
      if ("indeterminato".startsWith((value + "").toLowerCase())) {
        return "UNDETERMINED";
      }
      if ("sconosciuto".startsWith((value + "").toLowerCase())) {
        return "UNKNOWN";
      }
      if ("nuovo".startsWith((value + "").toLowerCase())) {
        return "NEW";
      }
      if ("richiesto".startsWith((value + "").toLowerCase())) {
        return "REQUESTED";
      }
      return "DEFAULT";
    }
    if (i18n.language === "fr") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("étranger".startsWith((value + "").toLowerCase())) {
        return "FOREIGN";
      }
      if ("déterminé".startsWith((value + "").toLowerCase())) {
        return "DETERMINED";
      }
      if ("indéterminé".startsWith((value + "").toLowerCase())) {
        return "UNDETERMINED";
      }
      if ("inconnu".startsWith((value + "").toLowerCase())) {
        return "UNKNOWN";
      }
      if ("nouveau".startsWith((value + "").toLowerCase())) {
        return "NEW";
      }
      if ("demandé".startsWith((value + "").toLowerCase())) {
        return "REQUESTED";
      }
      return "DEFAULT";
    }
  }

  const findEnumValueForLanguage = (value) => {
    if (i18n.language === "en") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("paid".startsWith((value + "").toLowerCase())) {
        return "PAID";
      }
      if ("overrdue".startsWith((value + "").toLowerCase())) {
        return "OVERDUE";
      }
      if ("canceled".startsWith((value + "").toLowerCase())) {
        return "STOPPED";
      }
      if ("due".startsWith((value + "").toLowerCase())) {
        return "ISSUED";
      }
      if ("downpaid".startsWith((value + "").toLowerCase())) {
        return "DOWNPAID";
      }
      if ("complited".startsWith((value + "").toLowerCase())) {
        return "DONE";
      }
      if ("new".startsWith((value + "").toLowerCase())) {
        return "PENDING";
      }
      if ("grace".startsWith((value + "").toLowerCase())) {
        return "GRACE";
      }
      return "DEFAULT";
    }
    if (i18n.language === "de") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("bezahlt".startsWith((value + "").toLowerCase())) {
        return "PAID";
      }
      if ("überfällig".startsWith((value + "").toLowerCase())) {
        return "OVERDUE";
      }
      if ("storniert".startsWith((value + "").toLowerCase())) {
        return "STOPPED";
      }
      if ("fällig".startsWith((value + "").toLowerCase())) {
        return "ISSUED";
      }
      if ("angezahlt".startsWith((value + "").toLowerCase())) {
        return "DOWNPAID";
      }
      if ("erledigt".startsWith((value + "").toLowerCase())) {
        return "DONE";
      }
      if ("neu".startsWith((value + "").toLowerCase())) {
        return "PENDING";
      }
      if ("schonfrist".startsWith((value + "").toLowerCase())) {
        return "GRACE";
      }
      return "DEFAULT";
    }
    if (i18n.language === "fr") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("payée".startsWith((value + "").toLowerCase())) {
        return "PAID";
      }
      if ("überfällig".startsWith((value + "").toLowerCase())) {
        return "OVERDUE";
      }
      if ("annulée".startsWith((value + "").toLowerCase())) {
        return "STOPPED";
      }
      if ("échue".startsWith((value + "").toLowerCase())) {
        return "ISSUED";
      }
      if ("acompte".startsWith((value + "").toLowerCase())) {
        return "DOWNPAID";
      }
      if ("faite".startsWith((value + "").toLowerCase())) {
        return "DONE";
      }
      if ("nouveau".startsWith((value + "").toLowerCase())) {
        return "PENDING";
      }
      if ("grace".startsWith((value + "").toLowerCase())) {
        return "GRACE";
      }
      return "DEFAULT";
    }
    if (i18n.language === "it") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("pagato".startsWith((value + "").toLowerCase())) {
        return "PAID";
      }
      if ("scaduto".startsWith((value + "").toLowerCase())) {
        return "OVERDUE";
      }
      if ("annullato".startsWith((value + "").toLowerCase())) {
        return "STOPPED";
      }
      if ("dovuto".startsWith((value + "").toLowerCase())) {
        return "ISSUED";
      }
      if ("acconto pagato".startsWith((value + "").toLowerCase())) {
        return "DOWNPAID";
      }
      if ("evaso".startsWith((value + "").toLowerCase())) {
        return "DONE";
      }
      if ("nuovo".startsWith((value + "").toLowerCase())) {
        return "PENDING";
      }
      if ("grazia".startsWith((value + "").toLowerCase())) {
        return "GRACE";
      }
      return "DEFAULT";
    }
  };

  const findUserStatusEnumValueForLanguage = (value) => {
    if (i18n.language === "en") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("paid".startsWith((value + "").toLowerCase())) {
        return "PAID";
      }
      if ("open".startsWith((value + "").toLowerCase())) {
        return "OPEN";
      }
      if ("cancelled".startsWith((value + "").toLowerCase())) {
        return "CANCELLED";
      }
      if ("closed".startsWith((value + "").toLowerCase())) {
        return "CLOSED";
      }

      return "DEFAULT";
    }
    if (i18n.language === "de") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("bezahlt".startsWith((value + "").toLowerCase())) {
        return "PAID";
      }
      if ("offen".startsWith((value + "").toLowerCase())) {
        return "OPEN";
      }
      if ("storniert".startsWith((value + "").toLowerCase())) {
        return "CANCELLED";
      }
      if ("geschlossen".startsWith((value + "").toLowerCase())) {
        return "CLOSED";
      }

      return "DEFAULT";
    }
    if (i18n.language === "fr") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("paye".startsWith((value + "").toLowerCase())) {
        return "PAID";
      }
      if ("ouvert".startsWith((value + "").toLowerCase())) {
        return "OPEN";
      }
      if ("annule".startsWith((value + "").toLowerCase())) {
        return "CANCELLED";
      }
      if ("ferme".startsWith((value + "").toLowerCase())) {
        return "CLOSED";
      }

      return "DEFAULT";
    }
    if (i18n.language === "it") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("pagato".startsWith((value + "").toLowerCase())) {
        return "PAID";
      }
      if ("aperto".startsWith((value + "").toLowerCase())) {
        return "OPEN";
      }
      if ("annullato".startsWith((value + "").toLowerCase())) {
        return "CANCELLED";
      }
      if ("chiuso".startsWith((value + "").toLowerCase())) {
        return "CLOSED";
      }

      return "DEFAULT";
    }
  };

  const findEnumLegalStatusValueForLanguage = (value) => {
    if(value === "-"){
      return "NONE";
    }
    if (i18n.language === "en") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("reminded".startsWith((value + "").toLowerCase())) {
        return "REMINDED";
      }
      if ("sued".startsWith((value + "").toLowerCase())) {
        return "SUED";
      }
      if ("terminated".startsWith((value + "").toLowerCase())) {
        return "TERMINATED";
      }
      if ("invoice sent".startsWith((value + "").toLowerCase())) {
        return "INVOICE_SENT";
      }
      if ("Enforced".startsWith((value + "").toLowerCase())) {
        return "OPERATION_DOC_SENT";
      }
      if ("to sue".startsWith((value + "").toLowerCase())) {
        return "TO_SUE";
      }
      if ("to enforce".startsWith((value + "").toLowerCase())) {
        return "TO_BE_ENFORCED";
      }
      if ("penalty fee demanded".startsWith((value + "").toLowerCase())) {
        return "PENALTY_FEE_DEMANDED";
      }

      return "DEFAULT";
    }

    if (i18n.language === "de") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("gemahnt".startsWith((value + "").toLowerCase())) {
        return "REMINDED";
      }
      if ("verzeigt".startsWith((value + "").toLowerCase())) {
        return "SUED";
      }
      if ("abgeschlossen".startsWith((value + "").toLowerCase())) {
        return "TERMINATED";
      }
      if ("rg versendet".startsWith((value + "").toLowerCase())) {
        return "INVOICE_SENT";
      }
      if ("betrieben".startsWith((value + "").toLowerCase())) {
        return "OPERATION_DOC_SENT";
      }
      if ("zu verzeigen".startsWith((value + "").toLowerCase())) {
        return "TO_SUE";
      }
      if ("zu betreiben".startsWith((value + "").toLowerCase())) {
        return "TO_BE_ENFORCED";
      }
      if ("strafgebühr eingefordert".startsWith((value + "").toLowerCase())) {
        return "PENALTY_FEE_DEMANDED";
      }

      return "DEFAULT";
    }
    if (i18n.language === "fr") {
      if (value === "") {
        return "DEFAULT";
      }
      if ("rappelée".startsWith((value + "").toLowerCase())) {
        return "REMINDED";
      }
      if ("dénoncée".startsWith((value + "").toLowerCase())) {
        return "SUED";
      }
      if ("fermé".startsWith((value + "").toLowerCase())) {
        return "TERMINATED";
      }
      if ("facture envoyée".startsWith((value + "").toLowerCase())) {
        return "INVOICE_SENT";
      }
      if (
          "poursuite en cours".startsWith((value + "").toLowerCase())
      ) {
        return "OPERATION_DOC_SENT";
      }
      if ("à dénoncer".startsWith((value + "").toLowerCase())) {
        return "TO_SUE";
      }
      if ("introduire poursuite".startsWith((value + "").toLowerCase())) {
        return "TO_BE_ENFORCED";
      }
      if ("pénalité demandée".startsWith((value + "").toLowerCase())) {
        return "PENALTY_FEE_DEMANDED";
      }

      return "DEFAULT";
    }
  };

  const showLicensePlate = (row) => {
    if (row?.violatorAddress?.street) {
      return (
        <div
          onClick={isViewer? null : () => {
            setOpenViolatorsAddressLocked(true);
            setViolatorDetails(row);
          }}
          style={{
            color: "green",
            cursor: "pointer",
            fontWeight: "bold",
          }}
        >
          {row.licensePlate}
        </div>
      );
    }
    if (row?.violatorAddress?.locked) {
      return (
          <div
              onClick={isViewer? null : () => {
                setOpenViolatorsAddressLocked(true);
                setViolatorDetails(row);
              }}
              style={{
                color: "red",
                cursor: "pointer",
                fontWeight: "bold",
              }}
          >
            {row.licensePlate}
          </div>
      );
    }
    return (
      <div
        onClick={isViewer? null : () => {
          setOpenViolatorsAddressLocked(true);
          setViolatorDetails(row);
        }}
      >
        {row.licensePlate}
      </div>
    );
  };

  const getCategoryNumber = (category) => {
    switch (category) {
      case gasStationTicketCategory.INTENT:
        return 1;
      case gasStationTicketCategory.PROMISSORY_NOTE:
        return 2;
      case gasStationTicketCategory.DEBT_NOTE:
        return 3;
      case gasStationTicketCategory.PUMP_SWAPPED:
        return 4;
      case gasStationTicketCategory.OTHER_REASON:
        return 5;
      default:
        return "";
    }
  };

  const getCategoryTranslation = (category) => {
    switch (category) {
      case gasStationTicketCategory.INTENT:
        return t("GasStationTicketCategory.INTENT");
      case gasStationTicketCategory.PROMISSORY_NOTE:
        return t("GasStationTicketCategory.PROMISSORY_NOTE");
      case gasStationTicketCategory.DEBT_NOTE:
        return t("GasStationTicketCategory.DEBT_NOTE");
      case gasStationTicketCategory.PUMP_SWAPPED:
        return t("GasStationTicketCategory.PUMP_SWAPPED");
      case gasStationTicketCategory.OTHER_REASON:
        return t("GasStationTicketCategory.OTHER_REASON");
      default:
        return "";
    }
  };

  const refViolationNumber = useRef(null);
  const refParkingZone = useRef(null);
  const refLicensePlate = useRef(null);
  const refBeppoliceman = useRef(null);
  const refViolatorAddress = useRef(null);
  const refStatus = useRef(null);
  const refLegalStatus = useRef(null);
  const refUserStatus = useRef(null);
  const refGsName = useRef(null);

  const closeFilter = (ref, name) => {
    ref.current.value = "";

    const newArray = modifyArray([...filter.filter.and], name, "");
    setFilter({ ...filter, filter: { and: newArray } });
  };

  const closeFilterForStatus = (ref, name) => {
    ref.current.value = "";
    const enumValue = findEnumValueForLanguage("");

    const newArray = modifyArrayForStatus(localArray, name, enumValue);

    setFilter({ ...filter, filter: { and: newArray } });
  };

  const [columns, setColumns] = useState([
    {
      title: isViewer? t("Columns.GS number") : t("Columns.GasStationZone"),
      field: "gasStationZone.number",
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      filterComponent: () => (
        <div style={{ marginTop: "12px" }} class="input-group">
          <SearchIcon closeFilter={closeFilter} />
          {refParkingZone?.current?.value !== "" && (
            <CloseIcon
              closeFilter={closeFilter}
              reference={refParkingZone}
              name={"gasStationZone.number"}
            />
          )}

          <input
            ref={refParkingZone}
            name="gasStationZone.number"
            className={Style.inputField}
            onChange={(e) => {
              const newArray = modifyArray(
                [...filter.filter.and],
                e.target.name,
                e.target.value
              );
              setFilter({ ...filter, filter: { and: newArray } });
            }}
          />
        </div>
      ),
      render: (row) => {
        return (
          <div
            style={{ color: isViewer ? "#000000" : "#005a92", cursor: isViewer ? "default" : "pointer" }}
            onClick={isViewer ? null : () => {
              setSelectedGasStationZoneId(row?.gasStationZone?.id);
              setOpenModalGasStationZoneDetails(true);
            }}
          >
            {row.gasStationZone?.number}
          </div>
        );
      },
    },
{
      title: t("Columns.GS name"),
      field: "gasStationZone.name",
      hidden: isViewer? false : true,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      filterComponent: () => (
        <div style={{ marginTop: "12px" }} class="input-group">
          <SearchIcon closeFilter={closeFilter} />
          {refGsName?.current?.value !== "" && (
            <CloseIcon
              closeFilter={closeFilter}
              reference={refGsName}
              name={"gasStationZone.name"}
            />
          )}

          <input
            ref={refGsName}
            name="gasStationZone.name"
            className={Style.inputField}
            onChange={(e) => {
              const newArray = modifyArray(
                [...filter.filter.and],
                e.target.name,
                e.target.value
              );
              setFilter({ ...filter, filter: { and: newArray } });
            }}
          />
        </div>
      ),
      render: (row) => {
        return row.gasStationZone?.name;
      },
    },
    {
      title: isViewer? t("Columns.Reference") : t("Columns.Violation Number"),
      field: "number",
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      filterComponent: () => (
        <div style={{ marginTop: "12px" }} class="input-group">
          <SearchIcon closeFilter={closeFilter} />
          {refViolationNumber?.current?.value !== "" && (
            <CloseIcon
              closeFilter={closeFilter}
              reference={refViolationNumber}
              name={"number"}
            />
          )}

          <input
            name="number"
            ref={refViolationNumber}
            className={Style.inputField}
            onChange={(e) => {
              const newArray = modifyArray(
                [...filter.filter.and],
                e.target.name,
                e.target.value
              );
              setFilter({ ...filter, filter: { and: newArray } });
            }}
          />
        </div>
      ),
    },
    {
      title: t("Columns.License Plate Number"),
      field: "licensePlate",
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      filterComponent: () => (
        <div style={{ marginTop: "12px" }} class="input-group">
          <SearchIcon closeFilter={closeFilter} />
          {refLicensePlate?.current?.value !== "" && (
            <CloseIcon
              closeFilter={closeFilter}
              reference={refLicensePlate}
              name={"licensePlate"}
            />
          )}
          <input
            name="licensePlate"
            ref={refLicensePlate}
            className={Style.inputField}
            onChange={(e) => {
              const newArray = modifyArray(
                [...filter.filter.and],
                e.target.name,
                e.target.value.toUpperCase()
              );
              setFilter({ ...filter, filter: { and: newArray } });
            }}
          />
        </div>
      ),
      render: (row) => {
        return showLicensePlate(row);
      },
    },
    {
      title: t("Columns.Address"),
      field: "violatorAddress.status",
      hidden: isViewer? true : false,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      filterComponent: () => (
          <div style={{ marginTop: "12px" }} class="input-group">
            <SearchIcon closeFilter={closeFilter} />
            {refViolatorAddress?.current?.value !== "" && (
                <CloseIcon
                    closeFilter={(ref,name)=>{
                      ref.current.value = "";
                      const array = [...filter.filter.and];
                      const newArray = array.map((element) => {
                        if (element.path.startsWith("violatorAddress") || element.path === "id") {
                          element.value = "";
                          element.filter= "notnull";
                          element.path = "id";
                        }
                        return element;
                      });
                      setFilter({ ...filter, filter: { and: newArray } });
                    }}
                    reference={refViolatorAddress}
                    name={"violatorAddress.status"}
                />
            )}
            <input
                name="violatorAddress.status"
                ref={refViolatorAddress}
                className={Style.inputField}
                onChange={(e) => {

                  const enumValue = findEnumViolatorAddressStatusForLanguage(e.target.value);
                  const newArray = modifyArrayForViolatorAddressStatus(
                      [...filter.filter.and],
                      e.target.name,
                      enumValue
                  );
                  setFilter({ ...filter, filter: { and: newArray } });
                }}
            />
          </div>
      ),
      render: (row) => {
        let value = "NEW";
        switch(row.violatorAddress?.status) {
          case "FOREIGN":
          case "UNDETERMINED":
          case "REQUESTED":
          case "UNKNOWN":
          case "DETERMINED":
            value = row.violatorAddress?.status;
            break;
          default:
            break;
        }
        return  <div
            style={{
              color: "gray",
              fontSize:"x-small"
            }}
        >
          {
            t(`ViolatorsAddressModal.Statuses.${value.toLowerCase()}`)
          }
        </div>
      },
    },
    {
      title: isViewer? t("Columns.Beppoliceman") : t("Columns.Beppo Officer"),
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      field: "reporter",
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,

      filterComponent: () => (
        <div style={{ marginTop: "12px" }} class="input-group">
          <SearchIcon closeFilter={closeFilter} />
          {refBeppoliceman?.current?.value !== "" && (
            <CloseIcon
              closeFilter={closeFilter}
              reference={refBeppoliceman}
              name={"reporterName"}
            />
          )}
          <input
            name="reporterName"
            ref={refBeppoliceman}
            className={Style.inputField}
            onChange={(e) => {
              const newArray = modifyArray(
                [...filter.filter.and],
                e.target.name,
                e.target.value
              );
              setFilter({ ...filter, filter: { and: newArray } });
            }}
          />
        </div>
      ),
      render: (row) => {
        return row.reporter?.beppoOfficerData ? (
          <div
            style={{ color: "#005a92", cursor: "pointer" }}
            onClick={() => {
              setOpenModalOwnerDetails(true);
              setSelectedOwner(row.reporter);
            }}
          >
            {row.reporter?.beppoOfficerData}
          </div>
        ) : (
          <div>{row.sharePUser}</div>
        );
      },
    },
    {
      title: t("Columns.Objections"),
      hidden: isViewer? true : false,
      field: "complaintText",
      headerStyle: headerStyle,
      filterComponent: () => null,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => {
        if (row.appeal) {
          return (
            <img
              src={Objection}
              style={{ cursor: "pointer", paddingLeft: 30 }}
              id="objection"
              alt="objection"
              height={20}
              onClick={() => {
                setObjectionOpen(true);
                setObjection(row.appeal);
              }}
            />
          );
        }
        return null;
      },
    },
    {
      width: "5%",
      title: t("Columns.Zendesk"),
      field: "zendeskIds",
      headerStyle: headerStyle,
      hidden: isSysAdmin ? false : true,
      filterComponent: () => null,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => {
        if (row.zendeskIds) {
          return row.zendeskIds.map((element) => {
            return (
              <div
                style={{ color: "#005a92", cursor: "pointer" }}
                onClick={() => {
                  setZendeskDetailsModalOpen(true);
                  setZendeskId(element);
                }}
              >
                {element}
              </div>
            );
          });
        }
        return null;
      },
    },
    {
      width: "6%",
      title: t("Columns.Comment"),
      hidden: isViewer? true : false,
      field: "comment",
      headerStyle: headerStyle,

      filterComponent: () => null,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => {
        if (row.comment) {
          return (
            <img
              src={Comment}
              style={{ cursor: "pointer", paddingLeft: 30 }}
              id="comment"
              alt="comment"
              height={20}
              onClick={() => {
                toogleModalForComment();
                setCommentDefaultValue(row.comment);
                setTicketNumberInCommentModal(row.number);
              }}
            />
          );
        }
        return null;
      },
    },
    {
      width: "4%",
      title: t("Columns.Images"),
      field: "scaledImageUrls",
      filterComponent: () => null,
      headerStyle: headerStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,

      render: (row) => {
        return (
          <AvatarGroup style={{ justifyContent: "left", cursor: "pointer" }}>
            {
              <Avatar
                alt="img"
                onClick={(e) => {
                  setShowPictures(true);
                  setImages(row.imageUrls);
                }}
                src={row.scaledImageUrls && row.scaledImageUrls[0]}
              ></Avatar>
            }
          </AvatarGroup>
        );
      },
    },

    {
      width: "4%",
      title: t("Columns.Videos"),
      field: "scaledVideosUrls",
      filterComponent: () => null,
      headerStyle: headerStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,

      render: (row) => {
        if (row.videoUrls) {
          return (
            <img
              src={Play}
              style={{ cursor: "pointer", paddingLeft: 30 }}
              id="Play"
              alt="Play"
              height={20}
              onClick={(e) => {
                setShowVideos(true);
                setVideos(row.videoUrls);
              }}
            />
          );
        }
        return null;
      },
    },

    {
      width: "4%",
      title: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>{t("DriveOffColumns.Amount")}</div>
          <div>{t("DriveOffColumns.CHF")}</div>
        </div>
      ),
      field: "gasStationTicketDetails.amount",
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      filterComponent: () => null,
      render: (row) => {
        return row?.amount?.toFixed(2);
      },
    },
    {
      width: "4%",
      title: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>{t("DriveOffColumns.Fees")}</div>
          <div>{t("DriveOffColumns.cancelled")}</div>
        </div>
      ),
      field: "feeCancellation",
      filterCellStyle: filterStyle,
      hidden: isViewer? true : false,
      headerStyle: headerStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      filterComponent: () => null,
      render: (row) => {
        if (typeof row?.feeCancellation === "undefined") return;
        return (
          row?.feeCancellation === true && <img src={Approved} alt="Approved" />
        );
      },
    },
    {
      width: "4%",
      title: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>{t("DriveOffColumns.Total")}</div>
          <div>{t("DriveOffColumns.CHF")}</div>
        </div>
      ),
      field: "totalGasStationAmount",
      filterComponent: () => null,
      filterCellStyle: filterStyle,
      hidden: isViewer? true : false,
      headerStyle: headerStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => {
        return row?.totalGasStationAmount?.toFixed(2);
      },
    },
    {
          title: t("Columns.UserStatus"),
          hidden: isViewer? false : true,
          field: "userStatus",
          headerStyle: headerStyle,
          filterCellStyle: filterStyle,
          cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
          render: (row) => (
            <div
              style={{ cursor: isViewer? "default" : "pointer" }}
              onClick={isViewer? null : () => {
                setTicketAuditModal(true);
                setTicketForAudit(row.number);
              }}
            >
              {switchUserStatus(t, row)}
            </div>
          ),

          filterComponent: () => (
            <div style={{ marginTop: "12px" }} class="input-group">
              <SearchIcon closeFilter={closeFilterForStatus} />
              {refUserStatus?.current?.value !== "" && (
                <CloseIcon
                  closeFilter={closeFilterForStatus}
                  reference={refUserStatus}
                  name={"userStatus"}
                />
              )}
              <input
                name="userStatus"
                ref={refUserStatus}
                className={Style.inputField}
                onChange={(e) => {
                  const enumValue = findUserStatusEnumValueForLanguage(
                    e.target.value
                  );

                  const newArray = modifyArrayForStatus(
                    localArray,
                    e.target.name,
                    enumValue
                  );
                  setFilter({ ...filter, filter: { and: newArray } });
                }}
              />
            </div>
          ),
        },
    {
      title: t("Columns.Status"),
      hidden: isViewer? true : false,
      field: "status",
      headerStyle: headerStyle,
      filterCellStyle: filterStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => switchStatus(t, row),
      filterComponent: () => (
        <div style={{ marginTop: "12px" }} class="input-group">
          <SearchIcon closeFilter={closeFilterForStatus} />
          {refStatus?.current?.value !== "" && (
            <CloseIcon
              closeFilter={closeFilterForStatus}
              reference={refStatus}
              name={"status"}
            />
          )}
          <input
            name="status"
            ref={refStatus}
            className={Style.inputField}
            onChange={(e) => {
              const enumValue = findEnumValueForLanguage(e.target.value);

              const newArray = modifyArrayForStatus(
                localArray,
                e.target.name,
                enumValue
              );

              setFilter({ ...filter, filter: { and: newArray } });
            }}
          />
        </div>
      ),
    },
    {
      title: t("Columns.LegalStatus"),
      hidden: isViewer? true : false,
      field: "legalStatus",
      headerStyle: headerStyle,
      filterCellStyle: filterStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setTicketAuditModal(true);
            setTicketForAudit(row.number);
          }}
        >
          {switchLegalStatus(t, row.legalStatus)}
        </div>
      ),

      filterComponent: () => (
        <div style={{ marginTop: "12px" }} class="input-group">
          <SearchIcon closeFilter={closeFilterForStatus} />
          {refLegalStatus?.current?.value !== "" && (
            <CloseIcon
              closeFilter={closeFilterForStatus}
              reference={refLegalStatus}
              name={"legalStatus"}
            />
          )}
          <input
            name="legalStatus"
            ref={refLegalStatus}
            className={Style.inputField}
            onChange={(e) => {
              const enumValue = findEnumLegalStatusValueForLanguage(
                e.target.value
              );

              const newArray = modifyArrayForStatus(
                localArray,
                e.target.name,
                enumValue
              );
              setFilter({ ...filter, filter: { and: newArray } });
            }}
          />
        </div>
      ),
    },
    {
      title: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {t("Columns.Category")}
        </div>
      ),
      field: "gasStationTicketDetails.category",
      filterComponent: () => null,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => (
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tooltip
            title={
              <div style={{ fontSize: 14 }}>
                {getCategoryTranslation(row?.gasStationTicketDetails?.category)}
              </div>
            }
            arrow={true}
            placement="top"
          >
            <div>
              {getCategoryNumber(row?.gasStationTicketDetails?.category)}
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: t("Columns.Due"),
      field: "date",
      headerStyle: headerStyle,
      filterCellStyle: filterStyle,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => {
        return moment(row.date).format("DD.MM.YYYY HH:mm");
      },
      filterComponent: () => (
        <div style={{ marginTop: "12px" }} class="input-group">
          <SearchIcon closeFilter={closeFilter} />
          {refDate?.current?.value && (
            <CloseIcon
              closeFilter={closeFilter}
              reference={refDate}
              name={"date"}
            />
          )}
          <div
            name="date"
            ref={refDate}
            className={Style.inputField}
            onClick={() => {
              setDateModalOpen(true);
            }}
          >
            <p>{refDate?.current?.value && refDate?.current?.value}</p>
          </div>
        </div>
      ),
    },
    {
      title: t("Columns.IssueDate"),
      field: "documentSent",
      hidden: isViewer? true : false,
      headerStyle: headerStyle,
      filterCellStyle: filterStyle,
      filterComponent: () => null,
      cellStyle: archivedCellStyle ? cellStyleDisabled : cellStyle,
      render: (row) => {
        if (row.documentSentDate) {
          return (
            <span>{moment(row.documentSentDate).format("DD.MM.YYYY")}</span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: t("Columns.Actions"),
      hidden: isViewer? true : false,
      headerStyle: headerStyle,
      filterComponent: () => null,
      field: "actions",
      cellStyle: archivedCellStyle
        ? violationsActionsCellStyleDisabled
        : violationsActionsCellStyle,
      render: (row) => (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu
            size="sm"
            style={{ color: "white", backgroundColor: "rgb(0, 90, 140)", overflowY:"scroll", maxHeight:"65vh" }}
            popperConfig={{
              strategy: "fixed",
              modifiers:[
                    {
                      name: 'preventOverflow',
                      enabled: true,
                      options: {
                        altAxis: true,
                        altBoundary: true,
                        tether: true,
                        rootBoundary: 'document',
                        padding: 0,
                      },
                    },
              ]
          }}
          >
            {!row.archived ? (
              <>
                <DropdownItem
                  onClickAction={() => {
                    setTicketAuditModal(true);
                    setTicketForAudit(row.number);
                  }}
                  translation={t("AuditTicket.History")}
                />

                <Dropdown.Item disabled>
                  {t("Violations.Menu.Status")}
                </Dropdown.Item>
                {dropdownItemFinancialStatus(row)}
                <Dropdown.Divider />
                <Dropdown.Item disabled>
                  {t("Violations.Menu.LegalStatusMenuLabel")}
                </Dropdown.Item>
                {row.status === ticketStatus.STOPPED ? (
                  <Dropdown.Item disabled>-</Dropdown.Item>
                ) : (
                  <DropdownItem
                    onClickAction={() =>
                      changeTicketLegalStatus(row.id, ticketLegalStatus.NONE)
                    }
                    translation="-"
                  />
                )}

                {row.status === ticketStatus.STOPPED ? (
                  <Dropdown.Item disabled>
                    {t("Violations.Menu.InvoiceSent")}
                  </Dropdown.Item>
                ) : (
                  <DropdownItem
                    onClickAction={() =>
                      changeTicketLegalStatus(
                        row.id,
                        ticketLegalStatus.INVOICE_SENT
                      )
                    }
                    translation={t("Violations.Menu.InvoiceSent")}
                  />
                )}

                {row.status === ticketStatus.STOPPED ? (
                  <Dropdown.Item disabled>
                    {t("Violations.Menu.Remind")}
                  </Dropdown.Item>
                ) : (
                  <DropdownItem
                    onClickAction={() =>
                      changeTicketLegalStatus(
                        row.id,
                        ticketLegalStatus.REMINDED
                      )
                    }
                    translation={t("Violations.Menu.Remind")}
                  />
                )}

                {row.status === ticketStatus.STOPPED ? (
                  <Dropdown.Item disabled>
                    {t("Violations.Menu.ToSue")}
                  </Dropdown.Item>
                ) : (
                  <DropdownItem
                    onClickAction={() =>
                      changeTicketLegalStatus(row.id, ticketLegalStatus.TO_SUE)
                    }
                    translation={t("Violations.Menu.ToSue")}
                  />
                )}

                {row.status === ticketStatus.STOPPED ? (
                  <Dropdown.Item disabled>
                    {t("Violations.Menu.Sued")}
                  </Dropdown.Item>
                ) : (
                  <DropdownItem
                    onClickAction={() =>
                      changeTicketLegalStatus(row.id, ticketLegalStatus.SUED)
                    }
                    translation={t("Violations.Menu.Sued")}
                  />
                )}
                <DropdownItem
                  disabled={row.status === ticketStatus.STOPPED}
                  onClickAction={() =>
                    changeTicketLegalStatus(
                      row.id,
                      ticketLegalStatus.TO_BE_ENFORCED
                    )
                  }
                  translation={t("Violations.Menu.ToBeEnforced")}
                />
                {row.status === ticketStatus.STOPPED ? (
                    <Dropdown.Item disabled>
                      {t("Violations.Menu.OperationDocSent")}
                    </Dropdown.Item>
                ) : (
                    <DropdownItem
                        onClickAction={() =>
                            changeTicketLegalStatus(
                                row.id,
                                ticketLegalStatus.OPERATION_DOC_SENT
                            )
                        }
                        translation={t("Violations.Menu.OperationDocSent")}
                    />
                )}

                <DropdownItem
                  disabled={row.status === ticketStatus.STOPPED}
                  onClickAction={() =>
                    changeTicketLegalStatus(
                      row.id,
                      ticketLegalStatus.PENALTY_FEE_DEMANDED
                    )
                  }
                  translation={t("Violations.Menu.PenaltyFeeDemanded")}
                />
                <DropdownItem
                  disabled={row.status === ticketStatus.STOPPED}
                  onClickAction={() =>
                    changeTicketLegalStatus(
                      row.id,
                      ticketLegalStatus.TERMINATED
                    )
                  }
                  translation={t("Violations.Menu.Close")}
                />
                {((row.status === ticketStatus.OVERDUE ||
                  row.status === ticketStatus.DONE ||
                    row.status === ticketStatus.CHECK &&
                    paymanetPeriodExpired(row.date, row.paymentPeriod))
                || row.status === ticketStatus.DOWNPAID
                ) && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.ItemText
                      style={{
                        color: "white",
                      }}
                    >
                      {t("Violations.Menu.DocumentLanguage")}
                      <select
                        type="text"
                        name={`docLang`}
                        style={{ marginLeft: "10px", borderRadius: "5px" }}
                        onChange={(e) => {
                          changeTicketDocumentLanguage(
                            row.number,
                            e.target.value
                          );
                        }}
                        value={
                          row.documentLanguage ? row.documentLanguage : "de"
                        }
                        defaultValue={"de"}
                      >
                        <option value="de" key={1}>
                          {"DE"}
                        </option>
                        <option value="fr" key={2}>
                          {"FR"}
                        </option>
                        <option value="it" key={3}>
                          {"IT"}
                        </option>
                      </select>
                    </Dropdown.ItemText>
                    <Dropdown.Divider style={{ opacity: 0.2 }} />
                    <DropdownItem
                      onClickAction={() => {
                        generateReminderPaymentSlip(
                          row.number,
                          row.licensePlate
                        );
                      }}
                      translation={t("Violations.Menu.CreateReminder")}
                    />
                  </>
                )}
                {(row.status === ticketStatus.ISSUED ||
                  row.status === ticketStatus.PENDING) && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.ItemText
                      style={{
                        color: "white",
                      }}
                    >
                      {t("Violations.Menu.DocumentLanguage")}
                      <select
                        type="text"
                        name={`docLang`}
                        style={{ marginLeft: "10px", borderRadius: "5px" }}
                        onChange={(e) => {
                          changeTicketDocumentLanguage(
                            row.number,
                            e.target.value
                          );
                        }}
                        value={
                          row.documentLanguage ? row.documentLanguage : "de"
                        }
                        defaultValue={"de"}
                      >
                        <option value="de" key={1}>
                          {"DE"}
                        </option>
                        <option value="fr" key={2}>
                          {"FR"}
                        </option>
                        <option value="it" key={3}>
                          {"IT"}
                        </option>
                      </select>
                    </Dropdown.ItemText>
                    <Dropdown.Divider style={{ opacity: 0.2 }} />
                    <DropdownItem
                      onClickAction={() => {
                        generatePaymentSlip(row.number, row.licensePlate);
                      }}
                      translation={t("Violations.Menu.CreatePaymentSlip")}
                    />
                  </>
                )}

                {(row.status === ticketStatus.NEW ||
                   row.status === ticketStatus.PENDING ||
                   row.status === ticketStatus.ISSUED ||
                   row.status === ticketStatus.OVERDUE ||
                   row.status === ticketStatus.DOWNPAID ||
                   row.status === ticketStatus.DONE)
                    && (
                    <DropdownItem
                      onClickAction={() => {
                        generateDocumentation(
                          row.number,
                          row.licensePlate)
                      }}
                      translation={t("GasStations.Menu.CreateSueDocumentation")}
                    />
                )}
                <Dropdown.Divider />
                <DropdownItem
                  onClickAction={() => {
                    toogleModalForComment();
                    setCommentDefaultValue(row.comment);
                    setTicketNumberInCommentModal(row.number);
                  }}
                  translation={t("Violations.Menu.Comment")}
                />
                {((row.legalStatus === ticketLegalStatus.TERMINATED &&
                  row.status === ticketStatus.PAID) ||
                  (row.legalStatus === ticketLegalStatus.TERMINATED &&
                    row.status === ticketStatus.STOPPED)) && (
                  <>
                    <Dropdown.Divider />
                    <DropdownItem
                      onClickAction={() => {
                        archiveTicket(row.number);
                      }}
                      translation={t("Violations.archive")}
                    />
                  </>
                )}
                {isSysAdmin && (
                  <>
                    <Dropdown.Divider />
                    <DropdownItem
                      onClickAction={() => {
                        setEditTicketModal(true);
                        setTicketForEdit(row);
                      }}
                      translation={t("Violations.editTicket")}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {((row.legalStatus === ticketLegalStatus.TERMINATED &&
                  row.status === ticketStatus.PAID) ||
                  (row.legalStatus === ticketLegalStatus.TERMINATED &&
                    row.status === ticketStatus.STOPPED)) && (
                  <>
                    <DropdownItem
                      onClickAction={() => {
                        archiveTicket(row.number);
                      }}
                      translation={t("Violations.unarchive")}
                    />
                  </>
                )}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ]);

  return columns;
}
