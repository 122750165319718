export const optionsAudit = () => {
  return {
    searchFieldAlignment: "left",
    pageSizeOptions: [5, 10],
    emptyRowsWhenPaging: false,
    search: false,
    pageSize: 10,
    minBodyHeight: 50,
    headerStyle: {
      backgroundColor: "rgb(242,242,242)",
      position: "sticky",
      zIndex: 2,
      height: 40,
      borderBottom: 0,
    },
    rowStyle: (rowData) => ({
      backgroundColor: "#fff",
      height: "55px",
    }),
  };
};

export const optionsGeneral = (maxHeight, filterRowStyleTop) => {
  return {
    showTitle: false,
    searchFieldAlignment: "left",
    filtering: true,
    emptyRowsWhenPaging: false,
    pageSize: 10,
    maxBodyHeight: maxHeight,
    minBodyHeight: 50,
    headerStyle: {
      backgroundColor: "rgb(242,242,242)",
      position: "sticky",
      zIndex: 2,
      height: 40,
      borderBottom: 0,
    },
    filterRowStyle: {
      position: "sticky",
      top: filterRowStyleTop,
      zIndex: 2,
      backgroundColor: "white",
    },
    searchFieldStyle: {
      height: 50,
      width: "100%",
      borderRadius: 4,
      backgroundColor: "white",
      shadowColor: "rgba(0, 0, 0, 0.05)",
      shadowOffset: {
        width: 1,
        height: 1,
      },
      shadowRadius: 10,
      shadowOpacity: 1,
    },
    rowStyle: (rowData) => ({
      backgroundColor: "#fff",
      height: "55px",
    }),
  };
};

export const optionsGeneralEnableDisable = (maxHeight, filterRowStyleTop) => {
  return {
    showTitle: false,
    searchFieldAlignment: "left",
    filtering: true,
    emptyRowsWhenPaging: false,
    pageSize: 10,
    maxBodyHeight: maxHeight,
    minBodyHeight: 50,
    headerStyle: {
      backgroundColor: "rgb(242,242,242)",
      position: "sticky",
      zIndex: 2,
      height: 40,
      borderBottom: 0,
    },
    filterRowStyle: {
      position: "sticky",
      top: filterRowStyleTop,
      zIndex: 2,
      backgroundColor: "white",
    },
    searchFieldStyle: {
      height: 50,
      width: "100%",
      borderRadius: 4,
      backgroundColor: "white",
      shadowColor: "rgba(0, 0, 0, 0.05)",
      shadowOffset: {
        width: 1,
        height: 1,
      },
      shadowRadius: 10,
      shadowOpacity: 1,
    },
    rowStyle: (rowData) => {
      if (rowData.enabled) {
        return {
          backgroundColor: "#fff",
          height: "55px",
        };
      } else {
        return {
          backgroundColor: "#C7C7C7",
          height: "55px",
        };
      }
    },
  };
};

export const optionsGeneralArchivedTickets = (maxHeight, filterRowStyleTop) => {
  return {
    showTitle: false,
    searchFieldAlignment: "left",
    filtering: true,
    emptyRowsWhenPaging: true,
    pageSize: 10,
    maxBodyHeight: maxHeight,
    minBodyHeight: 50,
    headerStyle: {
      backgroundColor: "rgb(242,242,242)",
      position: "sticky",
      zIndex: 2,
      height: 40,
      borderBottom: 0,
    },
    filterRowStyle: {
      position: "sticky",
      top: filterRowStyleTop,
      zIndex: 2,
      backgroundColor: "white",
    },
    searchFieldStyle: {
      height: 50,
      width: "100%",
      borderRadius: 4,
      backgroundColor: "white",
      shadowColor: "rgba(0, 0, 0, 0.05)",
      shadowOffset: {
        width: 1,
        height: 1,
      },
      shadowRadius: 10,
      shadowOpacity: 1,
    },
    rowStyle: (rowData) => {
      if (!rowData.archived) {
        return {
          backgroundColor: "#fff",
          height: "55px",
        };
      } else {
        return {
          backgroundColor: "#C7C7C7",
          height: "55px",
        };
      }
    },
  };
};
