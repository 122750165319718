import MaterialTable from "@material-table/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllOwners, getAllOwnersForPZAdmin } from "../../actions/owners";
import { changeTabInSidebar } from "../../actions/sidebar";
import { OwnersColumns } from "../../columns/ownersColumns";
import MagnifyingGlass from "../../common/assets/magnifying-glass.svg";
import { ROLES } from "../../consts";
import keycloak from "../../keycloak";
import LoadingIndicator from "../../reusable/loadingIndicator";
import { optionsGeneral } from "../../reusable/optionsForTables";
import Pagination from "../../reusable/pagination";
import {
  materialTableStyle,
  pageTitleStyle,
  paginationStyle,
  toolbarStyles,
  useStyles,
  useStylesForMaterial,
} from "../../reusable/styles";
import Sidebar from "../../sidebar/sidebar";
import {useAuth} from "react-oidc-context";

export default function Owners() {
  const auth = useAuth();
  const { t } = useTranslation();
  const classes = useStyles();
  const classesMaterial = useStylesForMaterial();
  const classesForm = toolbarStyles();
  const dispatch = useDispatch();
  const { owners } = useSelector((state) => state.owners);
  const { isLoading } = useSelector((state) => state.loader);
  const [height, setHeight] = useState(window.innerHeight);
  const isViewer = keycloak.hasRealmRole(ROLES.PARKING_VIEWER, auth);


  const handleResize = () => {
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  useEffect(() => {
    dispatch(changeTabInSidebar(1));
    if (keycloak.hasRealmRole(ROLES.SYSADMIN, auth)) {
      dispatch(getAllOwners());
    } else {
      dispatch(getAllOwnersForPZAdmin());
    }
  }, [dispatch]);

  function toolbar(props) {
    return (
      <div className={classesForm.root}>
        <div style={pageTitleStyle}>{ isViewer? t("Columns.Beppoliceman") : t("Owners.owners")}</div>
      </div>
    );
  }
  function pagination(props) {
    return (
      <td style={paginationStyle}>
        <Pagination translate={t} {...props} classes={{ ...classesMaterial }} />
      </td>
    );
  }

  return (
    <div className={classes.root}>
      <>
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />

          <Container maxWidth={false} className={classes.container}>
            {isLoading && <LoadingIndicator />}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className="text-center">
                  <div className="row content">
                    <div className="col-sm-12">
                      <div className={classes.paper}>
                        <MaterialTable
                          icons={{
                            Filter: () => (
                              <img src={MagnifyingGlass} alt="search" />
                            ),
                          }}
                          title={t("Owners.parkingZones")}
                          style={materialTableStyle}
                          data={owners}
                          columns={OwnersColumns(t)}
                          options={optionsGeneral(height - 240, 44)}
                          components={{
                            Toolbar: (props) => toolbar(props),
                            Pagination: (props) => pagination(props),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </main>
      </>
    </div>
  );
}
