import { GET_ALL_TICKET_SERIES, TICKETS_GENERATED } from "../actions/types";

const initialState = {
  ticketSeries: [],
  errorNotification: undefined,
};

const ticketSeriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TICKET_SERIES:
      return {
        ...state,
        ticketSeries: action.payload,
      };
    case TICKETS_GENERATED:
      return {
        ...state,
        ticketSeries: state.ticketSeries
          .reverse()
          .concat(action.payload)
          .reverse(),
      };

    default:
      return state;
  }
};

export default ticketSeriesReducer;
