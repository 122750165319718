import React from 'react';

export const AddressDisplay = ({ street, zipCode, city, countryCode, className}) => {
  return (
    <div className= {className || ""}>
      {`${street}, ${zipCode} ${city}, ${countryCode}`}
    </div>
  );
};


const AddressDisplaySingle = ({address}) => {
    const { city, zipCode, street, countryCode } = address || {};
    return address ? (<AddressDisplay
        street={street}
        zipCode={zipCode}
        city={city}
        countryCode={countryCode}
    />) : null;
};

export default AddressDisplaySingle;