import React, { useEffect } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Button from "../../common/button/button";
import { useForm } from "react-hook-form";
import InputFieldWithLabel from "../../common/input/inputFieldWithLabel";
import { editAdmin } from "../../actions/admin";
import { useDispatch } from "react-redux";
import { newModalStyle, submitButtonStyle } from "../../reusable/styles";
import { useTranslation } from "react-i18next";

const ModalEditAdmin = (props) => {
  const { handleSubmit, register, unregister, reset } = useForm({
    defaultValues: props.selectedAdmin,
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updateAdmin = (data) => {
    const dataForEdit = {
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      companyName: data.companyName,
    };

    dispatch(editAdmin(dataForEdit));
    props.close();
  };

  useEffect(() => {
    reset(props.selectedAdmin);
  }, [props.selectedAdmin, reset]);

  const closeModal = () => {
    props.close();
    unregister("firstName");
  };

  return (
    <Modal
      open={props.open}
      closeOnEs={true}
      onClose={closeModal}
      center
      styles={newModalStyle({ width: "450px" })}
    >
      <div className="modal__headerLine" />
      <div className="modal__title">Administrator</div>
      <form className="modalForm" onSubmit={handleSubmit(updateAdmin)}>
        <div className="form">
          <InputFieldWithLabel
            label={t("Admins.firstName")}
            name="firstName"
            required
            registerName="firstName"
            register={register}
          />
          <InputFieldWithLabel
            label={t("Admins.lastName")}
            name="lastName"
            registerName="lastName"
            required
            register={register}
          />

          <InputFieldWithLabel
            label="E-mail"
            name="emailAddress"
            disabled
            type="email"
            registerName="emailAddress"
            required
            register={register}
          />
          <InputFieldWithLabel
            label={t("Admins.phoneNumber")}
            name="phoneNumber"
            registerName="phoneNumber"
            register={register}
          />
          <InputFieldWithLabel
            label={t("Admins.companyName")}
            name="companyName"
            registerName="companyName"
            register={register}
          />
        </div>
        <Button text={t("Buttons.save")} buttonStyle={submitButtonStyle} />
      </form>
    </Modal>
  );
};
export default ModalEditAdmin;
