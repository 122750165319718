import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Sidebar from "../../sidebar/sidebar";
import MaterialTable from "@material-table/core";
import {
  useStyles,
  useStylesForMaterial,
  toolbarStyles,
  materialTableStyle,
  paginationStyle,
} from "../../reusable/styles";
import { useTranslation } from "react-i18next";
import Pagination from "../../reusable/pagination";
import { useDispatch, useSelector } from "react-redux";
import { optionsGeneralEnableDisable } from "../../reusable/optionsForTables";
import LoadingIndicator from "../../reusable/loadingIndicator";
import Style from "./viewer.module.css";
import { changeTabInSidebar } from "../../actions/sidebar";
import ErrorModal from "../../common/modal/errorModal";
import { mapUser } from "../../utils/mapUser";
import { ROLES } from "../../consts";
import MagnifyingGlass from "../../common/assets/magnifying-glass.svg";
import {
  checkViewerEmail, clearViewerData,
  closeDuplicateViewerModal,
  disableViewer,
  getAllViewers,
  getViewersByAdminId, removeViewerFromPZAdminsViewerList
} from "../../actions/viewer";
import ModalAddViewer from "./modalAddViewer";
import ModalAddViewerForm from "./modalAddViewerForm";
import {ViewerColumns} from "../../columns/viewerColumns";
import ModalEditViewer from "./modalEditViewer";
import {useAuth} from "react-oidc-context";

export default function Viewers() {
  const { t } = useTranslation();
  const [openAddViewerModal, setOpenAddViewerModal] = useState(false);
  const [openAddViewerModalForm, setOpenAddViewerModalForm] = useState(false);
  const [viewersEmail, setViewersEmail] = useState("");
  const [openEditViewerModal, setOpenEditViewerModal] = useState(false);
  const [selectedViewer, setSelectedViewer] = useState({});
  const classes = useStyles();
  const classesMaterial = useStylesForMaterial();
  const classesForm = toolbarStyles();
  const dispatch = useDispatch();
  const { viewers, duplicateViewer, viewer } = useSelector(
    (state) => state.viewer
  );
  const { isLoading } = useSelector((state) => state.loader);
  const auth = useAuth();

  const loggedUser = mapUser(auth);

  const [height, setHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  const closeModalDuplicatedViewer = () => {
    dispatch(closeDuplicateViewerModal());
  };

  const closeModal = () => {
    setOpenAddViewerModal(false);
    setOpenEditViewerModal(false);
    setSelectedViewer({});
  };

  const closeModalForm = () => {
    dispatch(clearViewerData());
    setOpenAddViewerModalForm(false);
  };

  useEffect(() => {
    dispatch(changeTabInSidebar(13));
    if (loggedUser?.roles?.includes(ROLES.SYSADMIN)) {
      dispatch(getAllViewers());
    } else {
      dispatch(getViewersByAdminId());
    }
  }, [dispatch, loggedUser?.roles]);

  function toolbar(props) {
    return (
      <div className={classesForm.root}>
        <div className={Style.header}>
          <div className={Style.header__text}>{t("Viewer.Viewers")}</div>

          <button
            className="addEntityButton"
            onClick={() => {
              setOpenAddViewerModal(true);
            }}
          >
            <p className={Style.header__addButtonText}>
              {t("Viewer.CreateViewer")}
            </p>
          </button>
        </div>
      </div>
    );
  }

  const disableViewerBySysAdmin = (email) => {
    dispatch(disableViewer(email));
  };

  const removeViewer = (email) => {
    dispatch(removeViewerFromPZAdminsViewerList(email));
  };

  function pagination(props) {
    return (
      <td style={paginationStyle}>
        <Pagination translate={t} {...props} classes={{ ...classesMaterial }} />
      </td>
    );
  }

  const checkViewer = (email) => {
    dispatch(checkViewerEmail(email));
    setViewersEmail(email);
    setOpenAddViewerModal(false);
    setOpenAddViewerModalForm(true);
  };

  return (
    <div className={classes.root}>
      <>
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth={false} className={classes.container}>
            {isLoading && <LoadingIndicator />}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className="text-center">
                  <div className="row content">
                    <div className="col-sm-12">
                      <div className={classes.paper}>
                        <MaterialTable
                          icons={{
                            Filter: () => (
                              <img src={MagnifyingGlass} alt="search" />
                            ),
                          }}
                          title="Parking zones"
                          style={materialTableStyle}
                          data={viewers?viewers:[]}
                          columns={ViewerColumns(
                            setOpenEditViewerModal,
                            setSelectedViewer,
                            t,
                            disableViewerBySysAdmin,
                            removeViewer
                          )}
                          options={optionsGeneralEnableDisable(
                            height - 240,
                            44
                          )}
                          components={{
                            Toolbar: (props) => toolbar(props),
                            Pagination: (props) => pagination(props),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </main>
        {openAddViewerModal && (
          <ModalAddViewer
            open={openAddViewerModal}
            close={closeModal}
            submit={checkViewer}
          />
        )}

        {openAddViewerModalForm && (
          <ModalAddViewerForm
            open={openAddViewerModalForm}
            close={closeModalForm}
            viewersEmail={viewersEmail}
            viewer={viewer}
          />
        )}

        {openEditViewerModal && (
          <ModalEditViewer
            open={openEditViewerModal}
            close={closeModal}
            selectedViewer={selectedViewer}
          />
        )}

        {duplicateViewer && (
          <ErrorModal
            title={t("DuplicateViewer.title")}
            errorMessage={t("DuplicateViewer.message")}
            open={duplicateViewer}
            onClose={() => closeModalDuplicatedViewer()}
            width="350px"
          />
        )}
      </>
    </div>
  );
}
