import {
  GET_ALL_REQUESTS,
  MARK_REQUEST_AS_SEEN,
  REMOVE_DELETE_USER_REQUEST_FROM_REQUESTS,
  REMOVE_PARKING_ZONE_OWNERSHIP_REQUEST_FROM_REQUESTS,
  REMOVE_TICKET_CANCELLATION_REQUEST_FROM_REQUESTS,
  REMOVE_GAS_STATION_OWNERSHIP_REQUEST_FROM_REQUESTS,
  REMOVE_TICKET_FEE_CANCELLATION_REQUEST_FROM_REQUESTS,
} from "../actions/types";
import { requestType } from "../globals";

const initialState = {
  requests: [],
};

const requestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REQUESTS:
      return {
        ...state,
        requests: action.payload,
      };
    case MARK_REQUEST_AS_SEEN:
      return {
        ...state,
        requests: state.requests.map((r) => {
          if (r.id === action.payload.id && r.type === action.payload.type) {
            return action.payload;
          }
          return r;
        }),
      };

    case REMOVE_TICKET_CANCELLATION_REQUEST_FROM_REQUESTS:
      return {
        ...state,
        requests: state.requests.filter(
          (r) =>
            r !==
            state.requests.find(
              (req) =>
                req.id === action.payload &&
                req.type === requestType.TICKET_CANCELLATION_REQUEST
            )
        ),
      };

    case REMOVE_TICKET_FEE_CANCELLATION_REQUEST_FROM_REQUESTS:
      return {
        ...state,
        requests: state.requests.filter(
          (r) =>
            r !==
            state.requests.find(
              (req) =>
                req.id === action.payload &&
                req.type === requestType.TICKET_FEE_CANCELLATION_REQUEST
            )
        ),
      };

    case REMOVE_PARKING_ZONE_OWNERSHIP_REQUEST_FROM_REQUESTS:
      return {
        ...state,
        requests: state.requests.filter((r) => {
          return (
            r !==
            state.requests.find(
              (req) =>
                req.id === action.payload &&
                req.type === requestType.PARKING_ZONE_OWNERSHIP_REQUEST
            )
          );
        }),
      };

    case REMOVE_GAS_STATION_OWNERSHIP_REQUEST_FROM_REQUESTS:
      return {
        ...state,
        requests: state.requests.filter((r) => {
          return (
            r !==
            state.requests.find(
              (req) =>
                req.id === action.payload &&
                req.type === requestType.GAS_STATION_OWNERSHIP_REQUEST
            )
          );
        }),
      };

    case REMOVE_DELETE_USER_REQUEST_FROM_REQUESTS:
      return {
        ...state,
        requests: state.requests.filter((r) => {
          return (
            r !==
            state.requests.find(
              (req) =>
                req.id === action.payload &&
                req.type === requestType.DELETE_USER_REQUEST
            )
          );
        }),
      };

    default:
      return state;
  }
};

export default requestsReducer;
