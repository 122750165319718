import React from "react";
import Objection from "../common/assets/objection.svg";
import moment from "moment";
import { cellStyle, filterStyle, headerStyle } from "./columnsGeneral";
import { ticketSeriesType } from "../globals";

export function TicketSeriesColumns(t) {
  const getTicketSeriesTypeTranslation = (type) => {
    switch (type) {
      case ticketSeriesType.PARKING_ZONE:
        return t("TicketSeriesType.PARKING_ZONE");
      case ticketSeriesType.DRIVE_OFF:
        return t("TicketSeriesType.DRIVE_OFF");
      default:
        return "";
    }
  };

  return [
    {
      title: t("Columns.Date"),
      field: "date",
      cellStyle: cellStyle,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      render: (row) => {
        return moment(row.date).format("DD.MM.YYYY");
      },
      customFilterAndSearch: (searchValue, row) => {
        if (
          moment(row.date).format("DD.MM.YYYY").toString().includes(searchValue)
        ) {
          return true;
        }
      },
    },
    {
      title: t("Columns.Serial"),
      field: "number",
      cellStyle: cellStyle,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Size"),
      field: "size",
      cellStyle: cellStyle,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.TicketSeriesType"),
      field: "ticketSeriesType",
      cellStyle: cellStyle,
      filterCellStyle: filterStyle,
      headerStyle: headerStyle,
      render: (row) => getTicketSeriesTypeTranslation(row.ticketSeriesType),
    },
    {
      title: t("Columns.Attachment"),
      headerStyle: {
        ...headerStyle,
        textAlign: "center",
      },
      filterComponent: () => null,
      cellStyle: {
        ...cellStyle,
        textAlign: "center",
      },
      render: (row) => {
        if (row.attachmentUrl) {
          return (
            <img
              src={Objection}
              style={{ cursor: "pointer" }}
              id="ticket-attachment"
              alt="ticket-attachment"
              height={20}
              onClick={() => {
                window.open(row.attachmentUrl, "_blank");
              }}
            />
          );
        }
        return null;
      },
    },
  ];
}
