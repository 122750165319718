
export const mapUser = (auth) => {
  if (auth.isLoading || !auth.isAuthenticated) {
    return null;
  }
  return {
    id: auth.user.profile.sub,
    roles: auth.user.profile?.roles,
    firstName: auth.user.profile?.given_name,
    lastName: auth.user.profile?.family_name,
    username: auth.user.profile?.username,
    email: auth.user.profile?.email,
    licensePlate: auth.user.profile?.license_plate
      ? auth.user.profile?.license_plate[0]
      : undefined,
    profileImageUrl: auth.user.profile?.profile_picture
      ? auth.user.profile?.profile_picture[0]
      : undefined,
    phoneNumber: auth.user.profile?.phone_number
      ? auth.user.profile?.phone_number[0]
      : undefined,
    address: auth.user.profile?.address
      ? auth.user.profile?.address[0]
      : undefined,
  };
};
