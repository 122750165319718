import React, { useEffect, useState, useRef } from "react";
import { MultiSelect } from "react-multi-select-component";
import Sidebar from "../../sidebar/sidebar";
import Container from "@material-ui/core/Container";
import LoadingIndicator from "../../reusable/loadingIndicator";
import {useStyles, toolbarStyles, materialTableStyle} from "../../reusable/styles";
import Style from "./report.module.css";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import Button from "../../common/button/button";
import ChartBar from "../../common/chart/bar"
import Divider from "@material-ui/core/Divider";
import {
  getAllParkingZones,
  getAllGasStations,
  getAllParkingZonesForPZAdmin,
  getAllGasStationsForGSAdmin,
  fetchChartData,
  exportExcel,
} from "../../actions/report";
import keycloak from "../../keycloak";
import { ROLES } from "../../consts";
import { useParams } from "react-router-dom";
import { colors } from '../../globals';
import {useAuth} from "react-oidc-context";


export default function Report() {
    const auth = useAuth();
  const { reportType } = useParams();
  const [selectedPZ, setSelectedPZ] = useState([]);
  const classes = useStyles();
  const {isLoading} = useSelector((state) => state.loader);
  const { t } = useTranslation();
  const classesForm = toolbarStyles();
  const dispatch = useDispatch();
  const { parkingZones } = useSelector((state) => state.report);
  const { gsZones } = useSelector((state) => state.report);
  const { chartData } = useSelector((state) => state.report);
  const initialRender = useRef(true);


  useEffect(() => {
      if (keycloak.hasRealmRole(ROLES.SYSADMIN, auth)) {
          if(reportType === "VIOLATIONS"){
            dispatch(getAllParkingZones());
          } else {
            dispatch(getAllGasStations());
          }
      } else {
          if(reportType == "VIOLATIONS"){
            dispatch(getAllParkingZonesForPZAdmin());
          } else {
            dispatch(getAllGasStationsForGSAdmin());
          }
      }
    }, [dispatch, reportType]);

    useEffect(() => {
        if (initialRender.current) {
          initialRender.current = false;
        } else {
          if (selectedPZ.length > 0) {
            dispatch(fetchChartData(selectedPZ.map(zone => zone.value), reportType));
          } else {
              // Reset chart data when no zones are selected
              dispatch({ type: 'RESET_CHART_DATA' });
          }
        }
    }, [selectedPZ, reportType, dispatch]);

    useEffect(() => {
        if (reportType == "VIOLATIONS" && parkingZones.length > 0) {
          setSelectedPZ(parkingZones.map(zone => ({
                value: zone.parkingZoneNumber,
                label: `${zone.parkingZoneNumber} - ${zone.parkingZoneName}`
              })))
        }
    }, [parkingZones]);

    useEffect(() => {
        if (reportType == "DRIVE_OFFS" && gsZones.length > 0) {
          setSelectedPZ(gsZones.map(zone => ({
                value: zone.number,
                label: `${zone.number} - ${zone.name}`
              })))
        }
    }, [gsZones]);

    let pzOptions;
    if(reportType == "VIOLATIONS"){
        pzOptions = parkingZones.map(zone => ({
                value: zone.parkingZoneNumber,
                label: `${zone.parkingZoneNumber} - ${zone.parkingZoneName}`
              }));
    } else {
        pzOptions = gsZones.map(zone => ({
                value: zone.number,
                label: `${zone.number} - ${zone.name}`
              }));
    }


      const handleExportExcel = async () => {
          await dispatch(exportExcel(selectedPZ.map(zone => zone.value), reportType, localStorage.getItem("beppoSelectedLanguage")))
      };


      const labels = chartData.chartLabels;
      const datasetsForTickets = [
        {
          label: t("Reports.Open"),
          backgroundColor: colors.blue,
          borderColor: colors.blue,
          borderWidth: 1,
          data: chartData.numberOfTickets ? chartData.numberOfTickets.OPEN : [],
        },
        {
          label: t("Reports.Paid"),
          backgroundColor: colors.green,
          borderColor: colors.green,
          borderWidth: 1,
          data: chartData.numberOfTickets ? chartData.numberOfTickets.PAID : [],
        },
        {
          label: t("Reports.Closed"),
          backgroundColor: colors.gray,
          borderColor: colors.gray,
          borderWidth: 1,
          data: chartData.numberOfTickets ? chartData.numberOfTickets.CLOSED : [],
        },
        {
          label: t("Reports.Cancelled"),
          backgroundColor: colors.red,
          borderColor: colors.red,
          borderWidth: 1,
          data: chartData.numberOfTickets ? chartData.numberOfTickets.CANCELLED : [],
        },
      ];

      const datasetsForCompensations = [
        {
          label: t("Reports.Open"),
          backgroundColor: colors.blue,
          borderColor: colors.blue,
          borderWidth: 1,
          data: chartData.compensationValue ? chartData.compensationValue.OPEN : [],
        },
        {
          label: t("Reports.Paid"),
          backgroundColor: colors.green,
          borderColor: colors.green,
          borderWidth: 1,
          data: chartData.compensationValue ? chartData.compensationValue.PAID : [],
        },
        {
          label: t("Reports.Closed"),
          backgroundColor: colors.gray,
          borderColor: colors.gray,
          borderWidth: 1,
          data: chartData.compensationValue ? chartData.compensationValue.CLOSED : [],
        },
        {
          label: t("Reports.Cancelled"),
          backgroundColor: colors.red,
          borderColor: colors.red,
          borderWidth: 1,
          data: chartData.compensationValue ? chartData.compensationValue.CANCELLED : [],
        },
      ];


    const selectedZoneNames = selectedPZ.map(zone => {
        return `${zone.label}`;
    });

    let titleNumberOfTickets;
    let titleCompensation;
    if (selectedPZ.length > 0) {
        if (selectedPZ.length > 3) {
            titleNumberOfTickets = <span dangerouslySetInnerHTML={{__html: `<b>${t("Reports.NoOfTickets")}</b> | ${selectedZoneNames.slice(0, 3).join(', ')}, ...`}} />;
            titleCompensation = <span dangerouslySetInnerHTML={{__html: `<b>${reportType === "VIOLATIONS" ? t("Reports.CompensationValueCHF") : t("Reports.FuelValueCHF")}</b> | ${selectedZoneNames.slice(0, 3).join(', ')}, ...`}} />;
        } else {
            titleNumberOfTickets = <span dangerouslySetInnerHTML={{__html: `<b>${t("Reports.NoOfTickets")}</b> | ${selectedZoneNames.slice(0, 3).join(', ')}`}} />;
            titleCompensation = <span dangerouslySetInnerHTML={{__html: `<b>${reportType === "VIOLATIONS" ? t("Reports.CompensationValueCHF") : t("Reports.FuelValueCHF")}</b> | ${selectedZoneNames.slice(0, 3).join(', ')}`}} />;
        }
    } else {
        titleNumberOfTickets = <span dangerouslySetInnerHTML={{__html: `<b>${t("Reports.NoOfTickets")}</b>`}} />;
        titleCompensation = <span dangerouslySetInnerHTML={{__html: `<b>${reportType === "VIOLATIONS" ? t("Reports.CompensationValueCHF") : t("Reports.FuelValueCHF")}</b>`}} />;
    }

return (
    <div className={classes.root}>
      <>
        <Sidebar/>
        <main className={classes.content}>
          <div className={classes.appBarSpacer}/>
          <Container maxWidth={false} className={classes.container}>
            {isLoading && <LoadingIndicator/>}

            <div className={classesForm.root}>
              <Grid container direction="row" className={Style.header}>
                <p className={Style.header__text}>
                  {reportType === "VIOLATIONS"
                    ? t("Reports.ParkingViolations")
                    : reportType === "DRIVE_OFFS"
                    ? t("Reports.DriveOffs")
                    : ""}
                </p>
                <div style={{ display: 'flex'}}>
                    <p className={Style.label__text}>
                        {reportType === "VIOLATIONS"
                        ? t("Reports.SelectParkingZones")
                        : reportType === "DRIVE_OFFS"
                        ? t("Reports.SelectGasStation")
                        : ""}
                    </p>
                    <MultiSelect
                      options={pzOptions}
                      value={selectedPZ}
                      onChange={setSelectedPZ}
                      labelledBy="Select..."
                      className={Style.multiSelectContainer}
                    />
                <Button
                    style={{ width: '100px', height: '10px'}}
                    buttonStyle={{ height: "40px" }}
                    className={Style.exportButton}
                    text={t("Reports.Export")}
                    onClick={() => handleExportExcel()}
                />
                </div>
              </Grid>
            </div>
            <div className={Style.scrollableDiv}>
                <Divider/>
                {chartData.numberOfTickets ? (
                 <>
                    <ChartBar labels={labels} data={datasetsForTickets} title={titleNumberOfTickets}/>
                    <Divider style={{marginTop: "30px"}}/>
                    <ChartBar labels={labels} data={datasetsForCompensations} title={titleCompensation}/>
                    <Divider style={{marginTop: "30px"}}/>
                 </>
                ):
                (
                    <p>{t("Reports.NoDataAvailable")}</p>
                )}
            </div>


            </Container>
        </main>
    </>
    </div>
  );
}