import React, { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import {
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { mapUser } from "../utils/mapUser";
import { AuthorizedElement } from "../utils/AuthorizedElement";
import { ROLES } from "../consts";
import Users from "../common/assets/Users.svg";
import Bell from "../common/assets/bell.svg";
import Car from "../common/assets/car.svg";
import Ticket from "../common/assets/ticket.svg";
import Container from "@material-ui/core/Container";
import ResetPasswordModal from "./resetPasswordModal";
import { useStylesSidebar } from "../reusable/styles";
import { useDispatch, useSelector } from "react-redux";
import { changeTabInSidebar } from "../actions/sidebar";
import CarBlue from "../common/assets/car-blue.svg";
import Shield from "../common/assets/shield-alt.svg";
import ShieldBlue from "../common/assets/shield-alt-blue.svg";
import TicketBlue from "../common/assets/ticket-blue.svg";
import BellBlue from "../common/assets/bell-blue.svg";
import UsersBlue from "../common/assets/Users-blue.svg";
import Style from "./listItems.module.css";
import LanguageMenu from "../reusable/languageMenu";
import GasStation from "../common/assets/gasStation.svg";
import GasStationBlue from "../common/assets/gasStationBlue.svg";
import FastDriveBlue from "../common/assets/fastDriveBlue.svg";
import FastDrive from "../common/assets/fastDrive.svg";
import Prohibition from "../common/assets/Prohibition.svg";
import ProhibitionBlue from "../common/assets/ProhibitionBlue.svg";
import ChartBar from "../common/assets/chart-bar.svg"
import ChartBarBlue from "../common/assets/chart-bar-blue.svg"
import Handshake from "../common/assets/handshake.svg"
import HandshakeBlue from "../common/assets/handshake-blue.svg"
import {reportType} from "../globals";
import keycloak from "../keycloak";
import {useAuth} from "react-oidc-context";


export const MainListItems = (props) => {
    const auth = useAuth();
  const { t } = useTranslation();
  const classes = useStylesSidebar();
  const dispatch = useDispatch();
  const { selected } = useSelector((state) => state.sidebar);
  const isViewer = keycloak.hasRealmRole(ROLES.PARKING_VIEWER, auth);

  const changeTab = (tabID) => {
    dispatch(changeTabInSidebar(tabID));
  };

  const linkStyle = { color: "white", borderBottom: "1px solid white" };

  return (
    <div className={classes.root}>
      <AuthorizedElement
        roles={[
          ROLES.SYSADMIN,
          ROLES.PARKING_ADMIN,
          ROLES.PARKING_MANAGER,
          ROLES.GAS_STATION_ADMIN,
          ROLES.GAS_STATION_MANAGER,
        ]}
      >
        <div style={{ display: "flex"}}>
          <Link to="/requests" style={linkStyle}>
            <ListItem
              style={{height: '40px' }}
              button
              onClick={() => changeTab(6)}
              selected={selected === 6}
              classes={{
                selected: classes.selected,
              }}
            >
              {selected === 6 ? (
                <ListItemIcon>
                  <img
                    src={BellBlue}
                    id="beppoLogo"
                    alt="beppo-logo"
                    fill="#FFFFFF"
                  />
                </ListItemIcon>
              ) : (
                <ListItemIcon>
                  <img
                    src={Bell}
                    id="beppoLogo"
                    alt="beppo-logo"
                    fill="#FFFFFF"
                  />
                </ListItemIcon>
              )}

              <ListItemText primary={t("Sidebar.Requests")} />
            </ListItem>
          </Link>
          <ListItem
            button
            selected={selected === 6}
            style={{ borderBottom: "1px solid white" , height: "41px"}}
            classes={{
              selected: classes.selected,
            }}
          >
            <ListItemText
              primary={props.requestsNumber}
              className={Style.numberOfRequests}
              style={{ flex: 0.5 }}
            />
          </ListItem>
        </div>
      </AuthorizedElement>
      <AuthorizedElement
        roles={[ROLES.SYSADMIN, ROLES.PARKING_ADMIN, ROLES.PARKING_MANAGER, ROLES.PARKING_VIEWER]}
      >
        <Link to="/parkingZones" style={linkStyle}>
          <ListItem
            style={{height: '40px' }}
            button
            selected={selected === 0}
            onClick={() => changeTab(0)}
            classes={{
              selected: classes.selected,
            }}
          >
            {selected === 0 ? (
              <ListItemIcon>
                <img
                  src={CarBlue}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <img src={Car} id="beppoLogo" alt="beppo-logo" fill="#FFFFFF" />
              </ListItemIcon>
            )}

            <ListItemText
              primary={t("Sidebar.Parking zones")}
              primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
            ></ListItemText>
          </ListItem>
        </Link>
      </AuthorizedElement>

      <AuthorizedElement
        roles={[ROLES.SYSADMIN, ROLES.PARKING_ADMIN, ROLES.PARKING_MANAGER, ROLES.PARKING_VIEWER]}
      >
        <Link to="/violations" style={linkStyle}>
          <ListItem
            style={{height: '40px' }}
            button
            onClick={() => changeTab(2)}
            selected={selected === 2}
            classes={{
              selected: classes.selected,
            }}
          >
            {selected === 2 ? (
              <ListItemIcon>
                <img
                  src={ProhibitionBlue}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <img
                  src={Prohibition}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            )}

            <ListItemText primary={t("Sidebar.Violations")} />
          </ListItem>
        </Link>
      </AuthorizedElement>

      <AuthorizedElement roles={[ROLES.SYSADMIN, ROLES.PARKING_ADMIN, ROLES.PARKING_MANAGER, ROLES.PARKING_VIEWER]}>
        <Link to={`/report/${reportType.VIOLATIONS}`} style={linkStyle}>
          <ListItem
            button
            style={{ borderBottom: "1px solid white",height: '40px' }}
            onClick={() => changeTab(11)}
            selected={selected === 11}
            classes={{
              selected: classes.selected,
            }}
          >
            {selected === 11 ? (
              <ListItemIcon>
                <img
                  src={ChartBarBlue}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <img
                  src={ChartBar}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            )}
            <ListItemText primary={t("Sidebar.Report")} />
          </ListItem>
        </Link>
      </AuthorizedElement>

      <AuthorizedElement
        roles={[
          ROLES.SYSADMIN,
          ROLES.GAS_STATION_ADMIN,
          ROLES.GAS_STATION_MANAGER,
          ROLES.GAS_STATION_VIEWER
        ]}
      >
        <Link to="/gasStations" style={linkStyle}>
          <ListItem
            style={{height: '40px' }}
            button
            selected={selected === 8}
            onClick={() => changeTab(8)}
            classes={{
              selected: classes.selected,
            }}
          >
            {selected === 8 ? (
              <ListItemIcon>
                <img
                  src={GasStationBlue}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <img
                  src={GasStation}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            )}

            <ListItemText
              primary={t("Sidebar.Gas stations")}
              primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
            ></ListItemText>
          </ListItem>
        </Link>
      </AuthorizedElement>

      <AuthorizedElement
        roles={[ROLES.SYSADMIN,
            ROLES.GAS_STATION_ADMIN,
            ROLES.GAS_STATION_MANAGER,
            ROLES.GAS_STATION_VIEWER]}
      >
        <Link to="/driveOff" style={linkStyle}>
          <ListItem
            style={{height: '40px' }}
            button
            onClick={() => changeTab(9)}
            selected={selected === 9}
            classes={{
              selected: classes.selected,
            }}
          >
            {selected === 9 ? (
              <ListItemIcon>
                <img
                  src={FastDriveBlue}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <img
                  src={FastDrive}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            )}

            <ListItemText primary={t("Sidebar.DriveOff")} />
          </ListItem>
        </Link>
      </AuthorizedElement>
      <AuthorizedElement roles={[ROLES.SYSADMIN, ROLES.GAS_STATION_ADMIN, ROLES.GAS_STATION_MANAGER, ROLES.GAS_STATION_VIEWER]}>
        <Link to={`/report/${reportType.DRIVE_OFFS}`} style={linkStyle}>
          <ListItem
            button
            style={{ borderBottom: "1px solid white",height: '40px' }}
            onClick={() => changeTab(12)}
            selected={selected === 12}
            classes={{
              selected: classes.selected,
            }}
          >
            {selected === 12 ? (
              <ListItemIcon>
                <img
                  src={ChartBarBlue}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <img
                  src={ChartBar}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            )}
            <ListItemText primary={t("Sidebar.Report")} />
          </ListItem>
        </Link>
      </AuthorizedElement>

      <AuthorizedElement roles={[ROLES.SYSADMIN]}>
              <Link to={`/client`} style={linkStyle}>
                <ListItem
                  button
                  style={{ borderBottom: "1px solid white",height: '40px' }}
                  onClick={() => changeTab(14)}
                  selected={selected === 14}
                  classes={{
                    selected: classes.selected,
                  }}
                >
                  {selected === 14 ? (
                    <ListItemIcon>
                      <img
                        src={HandshakeBlue}
                        id="beppoLogo"
                        alt="beppo-logo"
                        fill="#FFFFFF"
                      />
                    </ListItemIcon>
                  ) : (
                    <ListItemIcon>
                      <img
                        src={Handshake}
                        id="beppoLogo"
                        alt="beppo-logo"
                        fill="#FFFFFF"
                      />
                    </ListItemIcon>
                  )}
                  <ListItemText primary={t("Sidebar.Clients")} />
                </ListItem>
              </Link>
            </AuthorizedElement>

      <AuthorizedElement roles={[ROLES.SYSADMIN]}>
        <Link to="/admins" style={linkStyle}>
          <ListItem
            style={{height: '40px' }}
            button
            onClick={() => changeTab(3)}
            selected={selected === 3}
            classes={{
              selected: classes.selected,
            }}
          >
            {selected === 3 ? (
              <ListItemIcon>
                <img
                  src={ShieldBlue}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <img
                  src={Shield}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            )}
            <ListItemText primary={t("Sidebar.Admins")} />
          </ListItem>
        </Link>
      </AuthorizedElement>
      <AuthorizedElement
        roles={[ROLES.SYSADMIN, ROLES.PARKING_ADMIN, ROLES.GAS_STATION_ADMIN]}
      >
        <Link to="/managers" style={linkStyle}>
          <ListItem
            style={{height: '40px' }}
            button
            onClick={() => changeTab(7)}
            selected={selected === 7}
            classes={{
              selected: classes.selected,
            }}
          >
            {selected === 7 ? (
              <ListItemIcon>
                <img
                  src={ShieldBlue}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <img
                  src={Shield}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            )}
            <ListItemText primary={t("Sidebar.Managers")} />
          </ListItem>
        </Link>
      </AuthorizedElement>

        <AuthorizedElement
            roles={[ROLES.SYSADMIN, ROLES.PARKING_ADMIN, ROLES.GAS_STATION_ADMIN]}
        >
            <Link to="/viewers" style={linkStyle}>
                <ListItem
                    style={{height: '40px' }}
                    button
                    onClick={() => changeTab(13)}
                    selected={selected === 13}
                    classes={{
                        selected: classes.selected,
                    }}
                >
                    {selected === 13 ? (
                        <ListItemIcon>
                            <img
                                src={ShieldBlue}
                                id="beppoLogo"
                                alt="beppo-logo"
                                fill="#FFFFFF"
                            />
                        </ListItemIcon>
                    ) : (
                        <ListItemIcon>
                            <img
                                src={Shield}
                                id="beppoLogo"
                                alt="beppo-logo"
                                fill="#FFFFFF"
                            />
                        </ListItemIcon>
                    )}
                    <ListItemText primary={t("Sidebar.Viewers")} />
                </ListItem>
            </Link>
        </AuthorizedElement>

      <AuthorizedElement
        roles={[
          ROLES.SYSADMIN,
          ROLES.PARKING_ADMIN,
          ROLES.PARKING_MANAGER,
          ROLES.GAS_STATION_ADMIN,
          ROLES.GAS_STATION_MANAGER,
          ROLES.PARKING_VIEWER,
          ROLES.GAS_STATION_VIEWER
        ]}
      >
        <Link to="/owners" style={linkStyle}>
          <ListItem
            style={{height: '40px' }}
            button
            onClick={() => changeTab(1)}
            selected={selected === 1}
            classes={{
              selected: classes.selected,
            }}
          >
            {selected === 1 ? (
              <ListItemIcon>
                <img
                  src={UsersBlue}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <img
                  src={Users}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            )}

            <ListItemText primary={isViewer? t("Columns.Beppoliceman"): t("Sidebar.Owners")} />
          </ListItem>
        </Link>
      </AuthorizedElement>

      <AuthorizedElement roles={[ROLES.SYSADMIN]}>
        <Link to="/users" style={linkStyle}>
          <ListItem
            button
            onClick={() => changeTab(5)}
            selected={selected === 5}
            classes={{
              selected: classes.selected,
            }}
            style={{ borderBottom: "1px solid white",height: '40px' }}
          >
            {selected === 5 ? (
              <ListItemIcon>
                <img
                  src={UsersBlue}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <img
                  src={Users}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            )}
            <ListItemText primary={t("Sidebar.Users")} />
          </ListItem>
        </Link>
      </AuthorizedElement>

      <AuthorizedElement roles={[ROLES.SYSADMIN]}>
        <Link to="/ticketSeries" style={{ color: "white" }}>
          <ListItem
            button
            onClick={() => changeTab(4)}
            selected={selected === 4}
            style={{ borderBottom: "1px solid white",height: '40px' }}
            classes={{
              selected: classes.selected,
            }}
          >
            {selected === 4 ? (
              <ListItemIcon>
                <img
                  src={TicketBlue}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <img
                  src={Ticket}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            )}
            <ListItemText primary={t("Sidebar.TicketSeries")} />
          </ListItem>
        </Link>
      </AuthorizedElement>

      <AuthorizedElement roles={[ROLES.SYSADMIN]}>
        <Link to="/billingInfo" style={linkStyle}>
          <ListItem
            style={{height: '40px' }}
            button
            onClick={() => changeTab(10)}
            selected={selected === 10}
            classes={{
              selected: classes.selected,
            }}
          >
            {selected === 10 ? (
              <ListItemIcon>
                <img
                  src={ShieldBlue}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <img
                  src={Shield}
                  id="beppoLogo"
                  alt="beppo-logo"
                  fill="#FFFFFF"
                />
              </ListItemIcon>
            )}
            <ListItemText primary={t("Sidebar.BillingInfo")} />
          </ListItem>
        </Link>
      </AuthorizedElement>

    </div>
  );
};

export const UserDetails = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = mapUser(auth);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const selectedLanguage =
    localStorage.getItem("beppoSelectedLanguage") || "DE";


  return (
    <div>
      <Navbar bg="white" expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              <div className="test">
                <Avatar alt="Test">
                  {user?.firstName?.charAt(0) + "" + user?.lastName?.charAt(0)}
                </Avatar>
                <NavDropdown
                  title={`${user?.firstName} ${user?.lastName}`}
                  id="basic-nav-dropdown"
                  drop="left"
                >
                  <NavDropdown.Item
                    onClick={() => {
                      setResetPasswordModalOpen(true);
                    }}
                  >
                    {t("Columns.ChangePassword")}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown
                    title={selectedLanguage.toUpperCase()}
                    id="basic-nav-dropdown"
                    drop="left"
                  >
                    <LanguageMenu />
                  </NavDropdown>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    onClick={() => {
                      auth.signoutRedirect({
                          post_logout_redirect_uri: `${process.env.REACT_APP_FRONTEND_URL}/`,
                      });
                    }}
                  >
                    {t("Columns.Logout")}
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {resetPasswordModalOpen && (
        <ResetPasswordModal
          resetPasswordModalOpen={resetPasswordModalOpen}
          close={() => setResetPasswordModalOpen(false)}
        />
      )}
    </div>
  );
};
