import {FormControl, FormControlLabel, Radio, RadioGroup,} from "@material-ui/core";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {generateNewTickets} from "../../actions/ticket";
import Button from "../../common/button/button";
import InputFieldWithLabel from "../../common/input/inputFieldWithLabel";
import SelectWithLabel from "../../common/input/selectWithLabel";
import {
  createErrorNotification,
  createInfoNotification,
  createSuccessNotification,
} from "../../common/notifications/notification";
import {supportedCountries} from "../../globals";
import {newModalStyle} from "../../reusable/styles";

const ModalGenerateTickets = (props) => {
  const {
    handleSubmit,
    watch,
    register,
    reset,
      formState: {errors}
  } =
      useForm({
        defaultValues: {selectedLanguage: "0", selectedCountry: "0"},
        mode: "all",
      });
  const dispatch = useDispatch();
  const watchAmount = watch("amount");
  const watchLanguage = watch("selectedLanguage");
  const watchCountry = watch("selectedCountry");
  const { t } = useTranslation();
  const [ticketType, setTicketType] = useState("PZ");

  const handleTicketType = (event) => {
    setTicketType(event.target.value);
  };

  const generate = (data) => {
    const successNotification = () =>
      createSuccessNotification(
        t("Ticket.CreatedSuccessfully"),
        t("notifications.success")
      );

    const errorNotification = () =>
      createErrorNotification(
        t("Ticket.CreatedUnsuccessfully"),
        t("notifications.error")
      );
    const infoNotification = () =>
      createInfoNotification(
        t("Ticket.CreatedPostponed"),
        t("notifications.info")
      );

    const createSeriesDto = {
      size: data.amount,
      paymentPeriod: data.paymentPeriod,
      country: data.selectedCountry,
    };

    dispatch(
      generateNewTickets(
        createSeriesDto,
        successNotification,
        errorNotification,
        infoNotification,
        data.selectedLanguage,
        ticketType
      )
    );
    props.close();
    reset();
  };

  const closeModal = () => {
    props.close();
    reset();
  };

  const submitDisabled = !watchAmount || watchLanguage === "0" || watchCountry === "0";
  
  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={closeModal}
      center
      styles={newModalStyle({ width: "400px" })}
    >
      <div className="modal__headerLine" />
      <div className="modal__title">{t("Ticket.Generate Tickets Modal")}</div>
      <form className="modalForm" onSubmit={handleSubmit(generate)}>
        <div className="form">
          <InputFieldWithLabel
            label={t("Ticket.Amount")}
            pattern="^([1-9]|[1-9][0-9]|[1-9][0-9][0-9]|1000)"
            name="amount"
            registerName="amount"
            register={register}
            required
          />
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={ticketType}
              onChange={handleTicketType}
              row
            >
              <FormControlLabel
                value="PZ"
                control={<Radio />}
                label="Parking Zone"
              />
              <FormControlLabel
                value="DO"
                control={<Radio />}
                label="Drive Off"
              />
            </RadioGroup>
          </FormControl>
          <InputFieldWithLabel
            label={t("Ticket.paymentPeriod")}
            name="paymentPeriod"
            registerName="paymentPeriod"
            defaultValue={14}
            register={register}
            required
          />
          <SelectWithLabel
            name="selectedLanguage"
            style={{ backgroundColor: "white" }}
            whiteBackground
            registerName="selectedLanguage"
            label={t("Ticket.SelectLangauge")}
            register={register}
            required={true}
            defaultOption={t("Ticket.SelectLangauge")}
            options={["DE","FR","IT"]?.map((language, index) => {
              return (
                <option key={index + 1} value={language}>
                  {language}
                </option>
              );
            })}
          />
          <SelectWithLabel
              name="selectedCountry"
              registerName="selectedCountry"
              label={t("Addresses.country")}
              register={register}
              required={true}
              whiteBackground
              defaultOption={t("Addresses.chooseCountry")}
              options={[...supportedCountries.keys()].map((country) => {
                return (
                    <option key={country} value={country}>
                      {country}
                    </option>
                );
              })}

          />
        </div>

        <Button
            text={t("Ticket.Generate")}
            buttonStyle={{marginBottom: "20px", width: "100%"}}
            disabled={submitDisabled}
        />
      </form>
    </Modal>
  );
};
export default ModalGenerateTickets;
