import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Sidebar from "../../sidebar/sidebar";
import MaterialTable from "@material-table/core";
import {
  useStyles,
  useStylesForMaterial,
  toolbarStyles,
  materialTableStyle,
  paginationStyle,
} from "../../reusable/styles";
import { useTranslation } from "react-i18next";
import Pagination from "../../reusable/pagination";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { optionsGeneral } from "../../reusable/optionsForTables";
import LoadingIndicator from "../../reusable/loadingIndicator";
import keycloak from "../../keycloak";
import { ROLES } from "../../consts";
import { changeTabInSidebar } from "../../actions/sidebar";
import Style from "./gasStations.module.css";
import MagnifyingGlass from "../../common/assets/magnifying-glass.svg";
import {
  getAllGasStations,
  getAllGasStationsForGSAdmin,
} from "../../actions/gasStation";
import { GasStationColumns } from "../../columns/gasStationColumns";
import AddGasStationModal from "./addGasStationModal";
import GasStationDetailsModal from "./gasStationDetailsModal";
import EditGasStationModal from "./editGasStationModal";
import {useAuth} from "react-oidc-context";

export default function GasStations() {
  const auth = useAuth();
  const { t } = useTranslation();
  const classes = useStyles();
  const classesMaterial = useStylesForMaterial();
  const classesForm = toolbarStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { gasStations } = useSelector((state) => state.gasStation);

  const { isLoading } = useSelector((state) => state.loader);

  const [height, setHeight] = useState(window.innerHeight);

  const [gasStationAddModalOpen, setGasStationAddModalOpen] = useState(false);
  const [gasStationDetailsModalOpen, setGasStationDetailsModalOpen] =
    useState(false);

  const [gasStationEditModalOpen, setGasStationEditModalOpen] = useState(false);
  const [selectedGasStationId, setSelectedGasStationId] = useState("");

  const handleResize = () => {
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  useEffect(() => {
    dispatch(changeTabInSidebar(8));
  }, [dispatch]);

  useEffect(() => {
    if (keycloak.hasRealmRole(ROLES.SYSADMIN, auth)) {
      dispatch(getAllGasStations());
    } else {
      dispatch(getAllGasStationsForGSAdmin());
    }
  }, [dispatch]);

  const toolbar = (props) => {
    return (
      <div className={classesForm.root}>
        <Grid container direction="row" className={Style.header}>
          <p className={Style.header__text}>{t("GasStations.GasStations")}</p>

          {(keycloak.hasRealmRole(ROLES.SYSADMIN, auth) ||
            keycloak.hasRealmRole(ROLES.GAS_STATION_ADMIN, auth)) && (
            <button
              className="addEntityButton"
              onClick={() => {
                setGasStationAddModalOpen(true);
              }}
            >
              <p className={Style.header__addButtonText}>
                {t("GasStations.New gs")}
              </p>
            </button>
          )}
        </Grid>
      </div>
    );
  };
  const pagination = (props) => {
    return (
      <td style={paginationStyle}>
        <Pagination translate={t} {...props} classes={{ ...classesMaterial }} />
      </td>
    );
  };

  const openGasStationEditModal = () => {
    setGasStationEditModalOpen(true);
    setGasStationDetailsModalOpen(false);
  };

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {isLoading && <LoadingIndicator />}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="text-center">
                <div className="row content">
                  <div className="col-sm-12">
                    <div className={classes.paper}>
                      <MaterialTable
                        icons={{
                          Filter: () => (
                            <img src={MagnifyingGlass} alt="search" />
                          ),
                        }}
                        searchPlaceholder="Test"
                        title={t("GasStations.GasStations")}
                        style={materialTableStyle}
                        data={gasStations}
                        columns={GasStationColumns({
                          history,
                          // deleteParkingZone,
                          t,
                          keycloak,
                          setSelectedGasStationId,
                          setGasStationDetailsModalOpen,
                        })}
                        options={optionsGeneral(height - 240, 44)}
                        components={{
                          Toolbar: (props) => toolbar(props),
                          Pagination: (props) => pagination(props),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </main>

      {gasStationAddModalOpen && (
        <AddGasStationModal
          open={gasStationAddModalOpen}
          closeOnEsc
          onClose={() => setGasStationAddModalOpen(false)}
          title={t("GasStations.createGasStation")}
        />
      )}

      {gasStationDetailsModalOpen && (
        <GasStationDetailsModal
          open={gasStationDetailsModalOpen}
          onClose={() => setGasStationDetailsModalOpen(false)}
          gasStationId={selectedGasStationId}
          openGasStationEditModal={openGasStationEditModal}
        />
      )}

      {gasStationEditModalOpen && (
        <EditGasStationModal
          open={gasStationEditModalOpen}
          closeOnEsc
          onClose={() => setGasStationEditModalOpen(false)}
        />
      )}
    </div>
  );
}
