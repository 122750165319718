import Style from "./selectWithLabel.module.css";

const SelectWithLabel = (props) => {
  return (
    <div className="form-group" style={props.style}>
      {!props.withoutHairlineLabel && (
        <div className={Style.labels}>
          {props.label + (props.required ? "*" : "")}
          <hr className={Style.hr} />
        </div>
      )}

      <div>
        <select
          className={
            props.whiteBackground
              ? `${Style.textStyleSelect} ${Style.textStyleSelectWhite}`
              : Style.textStyleSelect
          }
          {...props.register(props.registerName, {onChange: props.changeHandler})}
          required={props.required}
          name={props.name}
          defaultValue={props.defaultValue}
        >
          <option
            value={props.optionValue ? props.optionValue : "0"}
            key={0}
            hidden={props?.hidden}
          >
            {props.defaultOption}
          </option>
          {props.options}
        </select>
      </div>
    </div>
  );
};

export default SelectWithLabel;
