import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import adminReducer from "./admin";
import loaderReducer from "./loader";
import ownerReducer from "./owner";
import parkingZoneReducer from "./parkingZone";
import ticketSeriesReducer from "./ticket";
import violationsReducer from "./violations";
import usersReducer from "./users";
import sidebarReducer from "./sidebar";
import requestsReducer from "./requests";
import ticketCancellationRequestsReducer from "./ticketCancellationRequest";
import parkingZoneOwnershipRequestsReducer from "./parkingZoneOwnershipRequest";
import gasStationOwnershipRequestsReducer from "./gasStationOwnershipRequest";
import deleteUserRequestsReducer from "./deleteUserRequest";
import whitelistReducer from "./whitelist";
import managerReducer from "./manager";
import blacklistReducer from "./blacklist";
import gasStationReducer from "./gasStation";
import ticketFeeCancellationRequestsReducer from "./ticketFeeCancelationRequest";
import billingInfoReducer from "./billingInfo";
import viewerReducer from "./viewer";
import reportReducer from "./report"
import clientReducer from "./client"

const persistConfig = {
  key: "root",
  storage,
  whiteList: [],
};

const rootReducer = combineReducers({
  admin: adminReducer,
  parkingZone: parkingZoneReducer,
  owners: ownerReducer,
  violations: violationsReducer,
  loader: loaderReducer,
  ticketSeries: ticketSeriesReducer,
  users: usersReducer,
  sidebar: sidebarReducer,
  requests: requestsReducer,
  ticketCancellationRequests: ticketCancellationRequestsReducer,
  parkingZoneOwnershipRequests: parkingZoneOwnershipRequestsReducer,
  deleteUserRequests: deleteUserRequestsReducer,
  whitelist: whitelistReducer,
  manager: managerReducer,
  viewer: viewerReducer,
  blacklist: blacklistReducer,
  gasStationOwnershipRequests: gasStationOwnershipRequestsReducer,
  gasStation: gasStationReducer,
  ticketFeeCancellationRequests: ticketFeeCancellationRequestsReducer,
  billingInfo: billingInfoReducer,
  report: reportReducer,
  client: clientReducer,
});

export default persistReducer(persistConfig, rootReducer);
