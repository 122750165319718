import cn from "classnames";
import MapPin from "../../common/assets/map-pin.svg";
import {berlinMarker, zurichMarker} from "../../consts";
import Style from "./marker.module.css";


const Marker = ({ lat, lng, onClick }) => {
    let key = `${lat}_${lng}`;
    return (
        <div
            key={key}
            style={{
                color: "white",
                padding: "10px 10px 10px 10px",
                display: "inline-flex",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "100%",
                transform: "translate(-50%, -50%)",
            }}
            className={cn(Style.animate, Style.pop)}
        >
            <img
                className="img-fluid"
                onClick={onClick}
                style={{height: "35px"}}
                src={MapPin}
                id="mapPin"
                alt="map-pin"
            />
        </div>
    );
}

export default Marker;

export const moveMapCenterByCountry = (markers, country, map) => {
    if (markers?.length > 0) {
        return; // we have a marker, we don't touch anything
    }

    let newMarker = null;
    switch (country) {
        case "DE":
            newMarker = berlinMarker;
            break;
        case "CH":
            newMarker = zurichMarker;
            break;
        default:
            return;
    }
    if (newMarker) {
        map?.setCenter(newMarker.center)
        map?.setZoom(newMarker.zoom)
    }
};

export const colorMapOptions = {
    scrollwheel: false,
    clickableIcons: false,
    disableDefaultUI: true,
    gestureHandling: "none",
    fullscreenControl: false,
    zoomControl: false,
    styles: [{stylers: [{'visibility': ' simplified'}] }]
}

export const dimmedMapOptions = {
    ...colorMapOptions,
    styles: [{stylers: [{'saturation': -100}, {'gamma': 0.8}, {'lightness': 5}, {'visibility': ' simplified'}]}]
}