import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Style from "./ticketCancellationRequestModal.module.css";
import Button from "../../common/button/button";
import { switchLegalStatus, switchStatus } from "../../utils/ticketStatusesHelper";
import ModalAddComment from "../violations/modalAddComment";
import {
  approveTicketFeeCancellationRequest,
  denyTicketFeeCancellationRequest,
} from "../../actions/ticketCancellationRequest";
import {
    commentTicket
} from "../../actions/violations";
import EntityDetail from "./entityDetail";
import { newModalStyle } from "../../reusable/styles";
import { useState } from "react";
import ViolationsDetailsModal from "../violations/violationsDetailsModal";
import OwnerDetailsModal from "../owner/ownerDetailsModal";
import Comment from "../../common/assets/comment.svg";

const TicketFeeCancellationRequestModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [openTicketModal, setOpenTicketModal] = useState(false);
  const [openOwnerModal, setOpenOwnerModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState("");
  const [selectedOwner, setSelectedOwner] = useState("");
  const [commentDefaultValue, setCommentDefaultValue] = useState("");
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [ticketNumberInCommentModal, setTicketNumberInCommentModal] = useState(false);


  const toogleModalForComment = () => {
    setCommentDefaultValue("");
    setOpenCommentModal(!openCommentModal);
  };

  const addComment = (data) => {
    const dataForEdit = {
      ticketNumber: ticketNumberInCommentModal,
      comment: data.comment,
    };

    dispatch(commentTicket(dataForEdit));
    toogleModalForComment();
  };

  const onClose = () => {
    props.setModalOpen(false);
  };

  const { ticketFeeCancelationRequest } = useSelector(
    (state) => state.ticketFeeCancellationRequests
  );
  const isGasStationTicket =
    ticketFeeCancelationRequest?.ticket?.gasStationZone;

  const keepTicket = (id) => {
    dispatch(denyTicketFeeCancellationRequest(id, props.setErrorModalOpen));
    onClose();
  };

  const cancelTicket = (id) => {
    dispatch(approveTicketFeeCancellationRequest(id, props.setErrorModalOpen));
    onClose();
  };

  return (
  <div>
      {openCommentModal && (
        <ModalAddComment
          open={openCommentModal}
          addComment={addComment}
          close={toogleModalForComment}
          defaultValue={commentDefaultValue}
        />
      )}
    <Modal
      open={props.open}
      closeOnEsc
      onClose={onClose}
      center
      styles={newModalStyle({ width: "539px" })}
    >
      <div className="modal__headerLine" />
      <div className="modal__title">{props.title}</div>

      <div className={Style.body}>
        <EntityDetail
          label={t("ticketFeeCancellationRequestModal.reference")}
          value={ticketFeeCancelationRequest?.ticket?.number}
          pressableValue={!isGasStationTicket}
          openModal={setOpenTicketModal}
          setSelectedTicket={() => {
            setSelectedTicket(ticketFeeCancelationRequest.ticket);
          }}
        />
        <EntityDetail
                  label={t("Columns.Status")}
                  value={switchStatus(t, ticketFeeCancelationRequest?.ticket)}
        />
        <EntityDetail
                  label={t("Columns.LegalStatus")}
                  value={switchLegalStatus(t, ticketFeeCancelationRequest?.ticket?.legalStatus)}
        />
        <EntityDetail
          label={t("ticketFeeCancellationRequestModal.dateOfRequest")}
          value={moment(ticketFeeCancelationRequest?.createdOn).format(
            "DD.MM.YYYY"
          )}
        />
        <EntityDetail
          label={t("ticketFeeCancellationRequestModal.timeOfRequest")}
          value={moment(ticketFeeCancelationRequest?.createdOn).format("HH:mm")}
        />
        <EntityDetail
          label={t("ticketFeeCancellationRequestModal.requestor")}
          value={ticketFeeCancelationRequest?.requestor?.beppoOfficerData}
          pressableValue
          openModal={setOpenOwnerModal}
          setSelectedTicket={() => {
            setSelectedOwner(ticketFeeCancelationRequest?.requestor);
          }}
        />
        <EntityDetail
          label={t("ticketFeeCancellationRequestModal.beppoliceman")}
          value={ticketFeeCancelationRequest?.beppoliceman?.beppoOfficerData}
          pressableValue
          openModal={setOpenOwnerModal}
          setSelectedTicket={() => {
            setSelectedOwner(ticketFeeCancelationRequest?.beppoliceman);
          }}
        />
        <EntityDetail
          label={t("ticketFeeCancellationRequestModal.justification")}
          value={ticketFeeCancelationRequest?.justification}
        />
        <EntityDetail
                  label={t("Columns.Comment")}
                  value={    <img
                                src={Comment}
                                style={{ cursor: "pointer", paddingLeft: 30 }}
                                id="comment"
                                alt="comment"
                                height={20}
                                onClick={() => {
                                                toogleModalForComment();
                                                setCommentDefaultValue(ticketFeeCancelationRequest?.ticket?.comment);
                                                setTicketNumberInCommentModal(ticketFeeCancelationRequest?.ticket?.number);
                                }}
                             />
                  }
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          text={t("ticketFeeCancellationRequestModal.accept")}
          buttonStyle={{
            marginBottom: "20px",
            marginTop: "20px",
            height: "40px",
            width: "210px",
            fontFamily: "Gilroy-Regular",
            marginRight: "28px",
          }}
          onClick={() => {
            cancelTicket(ticketFeeCancelationRequest?.id);
          }}
        />
        <Button
          text={t("ticketFeeCancellationRequestModal.reject")}
          buttonStyle={{
            marginBottom: "20px",
            marginTop: "20px",
            height: "40px",
            width: "210px",
            marginRight: "10px",
            backgroundColor: "#CC0605",
            fontFamily: "Gilroy-Regular",
          }}
          onClick={() => keepTicket(ticketFeeCancelationRequest?.id)}
        />
      </div>
      {openTicketModal && (
        <ViolationsDetailsModal
          title={t("ticketFeeCancellationRequestModal.title")}
          open={openTicketModal}
          setModalOpen={setOpenTicketModal}
          selectedTicket={selectedTicket}
          onClose={() => {
            setOpenTicketModal(false);
            setSelectedTicket("");
          }}
        />
      )}
      {openOwnerModal && (
        <OwnerDetailsModal
          open={openOwnerModal}
          setModalOpen={setOpenOwnerModal}
          owner={selectedOwner}
          onClose={() => {
            setOpenOwnerModal(false);
            setSelectedOwner("");
          }}
        />
      )}
    </Modal>
  </div>
  );
};
export default TicketFeeCancellationRequestModal;
