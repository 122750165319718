import {
  ADD_PARKING_ZONE,
  GET_ALL_PARKING_ZONES,
  DELETE_PARKING_ZONE,
  GET_PARKING_ZONE_FOR_UPDATE,
  CLEAR_PZ_FOR_EDIT,
  UPDATE_PARKING_ZONE,
  GET_PARKING_ZONE,
  CLEAR_PARKING_ZONE_FOR_DETAILS,
  CREATE_PARKING_ZONE_ERROR,
  UPDATE_PARKING_ZONE_ERROR,
  CLOSE_CREATE_PARKING_ZONE_ERROR_MODAL,
  GET_ALL_PARKING_ZONES_FOR_PZ_ADMIN_OR_PZ_MANAGER,
  CLOSE_UPDATE_PARKING_ZONE_ERROR_MODAL,
} from "../actions/types";

const initialState = {
  parkingZones: [],
  parkingZoneForUpdate: {},
  parkingZoneForDetails: {},
  parkingZone: {},
  parkingZoneCreateError: false,
  parkingZoneUpdateError: false,
};

const parkingZoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PARKING_ZONES:
      return {
        ...state,
        parkingZones: action.payload,
      };
    case GET_ALL_PARKING_ZONES_FOR_PZ_ADMIN_OR_PZ_MANAGER:
      return {
        ...state,
        parkingZones: action.payload,
      };
    case ADD_PARKING_ZONE:
      return {
        ...state,
        parkingZones: state.parkingZones
          .reverse()
          .concat(action.payload)
          .reverse(),
      };
    case DELETE_PARKING_ZONE:
      return {
        ...state,
        parkingZones: state.parkingZones.filter(
          (pz) => pz.id !== action.payload
        ),
      };
    case UPDATE_PARKING_ZONE:
      return {
        ...state,
        parkingZones: state.parkingZones.map((pz) =>
          pz.id !== action.payload.id ? pz : action.payload
        ),
      };
    case GET_PARKING_ZONE_FOR_UPDATE:
      return {
        ...state,
        parkingZoneForUpdate: action.payload,
        parkingZoneForDetails: action.payload,
      };

    case CLEAR_PZ_FOR_EDIT:
      return {
        ...state,
        parkingZoneForUpdate: {},
      };

    case CLEAR_PARKING_ZONE_FOR_DETAILS:
      return {
        ...state,
        parkingZoneForDetails: {},
      };

    case GET_PARKING_ZONE:
      return {
        ...state,
        parkingZone: action.payload,
      };

    case CREATE_PARKING_ZONE_ERROR:
      return {
        ...state,
        parkingZoneCreateError: true,
      };

    case UPDATE_PARKING_ZONE_ERROR:
      return {
        ...state,
        parkingZoneUpdateError: true,
      };

    case CLOSE_CREATE_PARKING_ZONE_ERROR_MODAL:
      return {
        ...state,
        parkingZoneCreateError: false,
      };

    case CLOSE_UPDATE_PARKING_ZONE_ERROR_MODAL:
      return {
        ...state,
        parkingZoneUpdateError: false,
      };
    default:
      return state;
  }
};

export default parkingZoneReducer;
