import InputStyle from "./inputFieldWithLabel.module.css"
import Style from "./textareaWithLabel.module.css";

const TextareaWithLabel = (props) => {
  return (
    <div className="form-group" style={props.style}>
      <div className={InputStyle.input__label}>
        {props.label}
        <hr className={InputStyle.input__hr} />
      </div>
      <div>
        <textarea
          type="text"
          className={Style.textarea}
          style={props.textAreaStyle}
          {...props.register(props.registerName)}
          required={props.required}
          name={props.name}
          defaultValue={props.defaultValue}
        />
      </div>
    </div>
  );
};

export default TextareaWithLabel;
