import { apiService } from "../utils/serviceApi";
import {
  ADD_VEHICLE_TO_PARKING_ZONE_BLACKLIST,
  CLOSE_DUPLICATE_BLACKLIST_WEHICLE_MODAL,
  DUPLICATE_BLACKLIST_WEHICLE,
  GET_ALL_BLACKLIST_FOR_PARKING_ZONE,
  REMOVE_VEHICLE_FROM_PARKING_ZONE_BLACKLIST,
  SET_LOADER,
} from "./types";

export const getAllBlacklistForParkingZone =
  (parkingZoneId) => async (dispatch) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    apiService
      .get(
        `${process.env.REACT_APP_SERVICE_URL}/api/blacklist/${parkingZoneId}`
      )
      .then((res) => {
        dispatch({
          type: GET_ALL_BLACKLIST_FOR_PARKING_ZONE,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };

export const addVehicleToParkingZoneBlacklist =
  (blacklistVehicle, setErrorBlacklistModal, closeModal) =>
  async (dispatch) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    apiService
      .post(
        `${process.env.REACT_APP_SERVICE_URL}/api/blacklist`,
        blacklistVehicle
      )
      .then((res) => {
        if (res.status === 409) {
          return dispatch({
            type: DUPLICATE_BLACKLIST_WEHICLE,
          });
        }
        if (res.status === 403) {
          setErrorBlacklistModal(true);
          return;
        }
        dispatch({
          type: ADD_VEHICLE_TO_PARKING_ZONE_BLACKLIST,
          payload: res.data,
        });
        closeModal();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };

export const removeVehicleFromParkingZoneBlacklist =
  (id, closeDeleteModal) => async (dispatch) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    apiService
      .put(`${process.env.REACT_APP_SERVICE_URL}/api/blacklist/remove/${id}`)
      .then(() => {
        dispatch({
          type: REMOVE_VEHICLE_FROM_PARKING_ZONE_BLACKLIST,
          payload: id,
        });
        closeDeleteModal();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };

export const closeDuplicateBlacklistVehicleModal = () => async (dispatch) => {
  dispatch({
    type: CLOSE_DUPLICATE_BLACKLIST_WEHICLE_MODAL,
  });
};
