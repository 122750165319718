import {Redirect, Route, useHistory} from "react-router-dom";
import {useEffect} from "react";
import {isAllowed} from "./isAllowed";
import {useAuth} from "react-oidc-context";
import {resolveRedirectLocation} from "./resolveRedirectLocation";


export function UnauthorizedRoute(
    {
        component: Component,
        roles,
        ...rest
    }
) {
    const auth = useAuth();
    const history = useHistory();
    const resolvedRedirectLocation = resolveRedirectLocation(auth);

    useEffect(() => {
        if(auth.isLoading) return;
        if (!auth.isAuthenticated) {
            auth.signinRedirect({redirect_uri: window.location.href});
        } else if (history.location.pathname !== resolvedRedirectLocation){
            history.push(resolvedRedirectLocation);
        }
    }, [ history, auth, resolvedRedirectLocation]);

    if (!auth.isLoading && auth.isAuthenticated && auth.user !== null && history.location.pathname !== resolvedRedirectLocation) {
        return (
            <Redirect to={`${resolvedRedirectLocation}`}/>
        );
    } else {
        return (
            <Route
                {...rest}
                render={(props) => <Component {...props}/>
                }
            />
        )
    }

}
