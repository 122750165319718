import {
GET_TICKET_FEE_CANCELLATION_REQUEST_BY_ID,
COMMENT_TICKET
} from "../actions/types";

const initialState = {
  ticketFeeCancelationRequest: {},
};

const ticketFeeCancellationRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TICKET_FEE_CANCELLATION_REQUEST_BY_ID:
      return {
        ...state,
        ticketFeeCancelationRequest: action.payload,
      };

    case COMMENT_TICKET:
          const { comment, ticketNumber } = action.payload;

      if (state.ticketFeeCancelationRequest.ticket.number === ticketNumber) {
        return {
          ...state,
          ticketFeeCancelationRequest: {
            ...state.ticketFeeCancelationRequest,
            ticket: {
              ...state.ticketFeeCancelationRequest.ticket,
              comment: comment,
            },
          },
        };
      }

    default:
      return state;
  }
};

export default ticketFeeCancellationRequestsReducer;
