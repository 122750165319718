import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Sidebar from "../../sidebar/sidebar";
import MaterialTable from "@material-table/core";
import {
  useStyles,
  useStylesForMaterial,
  toolbarStyles,
  materialTableStyle,
  paginationStyle,
} from "../../reusable/styles";
import { useTranslation } from "react-i18next";
import Pagination from "../../reusable/pagination";
import ModalAddManager from "./modalAddManager";
import { useDispatch, useSelector } from "react-redux";
import { optionsGeneralEnableDisable } from "../../reusable/optionsForTables";
import LoadingIndicator from "../../reusable/loadingIndicator";
import Style from "./managers.module.css";
import ModalAddManagerForm from "./modalAddManagerForm";
import { changeTabInSidebar } from "../../actions/sidebar";
import ErrorModal from "../../common/modal/errorModal";
import {
  checkManagerEmail,
  clearManagerData,
  closeDuplicateManagerModal,
  disableManager,
  getAllManagers,
  getManagersByAdminId,
  removeManagerFromPZAdminsManagerList,
} from "../../actions/manager";
import { ManagerColumns } from "../../columns/managerColumns";
import { mapUser } from "../../utils/mapUser";
import { ROLES } from "../../consts";
import ModalEditManager from "./modalEditManager";
import MagnifyingGlass from "../../common/assets/magnifying-glass.svg";
import keycloak from "../../keycloak";
import {useAuth} from "react-oidc-context";

export default function Managers() {
  const auth = useAuth();
  const { t } = useTranslation();
  const [openAddManagerModal, setOpenAddManagerModal] = useState(false);
  const [openAddManagerModalForm, setOpenAddManagerModalForm] = useState(false);
  const [managersEmail, setManagersEmail] = useState("");
  const [openEditManagerModal, setOpenEditManagerModal] = useState(false);
  const [selectedManager, setSelectedManager] = useState({});
  const classes = useStyles();
  const classesMaterial = useStylesForMaterial();
  const classesForm = toolbarStyles();
  const dispatch = useDispatch();
  const { managers, duplicateManager, manager } = useSelector(
    (state) => state.manager
  );
  const { isLoading } = useSelector((state) => state.loader);
  const loggedUser = mapUser(auth);

  const [height, setHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  const closeModalDuplicatedManager = () => {
    dispatch(closeDuplicateManagerModal());
  };

  const closeModal = () => {
    setOpenAddManagerModal(false);
    setOpenEditManagerModal(false);
    setSelectedManager({});
  };

  const closeModalForm = () => {
    dispatch(clearManagerData());
    setOpenAddManagerModalForm(false);
  };

  useEffect(() => {
    dispatch(changeTabInSidebar(7));
    if (loggedUser?.roles?.includes(ROLES.SYSADMIN)) {
      dispatch(getAllManagers());
    } else {
      dispatch(getManagersByAdminId());
    }
  }, [dispatch, loggedUser?.roles]);

  function toolbar(props) {
    return (
      <div className={classesForm.root}>
        <div className={Style.header}>
          <div className={Style.header__text}>{t("Manager.Managers")}</div>

          <button
            className="addEntityButton"
            hidden={keycloak.hasRealmRole(ROLES.PARKING_VIEWER, auth) || keycloak.hasRealmRole(ROLES.GAS_STATION_VIEWER, auth)}
            onClick={() => {
              setOpenAddManagerModal(true);
            }}
          >
            <p className={Style.header__addButtonText}>
              {t("Manager.CreateManager")}
            </p>
          </button>
        </div>
      </div>
    );
  }

  const disableManagerBySysAdmin = (email) => {
    dispatch(disableManager(email));
  };

  const removeManager = (email) => {
    dispatch(removeManagerFromPZAdminsManagerList(email));
  };

  function pagination(props) {
    return (
      <td style={paginationStyle}>
        <Pagination translate={t} {...props} classes={{ ...classesMaterial }} />
      </td>
    );
  }

  const checkManager = (email) => {
    dispatch(checkManagerEmail(email));
    setManagersEmail(email);
    setOpenAddManagerModal(false);
    setOpenAddManagerModalForm(true);
  };

  return (
    <div className={classes.root}>
      <>
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth={false} className={classes.container}>
            {isLoading && <LoadingIndicator />}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className="text-center">
                  <div className="row content">
                    <div className="col-sm-12">
                      <div className={classes.paper}>
                        <MaterialTable
                          icons={{
                            Filter: () => (
                              <img src={MagnifyingGlass} alt="search" />
                            ),
                          }}
                          title="Parking zones"
                          style={materialTableStyle}
                          data={managers}
                          columns={ManagerColumns(
                            setOpenEditManagerModal,
                            setSelectedManager,
                            t,
                            disableManagerBySysAdmin,
                            removeManager
                          )}
                          options={optionsGeneralEnableDisable(
                            height - 240,
                            44
                          )}
                          components={{
                            Toolbar: (props) => toolbar(props),
                            Pagination: (props) => pagination(props),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </main>
        {openAddManagerModal && (
          <ModalAddManager
            open={openAddManagerModal}
            close={closeModal}
            submit={checkManager}
          />
        )}

        {openAddManagerModalForm && (
          <ModalAddManagerForm
            open={openAddManagerModalForm}
            close={closeModalForm}
            managersEmail={managersEmail}
            manager={manager}
          />
        )}

        {openEditManagerModal && (
          <ModalEditManager
            open={openEditManagerModal}
            close={closeModal}
            selectedManager={selectedManager}
          />
        )}

        {duplicateManager && (
          <ErrorModal
            title={t("DulicateManager.title")}
            errorMessage={t("DulicateManager.message")}
            open={duplicateManager}
            onClose={() => closeModalDuplicatedManager()}
            width="350px"
          />
        )}
      </>
    </div>
  );
}
