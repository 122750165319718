import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Button from "../../common/button/button";
import { useForm } from "react-hook-form";
import { addVehicleToParkingZoneBlacklist } from "../../actions/blacklist";
import LicensePlateInputFieldWithLabel from "../../common/licensePlateInput.js/licensePlateInputFieldWithLabel";
import { useState } from "react";
import { modalCloseButtonStyle } from "../../reusable/styles";
import InputFieldWithLabel from "../../common/input/inputFieldWithLabel";
import ErrorModal from "../../common/modal/errorModal";

const formStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(5),
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `30px`,
      },
    },
  },
}));

const AddVehicleToBlacklistModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleSubmit, register, watch, reset } = useForm();
  const plateNumber = watch("plateNumber");
  const name = watch("name");
  const classesForm = formStyles();
  const [licensePlate, setLicensePlate] = useState("");
  const [errorWhitelistModal, setErrorWhitelistModal] = useState(false);

  const saveDisabled = !plateNumber;

  const closeModal = () => {
    setLicensePlate("");
    reset();
    props.setModalOpen(false);
  };

  const saveVehicle = (data) => {
    const newVehicle = {
      parkingZoneId: props?.parkingZone.id,
      name: data?.name,
      plateNumber: data?.plateNumber.toUpperCase(),
    };
    dispatch(
      addVehicleToParkingZoneBlacklist(
        newVehicle,
        setErrorWhitelistModal,
        closeModal
      )
    );
  };

  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={closeModal}
      center
      styles={{
        modal: { width: "470px", borderRadius: "4px" },
        closeIcon: { fill: "red", marginTop: 5 },
        closeButton: modalCloseButtonStyle,
      }}
    >
      <div className="modal__headerLine" />
      <div className="modal__title">
        {t("AddVehicleToBlacklistModal.title")}
      </div>
      <form className={classesForm.root} onSubmit={handleSubmit(saveVehicle)}>
        <div>
          <LicensePlateInputFieldWithLabel
            label={t("AddVehicleToBlacklistModal.licensePlate")}
            name="plateNumber"
            registerName="plateNumber"
            register={register}
            required
            noSpaceAllowed
            value={licensePlate.toUpperCase()}
            setValue={setLicensePlate}
          />
        </div>
        <InputFieldWithLabel
          label={t("AddVehicleToBlacklistModal.name")}
          name="name"
          registerName="name"
          register={register}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            paddingTop: "20px",
          }}
        >
          <Button
            text={t("AddVehicleToBlacklistModal.save")}
            buttonStyle={{
              marginBottom: "20px",
              marginTop: "20px",
              height: "40px",
              width: "181px",
              backgroundColor: saveDisabled ? "#c7c7c7" : "#005a8c",
              fontFamily: "Gilroy-Regular",
            }}
            disabled={saveDisabled}
          />
        </div>
      </form>
      <ErrorModal
        title={t("DulicateWhitelistWehicle.title")}
        errorMessage={t("DulicateWhitelistWehicle.message")}
        open={errorWhitelistModal}
        onClose={() => setErrorWhitelistModal(false)}
        width="400px"
      />
    </Modal>
  );
};
export default AddVehicleToBlacklistModal;
