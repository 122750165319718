import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Button from "../../common/button/button";
import { useForm } from "react-hook-form";
import InputFieldWithLabel from "../../common/input/inputFieldWithLabel";
import { useTranslation } from "react-i18next";
import { newModalStyle, submitButtonStyle } from "../../reusable/styles";

const ModalAddAdmin = (props) => {
  const { handleSubmit, register, reset } = useForm();

  const checkEmail = (data) => {
    props.submit(data.email);
    reset();
  };

  const { t } = useTranslation();
  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={props.close}
      center
      styles={newModalStyle({ width: "450px" })}
    >
      <div className="modal__headerLine" />
      <div className="modal__title">Administrator</div>
      <form className="modalForm" onSubmit={handleSubmit(checkEmail)}>
        <div className="form">
          <InputFieldWithLabel
            label={t("Admins.email")}
            name="email"
            type="email"
            registerName="email"
            required
            register={register}
            autoComplete="off"
          />

          <Button text={t("Buttons.save")} buttonStyle={submitButtonStyle} />
        </div>
      </form>
    </Modal>
  );
};
export default ModalAddAdmin;
