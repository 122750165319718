import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { addManager, clearManagerData } from "../../actions/manager";
import Button from "../../common/button/button";
import LabelWithHR from "../../common/labels/labelWithHR";
import { newModalStyle, submitButtonStyle } from "../../reusable/styles";

const ModalAddManagerForm = (props) => {
  const { handleSubmit, control } = useForm();
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const dispatch = useDispatch();
  const { manager } = useSelector((state) => state.manager);

  const saveManager = () => {
    const newData = {
      username: manager ? manager.username : userName,
      firstName: manager ? manager.firstName : firstName,
      lastName: manager ? manager.lastName : lastName,
      phoneNumber,
      companyName,
      email: props.managersEmail,
    };

    dispatch(addManager(newData));
    dispatch(clearManagerData());
    props.close();
  };

  const closeModal = () => {
    props.close();
  };

  const { t } = useTranslation();
  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={closeModal}
      center
      styles={newModalStyle({ width: "450px" })}
    >
      <div className="modal__headerLine" />
      <div className="modal__title">{t("ModalAddManager.title")}</div>
      <form className="modalForm" onSubmit={handleSubmit(saveManager)}>
        <div className="form">
          <LabelWithHR label={t("ModalAddManagerForm.email")} />
          <Controller
            name="email"
            defaultValue=""
            control={control}
            render={() => (
              <input
                type={"text"}
                className="textStyleInput"
                disabled={props.managersEmail}
                name="email"
                defaultValue={props.managersEmail}
              />
            )}
          />

          <LabelWithHR label={t("ModalAddManagerForm.username")} />
          <Controller
            name="userName"
            defaultValue=""
            control={control}
            render={() => (
              <input
                type="text"
                className="textStyleInput"
                disabled={props.manager}
                name="userName"
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                defaultValue={props.manager?.username}
                required
              />
            )}
          />
          <LabelWithHR label={t("ModalAddManagerForm.firstName")} />
          <Controller
            name="firstName"
            defaultValue=""
            control={control}
            render={() => (
              <input
                type="text"
                className="textStyleInput"
                disabled={props.manager}
                name="firstName"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                defaultValue={props.manager?.firstName}
                required
              />
            )}
          />
          <LabelWithHR label={t("ModalAddManagerForm.lastName")} />
          <Controller
            name="lastName"
            defaultValue=""
            control={control}
            render={() => (
              <input
                type="text"
                label={t("Manager.firstName")}
                className="textStyleInput"
                disabled={props.manager}
                name="lastName"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                defaultValue={props.manager?.lastName}
                required
              />
            )}
          />

          <LabelWithHR label={t("ModalAddManagerForm.phoneNumber")} />
          <Controller
            name="phoneNumber"
            defaultValue=""
            control={control}
            render={() => (
              <input
                type="text"
                label={t("Manager.firstName")}
                className="textStyleInput"
                name="phoneNumber"
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              />
            )}
          />

          <LabelWithHR label={t("ModalAddManagerForm.companyName")} />
          <Controller
            name="companyName"
            defaultValue=""
            control={control}
            render={() => (
              <input
                type="text"
                label={t("ModalAddManagerForm.firstName")}
                className="textStyleInput"
                name="companyName"
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              />
            )}
          />

          <Button text={t("Buttons.save")} buttonStyle={submitButtonStyle} />
        </div>
      </form>
    </Modal>
  );
};
export default ModalAddManagerForm;
