import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Sidebar from "../../sidebar/sidebar";
import MaterialTable from "@material-table/core";
import {
  useStyles,
  useStylesForMaterial,
  toolbarStyles,
  materialTableStyle,
  paginationStyle,
} from "../../reusable/styles";
import { useTranslation } from "react-i18next";
import Pagination from "../../reusable/pagination";
import { useDispatch, useSelector } from "react-redux";
import LoadingIndicator from "../../reusable/loadingIndicator";
import { getAllRequests, markRequestAsSeen } from "../../actions/requests";
import {
  getTicketCancellationRequestById,
  getTicketFeeCancellationRequestById,
} from "../../actions/ticketCancellationRequest";
import { changeTabInSidebar } from "../../actions/sidebar";
import Style from "./requests.module.css";
import { RequestsColumns } from "../../columns/requestsColumns";
import { optionsGeneral } from "../../reusable/optionsForTables";
import TicketCancellationRequestModal from "./ticketCancellationRequestModal";
import { getParkingZoneOwnershipRequestById } from "../../actions/parkingZoneOwnershipRequest";
import { getGasStationOwnershipRequestById } from "../../actions/gasStationOwnershipRequest";
import ParkingZoneOwnershipRequestModal from "./parkingZoneOwnershipRequsetModal";
import ErrorModal from "../../common/modal/errorModal";
import MagnifyingGlass from "../../common/assets/magnifying-glass.svg";
import DeleteUserRequestModal from "./deleteUserRequestModal";
import GasStationOwnershipRequestModal from "./gasStationOwnershipRequestModal";
import TicketFeeCancellationRequestModal from "./ticketFeeCancellationRequestModal";

export default function Requests() {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesMaterial = useStylesForMaterial();
  const classesForm = toolbarStyles();
  const dispatch = useDispatch();
  const { requests } = useSelector((state) => state.requests);
  const { isLoading } = useSelector((state) => state.loader);
  const [height, setHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setHeight(window.innerHeight);
  };

  const [
    ticketCancelationRequestViewModalOpen,
    setTicketCancelationRequestViewModalOpen,
  ] = useState(false);

  const [
    ticketFeeCancelationRequestViewModalOpen,
    setTicketFeeCancelationRequestViewModalOpen,
  ] = useState(false);

  const [
    parkingZoneOwnershipRequestViewModalOpen,
    setParkingZoneOwnershipRequestViewModalOpen,
  ] = useState(false);

  const [
    gasStationOwnershipRequestViewModalOpen,
    setGasStationOwnershipRequestViewModalOpen,
  ] = useState(false);

  const [deleteUserRequestViewModalOpen, setDeleteUserRequestViewModalOpen] =
    useState(false);

  const [
    ticketCancellationRequestIsNotInStatusPendingModalOpen,
    setTicketCancellationRequestIsNotInStatusPendingModalOpen,
  ] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  useEffect(() => {
    dispatch(getAllRequests());
    dispatch(changeTabInSidebar(6));
  }, [dispatch]);

  function toolbar(props) {
    return (
      <div className={classesForm.root}>
        <div className={Style.toolbar}>{t("Sidebar.Requests")}</div>
      </div>
    );
  }
  function pagination(props) {
    return (
      <td style={paginationStyle}>
        <Pagination translate={t} {...props} classes={{ ...classesMaterial }} />
      </td>
    );
  }

  return (
    <div className={classes.root}>
      <>
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />

          <Container maxWidth={false} className={classes.container}>
            {isLoading && <LoadingIndicator />}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className="text-center">
                  <div className="row content">
                    <div className="col-sm-12">
                      <div className={classes.paper}>
                        <MaterialTable
                          style={materialTableStyle}
                          icons={{
                            Filter: () => (
                              <img src={MagnifyingGlass} alt="search" />
                            ),
                          }}
                          data={requests}
                          columns={RequestsColumns(
                            t,
                            setTicketCancelationRequestViewModalOpen,
                            setTicketFeeCancelationRequestViewModalOpen,
                            markRequestAsSeen,
                            getTicketCancellationRequestById,
                            setParkingZoneOwnershipRequestViewModalOpen,
                            getParkingZoneOwnershipRequestById,
                            setDeleteUserRequestViewModalOpen,
                            setGasStationOwnershipRequestViewModalOpen,
                            getGasStationOwnershipRequestById,
                            getTicketFeeCancellationRequestById
                          )}
                          options={optionsGeneral(height - 240, 44)}
                          components={{
                            Toolbar: (props) => toolbar(props),
                            Pagination: (props) => pagination(props),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </main>

        {ticketCancelationRequestViewModalOpen && (
          <TicketCancellationRequestModal
            open={ticketCancelationRequestViewModalOpen}
            setModalOpen={setTicketCancelationRequestViewModalOpen}
            setErrorModalOpen={
              setTicketCancellationRequestIsNotInStatusPendingModalOpen
            }
          />
        )}

        {ticketFeeCancelationRequestViewModalOpen && (
          <TicketFeeCancellationRequestModal
            title={t("ticketFeeCancellationRequestModal.title")}
            open={ticketFeeCancelationRequestViewModalOpen}
            setModalOpen={setTicketFeeCancelationRequestViewModalOpen}
            setErrorModalOpen={
              setTicketCancellationRequestIsNotInStatusPendingModalOpen
            }
          />
        )}

        {parkingZoneOwnershipRequestViewModalOpen && (
          <ParkingZoneOwnershipRequestModal
            title={t("parkingZoneOwnershipRequestModal.title")}
            open={parkingZoneOwnershipRequestViewModalOpen}
            setModalOpen={setParkingZoneOwnershipRequestViewModalOpen}
          />
        )}

        {gasStationOwnershipRequestViewModalOpen && (
          <GasStationOwnershipRequestModal
            title={t("gasStationOwnershipRequestModal.title")}
            open={gasStationOwnershipRequestViewModalOpen}
            setModalOpen={setGasStationOwnershipRequestViewModalOpen}
          />
        )}

        {deleteUserRequestViewModalOpen && (
          <DeleteUserRequestModal
            title={t("DeleteUserRequestModal.title")}
            open={deleteUserRequestViewModalOpen}
            setModalOpen={setDeleteUserRequestViewModalOpen}
          />
        )}

        {ticketCancellationRequestIsNotInStatusPendingModalOpen && (
          <ErrorModal
            title={t("ticketCancellationRequestNotInPendingStatus.title")}
            errorMessage={t(
              "ticketCancellationRequestNotInPendingStatus.message"
            )}
            open={ticketCancellationRequestIsNotInStatusPendingModalOpen}
            onClose={() =>
              setTicketCancellationRequestIsNotInStatusPendingModalOpen(false)
            }
          />
        )}
      </>
    </div>
  );
}
