import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Style from "./deleteUserRequestModal.module.css";
import Button from "../../common/button/button";
import EntityDetail from "./entityDetail";
import { newModalStyle } from "../../reusable/styles";
import { acceptUserDeleteRequest } from "../../actions/userDeleteRequest";

const DeleteUserRequestModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClose = () => {
    props.setModalOpen(false);
  };

  const { deleteUserRequest } = useSelector(
    (state) => state.deleteUserRequests
  );

  const acceptDeleteRequest = async (id) => {
    await dispatch(acceptUserDeleteRequest(parseInt(id)));
    onClose();
  };

  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={onClose}
      center
      styles={newModalStyle({ width: "539px" })}
    >
      <div className="modal__headerLine" />
      <div className="modal__title">{props.title}</div>

      <div className={Style.body}>
        <EntityDetail
          label={t("DeleteUserRequestModal.firstName")}
          value={deleteUserRequest?.requestor?.firstName}
        />

        <EntityDetail
          label={t("DeleteUserRequestModal.lastName")}
          value={deleteUserRequest?.requestor?.lastName}
        />

        <EntityDetail
          label={t("DeleteUserRequestModal.email")}
          value={deleteUserRequest?.requestor?.email}
        />

        <EntityDetail
          label={t("DeleteUserRequestModal.dateOfRequest")}
          value={moment(deleteUserRequest?.createdOn).format("DD.MM.YYYY")}
        />

        <EntityDetail
          label={t("DeleteUserRequestModal.timeOfRequest")}
          value={moment(deleteUserRequest?.createdOn).format("HH:mm")}
        />
        <EntityDetail
          label={t("DeleteUserRequestModal.pendingTickets")}
          value={deleteUserRequest?.pendingTickets}
        />

        {deleteUserRequest?.sysadmin && (
          <EntityDetail
            label={t("DeleteUserRequestModal.Sysadmin")}
            value={t("DeleteUserRequestModal.Yes")}
          />
        )}
        {deleteUserRequest?.admin && (
          <EntityDetail
            label={t("DeleteUserRequestModal.Admin")}
            value={t("DeleteUserRequestModal.Yes")}
          />
        )}

        {deleteUserRequest?.manager && (
          <EntityDetail
            label={t("DeleteUserRequestModal.Manager")}
            value={t("DeleteUserRequestModal.Yes")}
          />
        )}
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          text={t("DeleteUserRequestModal.accept")}
          disabled={
            deleteUserRequest?.pendingTickets > 0 ||
            deleteUserRequest?.manager ||
            deleteUserRequest?.admin ||
            deleteUserRequest?.sysadmin
          }
          buttonStyle={{
            marginBottom: "20px",
            marginTop: "20px",
            height: "40px",
            width: "210px",
            fontFamily: "Gilroy-Regular",
          }}
          onClick={() => {
            acceptDeleteRequest(deleteUserRequest?.id);
          }}
        />
      </div>
    </Modal>
  );
};
export default DeleteUserRequestModal;
