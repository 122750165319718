import React, { useEffect, useState } from "react";
import Sidebar from "../../sidebar/sidebar";
import Container from "@material-ui/core/Container";
import LoadingIndicator from "../../reusable/loadingIndicator";
import {useSelector} from "react-redux";
import { useTranslation } from "react-i18next";


import {
  useStyles,
} from "../../reusable/styles";





export default function Unauthorized() {

    const { t } = useTranslation();
    const classes = useStyles();
    const { isLoading } = useSelector((state) => state.loader);
    const [height, setHeight] = useState(window.innerHeight);
    const handleResize = () => {
        setHeight(window.innerHeight);
    };


    useEffect(() => {
      window.addEventListener("resize", handleResize, false);
    }, []);


    return (
        <div className={classes.root}>
            <Sidebar />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth={false} className={classes.container}>
                    {isLoading && <LoadingIndicator />}
                    <div>
                        <h1>You do not have permissions to view the admin panel</h1>
                    </div>
                </Container>
            </main>
        </div>

    );

}
