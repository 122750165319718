import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Button from "../../common/button/button";
import { useForm } from "react-hook-form";
import TextareaWithLabel from "../../common/input/textareaWithLabel";
import { useTranslation } from "react-i18next";
import { newModalStyle, submitButtonStyle } from "../../reusable/styles";

const ModalAddComment = (props) => {
  const { handleSubmit, register, reset } = useForm();

  const addComment = (data) => {
    props.addComment(data);
    reset();
  };

  const { t } = useTranslation();
  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={props.close}
      center
      styles={newModalStyle({ width: "450px" })}
    >
      <form className="modalForm" onSubmit={handleSubmit(addComment)}>
        <div className="form">
          <TextareaWithLabel
            label={t("Violations.Menu.Comment")}
            name="comment"
            type="comment"
            registerName="comment"
            register={register}
            textAreaStyle={{ minHeight: "220px" }}
            autoComplete="off"
            defaultValue={props.defaultValue}
          />

          <Button text={t("Buttons.save")} buttonStyle={submitButtonStyle} />
        </div>
      </form>
    </Modal>
  );
};
export default ModalAddComment;
