import {TimePicker} from "@mui/x-date-pickers";
import Style from "./timePickerInputWithLabel.module.css";

const TimePickerInputWithLabel = ({
  value,
  style,
  label,
  setTime,
  disabled,
}) => {
  return (
    <div className="form-group" style={style}>
      <div className={Style.input__label}>
        {label}
        <hr className={Style.input__hr} />
      </div>
      <TimePicker
        ampm={false}
        views={["hours", "minutes"]}
        slotProps={{ textField: { size: "small" } }}
        value={value}
        onChange={(value) => setTime(value)}
        disabled={!disabled}
      />
    </div>
  );
};

export default TimePickerInputWithLabel;
