export const GET_ALL_ADMINS = "GET_ALL_ADMINS";
export const ADD_ADMIN = "ADD_ADMIN";
export const EDIT_DATA = "EDIT_DATA";
export const GET_ALL_PARKING_ZONES = "GET_ALL_PARKING_ZONES";
export const ADD_PARKING_ZONE = "ADD_PARKING_ZONE";
export const GET_ALL_OWNERS = "GET_ALL_OWNERS";
export const GET_ALL_VIOLATIONS = "GET_ALL_VIOLATIONS";
export const DELETE_PARKING_ZONE = "DELETE_PARKING_ZONE";
export const GET_PARKING_ZONE_FOR_UPDATE = "GET_PARKING_ZONE_FOR_UPDATE";
export const APPROVE_OWNER = "APPROVE_OWNER";
export const CLEAR_PZ_FOR_EDIT = "CLEAR_PZ_FOR_EDIT";
export const UPDATE_PARKING_ZONE = "UPDATE_PARKING_ZONE";
export const SET_LOADER = "SET_LOADER";
export const DELETE_TICKET = "DELETE_TICKET";
export const LAST_DATE_REQUEST = "LAST_DATE_REQUEST";
export const DISABLE_ADMIN = "DISABLE_ADMIN";
export const GET_ALL_TICKET_SERIES = "GET_ALL_TICKET_SERIES";
export const TICKETS_GENERATED = "TICKETS_GENERATED";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const ARCHIVE_OWNER = "ARCHIVE_OWNER";
export const DUPLICATE_ADMIN = "DUPLICATE_ADMIN";
export const CLOSE_DUPLICATE_MODAL = "CLOSE_DUPLICATE_MODAL";
export const CANCEL_TICKET = "CANCEL_TICKET";
export const CANCEL_TICKET_FEE = "CANCEL_TICKET_FEE";
export const GENERATE_DOCUMENTATION = "GENERATE_DOCUMENTATION";
export const CHECK_EMAIL = "CHECK_EMAIL";
export const CLEAR_ADMIN_DATA = "CLEAR_ADMIN_DATA";
export const SELECTED_TAB = "SELECTED_TAB";
export const SHOW_PICTURE = "SHOW_PICTURE";
export const MANUALLY_PAY = "MANUALLY_PAY";
export const GET_ALL_REQUESTS = "GET_ALL_REQUESTS";
export const MARK_REQUEST_AS_SEEN = "MARK_REQUEST_AS_SEEN";
export const GET_TICKET_CANCELLATION_REQUEST_BY_ID =
  "GET_TICKET_CANCELLATION_REQUEST_BY_ID";
export const GET_TICKET_FEE_CANCELLATION_REQUEST_BY_ID =
  "GET_TICKET_FEE_CANCELLATION_REQUEST_BY_ID";
export const REMOVE_TICKET_CANCELLATION_REQUEST_FROM_REQUESTS =
  "REMOVE_TICKET_CANCELLATION_REQUEST_FROM_REQUESTS";
export const REMOVE_TICKET_FEE_CANCELLATION_REQUEST_FROM_REQUESTS =
  "REMOVE_TICKET_FEE_CANCELLATION_REQUEST_FROM_REQUESTS";
export const GET_PARKING_ZONE_OWNERSHIO_REQUEST_BY_ID =
  "GET_PARKING_ZONE_OWNERSHIO_REQUEST_BY_ID";
export const REMOVE_PARKING_ZONE_OWNERSHIP_REQUEST_FROM_REQUESTS =
  "REMOVE_PARKING_ZONE_OWNERSHIP_REQUEST_FROM_REQUESTS";
export const CHANGE_TICKET_LEGAL_STATUS = "CHANGE_TICKET_LEGAL_STATUS";
export const GET_ALL_WHITELIST_FOR_PARKING_ZONE =
  "GET_ALL_WHITELIST_FOR_PARKING_ZONE";
export const GET_PARKING_ZONE = "GET_PARKING_ZONE";
export const ADD_VEHICLE_TO_PARKING_ZONE_WHITELIST =
  "ADD_VEHICLE_TO_PARKING_ZONE_WHITELIST";
export const REMOVE_VEHICLE_FROM_PARKING_ZONE_WHITELIST =
  "REMOVE_VEHICLE_FROM_PARKING_ZONE_WHITELIST";
export const DUPLICATE_WHITELIST_WEHICLE = "DUPLICATE_WHITELIST_WEHICLE";
export const CLOSE_DUPLICATE_WHITELIST_WEHICLE_MODAL =
  "CLOSE_DUPLICATE_WHITELIST_WEHICLE_MODAL";
export const CLEAR_PARKING_ZONE_FOR_DETAILS = "CLEAR_PARKING_ZONE_FOR_DETAILS";
export const CREATE_PARKING_ZONE_ERROR = "CREATE_PARKING_ZONE_ERROR";
export const UPDATE_PARKING_ZONE_ERROR = "UPDATE_PARKING_ZONE_ERROR";
export const CLOSE_CREATE_PARKING_ZONE_ERROR_MODAL =
  "CLOSE_CREATE_PARKING_ZONE_ERROR_MODAL";
export const CLOSE_UPDATE_PARKING_ZONE_ERROR_MODAL =
  "CLOSE_UPDATE_PARKING_ZONE_ERROR_MODAL";
export const USER_WITH_GIVEN_EMAIL_NOT_FOUND =
  "USER_WITH_GIVEN_EMAIL_NOT_FOUND";
export const GET_ALL_MANAGERS_BY_ADMIN_ID = "GET_ALL_MANAGERS_BY_ADMIN_ID";
export const ADD_MANAGER = "ADD_MANAGER";
export const CHECK_MANAGERS_EMAIL = "CHECK_MANAGERS_EMAIL";
export const DUPLICATE_MANAGER = "DUPLICATE_MANAGER";
export const CLOSE_DUPLICATE_MANAGER_MODAL = "CLOSE_DUPLICATE_MANAGER_MODAL";
export const USER_MANAGER_WITH_GIVEN_EMAIL_NOT_FOUND =
  "USER_MANAGER_WITH_GIVEN_EMAIL_NOT_FOUND";
export const CLEAR_MANAGER_DATA = "CLEAR_MANAGER_DATA";
export const GET_ALL_MANAGERS_FOR_SYSADMIN = "GET_ALL_MANAGERS_FOR_SYSADMIN";
export const EDIT_MANAGER = "EDIT_MANAGER";
export const DISABLE_MANAGER = "DISABLE_MANAGER";
export const REMOVE_MANAGER_FROM_PZ_ADMINS_MANAGER_LIST =
  "REMOVE_MANAGER_FROM_PZ_ADMINS_MANAGER_LIST";
export const GET_ALL_VIEWERS_BY_ADMIN_ID = "GET_ALL_VIEWERS_BY_ADMIN_ID";
export const ADD_VIEWER = "ADD_VIEWER";
export const CHECK_VIEWERS_EMAIL = "CHECK_VIEWERS_EMAIL";
export const DUPLICATE_VIEWER = "DUPLICATE_VIEWER";
export const CLOSE_DUPLICATE_VIEWER_MODAL = "CLOSE_DUPLICATE_VIEWER_MODAL";
export const USER_VIEWER_WITH_GIVEN_EMAIL_NOT_FOUND =
    "USER_VIEWER_WITH_GIVEN_EMAIL_NOT_FOUND";
export const CLEAR_VIEWER_DATA = "CLEAR_VIEWER_DATA";
export const GET_ALL_VIEWERS_FOR_SYSADMIN = "GET_ALL_VIEWERS_FOR_SYSADMIN";
export const EDIT_VIEWER = "EDIT_VIEWER";
export const DISABLE_VIEWER = "DISABLE_VIEWER";
export const REMOVE_VIEWER_FROM_PZ_ADMINS_VIEWER_LIST =
    "REMOVE_VIEWER_FROM_PZ_ADMINS_VIEWER_LIST";
export const GET_ALL_PARKING_ZONES_FOR_PZ_ADMIN_OR_PZ_MANAGER =
  "GET_ALL_PARKING_ZONES_FOR_PZ_ADMIN_OR_PZ_MANAGER";
export const DISABLE_USER = "DISABLE_USER";
export const ARCHIVED_TICKETS = "ARCHIVED_TICKETS";
export const COMMENT_TICKET = "COMMENT_TICKET";
export const MODIFY_TICKET = "MODIFY_TICKET";
export const GENERATE_REMINDER_PAYMENT_SLIP = "GENERATE_REMINDER_PAYMENT_SLIP";
export const GET_ALL_BLACKLIST_FOR_PARKING_ZONE =
  "GET_ALL_BLACKLIST_FOR_PARKING_ZONE";
export const ADD_VEHICLE_TO_PARKING_ZONE_BLACKLIST =
  "ADD_VEHICLE_TO_PARKING_ZONE_BLACKLIST";
export const REMOVE_VEHICLE_FROM_PARKING_ZONE_BLACKLIST =
  "REMOVE_VEHICLE_FROM_PARKING_ZONE_BLACKLIST";
export const DUPLICATE_BLACKLIST_WEHICLE = "DUPLICATE_BLACKLIST_WEHICLE";
export const CLOSE_DUPLICATE_BLACKLIST_WEHICLE_MODAL =
  "CLOSE_DUPLICATE_BLACKLIST_WEHICLE_MODAL";
export const GET_DELETE_USER_REQUEST_BY_ID = "GET_DELETE_USER_REQUEST_BY_ID";
export const REMOVE_DELETE_USER_REQUEST_FROM_REQUESTS =
  "REMOVE_DELETE_USER_REQUEST_FROM_REQUESTS";
export const GET_ZENDESK_TICKET = "GET_ZENDESK_TICKET";
export const CLEAR_ZENDESK_TICKET = "CLEAR_ZENDESK_TICKET";
export const OWNERS_PARKING_ZONES_FOR_EDITING_TICKET =
  "OWNERS_PARKING_ZONES_FOR_EDITING_TICKET";
export const DONE_TICKET = "DONE_TICKET";
export const GET_ALL_TICKET_AUDIT = "GET_ALL_TICKET_AUDIT";
export const GET_GAS_STATION_OWNERSHIO_REQUEST_BY_ID =
  "GET_GAS_STATION_OWNERSHIO_REQUEST_BY_ID";
export const APPROVE_GAS_STATION_OWNER = "APPROVE_GAS_STATION_OWNER";
export const REMOVE_GAS_STATION_OWNERSHIP_REQUEST_FROM_REQUESTS =
  "REMOVE_GAS_STATION_OWNERSHIP_REQUEST_FROM_REQUESTS";
export const GET_ALL_GAS_STATION_ZONES = "GET_ALL_GAS_STATION_ZONES";
export const ADD_GAS_STATION = "ADD_GAS_STATION";
export const GET_GAS_STATION_FOR_UPDATE = "GET_GAS_STATION_FOR_UPDATE";
export const CLEAR_GAS_STATION_FOR_DETAILS = "CLEAR_GAS_STATION_FOR_DETAILS";
export const CLEAR_GAS_STATION_FOR_EDIT = "CLEAR_GAS_STATION_FOR_EDIT";
export const UPDATE_GAS_STATION = "UPDATE_GAS_STATION";
export const GET_ALL_GAS_STATIONS_FOR_GS_ADMIN_OR_GS_MANAGER =
  "GET_ALL_GAS_STATIONS_FOR_GS_ADMIN_OR_GS_MANAGER";
export const GET_ALL_PARKING_ZONES_FOR_BILLING =
  "GET_ALL_PARKING_ZONES_FOR_BILLING";
export const OWNERS_GAS_STATION_ZONES_FOR_EDITING_TICKET =
  "OWNERS_GAS_STATION_ZONES_FOR_EDITING_TICKET";
export const CLEAR_OWNERS_LIST_FOR_GS_AND_PZ_EDIT =
  "CLEAR_OWNERS_LIST_FOR_GS_AND_PZ_EDIT";
export const GET_TICKET_FOR_EDIT = "GET_TICKET_FOR_EDIT";
export const CLEAR_TICKET_FOR_EDIT = "CLEAR_TICKET_FOR_EDIT";
export const CHANGE_TICKET_DOCUMENT_LANGUAGE = "CHANGE_TICKET_DOCUMENT_LANGUAGE";
export const GET_ALL_GAS_STATION_ZONES_FOR_BILLING =
    "GET_ALL_GAS_STATION_ZONES_FOR_BILLING";
export const GET_CHART_DATA = "GET_CHART_DATA";
export const GET_ALL_CLIENTS = "GET_ALL_CLIENTS";
export const ADD_CLIENT = "ADD_CLIENT";
export const GET_CLIENT_FOR_UPDATE = "GET_CLIENT_FOR_UPDATE";
export const CLEAR_CLIENT_FOR_UPDATE = "CLEAR_CLIENT_FOR_UPDATE";
export const CLEAR_CLIENT_FOR_DETAILS = "CLEAR_CLIENT_FOR_DETAILS";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const RESET_CHART_DATA = "RESET_CHART_DATA";