import { GET_PARKING_ZONE_OWNERSHIO_REQUEST_BY_ID } from "../actions/types";

const initialState = {
  parkingZoneOwnershipRequest: {},
};

const parkingZoneOwnershipRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARKING_ZONE_OWNERSHIO_REQUEST_BY_ID:
      return {
        ...state,
        parkingZoneOwnershipRequest: action.payload,
      };

    default:
      return state;
  }
};

export default parkingZoneOwnershipRequestsReducer;
