import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Style from "./parkingZoneOwnershipRequsetModal.module.css";
import Button from "../../common/button/button";
import { parkingZoneOwnershipRequestStatus } from "../../globals";
import { handleOwnershipStatus } from "../../actions/owners";
import EntityDetail from "./entityDetail";
import { newModalStyle } from "../../reusable/styles";
import { useState } from "react";
import OwnerDetailsModal from "../owner/ownerDetailsModal";
import EditParkingZoneModal from "../parkingZone/editParkingZoneModal";
import ParkingZoneDetailsModal from "../parkingZone/parkingZoneDetailsModal";

const ParkingZoneOwnershipRequestModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openOwnerModal, setOpenOwnerModal] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [openParkingZoneDetailsModal, setOpenParkingZoneDetailsModal] =
    useState(false);
  const [selectedParkingZone, setSelectedParkingZone] = useState("");
  const [openModalEditParkingZone, setOpenModalEditParkingZone] =
    useState(false);

  const onClose = () => {
    props.setModalOpen(false);
  };

  const openParkingZoneEditModal = () => {
    setOpenModalEditParkingZone(true);
    setOpenParkingZoneDetailsModal(false);
  };

  const { parkingZoneOwnershipRequest } = useSelector(
    (state) => state.parkingZoneOwnershipRequests
  );

  const acceptPZOwnershipRequest = async (id) => {
    await dispatch(
      handleOwnershipStatus(
        parseInt(id),
        parkingZoneOwnershipRequestStatus.APPROVED
      )
    );
    onClose();
  };

  const declinePZOwnershipRequest = async (id) => {
    await dispatch(
      handleOwnershipStatus(
        parseInt(id),
        parkingZoneOwnershipRequestStatus.DENIED
      )
    );
    onClose();
  };

  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={onClose}
      center
      styles={newModalStyle({ width: "539px" })}
    >
      <div className="modal__headerLine" />
      <div className="modal__title">{props.title}</div>

      <div className={Style.body}>
        <EntityDetail
          label={t("parkingZoneOwnershipRequestModal.pzNumber")}
          value={parkingZoneOwnershipRequest?.parkingZoneNumber}
          pressableValue
          openModal={setOpenParkingZoneDetailsModal}
          setSelectedTicket={() => {
            setSelectedParkingZone(parkingZoneOwnershipRequest?.parkingZoneId);
          }}
        />

        <EntityDetail
          label={t("parkingZoneOwnershipRequestModal.pzName")}
          value={parkingZoneOwnershipRequest?.parkingZoneName}
        />

        <EntityDetail
          label={t("parkingZoneOwnershipRequestModal.dateOfRequest")}
          value={moment(parkingZoneOwnershipRequest?.createdOn).format(
            "DD.MM.YYYY"
          )}
        />

        <EntityDetail
          label={t("parkingZoneOwnershipRequestModal.timeOfRequest")}
          value={moment(parkingZoneOwnershipRequest?.createdOn).format("HH:mm")}
        />

        <EntityDetail
          label={t("parkingZoneOwnershipRequestModal.requestor")}
          value={parkingZoneOwnershipRequest?.requestor?.beppoOfficerData}
          pressableValue
          openModal={setOpenOwnerModal}
          setSelectedTicket={() => {
            setSelectedOwner(parkingZoneOwnershipRequest?.requestor);
          }}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          text={t("parkingZoneOwnershipRequestModal.decline")}
          buttonStyle={{
            marginBottom: "20px",
            marginTop: "20px",
            height: "40px",
            width: "210px",
            backgroundColor: "#CC0605",
            fontFamily: "Gilroy-Regular",
            marginRight: "28px",
          }}
          onClick={() =>
            declinePZOwnershipRequest(parkingZoneOwnershipRequest?.id)
          }
        />

        <Button
          text={t("parkingZoneOwnershipRequestModal.accept")}
          buttonStyle={{
            marginBottom: "20px",
            marginTop: "20px",
            height: "40px",
            width: "210px",

            fontFamily: "Gilroy-Regular",
          }}
          onClick={() => {
            acceptPZOwnershipRequest(parkingZoneOwnershipRequest?.id);
          }}
        />
      </div>
      {openOwnerModal && (
        <OwnerDetailsModal
          open={openOwnerModal}
          setModalOpen={setOpenOwnerModal}
          owner={selectedOwner}
          onClose={() => {
            setOpenOwnerModal(false);
            setSelectedOwner("");
          }}
        />
      )}
      {openParkingZoneDetailsModal && (
        <ParkingZoneDetailsModal
          open={openParkingZoneDetailsModal}
          onClose={() => setOpenParkingZoneDetailsModal(false)}
          parkingZoneId={selectedParkingZone}
          openParkingZoneEditModal={openParkingZoneEditModal}
        />
      )}

      {openModalEditParkingZone && (
        <EditParkingZoneModal
          open={openModalEditParkingZone}
          closeOnEsc
          onClose={() => setOpenModalEditParkingZone(false)}
        />
      )}
    </Modal>
  );
};
export default ParkingZoneOwnershipRequestModal;
