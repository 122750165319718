import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "../src/i18next";
import {BrowserRouter as Router} from "react-router-dom";
import {AuthProvider} from "react-oidc-context";


const oidcConfig = {
    authority: `${process.env.REACT_APP_KEYCLOAK_URL}realms/${process.env.REACT_APP_KEYCLOAK_REALM}`,
    client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_FRONTEND_URL,
};

ReactDOM.render(
    <React.StrictMode>
        <AuthProvider {...oidcConfig}>
            <Suspense fallback={<div>Loading</div>}>
                <Router>
                    <App/>
                </Router>
            </Suspense>
        </AuthProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
