import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";

import {NotificationContainer} from "react-notifications";
import "react-notifications/lib/notifications.css";
import "./common/notifications/notification.css";
import {axiosConfig} from "./axios";
import {useEffect} from "react";
import AppInner from "./AppInner";



function App() {
    useEffect(() => {
        axiosConfig();
    },[]);

    return (
        <>
            <NotificationContainer/>
            <AppInner/>
        </>
    );
}

export default App;
