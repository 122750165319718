import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Style from "./gasStationOwnershipRequestModal.module.css";
import Button from "../../common/button/button";
import { parkingZoneOwnershipRequestStatus } from "../../globals";
import { handleGSOwnershipStatus } from "../../actions/owners";
import EntityDetail from "./entityDetail";
import { newModalStyle } from "../../reusable/styles";
import { useState } from "react";
import OwnerDetailsModal from "../owner/ownerDetailsModal";

const GasStationOwnershipRequestModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openOwnerModal, setOpenOwnerModal] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [openParkingZoneDetailsModal, setOpenParkingZoneDetailsModal] =
    useState(false);
  const [selectedParkingZone, setSelectedParkingZone] = useState("");
  const [openModalEditParkingZone, setOpenModalEditParkingZone] =
    useState(false);

  const onClose = () => {
    props.setModalOpen(false);
  };

  const openParkingZoneEditModal = () => {
    setOpenModalEditParkingZone(true);
    setOpenParkingZoneDetailsModal(false);
  };

  const { gasStationOwnershipRequest } = useSelector(
    (state) => state.gasStationOwnershipRequests
  );

  console.log(gasStationOwnershipRequest);

  const acceptGSOwnershipRequest = async (id) => {
    await dispatch(
      handleGSOwnershipStatus(
        parseInt(id),
        parkingZoneOwnershipRequestStatus.APPROVED
      )
    );
    onClose();
  };

  const declineGSOwnershipRequest = async (id) => {
    await dispatch(
      handleGSOwnershipStatus(
        parseInt(id),
        parkingZoneOwnershipRequestStatus.DENIED
      )
    );
    onClose();
  };

  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={onClose}
      center
      styles={newModalStyle({ width: "539px" })}
    >
      <div className="modal__headerLine" />
      <div className="modal__title">{props.title}</div>

      <div className={Style.body}>
        <EntityDetail
          label={t("gasStationOwnershipRequestModal.gsNumber")}
          value={gasStationOwnershipRequest?.number}
          // pressableValue
          // ovde gas Station modal zone da se otvori kad bude gotov
          // openModal={setOpenParkingZoneDetailsModal}
          // setSelectedTicket={() => {
          //   setSelectedParkingZone(gasStationOwnershipRequest?.parkingZoneId);
          // }}
        />

        <EntityDetail
          label={t("gasStationOwnershipRequestModal.gsName")}
          value={gasStationOwnershipRequest?.name}
        />

        <EntityDetail
          label={t("gasStationOwnershipRequestModal.dateOfRequest")}
          value={moment(gasStationOwnershipRequest?.createdOn).format(
            "DD.MM.YYYY"
          )}
        />

        <EntityDetail
          label={t("gasStationOwnershipRequestModal.timeOfRequest")}
          value={moment(gasStationOwnershipRequest?.createdOn).format("HH:mm")}
        />

        <EntityDetail
          label={t("gasStationOwnershipRequestModal.requestor")}
          value={gasStationOwnershipRequest?.requestor?.beppoOfficerData}
          pressableValue
          openModal={setOpenOwnerModal}
          setSelectedTicket={() => {
            setSelectedOwner(gasStationOwnershipRequest?.requestor);
          }}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          text={t("gasStationOwnershipRequestModal.decline")}
          buttonStyle={{
            marginBottom: "20px",
            marginTop: "20px",
            height: "40px",
            width: "210px",
            backgroundColor: "#CC0605",
            fontFamily: "Gilroy-Regular",
            marginRight: "28px",
          }}
          onClick={() =>
            declineGSOwnershipRequest(gasStationOwnershipRequest?.id)
          }
        />

        <Button
          text={t("gasStationOwnershipRequestModal.accept")}
          buttonStyle={{
            marginBottom: "20px",
            marginTop: "20px",
            height: "40px",
            width: "210px",

            fontFamily: "Gilroy-Regular",
          }}
          onClick={() => {
            acceptGSOwnershipRequest(gasStationOwnershipRequest?.id);
          }}
        />
      </div>
      {openOwnerModal && (
        <OwnerDetailsModal
          open={openOwnerModal}
          setModalOpen={setOpenOwnerModal}
          owner={selectedOwner}
          onClose={() => {
            setOpenOwnerModal(false);
            setSelectedOwner("");
          }}
        />
      )}
    </Modal>
  );
};
export default GasStationOwnershipRequestModal;
