import React, { useState } from "react";
import Modal from "react-responsive-modal";
import Style from "./resetPasswordModal.module.css";
import Button from "../common/button/button";
import SmallLoadingIndicator from "../reusable/smallLoadingIndicator";
import { apiService } from "../utils/serviceApi";
import { useAuth } from "react-oidc-context";
import ConfirmationModal from "../common/modal/confirmationModal";
import {useTranslation} from "react-i18next";

const ResetPasswordModal = (props) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState();
  const [fail, setFail] = useState();
  const { t } = useTranslation();

    const sendResetPasswordRequest = async () => {
    if (user !== null && user?.profile.email !== undefined) {
      setIsLoading(true);
      apiService
          .post(
              `${process.env.REACT_APP_SERVICE_URL}/api/accounts/password/${user?.profile.email}`,
              { email: user?.profile.email },
          )
          .then(() => {
            setSuccess(true);
            setFail(false);
          })
          .catch((error) => {
            setSuccess(false);
            setFail(true);
            console.log(error);
          })
          .finally(() => {
            setIsLoading(false);
            props.close();
          });
    }
  };

  return (
      <ConfirmationModal
          title={t("Users.ResetPassword")}
          message={t("Users.ResetPasswordMessage")}
          confirmationButtonText={t("Users.Reset")}
          open={props.resetPasswordModalOpen}

          onClose={() => {
              props.close();
          }}
          confirmationAction={() => {
              sendResetPasswordRequest();
          }}
          width="450px"
      />
  );
};

export default ResetPasswordModal;