import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useTranslation } from "react-i18next";
import { newModalStyle, submitButtonStyle } from "../../reusable/styles";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import LabelWithHR from "../../common/labels/labelWithHR";
import Style from "./modalEditTicket.module.css";
import Button from "../../common/button/button";
import { useDispatch } from "react-redux";
import { editLockedViolatorAddress } from "../../actions/violations";

const ViolatorsAddressLockedModal = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm();
  const dispatch = useDispatch();

  const [violatorAddress, setViolatorAddress] = useState({
    ticketId: props?.ticket?.id,
    name: props?.ticket?.violatorAddress?.name,
    street: props?.ticket?.violatorAddress?.street,
    area: props?.ticket?.violatorAddress?.area,
    country: props?.ticket?.violatorAddress?.country,
  });

  const editViolatorAddress = () => {
    dispatch(editLockedViolatorAddress(violatorAddress));
    props.close();
  };
  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={props.close}
      center
      styles={newModalStyle({ width: "450px" })}
    >
      <form onSubmit={handleSubmit(editViolatorAddress)}>
        <div className="modal__title">
          {props?.ticket?.licensePlate}{" "}
          {props.ticket.violatorAddress?.locked && (
            <>- {t("ViolatorsAddressModal.LPLocked")}</>
          )}
        </div>
        <div>
          <LabelWithHR label={t("ViolatorsAddressModal.name")} />
          <Controller
            name="name"
            control={control}
            render={() => (
              <input
                className={Style.textStyleInputEditModal}
                name="name"
                onChange={(e) => {
                  setViolatorAddress({
                    ...violatorAddress,
                    name: e.target.value,
                  });
                }}
                defaultValue={violatorAddress.name}
              />
            )}
          />
        </div>
        <div>
          <LabelWithHR label={t("ViolatorsAddressModal.street")} />
          <Controller
            name="street"
            control={control}
            render={() => (
              <input
                className={Style.textStyleInputEditModal}
                onChange={(e) => {
                  setViolatorAddress({
                    ...violatorAddress,
                    street: e.target.value,
                  });
                }}
                defaultValue={violatorAddress.street}
              />
            )}
          />
        </div>
        <div>
          <LabelWithHR label={t("ViolatorsAddressModal.area")} />
          <Controller
            name="area"
            control={control}
            render={() => (
              <input
                className={Style.textStyleInputEditModal}
                onChange={(e) => {
                  setViolatorAddress({
                    ...violatorAddress,
                    area: e.target.value,
                  });
                }}
                defaultValue={violatorAddress.area}
              />
            )}
          />
        </div>
        <div>
          <LabelWithHR label={t("ViolatorsAddressModal.country")} />
          <Controller
            name="area"
            control={control}
            render={() => (
              <input
                className={Style.textStyleInputEditModal}
                onChange={(e) => {
                  setViolatorAddress({
                    ...violatorAddress,
                    country: e.target.value,
                  });
                }}
                defaultValue={violatorAddress.country}
              />
            )}
          />
        </div>
          <LabelWithHR label={t("ViolatorsAddressModal.status")} />
          <Controller
              name="status"
              control={control}
              render={() => (
                  <select
                      type="text"
                      name={`status`}
                      style={{ borderRadius: "5px", width:"100%", height:"3em", padding:"10px 5px" }}
                      onChange={(e) => {
                        setViolatorAddress({
                            ...violatorAddress,
                            status: e.target.value
                        })
                      }}
                  >
                      <option disabled selected value>
                      {"-"}
                     </option>
                      <option value="REQUESTED" key={1}>
                          {t(`ViolatorsAddressModal.Statuses.requested`)}
                      </option>
                      <option value="UNKNOWN" key={2}>
                          {t(`ViolatorsAddressModal.Statuses.unknown`)}
                      </option>
                  </select>
              )}
          />
        <Button text={t("Buttons.save")} buttonStyle={submitButtonStyle} />
      </form>
    </Modal>
  );
};
export default ViolatorsAddressLockedModal;
