import {Tooltip} from "@material-ui/core";
import cn from "classnames";
import moment from "moment";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useAuth} from "react-oidc-context";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {clearParkingZoneForDetails, getPaprkingZoneForUpdate,} from "../../actions/parkingZone";
import AddressDisplaySingle from "../../common/address/AddressDisplay";
import Button from "../../common/button/button";
import EntityDetail from "../../common/entityDetail/entityDetail";
import {ROLES} from "../../consts";
import keycloak from "../../keycloak";
import ModalLoadingIndicator from "../../reusable/modalLoadingIndicator";
import {newModalStyle} from "../../reusable/styles";
import {mapUser} from "../../utils/mapUser";
import Style from "./parkingZoneDetailsModal.module.css";

const ParkingZoneDetailsModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const parkingZone = useSelector(
    (state) => state.parkingZone.parkingZoneForDetails
  );
  const { isLoading } = useSelector((state) => state.loader);
  const auth = useAuth();
  const loggedUser = mapUser(auth);
  const isSysAdmin = keycloak.hasRealmRole(ROLES.SYSADMIN, auth);

  useEffect(() => {
    dispatch(getPaprkingZoneForUpdate(props.parkingZoneId));
    return () => {
      dispatch(clearParkingZoneForDetails());
    };
  }, [dispatch, props.parkingZoneId]);

  const SectionTitle = (props) => {
    return <div className={props.style}>{props.title}</div>;
  };

  const isParkingZoneEditDisabled = () => {
    if (loggedUser?.roles?.includes(ROLES.SYSADMIN)) {
      return false;
    }

    return loggedUser?.id !== parkingZone?.keycloakAdminId;
  };

  const showDocumentsNames = (names, urls) => {
    const urlArray = urls?.split(",");

    return names?.split("|")?.map((n, index) => (
      <div key={index} className={Style.uploadedFileName}>
        {<div onClick={() => window.open(urlArray[index], "_blank")}>{n}</div>}
      </div>
    ));
  };

  const calculateDurationTime = (minutes) => {
    if (!minutes) return "00:00";
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = remainingMinutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  };

  const renderUsersNames = (nameList) => {
    const concatNames = (names) => {
      return names?.map((m, i) => (i === 0 ? m : ", " + m));
    };

    return (
      nameList &&
      (concatNames(nameList)?.join().length > 135 ? (
        <Tooltip title={concatNames(nameList)} placement="right" arrow>
          <div className={Style.usersNames}>{concatNames(nameList)}</div>
        </Tooltip>
      ) : (
        <div className={Style.usersNames}>{concatNames(nameList)}</div>
      ))
    );
  };

  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={props.onClose}
      center
      styles={newModalStyle({ width: "900px" })}
    >
      <div className="modal__headerLine" />
      <div className="modal__title">{t("ParkingZoneDetailsModal.title")}</div>
      {isLoading && <ModalLoadingIndicator />}
      <div className={Style.bodyWrapper}>
        <SectionTitle
          style={Style.sectionTitle}
          title={t("ParkingZoneDetailsModal.generalInfo")}
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.pzNumber")}
          value={parkingZone?.parkingZoneNumber}
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.pzName")}
          value={parkingZone?.parkingZoneName}
        />
        <EntityDetail
          label={t("Clients.Client")}
          value={parkingZone?.customer}
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.staticDailyCode")}
          value={parkingZone?.staticPinCode || t("ParkingZoneDetailsModal.NO")}
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.numberOfParkingPlaces")}
          value={parkingZone?.numberOfParkingPlaces}
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.Geocode")}
          value={
            parkingZone?.markers?.length > 0
              ? t("ParkingZoneDetailsModal.Yes")
              : t("ParkingZoneDetailsModal.No")
          }
        />

        {parkingZone?.parkingPermitDurationTime && (
          <>
            <EntityDetail
              label={t("ParkingZoneDetailsModal.parkingPermitTime")}
              value={calculateDurationTime(
                parkingZone?.parkingPermitDurationTime
              )}
            />
            <EntityDetail
              label={t("ParkingZoneDetailsModal.freezeTime")}
              value={calculateDurationTime(parkingZone?.freezeTime)}
            />
          </>
        )}

        <EntityDetail
          label={t("ParkingZones.viacarIntegrationImmediately")}
          value={
            parkingZone?.viacarIntegrationImmediately
              ? t("ParkingZoneDetailsModal.Yes")
              : t("ParkingZoneDetailsModal.No")
          }
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.parkingPayZone")}
          value={
            parkingZone?.parkingPayZone || t("ParkingZoneDetailsModal.None")
          }
        />

        <EntityDetail
          label={t("ParkingZoneDetailsModal.price")}
          value={parkingZone?.price?.toFixed(2)}
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.paymentPeriod")}
          value={parkingZone?.paymentPeriod}
        />

        <EntityDetail
          label={t("ParkingZoneDetailsModal.objection")}
          value={
            parkingZone?.objectionEnabled
              ? t("ParkingZoneDetailsModal.objectionPossible")
              : t("ParkingZoneDetailsModal.objectionImpossible")
          }
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.reminderFee")}
          value={parkingZone?.reminderFee?.toFixed(2)}
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.additionalCost")}
          value={parkingZone?.additionalCost?.toFixed(2)}
        />

        <EntityDetail
          label={t("ParkingZoneDetailsModal.reminderPaymentPeriod")}
          value={parkingZone?.reminderPaymentPeriod}
        />


        <EntityDetail
            label={t("ParkingZoneDetailsModal.videoAnalysis")}
            value={
              parkingZone?.videoAnalyzed
                  ? t("ParkingZoneDetailsModal.Yes")
                  : t("ParkingZoneDetailsModal.No")
            }
        />

        {isSysAdmin && (
          <>
            {parkingZone?.salesArea && (
              <EntityDetail
                label={t("ParkingZones.salesArea")}
                value={parkingZone?.salesArea}
              />
            )}

            {parkingZone?.contractDate && (
              <EntityDetail
                label={t("ParkingZones.contractDate")}
                value={moment(parkingZone?.contractDate).format("DD.MM.YYYY")}
              />
            )}
            {parkingZone.pzComment && (
              <>
                <SectionTitle
                  style={Style.sectionTitle}
                  title={t("ParkingZones.pzComment")}
                />
                <p>{parkingZone.pzComment}</p>
              </>
            )}
          </>
        )}

        <SectionTitle
          style={Style.sectionTitle}
          title={t("ParkingZoneDetailsModal.text")}
        />

        <div
          className={Style.textOnProhibitionSign}
          dangerouslySetInnerHTML={{ __html: parkingZone?.parkingZoneText }}
        />

        <SectionTitle
          style={Style.sectionTitle}
          title={t("ParkingZoneDetailsModal.paymentInfo")}
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.payee")}
          value={parkingZone?.payee}
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.iban")}
          value={parkingZone?.iban}
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.bic")}
          value={parkingZone?.bic}
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.financialInstitution")}
          value={parkingZone?.financialInstitution}
        />

        <SectionTitle
          style={Style.sectionTitle}
          title={t("ParkingZoneDetailsModal.billingInfo")}
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.creditFormula")}
          value={parkingZone?.creditFormula}
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.invoicingFormula")}
          value={parkingZone?.invoicingFormula}
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.customerShare")}
          value={parkingZone?.customerShare}
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.commissionFormula")}
          value={parkingZone?.commissionFormula}
        />
        <EntityDetail
          label={t("ParkingZoneDetailsModal.commissionShare")}
          value={parkingZone?.commissionShare}
        />

        <div className={Style.twoColumnsContainer}>
          <div className={Style.leftColumnContainer}>
            <SectionTitle
              style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
              title={t("ParkingZoneDetailsModal.documents")}
            />
            <>
              <div className={Style.uploadedFileTitle}>
                {t("ParkingZoneDetailsModal.PowerOfAttorney")}
              </div>
              {parkingZone?.powerOfAttorneyName === "" ? null : (
                <div className={Style.text}>
                  {showDocumentsNames(
                    parkingZone?.powerOfAttorneyName,
                    parkingZone?.powerOfAttorney
                  )}
                </div>
              )}
            </>

            <>
              <div className={Style.uploadedFileTitle}>
                {t("ParkingZoneDetailsModal.LandRegister")}
              </div>
              {parkingZone?.landRegisterName === "" ? null : (
                <div className={Style.text}>
                  {showDocumentsNames(
                    parkingZone?.landRegisterName,
                    parkingZone?.landRegister
                  )}
                </div>
              )}
            </>

            <>
              <div className={Style.uploadedFileTitle}>
                {t("ParkingZoneDetailsModal.PZPhotos")}
              </div>
              {parkingZone?.parkingPhotoName === "" ? null : (
                <div className={Style.text}>
                  {showDocumentsNames(
                    parkingZone?.parkingPhotoName,
                    parkingZone?.parkingPhoto
                  )}
                </div>
              )}
            </>
            <>
              <div className={Style.uploadedFileTitle}>
                {t("ParkingZoneDetailsModal.PZDocumentation")}
              </div>
              {parkingZone?.documentationName === "" ? null : (
                <div className={Style.text}>
                  {showDocumentsNames(
                    parkingZone?.documentationName,
                    parkingZone?.documentation
                  )}
                </div>
              )}
            </>

            <SectionTitle
              style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
              title={t("Addresses.address")}
            />
            <div className={Style.text}>
              <AddressDisplaySingle address={parkingZone?.address} />
            </div>
          </div>
          <div className={Style.rightColumnContainer}>

            <SectionTitle
              style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
              title={t("Clients.Client")}
            />
            <div className={Style.text}>
              {parkingZone?.client && `${parkingZone.client.name} | ${parkingZone.client.representativeName}`}
            </div>

            <SectionTitle
                style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
                title={t("Viewer.Viewers")}
            />
            {renderUsersNames(parkingZone?.viewersNames)}

            <SectionTitle
              style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
              title={t("ParkingZoneDetailsModal.administrators")}
            />
            <div className={Style.text}>
              {parkingZone?.parkingZoneAdminName}
            </div>

            <SectionTitle
              style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
              title={t("ParkingZoneDetailsModal.managers")}
            />
            {renderUsersNames(parkingZone?.managersNames)}

            <SectionTitle
              style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
              title={t("ParkingZoneDetailsModal.beppolicemans")}
            />

            {renderUsersNames(parkingZone?.beppolicemansNames)}
          </div>
        </div>

        <div className={Style.editButton}>
          <Button
            text={t("ParkingZoneDetailsModal.edit")}
            buttonStyle={{
              marginBottom: "20px",
              marginTop: "20px",
              height: "40px",
              width: "181px",
              marginRight: "10px",
              fontFamily: "Gilroy-Regular",
            }}
            onClick={() => props.openParkingZoneEditModal()}
            disabled={isParkingZoneEditDisabled()}
          />
        </div>
      </div>
    </Modal>
  );
};
export default ParkingZoneDetailsModal;
