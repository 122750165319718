import Modal from "react-responsive-modal";
import React, { useEffect } from "react";
import "react-responsive-modal/styles.css";
import { materialTableStyle, newModalStyle } from "../../reusable/styles";
import MaterialTable from "@material-table/core";
import { useDispatch, useSelector } from "react-redux";
import { getAuditTickets } from "../../actions/violations";
import { useTranslation } from "react-i18next";
import { cellStyle, headerStyle } from "../../columns/columnsGeneral";
import moment from "moment";
import { optionsAudit } from "../../reusable/optionsForTables";

const ModalAuditTicket = (props) => {
  const { open, close, ticketForAudit } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { ticketAudit } = useSelector((state) => state.violations);

  useEffect(() => {
    dispatch(getAuditTickets(ticketForAudit));
  }, []);

  return (
    <Modal
      open={open}
      closeOnEsc
      onClose={close}
      center
      styles={newModalStyle()}
    >
      <div style={{ margin: "10px" }}>
        <MaterialTable
          title={t("AuditTicket.History")}
          style={materialTableStyle}
          data={ticketAudit}
          options={optionsAudit()}
          columns={[
            {
              title: t("AuditTicket.ModifiedOn"),
              field: "modifiedOn",
              cellStyle: cellStyle,
              headerStyle: headerStyle,
              render: (row) => (
                <>{moment(row.modifiedOn).format("DD.MM.YYYY")}</>
              ),
            },
            {
              title: t("AuditTicket.StatusUntil"),
              field: "statusUntil",
              cellStyle: cellStyle,
              headerStyle: headerStyle,
              render: (row) => (
                <>{moment(row.statusUntil).format("DD.MM.YYYY")}</>
              ),
            },
            {
              title: t("AuditTicket.Status"),
              field: "status",
              cellStyle: cellStyle,
              headerStyle: headerStyle,
            },
            {
              title: t("AuditTicket.LegalStatus"),
              field: "legalStatus",
              cellStyle: cellStyle,
              headerStyle: headerStyle,
            },
            {
              title: t("AuditTicket.PaidAmount"),
              field: "paidAmount",
              cellStyle: cellStyle,
              headerStyle: headerStyle,
            },
            {
              title: t("AuditTicket.Amount"),
              field: "amount",
              cellStyle: cellStyle,
              headerStyle: headerStyle,
            },
            {
              title: t("AuditTicket.ModifiedBy"),
              field: "modifiedBy",
              cellStyle: cellStyle,
              headerStyle: headerStyle,
            },
          ]}
        />
      </div>
    </Modal>
  );
};
export default ModalAuditTicket;
