import keycloak from "../keycloak";

export const isAllowed = (roles, auth) => {
  if (roles === undefined) {
    return true; // if no roles need to be authorized
  }
  return roles.some((r) => {
    const realm = keycloak.hasRealmRole(r, auth);
    return realm;
  });
};
