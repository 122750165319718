import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import cn from "classnames";
import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useAuth} from "react-oidc-context";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {addClient} from "../../actions/client";
import {getAllGasStations} from "../../actions/gasStation";

import {getAllParkingZones} from "../../actions/parkingZone";
import AddressesComponent from "../../common/address/AddressesComponent";

import XIcon from "../../common/assets/x-icon.svg";
import Button from "../../common/button/button";
import InputFieldWithLabel from "../../common/input/inputFieldWithLabel";
import {ROLES} from "../../consts";
import keycloak from "../../keycloak";
import {newModalStyle} from "../../reusable/styles";
import Style from "./addClientModal.module.css";

const AddClientModal = (props) => {
    const auth = useAuth();
    const { handleSubmit, register, watch, control } = useForm();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { parkingZones } = useSelector((state) => state.parkingZone);
    const { gasStations } = useSelector((state) => state.gasStation);
    const [pzIds, setPzIds] = useState([]);
    const [gsIds, setGsIds] = useState([]);
    const [pzComboBoxNames, setPzComboBoxNames] = useState([]);
    const [gsComboBoxNames, setGsComboBoxNames] = useState([]);
    const [pzCounter, setPzCounter] = useState(0);
    const [gsCounter, setGsCounter] = useState(0);
    const createParkingZoneDto = (id) => ({ id });
    const createGasStationZoneDto = (id) => ({ id });

    const [powerOfAttorneyName, setPowerOfAttorneyName] = useState("");
    const [powerOfAttorney, setPowerOfAttorney] = useState(null);
    const [documentation, setDocumentation] = useState(null);
    const [documentationName, setDocumentationName] = useState("");

    const isSysAdmin = keycloak.hasRealmRole(ROLES.SYSADMIN, auth);

    const name = watch("name");
    const representativeName = watch("representativeName");
    const email = watch("email");
    const phoneNumber = watch("phoneNumber");
    const [address, setAddress] = useState(null);
    const iban = watch("iban");
    const bic = watch("bic");
    const payee = watch("payee");
    const financialInstitution = watch("financialInstitution");
    const bankAccountNumber = watch("bankAccountNumber");

    const isSubmitDisabled =
        !address || !name || !representativeName || !email;

    useEffect(() => {
        if (isSysAdmin) {
            dispatch(getAllParkingZones());
            dispatch(getAllGasStations());
        }
    }, [dispatch, isSysAdmin]);

    const documentUpload = (e, files, setFiles, filesNames, setFilesNames, isSingleFile) => {
        if (e.target.files.length !== 0) {
            let fileList = files === null ? [] : files;
            let names = filesNames;
            for (let i = 0; i < e.target.files.length; i++) {
                if (isSingleFile && i === 0) {
                    fileList = [];
                    fileList.push(e.target.files[i])
                    names = e.target.files[i].name;
                } else {
                    fileList.push(e.target.files[i]);
                    names = names === "" ? e.target.files[i].name : names.concat("|", e.target.files[i].name);
                }
            }
            setFiles(fileList);
            setFilesNames(names);
        }
    };

    const saveClient = async () => {
        const formData = new FormData();

        for (let i = 0; i < powerOfAttorney?.length; i++) {
            formData.append("powerOfAttorney", powerOfAttorney[i]);
        }
        formData.append("powerOfAttorneyName", powerOfAttorneyName);
        for (let i = 0; i < documentation?.length; i++) {
            formData.append("documentation", documentation[i]);
        }
        formData.append("documentationName", documentationName);
        const parkingZones = pzIds.map(createParkingZoneDto);
        const gasStationZones = gsIds.map(createGasStationZoneDto);
        const newClient = {
            name,
            representativeName,
            email,
            phoneNumber,
            address,
            iban,
            bic,
            payee,
            financialInstitution,
            bankAccountNumber,
            pzIds,
            gsIds,
            parkingZones,
            gasStationZones
        };

        formData.append(
            "client",
            new Blob([JSON.stringify(newClient)], {
                type: "application/json",
            })
        );

        dispatch(addClient(formData, props.onClose));
    };

    const removeDocument = (
        files,
        setFiles,
        filesNames,
        setFilesNames,
        index
    ) => {
        setFiles([...files?.filter((f, i) => i !== index)]);
        const namesArray = filesNames?.split("|");
        namesArray.splice(index, 1);
        setFilesNames(namesArray?.join("|"));
    };

    const showDocumentsNames = (files, setFiles, filesNames, setFilesNames) => {
        return filesNames?.split("|")?.map((n, index) => (
            <div key={index} className={Style.uploadedFile}>
                <div className={Style.uploadedFile__name}>{n}</div>
                <div className={Style.uploadedFile__line} />
                <div className={Style.uploadedFile__removeBtn}>
                    <img
                        alt=""
                        src={XIcon}
                        height="25px"
                        width="25px"
                        onClick={() => {
                            removeDocument(files, setFiles, filesNames, setFilesNames, index);
                        }}
                    />
                </div>
            </div>
        ));
    };

    const renderParkingZones = () => {
        const getListOfParkingZones = (parkingZones, ordinalNumber) => {
            if (ordinalNumber === 1) return parkingZones;
            return parkingZones.filter(
                (p) => !pzIds.slice(0, ordinalNumber - 1).includes(p.id)
            );
        };

        return (
            <div>
                {pzIds.map((pz, index) => {
                    const ordinalNumber = index + 1;

                    return (
                        <div key={index} className={Style.combo}>
                            <div className={Style.combo__select}>
                                <Controller
                                    name={`parkingZone_${pzComboBoxNames[index]}`}
                                    control={control}
                                    render={() => (
                                        <Autocomplete
                                            className={Style.textStyleSelectEditModal}
                                            id={`parkingZone_${pzComboBoxNames[index]}`}
                                            onChange={(e,value)  => {
                                                if(value) {
                                                    pzIds[index] = value.id;
                                                    setPzIds([...pzIds]);
                                                }
                                            }}
                                            disableClearable
                                            value = {parkingZones.find(x=>x.id===pz) || null}
                                            renderInput={(params) => <TextField {...params} placeholder={t("Clients.Choose")}/>}
                                            options={getListOfParkingZones(parkingZones, ordinalNumber)}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            getOptionLabel={(x) => `${x.parkingZoneNumber} ${x.parkingZoneName}`}
                                        />
                                    )}
                                />
                            </div>
                            <div className={Style.combo__removeBtn}>
                                <img
                                    alt=""
                                    src={XIcon}
                                    height="25px"
                                    width="25px"
                                    onClick={(e) => {
                                        setPzIds([
                                            ...pzIds.filter((m, i) => i !== index),
                                        ]);
                                        pzComboBoxNames.splice(index, 1);
                                        setPzComboBoxNames([...pzComboBoxNames]);
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderGasStations = () => {
        const getListOfGasStations = (gasStations, ordinalNumber) => {
            if (ordinalNumber === 1) return gasStations;
            return gasStations.filter(
                (g) => !gsIds.slice(0, ordinalNumber - 1).includes(g.id)
            );
        };

        return (
            <div>
                {gsIds.map((gs, index) => {
                    const ordinalNumber = index + 1;

                    return (
                        <div key={index} className={Style.combo}>
                            <div className={Style.combo__select}>
                                <Controller
                                    name={`gasStation_${gsComboBoxNames[index]}`}
                                    control={control}
                                    render={() => (
                                        <Autocomplete
                                            className={Style.textStyleSelectEditModal}
                                            id={`gasStation_${gsComboBoxNames[index]}`}
                                            onChange={(e,value)  => {
                                                if(value) {
                                                    gsIds[index] = value.id;
                                                    setGsIds([...gsIds]);
                                                }
                                            }}
                                            disableClearable
                                            value = {gasStations.find(x=>x.id===gs) || null}
                                            renderInput={(params) => <TextField {...params} placeholder={t("Clients.Choose")}/>}
                                            options={getListOfGasStations(gasStations, ordinalNumber)}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            getOptionLabel={(x) => `${x.number} ${x.name}`}
                                        />
                                    )}
                                />
                            </div>
                            <div className={Style.combo__removeBtn}>
                                <img
                                    alt=""
                                    src={XIcon}
                                    height="25px"
                                    width="25px"
                                    onClick={(e) => {
                                        setGsIds([
                                            ...gsIds.filter((m, i) => i !== index),
                                        ]);
                                        gsComboBoxNames.splice(index, 1);
                                        setGsComboBoxNames([...gsComboBoxNames]);
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <Modal
            open={props.open}
            closeOnEsc
            onClose={props.onClose}
            center
            styles={newModalStyle({ width: "70%", maxWidth: "900px" })}
        >
            <div className="modal__headerLine" />
            <div className={cn("modal__title", Style.modal__title)}>
                {props.title}
            </div>
            <form onSubmit={handleSubmit(saveClient)}>
                <div className={Style.formContainer}>
                    <div className={Style.section}>
                        <div className={Style.section__title}>
                            {t("Clients.BasicInfo")}
                        </div>

                        <div className={Style.clientSectionContainer}>
                            <div className={Style.clientLeftInput}>
                                <InputFieldWithLabel
                                    label={t("Clients.Columns.Name")}
                                    name="name"
                                    registerName="name"
                                    register={register}
                                    required={true}
                                />
                            </div>

                            <div className={Style.clientRightInput}>
                                <InputFieldWithLabel
                                    label={t("Clients.Columns.RepresentativeName")}
                                    name="representativeName"
                                    registerName="representativeName"
                                    register={register}
                                    required={true}
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className={Style.clientSectionContainer}>
                            <div className={Style.clientLeftInput}>
                                <InputFieldWithLabel
                                    label={t("Clients.Columns.Email")}
                                    name="email"
                                    registerName="email"
                                    register={register}
                                    required={true}
                                    type="email"
                                />
                            </div>

                            <div className={Style.clientRightInput}>
                                <InputFieldWithLabel
                                    label={t("Clients.Columns.PhoneNumber")}
                                    name="phoneNumber"
                                    registerName="phoneNumber"
                                    register={register}
                                    type="tel"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Address Section */}
                    <div className={Style.section}>
                        <AddressesComponent
                            onAddressAdded={(newAddress) => {
                                setAddress(newAddress)
                            }}
                            watch={watch}
                            register={register}
                        />
                    </div>

                    <div className={cn(Style.section, Style["section--payment"])}>
                        <div className={Style.section__title}>
                            {t("Clients.PaymentInfo")}
                        </div>
                        <div className={Style.paymentSection__rowContainer}>
                            <div
                                className={cn(
                                    Style.container,
                                    Style["container--margineRight"]
                                )}
                            >
                                <InputFieldWithLabel
                                    label={t("Clients.Iban")}
                                    name="iban"
                                    registerName="iban"
                                    register={register}
                                />
                            </div>
                            <div
                                className={cn(Style.container, Style["container"])}
                            >
                                <InputFieldWithLabel
                                    label={t("Clients.Bic")}
                                    name="bic"
                                    registerName="bic"
                                    register={register}
                                />
                            </div>
                        </div>
                        <div className={Style.paymentSection__rowContainer}>
                            <div
                                className={cn(
                                    Style.container,
                                    Style["container--margineRight"]
                                )}
                            >
                                <InputFieldWithLabel
                                    label={t("Clients.Payee")}
                                    name="payee"
                                    registerName="payee"
                                    register={register}
                                />
                            </div>
                            <div
                                className={cn(Style.container, Style["container"])}
                            >
                                <InputFieldWithLabel
                                    label={t("Clients.FinancialInstitution")}
                                    name="financialInstitution"
                                    registerName="financialInstitution"
                                    register={register}
                                />
                            </div>
                        </div>
                        <div
                            className={cn(Style.container, Style["container"])}
                        >
                            <InputFieldWithLabel
                                label={t("Clients.Columns.BankAccountNumber")}
                                name="bankAccountNumber"
                                registerName="bankAccountNumber"
                                register={register}
                            />
                        </div>
                    </div>

                    <div className={Style.section}>
                        <div className={Style.section__title}>
                            {t("Clients.Documents")}
                        </div>
                        <div className={Style.container}>
                            <>
                                <label htmlFor="file-upload" className={Style.fileUpload}>
                                    {t("Clients.PowerOfAttorney")}
                                </label>
                                {powerOfAttorneyName !== "" && (
                                    <label>
                                        {powerOfAttorneyName === "" ? null : (
                                            <div>
                                                {showDocumentsNames(
                                                    powerOfAttorney,
                                                    setPowerOfAttorney,
                                                    powerOfAttorneyName,
                                                    setPowerOfAttorneyName
                                                )}
                                            </div>
                                        )}
                                    </label>
                                )}

                                <input
                                    id="file-upload"
                                    type="file"
                                    accept="image/jpg, image/png, image/jpeg"
                                    className="powerOfAttorney"
                                    onChange={(e) => {
                                        documentUpload(
                                            e,
                                            powerOfAttorney,
                                            setPowerOfAttorney,
                                            powerOfAttorneyName,
                                            setPowerOfAttorneyName,
                                            true
                                        );
                                    }}
                                    multiple={false}
                                />
                                <label htmlFor="documentation" className={Style.fileUpload}>
                                    {t("Clients.BranchDocuments")}
                                </label>
                                {documentationName !== "" && (
                                    <label>
                                        {documentationName === "" ? null : (
                                            <div>
                                                {showDocumentsNames(
                                                    documentation,
                                                    setDocumentation,
                                                    documentationName,
                                                    setDocumentationName
                                                )}
                                            </div>
                                        )}
                                    </label>
                                )}
                                <input
                                    id="documentation"
                                    type="file"
                                    accept=".pdf, .docx"
                                    className="documentation"
                                    multiple
                                    onChange={(e) => {
                                        documentUpload(
                                            e,
                                            documentation,
                                            setDocumentation,
                                            documentationName,
                                            setDocumentationName,
                                            false
                                        );
                                    }}
                                />
                            </>
                        </div>
                    </div>

                    <div className={Style.section}>
                        <div
                            className={cn(
                                Style.section__title,
                                Style["section__title--admin"]
                            )}
                        >
                            {t("Clients.ParkingZones")}
                        </div>

                        {renderParkingZones()}

                        <button
                            type="button"
                            className={
                                pzIds?.includes("0")
                                    ? cn(
                                        Style.addComboBoxButton,
                                        Style["addComboBoxButton--disabled"]
                                    )
                                    : cn(
                                        Style.addComboBoxButton,
                                        Style["addComboBoxButton--enabled"]
                                    )
                            }
                            onClick={() => {
                                setPzIds([...pzIds, "0"]);
                                setPzComboBoxNames([
                                    ...pzComboBoxNames,
                                    pzCounter + 1,
                                ]);
                                setPzCounter(pzCounter + 1);
                            }}
                            disabled={pzIds?.includes("0")}
                        >
                            {t("Clients.AddParkingZone")}
                        </button>

                        <div
                            className={cn(
                                Style.section__title,
                                Style["section__title--admin"]
                            )}
                        >
                            {t("Clients.GasStations")}
                        </div>
                        {renderGasStations()}
                        <button
                            type="button"
                            className={
                                gsIds?.includes("0")
                                    ? cn(
                                        Style.addComboBoxButton,
                                        Style["addComboBoxButton--disabled"]
                                    )
                                    : cn(
                                        Style.addComboBoxButton,
                                        Style["addComboBoxButton--enabled"]
                                    )
                            }
                            onClick={() => {
                                setGsIds([...gsIds, "0"]);
                                setGsComboBoxNames([
                                    ...gsComboBoxNames,
                                    gsCounter + 1,
                                ]);
                                setGsCounter(gsCounter + 1);
                            }}
                            disabled={gsIds?.includes("0")}
                        >
                            {t("Clients.AddGasStation")}
                        </button>
                        <div className={Style.buttonContainer}>
                            <Button
                                style={{
                                    width: "25%",
                                }}
                                buttonStyle={{
                                    width: "100%",
                                }}
                                disabled={isSubmitDisabled}
                                text={t("Clients.SaveClient")}
                            />
                        </div>
                    </div>

                </div>
            </form>
        </Modal>
    );


};
export default AddClientModal;