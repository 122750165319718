import React from "react";
import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import Style from "./objectionModal.module.css";
import { modalStyle } from "../reusable/styles";
import moment from "moment";

export default function ObjectionModal(props) {
  return (
    <Modal
      open={props.objectionOpen}
      closeOnEs={true}
      onClose={props.close}
      center
      styles={modalStyle}
    >
      <form className="modalForm">
        <div className="form">
          <div className={Style.container}>
            <div className={Style.flex}>
              <div>
                <span className={Style.italicLabelsDesktop}>E-mail: </span>
              </div>
              <div>
                <span className={Style.boldDesktopValues}>
                  {props.objection.email}
                </span>
              </div>
            </div>
            <div className={Style.flex}>
              <div>
                <span className={Style.italicLabelsDesktop}>Vorname: </span>
              </div>
              <div>
                <span className={Style.boldDesktopValues}>
                  {props.objection.firstName}
                </span>
              </div>
            </div>
            <div className={Style.flex}>
              <div>
                <span className={Style.italicLabelsDesktop}>Nachname: </span>
              </div>
              <div>
                <span className={Style.boldDesktopValues}>
                  {props.objection.lastName}
                </span>
              </div>
            </div>
            <div className={Style.flex}>
              <div>
                <span className={Style.italicLabelsDesktop}>Zeit: </span>
              </div>
              <div>
                <span className={Style.boldDesktopValues}>
                  {moment(props.objection.createdOn).format("DD.MM.YYYY HH:mm")}
                </span>
              </div>
            </div>
            <div className={Style.flex}>
              <div>
                <span className={Style.italicLabelsDesktop}>Nachricht: </span>
              </div>
              <div>
                <span className={Style.boldDesktopValues}>
                  {props.objection.complaintText}
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
