import React, { useState } from "react";
import { cellStyle, filterStyle, headerStyle } from "./columnsGeneral";

export function OwnersColumns(t) {
  const columns = useState([
    {
      title: t("Columns.First name"),
      field: "firstName",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Last name"),
      field: "lastName",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Email"),
      field: "emailAddress",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Phone admins page"),
      field: "phoneNumber",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },

    {
      title: t("Columns.ParkingZone"),
      field: "ownershipParkingZonesAndReportedViolations",
      filterCellStyle: filterStyle,
      headerStyle: {
        ...headerStyle,
        textAlign: "center",
      },
      cellStyle: {
        ...cellStyle,
        textAlign: "left",
      },
      customFilterAndSearch: (searchValue, row) => {
        const joinedPZNumbers = Object.keys(
          row.ownershipParkingZonesAndReportedViolations
        ).join();

        if (joinedPZNumbers.includes(searchValue)) {
          return true;
        }
      },
      render: (row) => {
        if (!row.ownershipParkingZonesAndReportedViolations) {
          return "";
        }

        const keys =
          Object.keys(row.ownershipParkingZonesAndReportedViolations) || "";
        return (
          keys &&
          keys.map((key, index) => {
            return <p key={index}>{key}</p>;
          })
        );
      },
    },
    {
      title: t("Columns.PZ Ticket"),
      headerStyle: headerStyle,
      cellStyle: cellStyle,
      render: (row) => {
        if (!row.ownershipParkingZonesAndReportedViolations) {
          return "";
        }
        const values = Object.values(
          row.ownershipParkingZonesAndReportedViolations
        );
        return (
          values &&
          values.map((value, index) => {
            return <p key={index}>{value}</p>;
          })
        );
      },
    },
    {
      title: t("Columns.GasStation"),
      field: "ownershipGasStationsAndReportedViolations",
      filterCellStyle: filterStyle,
      headerStyle: {
        ...headerStyle,
        textAlign: "center",
      },
      cellStyle: {
        ...cellStyle,
        textAlign: "left",
      },
      customFilterAndSearch: (searchValue, row) => {
        const joinedGSNumbers = Object.keys(
          row.ownershipGasStationsAndReportedViolations
        ).join();

        if (joinedGSNumbers.includes(searchValue)) {
          return true;
        }
      },
      render: (row) => {
        if (!row.ownershipGasStationsAndReportedViolations) {
          return "";
        }

        const keys =
          Object.keys(row.ownershipGasStationsAndReportedViolations) || "";
        return (
          keys &&
          keys.map((key, index) => {
            return <p key={index}>{key}</p>;
          })
        );
      },
    },
    {
      title: t("Columns.GS Ticket"),
      headerStyle: headerStyle,
      cellStyle: cellStyle,
      render: (row) => {
        if (!row.ownershipGasStationsAndReportedViolations) {
          return "";
        }
        const values = Object.values(
          row.ownershipGasStationsAndReportedViolations
        );
        return (
          values &&
          values.map((value, index) => {
            return <p key={index}>{value}</p>;
          })
        );
      },
    },
  ]);
  return columns[0];
}
