import {
  ADD_VEHICLE_TO_PARKING_ZONE_BLACKLIST,
  CLOSE_DUPLICATE_BLACKLIST_WEHICLE_MODAL,
  DUPLICATE_BLACKLIST_WEHICLE,
  GET_ALL_BLACKLIST_FOR_PARKING_ZONE,
  REMOVE_VEHICLE_FROM_PARKING_ZONE_BLACKLIST,
} from "../actions/types";

const initialState = {
  blacklist: [],
  duplicateBlacklistVehicle: false,
};

const blacklistReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BLACKLIST_FOR_PARKING_ZONE:
      return {
        ...state,
        blacklist: action.payload,
      };

    case ADD_VEHICLE_TO_PARKING_ZONE_BLACKLIST:
      return {
        ...state,
        blacklist: state.blacklist.concat(action.payload),
      };

    case REMOVE_VEHICLE_FROM_PARKING_ZONE_BLACKLIST:
      return {
        ...state,
        blacklist: state.blacklist.filter((v) => v.id !== action.payload),
      };

    case DUPLICATE_BLACKLIST_WEHICLE:
      return {
        ...state,
        duplicateBlacklistVehicle: true,
      };
    case CLOSE_DUPLICATE_BLACKLIST_WEHICLE_MODAL:
      return {
        ...state,
        duplicateBlacklistVehicle: false,
      };

    default:
      return state;
  }
};

export default blacklistReducer;
