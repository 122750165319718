import { DISABLE_USER, GET_ALL_USERS, SET_LOADER } from "./types";
import { apiService } from "../utils/serviceApi";

export const getAllUsers = (setInactiveCellStyle) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(`${process.env.REACT_APP_SERVICE_URL}/api/accounts/enabledUsers`)
    .then((res) => {
      dispatch({
        type: GET_ALL_USERS,
        payload: res.data,
      });
    })
    .then(() => {
      setInactiveCellStyle(false);
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const getAllDisabledUsers =
  (setInactiveCellStyle) => async (dispatch) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    apiService
      .get(`${process.env.REACT_APP_SERVICE_URL}/api/accounts/disabledUsers`)
      .then((res) => {
        dispatch({
          type: GET_ALL_USERS,
          payload: res.data,
        });
      })
      .then(() => {
        setInactiveCellStyle(true);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };
export const disableUserBySysadmin =
  (id, setConfirmationDisableUser, setUserIDForArchive) => async (dispatch) => {
    apiService
      .put(
        `${process.env.REACT_APP_SERVICE_URL}/api/accounts/disableUser/${id}`
      )
      .then((res) => {
        dispatch({
          type: DISABLE_USER,
          payload: id,
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setConfirmationDisableUser(false);
        setUserIDForArchive("");
      });
  };

export const enableUserBySysadmin =
  (id, setConfirmationDisableUser, setUserIDForArchive) => async (dispatch) => {
    apiService
      .put(`${process.env.REACT_APP_SERVICE_URL}/api/accounts/enableUser/${id}`)
      .then((res) => {
        dispatch({
          type: DISABLE_USER,
          payload: id,
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setConfirmationDisableUser(false);
        setUserIDForArchive("");
      });
  };


export const requestPasswordResetBySysadmin = (
    email,
    setConfirmationResetPassword,
    setUserEmailForResetPassword
) => async (dispatch) => {
    apiService
        .post(
        `${process.env.REACT_APP_SERVICE_URL}/api/accounts/password/${email}`,
        { email: email }
        )
        .then(() => {
        console.log("Password reset request sent");
        })
        .catch((e) => {
        console.log(e);
        })
        .finally(() => {
        setConfirmationResetPassword(false);
        setUserEmailForResetPassword("");
        });
}