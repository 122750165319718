import Style from "./entityDetail.module.css";

const EntityDetail = (props) => {
  const { label, value } = props || {};
  return (
    <div className={Style.entityDetailContainer}>
      <div className={Style.entityDetailLabel}>{label}</div>
      <div className={Style.entityDetailLine} />
      <div className={Style.entityDetailValue}>{value}</div>
    </div>
  );
};
export default EntityDetail;
