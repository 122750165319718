import React from "react";
import Loader from "react-loader-spinner";
import Style from "./loadingIndicator.module.css";

const LoadingIndicator = (props) => {
  return (
    <div className={Style.loadingIndicatorWrapper}>
      <Loader
        type="Oval"
        color="#005a92"
        height="500"
        width="100"
        justify-content="center"
        align-items="center"
      />
    </div>
  );
};

export default LoadingIndicator;
