import {Tooltip} from "@material-ui/core";
import cn from "classnames";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useAuth} from "react-oidc-context";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {clearClientForDetails, getClient,} from "../../actions/client";
import AddressDisplaySingle from "../../common/address/AddressDisplay";
import Button from "../../common/button/button";
import EntityDetail from "../../common/entityDetail/entityDetail";
import {ROLES} from "../../consts";
import keycloak from "../../keycloak";
import ModalLoadingIndicator from "../../reusable/modalLoadingIndicator";
import {newModalStyle} from "../../reusable/styles";
import Style from "./clientDetailsModal.module.css";


const ClientDetailsModal = (props) => {
    const auth = useAuth();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const client = useSelector(
        (state) => state.client.clientForUpdate
    );
    const { isLoading } = useSelector((state) => state.loader);
    const isSysAdmin = keycloak.hasRealmRole(ROLES.SYSADMIN, auth);

    const getAddresses = (addresses) => {
        return addresses?.map((address, index) => {
          const { city, zipCode, street } = address || {};
          return <div key={index}>{street.concat(", ", zipCode, " ", city)}</div>;
        });
      };

    const showDocumentsNames = (names, urls) => {
        const urlArray = urls?.split(",");
        return names?.split("|")?.map((n, index) => (
          <div key={index} className={Style.uploadedFileName}>
            {<div onClick={() => window.open(urlArray[index], "_blank")}>{n}</div>}
          </div>
        ));
      };

    const SectionTitle = (props) => {
        return <div className={props.style}>{props.title}</div>;
      };

    const isClientEditDisabled = () => {
        return !isSysAdmin;
      };

    const renderPzList = (parkingZones) => {
        const concatParkingZones = (zones) => {
            return zones?.map((zone, i) =>
                i === 0 ? `${zone.parkingZoneNumber} ${zone.parkingZoneName}` : `, ${zone.parkingZoneNumber} ${zone.parkingZoneName}`
            );
        };

        return (
            parkingZones &&
            (concatParkingZones(parkingZones)?.join('').length > 135 ? (
                <Tooltip title={concatParkingZones(parkingZones).join('')} placement="right" arrow>
                    <div className={Style.usersNames}>{concatParkingZones(parkingZones).join('')}</div>
                </Tooltip>
            ) : (
                <div className={Style.usersNames}>{concatParkingZones(parkingZones).join('')}</div>
            ))
        );
    };

    const renderGsList = (gasStations) => {
        const concatGasStationZones = (zones) => {
            return zones?.map((zone, i) =>
                i === 0 ? `${zone.number} ${zone.name}` : `, ${zone.number} ${zone.name}`
            );
        };

        return (
            gasStations &&
            (concatGasStationZones(gasStations)?.join('').length > 135 ? (
                <Tooltip title={concatGasStationZones(gasStations).join('')} placement="right" arrow>
                    <div className={Style.usersNames}>{concatGasStationZones(gasStations).join('')}</div>
                </Tooltip>
            ) : (
                <div className={Style.usersNames}>{concatGasStationZones(gasStations).join('')}</div>
            ))
        );
    };


    useEffect(() => {
        dispatch(getClient(props.clientId));
        return () => {
          dispatch(clearClientForDetails());
        };
      }, [dispatch, props.clientId]);

    return (
        <Modal
          open={props.open}
          closeOnEsc
          onClose={props.onClose}
          center
          styles={newModalStyle({ width: "900px" })}
        >
        <div className="modal__headerLine" />
        <div className="modal__title">
          {t("Clients.Client")}
        </div>
        {isLoading && <ModalLoadingIndicator />}
            <div className={Style.bodyWrapper}>
                <SectionTitle style={Style.sectionTitle} title={t("Clients.BasicInfo")}/>
                <EntityDetail
                    label={t("Clients.Columns.Name")}
                    value={client?.name}
                />
                <EntityDetail
                    label={t("Clients.Columns.RepresentativeName")}
                    value={client?.representativeName}
                />
                <EntityDetail
                    label={t("Clients.Columns.Email")}
                    value={client?.email}
                />
                <EntityDetail
                    label={t("Clients.Columns.PhoneNumber")}
                    value={client?.phoneNumber}
                />

                <SectionTitle
                    style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
                    title={t("Addresses.address")}
                />
                <div className={Style.text}>
                    <AddressDisplaySingle address={client?.address}/>
                </div>

                <SectionTitle
                    style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
                    title={t("Clients.PaymentInfo")}
                />
                <EntityDetail
                    label={t("Clients.Iban")}
                    value={client?.iban}
                />
                <EntityDetail
                    label={t("Clients.Bic")}
                    value={client?.bic}
                />
                <EntityDetail
                    label={t("Clients.Payee")}
                    value={client?.payee}
                />
                <EntityDetail
                    label={t("Clients.FinancialInstitution")}
                    value={client?.financialInstitution}
                />
                <EntityDetail
                    label={t("Clients.Columns.BankAccountNumber")}
                    value={client?.bankAccountNumber}
                />

                <SectionTitle
                    style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
                    title={t("Clients.Documents")}
                />
                <>
                    <div className={Style.uploadedFileTitle}>
                        {t("Clients.PowerOfAttorney")}
                    </div>
                    {client?.powerOfAttorneyName === "" ? null : (
                        <div className={Style.text}>
                            {showDocumentsNames(
                                client?.powerOfAttorneyName,
                                client?.powerOfAttorney
                            )}
                        </div>
                    )}
                    <div className={Style.uploadedFileTitle}>
                        {t("Clients.BranchDocuments")}
                    </div>
                    {client?.documentationName === "" ? null : (
                        <div className={Style.text}>
                            {showDocumentsNames(
                                client?.documentationName,
                                client?.documentation
                            )}
                        </div>
                    )}
                </>

                <SectionTitle
                    style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
                    title={t("Clients.ParkingZones")}
                />
                {renderPzList(client?.parkingZones)}

                <SectionTitle
                    style={cn(Style.sectionTitle, Style["sectionTitle--paddingTop"])}
                    title={t("Clients.GasStations")}
                />
                {renderGsList(client?.gasStationZones)}

                <div className={Style.editButton}>
                    <Button
                        text={t("Clients.Edit")}
                        buttonStyle={{
                            marginBottom: "20px",
                            marginTop: "20px",
                            height: "40px",
                            width: "181px",
                            marginRight: "10px",
                            fontFamily: "Gilroy-Regular",
                        }}
                        onClick={() => props.openClientEditModal()}
                        disabled={isClientEditDisabled()}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ClientDetailsModal;