import {
  ADD_ADMIN,
  CLOSE_DUPLICATE_MODAL,
  DISABLE_ADMIN,
  DUPLICATE_ADMIN,
  EDIT_DATA,
  GET_ALL_ADMINS,
  CHECK_EMAIL,
  CLEAR_ADMIN_DATA,
  USER_WITH_GIVEN_EMAIL_NOT_FOUND,
} from "../actions/types";

const initialState = {
  admins: [],
  duplicateAdmin: false,
  admin: null,
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ADMINS:
      return {
        ...state,
        admins: action.payload,
        admin: null,
      };
    case ADD_ADMIN:
      let adm = state.admins.find((a) => a?.id === action?.payload?.id);
      adm = { ...adm, enabled: false };
      return {
        ...state,
        admins: state.admins
          .filter((a) => a.id !== action.payload.id)
          .reverse()
          .concat(adm)
          .reverse(),
        admin: null,
      };
    case EDIT_DATA:
      return {
        ...state,
        admins: state.admins.map((a) =>
          a.id === action.payload.id ? action.payload : a
        ),
      };
    case DISABLE_ADMIN:
      return {
        ...state,
        admins: state.admins.filter((a) => a.emailAddress !== action.payload),
      };
    case DUPLICATE_ADMIN:
      return {
        ...state,
        duplicateAdmin: true,
        admin: null,
      };
    case CLOSE_DUPLICATE_MODAL:
      return {
        ...state,
        duplicateAdmin: false,
        admin: null,
      };
    case CHECK_EMAIL:
      return {
        ...state,
        admin: action.payload,
        duplicateAdmin: false,
      };
    case USER_WITH_GIVEN_EMAIL_NOT_FOUND:
      return {
        ...state,
        admin: null,
        duplicateAdmin: false,
      };
    case CLEAR_ADMIN_DATA:
      return {
        ...state,
        admin: null,
        duplicateAdmin: false,
      };
    default:
      return state;
  }
};

export default adminReducer;
