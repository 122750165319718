import React from "react";
const SearchIcon = (props) => {
  return (
    <span
      style={{
        position: "absolute",
        marginLeft: "5px",
        marginBottom: "5px",
        height: "25px",
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <i
        class="fa fa-search"
        style={{ color: "lightgray" }}
        aria-hidden="true"
      ></i>
    </span>
  );
};

export default SearchIcon;
