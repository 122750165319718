import React, { useEffect } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Button from "../../common/button/button";
import { useForm } from "react-hook-form";
import InputFieldWithLabel from "../../common/input/inputFieldWithLabel";
import { useDispatch } from "react-redux";
import { newModalStyle, submitButtonStyle } from "../../reusable/styles";
import { editManager } from "../../actions/manager";
import { useTranslation } from "react-i18next";

const ModalEditManager = (props) => {
  const { handleSubmit, register, unregister, reset } = useForm({
    defaultValues: props.selectedManager,
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updateManager = (data) => {
    const dataForEdit = {
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      companyName: data.companyName,
    };

    dispatch(editManager(dataForEdit));
    props.close();
  };

  useEffect(() => {
    reset(props.selectedManager);
  }, [props.selectedManager, reset]);

  const closeModal = () => {
    props.close();
    unregister("firstName");
  };

  return (
    <Modal
      open={props.open}
      closeOnEs={true}
      onClose={closeModal}
      center
      styles={newModalStyle({ width: "450px" })}
    >
      <div className="modal__headerLine" />
      <div className="modal__title">Manager</div>
      <form className="modalForm" onSubmit={handleSubmit(updateManager)}>
        <div className="form">
          <InputFieldWithLabel
            label={t("ModalAddManagerForm.firstName")}
            name="firstName"
            required
            registerName="firstName"
            register={register}
          />
          <InputFieldWithLabel
            label={t("ModalAddManagerForm.lastName")}
            name="lastName"
            registerName="lastName"
            required
            register={register}
          />

          <InputFieldWithLabel
            label="E-mail"
            name="emailAddress"
            disabled
            type="email"
            registerName="emailAddress"
            required
            register={register}
          />
          <InputFieldWithLabel
            label={t("ModalAddManagerForm.phoneNumber")}
            name="phoneNumber"
            registerName="phoneNumber"
            register={register}
          />
          <InputFieldWithLabel
            label={t("ModalAddManagerForm.companyName")}
            name="companyName"
            registerName="companyName"
            register={register}
          />
        </div>
        <Button text="Speichern" buttonStyle={submitButtonStyle} />
      </form>
    </Modal>
  );
};
export default ModalEditManager;
