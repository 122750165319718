import React from "react";
import { useTranslation } from "react-i18next";
import { NavDropdown } from "react-bootstrap";

const LanguageMenu = (props) => {
  const { i18n } = useTranslation();

  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    window.location.reload();
  };

  return (
    <>
      <NavDropdown.Item
        onClick={() => {
          handleClick("de");
          localStorage.setItem("beppoSelectedLanguage", "de");
        }}
      >
        DE
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        onClick={() => {
          handleClick("en");
          localStorage.setItem("beppoSelectedLanguage", "en");
        }}
      >
        EN
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        onClick={() => {
          handleClick("fr");
          localStorage.setItem("beppoSelectedLanguage", "fr");
        }}
      >
        FR
      </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
            onClick={() => {
                handleClick("it");
                localStorage.setItem("beppoSelectedLanguage", "it");
            }}
        >
            IT
        </NavDropdown.Item>
    </>
  );
};

export default LanguageMenu;
