import React from "react";
const CloseIcon = (props) => {
  return (
    <span
      style={{
        position: "absolute",
        marginLeft: "90%",
        marginBottom: "5px",
        height: "30px",
      }}
      onClick={() => {
        props.closeFilter(props.reference, props.name);
      }}
    >
      <i
        class="fa fa-close"
        style={{ color: "lightgray" }}
        aria-hidden="true"
      ></i>
    </span>
  );
};

export default CloseIcon;
