import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

export const cellStyle = {
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "3px",
  paddingRight: "0px",
  minWidth: "120px",
};

export const cellStyleDisabled = {
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "3px",
  paddingRight: "0px",
  backgroundColor: "#C7C7C7",
};

export const headerStyle = {
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "3px",
  paddingRight: "0px",
  fontWeight: "bold",
  whiteSpace: "nowrap",
  backgroundColor: "#C7C7C7",
  minWidth: "120px",
};

export const filterStyle = {
  paddingLeft: "4px",
  paddingRight: "0px",
};

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/#"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <div style={{ fontWeight: "bolder", fontSize: "x-large" }}>&#xFE19;</div>
  </a>
));

export const dropdownItem = (onClickAction, translation) => {
  return (
    <Dropdown.Item
      style={{
        color: "white",
      }}
      onClick={() => onClickAction()}
    >
      {translation}
    </Dropdown.Item>
  );
};
