import {NotificationManager} from "react-notifications";
import {notificationType} from "../../globals";
import i18next from "../../i18next";

const duration = 4000;

export const createNotification = (type, message, title) => {
  switch (type) {
    case notificationType.INFO:
      NotificationManager.info(message, title, duration);
      break;
    case notificationType.SUCCESS:
      NotificationManager.success(message, title, duration);
      break;
    case notificationType.WARNING:
      NotificationManager.warning(message, title, duration);
      break;
    case notificationType.ERROR:
      NotificationManager.error(message, title, duration);
      break;
    default:
      NotificationManager.info(message, title, duration);
  }
};

export const createInfoNotification = (message, title) => {
  NotificationManager.info(message, title, duration);
};

export const createSuccessNotification = (message, title) => {
  NotificationManager.success(message, title, duration);
};
export const createWarningNotification = (message, title) => {
  NotificationManager.warning(message, title, duration);
};

export const createErrorNotification = (message, title) => {
  NotificationManager.error(message, title, duration);
};


export const createSimpleSuccessNotification = () => {
  createSuccessNotification(i18next.t("notifications.success"));
};

export const createSimpleAxiosWarningNotification = (res) => {
  createWarningNotification("Technical message: " + res?.data?.errors.map(JSON.stringify), i18next.t("notifications.error"))
};

export const createSimpleAxiosErrorNotification =  (e) => {
  createErrorNotification(e.message + ": " + e.response?.data?.error, i18next.t("notifications.error"));
};
