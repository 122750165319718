import useGaTracker from "./useGaTracker";
import {useAuth} from "react-oidc-context";
import {resolveRedirectLocation} from "./utils/resolveRedirectLocation";
import {Provider} from "react-redux";
import {persistor, store} from "./store";
import {PersistGate} from "redux-persist/integration/react";
import {Redirect, Switch} from "react-router-dom";
import {AuthorizedRoute} from "./utils/AuthorizedRoute";
import {ROLES} from "./consts";
import ParkingZones from "./containers/parkingZone/parkingZones";
import GasStations from "./containers/gasStation/gasStations";
import Owners from "./containers/owner/owners";
import Admins from "./containers/admin/admins";
import Violations from "./containers/violations/violations";
import DriveOff from "./containers/violations/driveOff";
import TicketSeries from "./containers/ticket/ticketSeries";
import Users from "./containers/users/users";
import Requests from "./containers/requests/requests";
import Whitelist from "./containers/parkingZone/whitelist";
import Blacklist from "./containers/parkingZone/blacklist";
import Managers from "./containers/manager/managers";
import Viewers from "./containers/viewer/viewers";
import BillingInfo from "./containers/billingInfo/billingInfo";
import Report from "./containers/report/report";
import Client from "./containers/client/client";
import {UnauthorizedRoute} from "./utils/UnauthorizedRoute";
import Unauthorized from "./containers/unauthorized/unauthorized";
import {useEffect} from "react";


export default function AppInner() {
    useGaTracker();
    const auth = useAuth();
    if (auth && !auth.isLoading) {
        const redirectResult = resolveRedirectLocation(auth);
        if(!auth.isAuthenticated) {
            auth.signinRedirect({redirect_uri: window.location.origin})
            return null;
        }
        return (
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <Switch>
                        <Redirect exact from="/" to={redirectResult}/>

                        <AuthorizedRoute
                            exact
                            roles={[
                                ROLES.SYSADMIN,
                                ROLES.PARKING_ADMIN,
                                ROLES.PARKING_MANAGER,
                                ROLES.PARKING_VIEWER
                            ]}
                            path="/parkingZones"
                            component={ParkingZones}
                        />

                        <AuthorizedRoute
                            exact
                            roles={[
                                ROLES.SYSADMIN,
                                ROLES.GAS_STATION_ADMIN,
                                ROLES.GAS_STATION_MANAGER,
                                ROLES.GAS_STATION_VIEWER
                            ]}
                            path="/gasStations"
                            component={GasStations}
                        />
                        <AuthorizedRoute
                            exact
                            roles={[
                                ROLES.SYSADMIN,
                                ROLES.PARKING_ADMIN,
                                ROLES.PARKING_MANAGER,
                                ROLES.GAS_STATION_ADMIN,
                                ROLES.GAS_STATION_MANAGER,
                                ROLES.PARKING_VIEWER,
                                ROLES.GAS_STATION_VIEWER
                            ]}
                            path="/owners"
                            component={Owners}
                        />
                        <AuthorizedRoute
                            exact
                            roles={[ROLES.SYSADMIN]}
                            path="/admins"
                            component={Admins}
                        />
                        <AuthorizedRoute
                            exact
                            roles={[
                                ROLES.SYSADMIN,
                                ROLES.PARKING_ADMIN,
                                ROLES.PARKING_MANAGER,
                                ROLES.PARKING_VIEWER
                            ]}
                            path="/violations"
                            component={Violations}
                        />
                        <AuthorizedRoute
                            exact
                            roles={[
                                ROLES.SYSADMIN,
                                ROLES.PARKING_ADMIN,
                                ROLES.PARKING_MANAGER,
                                ROLES.GAS_STATION_VIEWER
                            ]}
                            path="/driveOff"
                            component={DriveOff}
                        />
                        <AuthorizedRoute
                            exact
                            roles={[ROLES.SYSADMIN]}
                            path="/ticketSeries"
                            component={TicketSeries}
                        />
                        <AuthorizedRoute
                            exact
                            roles={[ROLES.SYSADMIN]}
                            path="/users"
                            component={Users}
                        />
                        <AuthorizedRoute
                            exact
                            roles={[
                                ROLES.SYSADMIN,
                                ROLES.PARKING_ADMIN,
                                ROLES.PARKING_MANAGER,
                                ROLES.GAS_STATION_ADMIN,
                                ROLES.GAS_STATION_MANAGER,
                            ]}
                            path="/requests"
                            component={Requests}
                        />
                        <AuthorizedRoute
                            exact
                            roles={[
                                ROLES.SYSADMIN,
                                ROLES.PARKING_ADMIN,
                                ROLES.PARKING_MANAGER,
                            ]}
                            path="/whitelist/:id"
                            component={Whitelist}
                        />
                        <AuthorizedRoute
                            exact
                            roles={[
                                ROLES.SYSADMIN,
                                ROLES.PARKING_ADMIN,
                                ROLES.PARKING_MANAGER,
                            ]}
                            path="/blacklist/:id"
                            component={Blacklist}
                        />
                        <AuthorizedRoute
                            exact
                            roles={[
                                ROLES.SYSADMIN,
                                ROLES.PARKING_ADMIN,
                                ROLES.GAS_STATION_ADMIN,
                            ]}
                            path="/managers"
                            component={Managers}
                        />
                        <AuthorizedRoute
                            exact
                            roles={[
                                ROLES.SYSADMIN,
                                ROLES.PARKING_ADMIN,
                                ROLES.GAS_STATION_ADMIN
                            ]}
                            path="/viewers"
                            component={Viewers}
                        />
                        <AuthorizedRoute
                            exact
                            roles={[ROLES.SYSADMIN]}
                            path="/billingInfo"
                            component={BillingInfo}
                        />
                        <AuthorizedRoute
                            exact
                            roles={[ROLES.SYSADMIN,
                                ROLES.PARKING_ADMIN, ROLES.PARKING_MANAGER, ROLES.PARKING_VIEWER,
                                ROLES.GAS_STATION_ADMIN, ROLES.GAS_STATION_MANAGER, ROLES.GAS_STATION_VIEWER]}
                            path="/report/:reportType"
                            component={Report}
                        />
                        <AuthorizedRoute
                            exact
                            roles={[ROLES.SYSADMIN]}
                            path="/client"
                            component={Client}
                        />
                        <UnauthorizedRoute exact path="/unauthorized" component={Unauthorized}/>
                        <Redirect to={redirectResult}/>
                    </Switch>
                </PersistGate>
            </Provider>
        )
    } else {
        return null;
    }
}