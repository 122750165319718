import {
  GENERATE_DOCUMENTATION
} from "./types";
import { apiService } from "../utils/serviceApi";

export const generateSueDocumentationForDO =
  (ticketId, licencePlate) => async (dispatch) => {
    apiService
      .getDocument(
        `${process.env.REACT_APP_SERVICE_URL}/api/report/${ticketId}/${licencePlate}/generateSueDocsForDO`
      )
      .then((response) => {
        dispatch({
          type: GENERATE_DOCUMENTATION,
          payload: ticketId,
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download", response.headers['content-disposition'].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.log(e);
      });
  };