import { GET_ALL_REQUESTS, SET_LOADER, MARK_REQUEST_AS_SEEN } from "./types";
import { apiService } from "../utils/serviceApi";

export const getAllRequests = () => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(`${process.env.REACT_APP_SERVICE_URL}/api/requests`)
    .then((res) => {
      dispatch({
        type: GET_ALL_REQUESTS,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const markRequestAsSeen =
  (requestId, requestType) => async (dispatch) => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    apiService
      .put(
        `${process.env.REACT_APP_SERVICE_URL}/api/requests/markAsSeen/${requestId}/${requestType}`
      )
      .then((res) => {
        dispatch({
          type: MARK_REQUEST_AS_SEEN,
          payload: res.data,
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  };
