import React from "react";
import Loader from "react-loader-spinner";
import Style from "./modalLoadingIndicator.module.css";

const ModalLoadingIndicator = (props) => {
  return (
    <div className={Style.loadingIndicatorWrapper}>
      <Loader type="Oval" color="#005a92" height="100px" width="100px" />
    </div>
  );
};

export default ModalLoadingIndicator;
