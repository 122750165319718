import {
  ADD_VEHICLE_TO_PARKING_ZONE_WHITELIST,
  CLOSE_DUPLICATE_WHITELIST_WEHICLE_MODAL,
  DUPLICATE_WHITELIST_WEHICLE,
  GET_ALL_WHITELIST_FOR_PARKING_ZONE,
  REMOVE_VEHICLE_FROM_PARKING_ZONE_WHITELIST,
} from "../actions/types";

const initialState = {
  whitelist: [],
  duplicateWhitelistVehicle: false,
};

const whitelistReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_WHITELIST_FOR_PARKING_ZONE:
      return {
        ...state,
        whitelist: action.payload,
      };

    case ADD_VEHICLE_TO_PARKING_ZONE_WHITELIST:
      return {
        ...state,
        whitelist: state.whitelist.concat(action.payload),
      };

    case REMOVE_VEHICLE_FROM_PARKING_ZONE_WHITELIST:
      return {
        ...state,
        whitelist: state.whitelist.filter((v) => v.id !== action.payload),
      };

    case DUPLICATE_WHITELIST_WEHICLE:
      return {
        ...state,
        duplicateWhitelistVehicle: true,
      };
    case CLOSE_DUPLICATE_WHITELIST_WEHICLE_MODAL:
      return {
        ...state,
        duplicateWhitelistVehicle: false,
      };

    default:
      return state;
  }
};

export default whitelistReducer;
