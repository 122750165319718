import {
  APPROVE_GAS_STATION_OWNER,
  APPROVE_OWNER,
  ARCHIVE_OWNER,
  GET_ALL_OWNERS,
  LAST_DATE_REQUEST,
  REMOVE_GAS_STATION_OWNERSHIP_REQUEST_FROM_REQUESTS,
  REMOVE_PARKING_ZONE_OWNERSHIP_REQUEST_FROM_REQUESTS,
  SET_LOADER,
} from "./types";
import { apiService } from "../utils/serviceApi";

export const getAllOwners = () => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(`${process.env.REACT_APP_SERVICE_URL}/api/accounts/owner`)
    .then((res) => {
      dispatch({
        type: GET_ALL_OWNERS,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const handleOwnershipStatus = (id, status) => async (dispatch) => {
  await apiService
    .put(
      `${process.env.REACT_APP_SERVICE_URL}/api/parking-zone-ownership/request/${id}/${status}`
    )
    .then((res) => {
      dispatch({
        type: APPROVE_OWNER,
        payload: res.data,
      });
      dispatch({
        type: REMOVE_PARKING_ZONE_OWNERSHIP_REQUEST_FROM_REQUESTS,
        payload: id,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const handleGSOwnershipStatus = (id, status) => async (dispatch) => {
  await apiService
    .put(
      `${process.env.REACT_APP_SERVICE_URL}/api/gas-station-ownership/request/${id}/${status}`
    )
    .then((res) => {
      dispatch({
        type: APPROVE_GAS_STATION_OWNER,
        payload: res.data,
      });
      dispatch({
        type: REMOVE_GAS_STATION_OWNERSHIP_REQUEST_FROM_REQUESTS,
        payload: id,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const checkChange = () => async (dispatch) => {
  await apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/parking-zone-ownership/checkChange`
    )
    .then((res) => {
      dispatch({
        type: LAST_DATE_REQUEST,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};
export const getAllOwnersForPZAdmin = () => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(`${process.env.REACT_APP_SERVICE_URL}/api/accounts/ownersForPZAdmin`)
    .then((res) => {
      dispatch({
        type: GET_ALL_OWNERS,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const archive = (id) => async (dispatch) => {
  apiService
    .put(`${process.env.REACT_APP_SERVICE_URL}/api/accounts/archiveOwner/${id}`)
    .then((res) => {
      dispatch({
        type: ARCHIVE_OWNER,
        payload: id,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};
