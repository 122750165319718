import Dropdown from "react-bootstrap/Dropdown";

const DropdownItem = (props) => {
  const { onClickAction, translation } = props || {};
  return (
    <Dropdown.Item
      style={{
        color: "white",
      }}
      onClick={onClickAction}
    >
      {translation}
    </Dropdown.Item>
  );
};

export default DropdownItem;
