import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { modalCloseButtonStyle } from "../../reusable/styles";
import Button from "../button/button";
import Style from "./confirmationModal.module.css";

const ConfirmationModal = (props) => {
  const buttonStyle = {
    marginBottom: "20px",
    marginTop: "20px",
    height: "40px",
    width: "181px",
    backgroundColor: "#005a8c",
    fontFamily: "Gilroy-Regular",
  };
  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={props.onClose}
      center
      styles={{
        modal: {
          width: props.width ? props.width : "539px",
          borderRadius: "4px",
        },
        closeButton: modalCloseButtonStyle,
        closeIcon: { fill: "red", marginTop: 5 },
      }}
    >
      <div className={Style.headerLine} />
      <div className={Style.modalTitle}>{props.title}</div>

      <div className={Style.bodyWrapper}>
        <div className={Style.body}>
          <div className={Style.message}>{props.message}</div>
        </div>
        <div className={Style.footer}>
          <Button
            text={props.confirmationButtonText}
            buttonStyle={buttonStyle}
            onClick={props.confirmationAction}
          />
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmationModal;
