import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    overflowY: "hidden",
    backgroundColor: "#FFF",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    height: "100%",
    borderTop: "1px solid black",
  },
  paper: {
    // padding: theme.spacing(2),\
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    // height: "88vh",
  },
}));

export const useStylesSidebar = makeStyles((theme) => ({
  root: {
    // "& .root.Mui-selected:hover": {
    //   backgroundColor: "red",
    // },
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "#fad201",
      color: "#005a92",
    },
  },
}));

export const useStylesForMaterial = makeStyles((theme) => ({}));

export const toolbarStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `30px`,
      },
    },
    "& .MuiToolbar-gutters": {
      paddingLeft: `0px`,
      paddingRight: `0px`,
    },
    "& .MuiInput-underline:before": {
      borderBottom: "0px solid white",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "0px solid white",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MTableToolbar-spacer-28": {
      flex: 0,
    },
    "& .MuiToolbar-regular": {
      minHeight: "50px",
    },
  },
}));

export const modalStyle = {
  modal: { width: "400px" },
  closeIcon: { backgroundColor: "red", fill: "white" },
  closeButton: {
    outlineColor: "transparent",
    outline: "none",
    boxShadow: "none",
  },
};

export const wideModalStyle = {
  modal: { width: "600px" },
  closeIcon: { backgroundColor: "red", fill: "white" },
  closeButton: {
    outlineColor: "transparent",
    outline: "none",
    boxShadow: "none",
  },
};

export const submitButtonStyle = {
  marginBottom: "20px",
  width: "100%",
  marginTop: "20px",
};

export const pageTitleStyle = {
  fontFamily: "Gilroy-Bold",
  fontSize: 20,
  fontWeight: "bold",
  fontStyle: "normal",
  textAlign: "left",
  letterSpacing: 0,
  color: "#000000",
  marginBottom: "15px",
};

export const materialTableStyle = {
  paddingLeft: "2%",
  paddingRight: "2%",
  paddingTop: "1%",
  backgroundColor: "inherit",
  boxShadow: "inherit",
  marginBottom: "5%",
};

export const paginationStyle = {
  width: "100%",
  display: "block",
  backgroundColor: "white",
};

export const modalCloseButtonStyle = {
  outlineColor: "transparent",
  outline: "none",
  boxShadow: "none",
};

export const newModalStyle = (props) => {
  const { width, maxWidth } = props || {};

  return {
    root: { zIndex: 1200 },
    modal: {
      zIndex: 1,
      width: width,
      maxWidth: maxWidth ? maxWidth : "100%",
      borderRadius: "4px",
    },
    closeButton: {
      outlineColor: "transparent",
      outline: "none",
      boxShadow: "none",
    },
    closeIcon: { fill: "red", marginTop: 5 },
  };
};
