import { apiService } from "../utils/serviceApi";
import {
  ADD_MANAGER,
  CHECK_MANAGERS_EMAIL,
  CLEAR_MANAGER_DATA,
  CLOSE_DUPLICATE_MANAGER_MODAL,
  DISABLE_MANAGER,
  DUPLICATE_MANAGER,
  EDIT_MANAGER,
  GET_ALL_MANAGERS_BY_ADMIN_ID,
  GET_ALL_MANAGERS_FOR_SYSADMIN,
  REMOVE_MANAGER_FROM_PZ_ADMINS_MANAGER_LIST,
  SET_LOADER,
  USER_MANAGER_WITH_GIVEN_EMAIL_NOT_FOUND,
} from "./types";

export const getManagersByAdminId = () => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/accounts/getManagersByParkingZoneAdmin`
    )
    .then((res) => {
      dispatch({
        type: GET_ALL_MANAGERS_BY_ADMIN_ID,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const getAllManagers = () => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/accounts/getAllManagersBySysAdmin`
    )
    .then((res) => {
      dispatch({
        type: GET_ALL_MANAGERS_FOR_SYSADMIN,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const getAllParkingZoneManagers = () => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/accounts/getAllParkingZoneManagersBySysAdmin`
    )
    .then((res) => {
      dispatch({
        type: GET_ALL_MANAGERS_FOR_SYSADMIN,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const getAllGasStationManagers = () => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/accounts/getAllGasStationManagersBySysAdmin`
    )
    .then((res) => {
      dispatch({
        type: GET_ALL_MANAGERS_FOR_SYSADMIN,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const addManager = (data) => async (dispatch) => {
  apiService
    .post(`${process.env.REACT_APP_SERVICE_URL}/api/accounts/manager`, data)
    .then((res) => {
      if (res.status === 409) {
        return dispatch({
          type: DUPLICATE_MANAGER,
        });
      }
      dispatch({
        type: ADD_MANAGER,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const closeDuplicateManagerModal = () => async (dispatch) => {
  dispatch({
    type: CLOSE_DUPLICATE_MANAGER_MODAL,
  });
};

export const checkManagerEmail = (email) => async (dispatch) => {
  apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/accounts/checkAdminEmail/${email}`
    )
    .then((res) => {
      if (res.status === 404) {
        return dispatch({
          type: USER_MANAGER_WITH_GIVEN_EMAIL_NOT_FOUND,
        });
      }
      dispatch({
        type: CHECK_MANAGERS_EMAIL,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const clearManagerData = () => async (dispatch) => {
  dispatch({
    type: CLEAR_MANAGER_DATA,
  });
};

export const editManager = (data) => async (dispatch) => {
  apiService
    .put(
      `${process.env.REACT_APP_SERVICE_URL}/api/accounts/user-data/sysadmin`,
      data
    )
    .then((res) => {
      dispatch({
        type: EDIT_MANAGER,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const disableManager = (email) => async (dispatch) => {
  apiService
    .put(
      `${process.env.REACT_APP_SERVICE_URL}/api/accounts/disableManagerRole/${email}`,
      email
    )
    .then((res) => {
      dispatch({
        type: DISABLE_MANAGER,
        payload: email,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const removeManagerFromPZAdminsManagerList =
  (email) => async (dispatch) => {
    apiService
      .put(
        `${process.env.REACT_APP_SERVICE_URL}/api/accounts/removeManagerFromParkingZoneAdminsManagerList/${email}`,
        email
      )
      .then((res) => {
        dispatch({
          type: REMOVE_MANAGER_FROM_PZ_ADMINS_MANAGER_LIST,
          payload: email,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
