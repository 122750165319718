import { apiService } from "../utils/serviceApi";
import {
  GET_DELETE_USER_REQUEST_BY_ID,
  REMOVE_DELETE_USER_REQUEST_FROM_REQUESTS,
  SET_LOADER,
} from "./types";

export const getUserDeleteRequestById = (requestId) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/deleteUserRequest/${requestId}`
    )
    .then((res) => {
      dispatch({
        type: GET_DELETE_USER_REQUEST_BY_ID,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const acceptUserDeleteRequest = (requestId) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .put(
      `${process.env.REACT_APP_SERVICE_URL}/api/deleteUserRequest/acceptDeleteRequest/${requestId}`
    )
    .then((res) => {
      dispatch({
        type: REMOVE_DELETE_USER_REQUEST_FROM_REQUESTS,
        payload: requestId,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};
