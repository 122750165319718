import {
  ADD_ADMIN,
  CHECK_EMAIL,
  CLEAR_ADMIN_DATA,
  CLOSE_DUPLICATE_MODAL,
  DISABLE_ADMIN,
  DUPLICATE_ADMIN,
  EDIT_DATA,
  GET_ALL_ADMINS,
  SET_LOADER,
  USER_WITH_GIVEN_EMAIL_NOT_FOUND,
} from "./types";
import { apiService } from "../utils/serviceApi";

export const getAllAdmins = () => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(`${process.env.REACT_APP_SERVICE_URL}/api/accounts/admin`)
    .then((res) => {
      dispatch({
        type: GET_ALL_ADMINS,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const getAllGasStationAdmins = () => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(`${process.env.REACT_APP_SERVICE_URL}/api/accounts/gasStationAdmins`)
    .then((res) => {
      dispatch({
        type: GET_ALL_ADMINS,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const getAllParkingZoneAdmins = () => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: true,
  });
  apiService
    .get(`${process.env.REACT_APP_SERVICE_URL}/api/accounts/parkingZoneAdmins`)
    .then((res) => {
      dispatch({
        type: GET_ALL_ADMINS,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    })
    .finally(() => {
      dispatch({
        type: SET_LOADER,
        payload: false,
      });
    });
};

export const addAdmin = (data) => async (dispatch) => {
  apiService
    .post(`${process.env.REACT_APP_SERVICE_URL}/api/accounts/admin`, data)
    .then((res) => {
      if (res.status === 409) {
        return dispatch({
          type: DUPLICATE_ADMIN,
        });
      }
      dispatch({
        type: ADD_ADMIN,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const editAdmin = (data) => async (dispatch) => {
  apiService
    .put(
      `${process.env.REACT_APP_SERVICE_URL}/api/accounts/user-data/sysadmin`,
      data
    )
    .then((res) => {
      dispatch({
        type: EDIT_DATA,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};
export const disableAdmin = (email) => async (dispatch) => {
  apiService
    .put(
      `${process.env.REACT_APP_SERVICE_URL}/api/accounts/disableAdminRole/${email}`,
      email
    )
    .then((res) => {
      dispatch({
        type: DISABLE_ADMIN,
        payload: email,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

export const closeDuplicateModal = () => async (dispatch) => {
  dispatch({
    type: CLOSE_DUPLICATE_MODAL,
  });
};
export const checkAdminEmail = (email) => async (dispatch) => {
  apiService
    .get(
      `${process.env.REACT_APP_SERVICE_URL}/api/accounts/checkAdminEmail/${email}`
    )
    .then((res) => {
      if (res.status === 404) {
        return dispatch({
          type: USER_WITH_GIVEN_EMAIL_NOT_FOUND,
        });
      }
      dispatch({
        type: CHECK_EMAIL,
        payload: res.data,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};
export const clearAdminData = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ADMIN_DATA,
  });
};
