import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {
  cellStyle,
  CustomToggle,
  filterStyle,
  headerStyle,
} from "./columnsGeneral";

export function AdminColumns(
  setOpenEditOwnerModal,
  setSelectedAdmin,
  t,
  disableAdmin
) {
  const columns = useState([
    {
      title: t("Columns.First name"),
      field: "firstName",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Last name"),
      field: "lastName",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.PZ number"),
      filterCellStyle: filterStyle,
      field: "administeredParkingZones",
      cellStyle: {
        paddingTop: "10px",
        paddingBottom: "10px",
        width: "12%",
      },
      headerStyle: headerStyle,
      render: (row) => (
        <div style={{ maxWidth: "min-content" }}>
          {row.administeredParkingZones?.map((a, index) => {
            if (index === 0) {
              return a;
            }
            return " " + a;
          })}
        </div>
      ),
    },
    {
      title: t("Columns.GS numbers"),
      filterCellStyle: filterStyle,
      field: "administeredGasStations",
      cellStyle: {
        paddingTop: "10px",
        paddingBottom: "10px",
        width: "12%",
      },
      headerStyle: headerStyle,
      render: (row) => (
        <div style={{ maxWidth: "min-content" }}>
          {row.administeredGasStations?.map((a, index) => {
            if (index === 0) {
              return a;
            }
            return " " + a;
          })}
        </div>
      ),
    },
    {
      title: t("Columns.Email"),
      field: "emailAddress",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Phone admins page"),
      field: "phoneNumber",
      filterCellStyle: filterStyle,
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Company"),
      filterCellStyle: filterStyle,
      field: "companyName",
      cellStyle: cellStyle,
      headerStyle: headerStyle,
    },
    {
      title: t("Columns.Actions"),
      headerStyle: headerStyle,
      field: "actions",
      filterComponent: () => null,
      cellStyle: {
        width: "10%",
        textAlign: "center",
        paddingRight: "60px",
        paddingTop: "10px",
        paddingBottom: "10px",
      },
      render: (row) => (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu
            size="sm"
            style={{
              color: "white",
              backgroundColor: "rgb(0,90,140)",
            }}
            popperConfig={{ strategy: "fixed" }}
          >
            <Dropdown.Item
              style={{ color: "white" }}
              onClick={() => {
                setOpenEditOwnerModal(true);
                setSelectedAdmin(row);
              }}
            >
              {t("Admins.change")}
            </Dropdown.Item>

            {(row.administeredParkingZones &&
            row.administeredParkingZones.length > 0)
                ||  (row.administeredGasStations &&
            row.administeredGasStations.length > 0)  ? (
              ""
            ) : (
              <Dropdown.Item
                style={{ color: "white" }}
                onClick={() => {
                  disableAdmin(row.emailAddress);
                }}
              >
                {t("Admins.delete")}
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ]);
  return columns[0];
}
