import {Redirect, Route} from "react-router-dom";
import {useEffect} from "react";
import {isAllowed} from "./isAllowed";
import {useAuth} from "react-oidc-context";

export function AuthorizedRoute(
    {
        component: Component,
        roles,
        ...rest
    }
) {
    const auth = useAuth();
    const { isLoading, user,isAuthenticated, signinRedirect } = useAuth();

    useEffect(() => {
        if (!isLoading && !isAuthenticated && user === null) {
            signinRedirect({redirect_uri: window.location.href});
        }
    }, [isLoading, isAuthenticated, signinRedirect, user]);


    // TODO this is not too elegant, should redirect to 403 page, or post a message
    return (
        <Route
            {...rest}
            render={(props) => isAllowed(roles, auth)? <Component {...props} /> : <Redirect to="/" />
            }
        />
    )
}
