import React, { useState } from "react";
import {
  cellStyle,
  filterStyle,
  headerStyle,
  CustomToggle,
  dropdownItem,
} from "./columnsGeneral";
import Dropdown from "react-bootstrap/Dropdown";

export function ClientColumns({
    t,
    setSelectedClientId,
    setClientDetailsModalOpen
}) {
    const columns = useState([
        {
          title: t("Clients.Columns.Name"),
          field: "name",
          filterCellStyle: filterStyle,
          cellStyle: cellStyle,
          headerStyle: headerStyle,
        },
        {
          title: t("Clients.Columns.RepresentativeName"),
          field: "representativeName",
          filterCellStyle: filterStyle,
          cellStyle: cellStyle,
          headerStyle: headerStyle,
        },
        {
          title: t("Clients.Columns.PZNumbers"),
          filterCellStyle: filterStyle,
          field: "parkingZones",
          cellStyle: {
            paddingTop: "10px",
            paddingBottom: "10px",
            width: "12%",
          },
          headerStyle: headerStyle,
          render: (row) => (
            <div style={{ maxWidth: "min-content" }}>
              {row.parkingZones?.map((a, index) => {
                if (index === 0) {
                  return a.parkingZoneNumber;
                }
                return " " + a.parkingZoneNumber;
              })}
            </div>
          ),
        },
        {
          title: t("Clients.Columns.GSNumbers"),
          filterCellStyle: filterStyle,
          field: "gasStationZones",
          cellStyle: {
            paddingTop: "10px",
            paddingBottom: "10px",
            width: "12%",
          },
          headerStyle: headerStyle,
          render: (row) => (
            <div style={{ maxWidth: "min-content" }}>
              {row.gasStationZones?.map((a, index) => {
                if (index === 0) {
                  return a.number;
                }
                return " " + a.number;
              })}
            </div>
          ),
        },
        {
          title: t("Clients.Columns.Email"),
          field: "email",
          filterCellStyle: filterStyle,
          cellStyle: cellStyle,
          headerStyle: headerStyle,
        },
        {
          title: t("Clients.Columns.PhoneNumber"),
          field: "phoneNumber",
          filterCellStyle: filterStyle,
          cellStyle: cellStyle,
          headerStyle: headerStyle,
        },
        {
          title: t("Clients.Columns.BankAccountNumber"),
          field: "bankAccountNumber",
          filterCellStyle: filterStyle,
          cellStyle: cellStyle,
          headerStyle: headerStyle,
        },
    {
      title: t("Columns.Actions"),
      headerStyle: {
        ...headerStyle,
        textAlign: "right",
        align: "right",
      },
      filterComponent: () => null,
      field: "actions",
      cellStyle: {
        ...cellStyle,
        width: "10%",
        textAlign: "left",
        paddingRight: "60px",
      },

      render: (row) => (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu
            size="sm"
            style={{
              backgroundColor: "rgb(0,90,140)",
              color: "white",
            }}
            popperConfig={{ strategy: "fixed" }}
          >
            {dropdownItem(() => {
              setClientDetailsModalOpen(true);
              setSelectedClientId(row.id);
            }, "Details")}
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
    ]);

  return columns[0];

}