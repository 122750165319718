import {
  GET_ALL_GAS_STATION_ZONES_FOR_BILLING,
  GET_ALL_PARKING_ZONES_FOR_BILLING,
} from "../actions/types";

const initialState = {
  parkingZones: [],
  gasStationZones: [],
  gasStationZoneCustomers: []
};

const billingInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PARKING_ZONES_FOR_BILLING:
      return {
        ...state,
        parkingZones: action.payload.map(x => {
          return ({
            label: x.parkingZoneName + " - " + x.parkingZoneNumber,
            value: x.parkingZoneNumber,
            disabled: !x.billingInfoPopulated
          });
        }),
      };
    case GET_ALL_GAS_STATION_ZONES_FOR_BILLING:
      return {
        ...state,
        gasStationZones: action.payload.map(x => {
          return ({
            label: x.name + " - " + x.number,
            value: x.number
          });
        }),
        gasStationZoneCustomers: action.payload.map(x => {
          return ({
            label: x.customer,
            value: x.customer
          })
        }).filter(x=>x.value)
      };
    default:
      return state;
  }
};

export default billingInfoReducer;
