import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { modalCloseButtonStyle } from "../reusable/styles";

const ModalForPropotionalPicture = (props) => {
  const mapImagesToPhotos = () => {
    let photos = [];
    props.images.map((image) => {
      const photo = {
        original: image,
        thumbnail: image,
      };
      return photos.push(photo);
    });

    return photos;
  };

  return (
    <Modal
      open={props.showPictures}
      closeOnEsc
      onClose={props.closePictures}
      center
      animationDuration={500}
      styles={{
        modal: {
          maxWidth: "25%",
        },
        closeButton: modalCloseButtonStyle,
      }}
      showCloseIcon={false}
    >
      <ImageGallery items={mapImagesToPhotos()} showPlayButton={false} />
    </Modal>
  );
};
export default ModalForPropotionalPicture;
