import Style from "./licensePlateInputFieldWithLabel.module.css";

const LicensePlateInputFieldWithLabel = (props) => {
  return (
    <div className="form-group" style={props.style}>
      <div className={Style.labels}>
        {props.label}
        <hr className={Style.hr} />
      </div>
      <div>
        <input
          type={props.type ? props.type : "text"}
          className={
            props.disabled ? Style.textStyleInputDisable : Style.textStyleInput
          }
          disabled={props.disabled}
          {...props.register(props.registerName)}
          required={props.required}
          name={props.name}
          defaultValue={props.defaultValue}
          pattern={props.pattern}
          autoComplete={props.autoComplete}
          value={props.value}
          onChange={
            props.noSpaceAllowed
              ? (e) => {
                  if (e.target.value.includes(" ")) {
                    return;
                  }
                  props.setValue(e.target.value);
                }
              : (e) => props.setValue(e.target.value)
          }
        />
      </div>
    </div>
  );
};

export default LicensePlateInputFieldWithLabel;
