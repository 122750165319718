import {
  ADD_GAS_STATION,
  CLEAR_GAS_STATION_FOR_DETAILS,
  CLEAR_GAS_STATION_FOR_EDIT,
  GET_ALL_GAS_STATIONS_FOR_GS_ADMIN_OR_GS_MANAGER,
  GET_ALL_GAS_STATION_ZONES,
  GET_GAS_STATION_FOR_UPDATE,
  UPDATE_GAS_STATION,
} from "../actions/types";

const initialState = {
  gasStations: [],
  gasStation: {},
  gasStationForUpdate: {},
  gasStationForDetails: {},
};

const gasStationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_GAS_STATION_ZONES:
      return {
        ...state,
        gasStations: action.payload,
      };

    case GET_ALL_GAS_STATIONS_FOR_GS_ADMIN_OR_GS_MANAGER:
      return {
        ...state,
        gasStations: action.payload,
      };

    case ADD_GAS_STATION:
      return {
        ...state,
        gasStations: state.gasStations
          .reverse()
          .concat(action.payload)
          .reverse(),
      };

    case GET_GAS_STATION_FOR_UPDATE:
      return {
        ...state,
        gasStationForUpdate: action.payload,
        gasStationForDetails: action.payload,
      };

    case CLEAR_GAS_STATION_FOR_DETAILS:
      return {
        ...state,
        gasStationForDetails: {},
      };
    case CLEAR_GAS_STATION_FOR_EDIT:
      return {
        ...state,
        gasStationForUpdate: {},
      };

    case UPDATE_GAS_STATION:
      return {
        ...state,
        gasStations: state.gasStations.map((gs) =>
          gs.id !== action.payload.id ? gs : action.payload
        ),
      };

    default:
      return state;
  }
};

export default gasStationReducer;
