import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import cn from "classnames";
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useAuth} from "react-oidc-context";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {getAllGasStationAdmins} from "../../actions/admin";
import {getAllClients} from "../../actions/client";
import {clearGasStationForEdit, editGasStation,} from "../../actions/gasStation";
import {getAllGasStationManagers, getManagersByAdminId,} from "../../actions/manager";
import {getAllViewers, getViewersByAdminId} from "../../actions/viewer";
import AddressesComponent from "../../common/address/AddressesComponent";
import XIcon from "../../common/assets/x-icon.svg";
import Button from "../../common/button/button";
import InputController from "../../common/input/inputController";
import LabelWithHR from "../../common/labels/labelWithHR";
import {ROLES} from "../../consts";
import ModalLoadingIndicator from "../../reusable/modalLoadingIndicator";
import {newModalStyle} from "../../reusable/styles";
import {mapUser} from "../../utils/mapUser";
import Style from "./editGasStationModal.module.css";

const EditGasStationModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit, register, watch, control, setValue } = useForm();
  const auth = useAuth();

  const loggedUser = mapUser(auth);

  const { admins } = useSelector((state) => state.admin);
  const { clients } = useSelector((state) => state.client);
  const { gasStationForUpdate } = useSelector((state) => state.gasStation);
  const { isLoading } = useSelector((state) => state.loader);

  const [counter, setCounter] = useState(0);
  const [viewerCounter, setViewerCounter] = useState(0);

  const { managers } = useSelector((state) => state.manager);
  const { viewers } = useSelector((state) => state.viewer);
  const [managersIds, setManagersIds] = useState([]);
  const [viewersIds, setViewersIds] = useState([]);
  const [managersComboBoxNames, setManagersComboBoxNames] = useState([]);
  const [viewersComboBoxNames, setViewersComboBoxNames] = useState([]);
  const [useClientDocs, setUseClientDocs] = useState(gasStationForUpdate?.useClientDocs ? true : false);


  useEffect(() => {
    setPowerOfAttorneyName(
      gasStationForUpdate.powerOfAttorneyName ? gasStationForUpdate.powerOfAttorneyName : ""
    );
  }, [gasStationForUpdate]);

  useEffect(() => {
      setDocumentationName(
        gasStationForUpdate.documentationName ? gasStationForUpdate.documentationName : ""
      );
    }, [gasStationForUpdate]);

  const [modifyGasStation, setModifyGasStation] = useState({
    number: gasStationForUpdate?.number,
    name: gasStationForUpdate?.name,
    customer: gasStationForUpdate?.customer,
    address: gasStationForUpdate?.address,
    adminKeycloakId: gasStationForUpdate?.adminKeycloakId,
    internationalFee: gasStationForUpdate?.internationalFee,
    clientName: gasStationForUpdate?.clientName,
    clientNumber: gasStationForUpdate?.clientNumber,
    profitCenter: gasStationForUpdate?.profitCenter,
    costCenter: gasStationForUpdate?.costCenter,
    payee: gasStationForUpdate?.payee,
    bic: gasStationForUpdate?.bic,
    iban: gasStationForUpdate?.iban,
    financialInstitution: gasStationForUpdate?.financialInstitution,
    contractDate: gasStationForUpdate?.contractDate?new Date(gasStationForUpdate.contractDate):null,
    salesArea: gasStationForUpdate?.salesArea,
    others: gasStationForUpdate?.others,
    representedBy: gasStationForUpdate?.representedBy,
    relationToProperty: gasStationForUpdate?.relationToProperty,
    notedBy: gasStationForUpdate?.notedBy,
    injuredPerson: gasStationForUpdate?.injuredPerson,
    additionalInfo: gasStationForUpdate?.additionalInfo,
    client: gasStationForUpdate?.client,
    clientId: gasStationForUpdate?.client?.id,
    useClientDocs: gasStationForUpdate?.useClientDocs
  });

  const [powerOfAttorneyName, setPowerOfAttorneyName] = useState("");
  const [powerOfAttorney, setPowerOfAttorney] = useState(null);
  const [documentation, setDocumentation] = useState(null);
  const [documentationName, setDocumentationName] = useState("");

  const [categoryPaymentDetails, setCategoryPaymentDetails] = useState({
    intentAdditionalFee:
      gasStationForUpdate?.categoryPaymentDetails?.intentAdditionalFee,
    intentPaymentPeriod:
      gasStationForUpdate?.categoryPaymentDetails?.intentPaymentPeriod,
    intentReminderFee:
      gasStationForUpdate?.categoryPaymentDetails?.intentReminderFee,
    intentReminderPaymentPeriod:
      gasStationForUpdate?.categoryPaymentDetails?.intentReminderPaymentPeriod,

    promissoryNoteAdditionalFee:
      gasStationForUpdate?.categoryPaymentDetails?.promissoryNoteAdditionalFee,
    promissoryNotePaymentPeriod:
      gasStationForUpdate?.categoryPaymentDetails?.promissoryNotePaymentPeriod,
    promissoryNoteReminderFee:
      gasStationForUpdate?.categoryPaymentDetails?.promissoryNoteReminderFee,
    promissoryNoteReminderPaymentPeriod:
      gasStationForUpdate?.categoryPaymentDetails
        ?.promissoryNoteReminderPaymentPeriod,
    promissoryNoteGracePeriod:
      gasStationForUpdate?.categoryPaymentDetails?.promissoryNoteGracePeriod,
    promissoryNotePaymentFee:
          gasStationForUpdate?.categoryPaymentDetails?.promissoryNotePaymentFee,

    debtNoteAdditionalFee:
      gasStationForUpdate?.categoryPaymentDetails?.debtNoteAdditionalFee,
    debtNotePaymentPeriod:
      gasStationForUpdate?.categoryPaymentDetails?.debtNotePaymentPeriod,
    debtNoteReminderFee:
      gasStationForUpdate?.categoryPaymentDetails?.debtNoteReminderFee,
    debtNoteReminderPaymentPeriod:
      gasStationForUpdate?.categoryPaymentDetails
        ?.debtNoteReminderPaymentPeriod,

    pumpSwappedAdditionalFee:
      gasStationForUpdate?.categoryPaymentDetails?.pumpSwappedAdditionalFee,
    pumpSwappedPaymentPeriod:
      gasStationForUpdate?.categoryPaymentDetails?.pumpSwappedPaymentPeriod,
    pumpSwappedReminderFee:
      gasStationForUpdate?.categoryPaymentDetails?.pumpSwappedReminderFee,
    pumpSwappedReminderPaymentPeriod:
      gasStationForUpdate?.categoryPaymentDetails
        ?.pumpSwappedReminderPaymentPeriod,

    otherReasonAdditionalFee:
      gasStationForUpdate?.categoryPaymentDetails?.otherReasonAdditionalFee,
    otherReasonPaymentPeriod:
      gasStationForUpdate?.categoryPaymentDetails?.otherReasonPaymentPeriod,
    otherReasonReminderFee:
      gasStationForUpdate?.categoryPaymentDetails?.otherReasonReminderFee,
    otherReasonReminderPaymentPeriod:
      gasStationForUpdate?.categoryPaymentDetails
        ?.otherReasonReminderPaymentPeriod,
  });

  useEffect(() => {
    if (!gasStationForUpdate?.managersIds) {
      setManagersIds([]);
      setManagersComboBoxNames([]);
      setCounter(0);
    } else {
      setManagersIds(gasStationForUpdate?.managersIds);
      const array = gasStationForUpdate?.managersIds.map(
        (m, index) => index + 1
      );
      setManagersComboBoxNames(array);
      setCounter(gasStationForUpdate?.managersIds.length);
    }
    if (!gasStationForUpdate?.viewersIds) {
      setViewersIds([]);
      setViewersComboBoxNames([]);
      setViewerCounter(0);
    } else {
      setViewersIds(gasStationForUpdate?.viewersIds);
      const array = gasStationForUpdate?.viewersIds.map(
          (m, index) => index + 1
      );
      setViewersComboBoxNames(array);
      setViewerCounter(gasStationForUpdate?.viewersIds.length);
    }
  }, [gasStationForUpdate]);

  const documentUpload = (e, files, setFiles, filesNames, setFilesNames, isSingleFile) => {
    if (e.target.files.length !== 0) {
     let fileList = files === null ? [] : files;
             let names = filesNames;
             for (let i = 0; i < e.target.files.length; i++) {
               if (isSingleFile && i === 0) {
                 fileList = [];
                 fileList.push(e.target.files[i])
                 names = e.target.files[i].name;
               } else {
                 fileList.push(e.target.files[i]);
                 names = names === "" ? e.target.files[i].name : names.concat("|", e.target.files[i].name);
               }
             }
             setFiles(fileList);
             setFilesNames(names);
           }
  };

  const removeDocument = (
    files,
    setFiles,
    filesNames,
    setFilesNames,
    elementForRemoveName
  ) => {
    files !== null &&
      setFiles([...files?.filter((f) => f.name !== elementForRemoveName)]);
    const namesArray = filesNames
      ?.split("|")
      .filter((fn) => fn !== elementForRemoveName);
    setFilesNames(namesArray?.join("|"));
  };

  const showDocumentsNames = (files, setFiles, filesNames, setFilesNames) => {
    return filesNames?.split("|")?.map((n, index) => (
      <div key={index} className={Style.documentRow}>
        <div className={Style.uploadedFileName}>{n}</div>
        <div className={Style.documentLine} />
        <div className={Style.removeDocument}>
          <img
            alt=""
            src={XIcon}
            height="25px"
            width="25px"
            onClick={() => {
              removeDocument(files, setFiles, filesNames, setFilesNames, n);
            }}
          />
        </div>
      </div>
    ));
  };

  const isLoggedUserSysadmin = loggedUser?.roles?.includes(ROLES.SYSADMIN);

  useEffect(() => {
    if (isLoggedUserSysadmin) {
      dispatch(getAllGasStationAdmins());
      dispatch(getAllGasStationManagers());
      dispatch(getAllViewers());
      dispatch(getAllClients());
    } else {
      dispatch(getManagersByAdminId());
      dispatch(getViewersByAdminId());
    }
    return () => {
      dispatch(clearGasStationForEdit());
    };
  }, [dispatch, isLoggedUserSysadmin]);

  const setGasStationAdmin = (id) => {
    setModifyGasStation({
      ...modifyGasStation,
      adminKeycloakId: id,
    });
  };

  const setClientId = (id) => {
      setModifyGasStation({
        ...modifyGasStation,
        clientId: id,
        customer: id === null ? "" : modifyGasStation.customer,
      });
    };

  const renderEditManagers = (managersIds) => {
    const getListOfManagers = (managers, ordinalNumber) => {
      if (ordinalNumber === 1) return managers;
      return managers.filter(
        (m) => !managersIds.slice(0, ordinalNumber - 1).includes(m.id)
      );
    };

    return (
      <div className={Style.paddingTop}>
        <div className={Style.section__title}>{"PZ Manager"}</div>
        {managersIds.map((manager, index) => (
          <div className={Style.managerListContainer} key={index}>
            <div className={Style.selectManager}>
              <Controller
                name={`parkingZoneManager_${managersComboBoxNames[index]}`}
                control={control}
                render={() => (
                    <Autocomplete
                        className={Style.textStyleSelectEditModal}
                        id={`parkingZoneManager_${managersComboBoxNames[index]}`}
                        onChange={(e,value)  => {
                          if(value) {
                            managersIds[index] = value.id;
                            setManagersIds([...managersIds]);
                          }
                        }}
                        disableClearable
                        value = {managers.find(x=>x.id===manager) || null}
                        renderInput={(params) => <TextField {...params} placeholder={"auswählen"}/>}
                        options={getListOfManagers(managers, index + 1).filter((m) => m.id !== modifyGasStation?.keycloakAdminId)}
                        getOptionSelected={(option, value) => option.id === value.id}
                        getOptionLabel={(x) => `${x.firstName} ${x.lastName} | ${x.emailAddress} ${x.companyName?"| "+x.companyName:""}`}
                    />
                )}
              />
            </div>
            <div className={Style.removeManager}>
              <img
                alt=""
                src={XIcon}
                height="25px"
                width="25px"
                onClick={(e) => {
                  setManagersIds([
                    ...managersIds.filter((m, i) => i !== index),
                  ]);
                  managersComboBoxNames.splice(index, 1);
                  setManagersComboBoxNames([...managersComboBoxNames]);
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderEditViewers = (viewerIds) => {
    const getListOfViewers = (viewers, ordinalNumber) => {
      if (ordinalNumber === 1) return viewers;
      return viewers.filter(
          (m) => !viewerIds.slice(0, ordinalNumber - 1).includes(m.id)
      );
    };

    return (
        <div className={Style.paddingTop}>
          <div className={Style.section__title}>{t("Viewer.Viewers")}</div>
          {viewerIds.map((viewer, index) => (
              <div className={Style.managerListContainer} key={index}>
                <div className={Style.selectManager}>
                  <Controller
                      name={`parkingZoneViewer_${viewersComboBoxNames[index]}`}
                      control={control}
                      render={() => (
                          <Autocomplete
                              className={Style.textStyleSelectEditModal}
                              id={`parkingZoneViewer_${viewersComboBoxNames[index]}`}
                              onChange={(e,value)  => {
                                if(value) {
                                  viewerIds[index] = value.id;
                                  setViewersIds([...viewerIds]);
                                }
                              }}
                              disableClearable
                              value = {viewers.find(x=>x.id===viewer) || null}
                              renderInput={(params) => <TextField {...params} placeholder={"auswählen"}/>}
                              options={getListOfViewers(viewers, index + 1).filter((m) => m.id !== modifyGasStation?.keycloakAdminId)}
                              getOptionSelected={(option, value) => option.id === value.id}
                              getOptionLabel={(x) => `${x.firstName} ${x.lastName} | ${x.emailAddress} ${x.companyName?"| "+x.companyName:""}`}
                          />
                      )}
                  />

                </div>
                <div className={Style.removeManager}>
                  <img
                      alt=""
                      src={XIcon}
                      height="25px"
                      width="25px"
                      onClick={(e) => {
                        setViewersIds([
                          ...viewerIds.filter((m, i) => i !== index),
                        ]);
                        viewersComboBoxNames.splice(index, 1);
                        setViewersComboBoxNames([...viewersComboBoxNames]);
                      }}
                  />
                </div>
              </div>
          ))}
        </div>
    );
  };

  const isSubmitDisabled =
    !modifyGasStation?.address ||
    !modifyGasStation?.name ||
    modifyGasStation?.adminKeycloakId === "0" ||
    managersIds?.includes("0") ||
    managersIds?.includes(modifyGasStation?.keycloakAdminId) ||
    viewersIds?.includes("0") ||
    viewersIds?.includes(modifyGasStation?.keycloakAdminId);

  const saveGasStation = async () => {
    const formData = new FormData();

    formData.append("powerOfAttorney", powerOfAttorney && powerOfAttorney[0]);
    formData.append("powerOfAttorneyName", powerOfAttorneyName);
    for (let i = 0; i < documentation?.length; i++) {
          formData.append("documentation", documentation[i]);
    }
    formData.append("documentationName", documentationName);
    const gasStationForEdit = {
      ...modifyGasStation,
      categoryPaymentDetails,
      managersIds,
      viewersIds,
      useClientDocs
    };

    formData.append(
      "gasStationZone",
      new Blob([JSON.stringify(gasStationForEdit)], {
        type: "application/json",
      })
    );

    await dispatch(
      editGasStation(gasStationForUpdate.id, formData, props.onClose)
    );
  };

  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={props.onClose}
      center
      styles={newModalStyle({ width: "70%", maxWidth: "900px" })}
    >
      <div className="modal__headerLine" />
      <div className={cn("modal__title", Style.modal__title)}>
        {t("EditGasStationModal.title")}
      </div>
      {isLoading && <ModalLoadingIndicator />}
      <form onSubmit={handleSubmit(saveGasStation)}>
        <div className={Style.formContainer}>

          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("EditGasStationModal.basicInfo")}
            </div>

            <div className={Style.rowContainer}>
              <div className={Style.container}>
                <LabelWithHR label={t("EditGasStationModal.number")}/>
                <Controller
                    name="number"
                    control={control}
                    render={() => (
                        <input
                            type={"text"}
                            className={Style.textStyleInputEditModalDisabled}
                            name="number"
                            defaultValue={modifyGasStation.number}
                            disabled
                        />
                    )}
                />
              </div>
              <div className={Style.nameContainer}>
                <div className={cn(Style.container)}>
                  <LabelWithHR label={t("EditGasStationModal.name")}/>
                  <Controller
                      name="name"
                      control={control}
                      render={() => (
                          <input
                              type={"text"}
                              className={Style.textStyleInputEditModal}
                              name="name"
                              onChange={(e) => {
                                setModifyGasStation({
                                  ...modifyGasStation,
                                  name: e.target.value,
                                });
                              }}
                              defaultValue={modifyGasStation?.name}
                          />
                      )}
                  />
                </div>
              </div>

              <div className={Style.nameContainer}>
                <div className={cn(Style.container)}>
                  <LabelWithHR label={t("Clients.Client")}/>
                  <Controller
                      name="customer"
                      control={control}
                      render={({field}) => (
                          <input
                              {...field}
                              type={"text"}
                              className={Style.textStyleInputEditModal}
                              name="customer"
                              onChange={(e) => {
                                setModifyGasStation({
                                  ...modifyGasStation,
                                  customer: e.target.value,
                                });
                                field.onChange(e);
                              }}
                              defaultValue={modifyGasStation?.customer}
                          />
                      )}
                  />
                </div>
              </div>
            </div>
            <div className={Style.rowContainer}>
              <div className={cn(Style.container, Style["container--margineRightSmall"])}>
                <LabelWithHR label={t("EditGasStationModal.clientNumber")}/>
                <Controller
                    name="clientNumber"
                    control={control}
                    render={() => (
                        <input
                            type={"text"}
                            className={Style.textStyleInputEditModal}
                            name="clientNumber"
                            onChange={(e) => {
                              setModifyGasStation({
                                ...modifyGasStation,
                                clientNumber: e.target.value,
                              });
                            }}
                            defaultValue={modifyGasStation?.clientNumber}
                        />
                    )}
                />
              </div>
              <div className={cn(Style.container)}>
                <LabelWithHR label={t("EditGasStationModal.clientName")}/>
                <Controller
                    name="clientName"
                    control={control}
                    render={() => (
                        <input
                            type={"text"}
                            className={Style.textStyleInputEditModal}
                            name="clientName"
                            onChange={(e) => {
                              setModifyGasStation({
                                ...modifyGasStation,
                                clientName: e.target.value,
                              });
                            }}
                            defaultValue={modifyGasStation?.clientName}
                        />
                    )}
                />
              </div>
            </div>
            <div className={Style.rowContainer}>
              <div className={cn(Style.container, Style["container--margineRightSmall"])}>
                <LabelWithHR label={t("EditGasStationModal.profitCenter")}/>
                <Controller
                    name="profitCenter"
                    control={control}
                    render={() => (
                        <input
                            type={"text"}
                            className={Style.textStyleInputEditModal}
                            name="profitCenter"
                            onChange={(e) => {
                              setModifyGasStation({
                                ...modifyGasStation,
                                profitCenter: e.target.value,
                              });
                            }}
                            defaultValue={modifyGasStation?.profitCenter}
                        />
                    )}
                />
              </div>
              <div className={cn(Style.container)}>
                <LabelWithHR label={t("EditGasStationModal.costCenter")}/>
                <Controller
                    name="costCenter"
                    control={control}
                    render={() => (
                        <input
                            type={"text"}
                            className={Style.textStyleInputEditModal}
                            name="costCenter"
                            onChange={(e) => {
                              setModifyGasStation({
                                ...modifyGasStation,
                                costCenter: e.target.value,
                              });
                            }}
                            defaultValue={modifyGasStation?.costCenter}
                        />
                    )}
                />
              </div>
            </div>
            <div
                className={Style.gsPaymentInfoContainer}
                style={{width: "50%"}}
            >
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRightSmall"]
                  )}
                  label={t("GasStations.internationalFee")}
                  name={"internationalFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  defaultValue={modifyGasStation?.internationalFee}
                  onChange={(e) => {
                    setModifyGasStation({
                      ...modifyGasStation,
                      internationalFee: e.target.value,
                    });
                  }}
              />
            </div>
          </div>

          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("GasStations.inetntPaymentInfoTitle")}
            </div>
            <div className={Style.rowContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={t("GasStations.additionalFee")}
                  name={"intentAdditionalFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      intentAdditionalFee: e.target.value,
                    });
                  }}
                  defaultValue={categoryPaymentDetails?.intentAdditionalFee}
              />

              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.paymentPeriod")}
                  name={"intentPaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      intentPaymentPeriod: e.target.value,
                    });
                  }}
                  defaultValue={categoryPaymentDetails?.intentPaymentPeriod}
              />
            </div>
            <div className={Style.rowContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={t("GasStations.reminderFee")}
                  name={"intentReminderFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      intentReminderFee: e.target.value,
                    });
                  }}
                  defaultValue={categoryPaymentDetails?.intentReminderFee}
              />

              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.reminderPaymentPeriod")}
                  name={"intentReminderPaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      intentReminderPaymentPeriod: e.target.value,
                    });
                  }}
                  defaultValue={
                    categoryPaymentDetails?.intentReminderPaymentPeriod
                  }
              />
            </div>
          </div>

          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("GasStations.promissoryNotePaymentInfoTitle")}
            </div>
            <div className={Style.rowContainer}>
                <InputController
                    containerStyles={cn(
                        Style.container,
                        Style["container--margineRight"]
                    )}
                    label={t("GasStations.paymentFee")}
                    name={"promissoryNotePaymentFee"}
                    control={control}
                    controllerClassName={Style.textStyleInputEditModal}
                    type={"number"}
                    min={0}
                    onChange={(e) => {
                      setCategoryPaymentDetails({
                        ...categoryPaymentDetails,
                        promissoryNotePaymentFee: e.target.value,
                      });
                    }}
                    defaultValue={
                      categoryPaymentDetails?.promissoryNotePaymentFee
                    }
                />

                <InputController
                    containerStyles={Style.container}
                    label={t("GasStations.gracePeriod")}
                    name={"promissoryNoteGracePeriod"}
                    control={control}
                    controllerClassName={Style.textStyleInputEditModal}
                    type={"number"}
                    min={0}
                    defaultValue={categoryPaymentDetails?.promissoryNoteGracePeriod}
                    onChange={(e) => {
                      setCategoryPaymentDetails({
                        ...categoryPaymentDetails,
                        promissoryNoteGracePeriod: e.target.value,
                      });
                    }}
                />
            </div>

            <div className={Style.rowContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={t("GasStations.additionalFee")}
                  name={"promissoryNoteAdditionalFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      promissoryNoteAdditionalFee: e.target.value,
                    });
                  }}
                  defaultValue={
                    categoryPaymentDetails?.promissoryNoteAdditionalFee
                  }
              />

              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.paymentPeriod")}
                  name={"promissoryNotePaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      promissoryNotePaymentPeriod: e.target.value,
                    });
                  }}
                  defaultValue={
                    categoryPaymentDetails?.promissoryNotePaymentPeriod
                  }
              />
            </div>
            <div className={Style.rowContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={t("GasStations.reminderFee")}
                  name={"promissoryNoteReminderFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      promissoryNoteReminderFee: e.target.value,
                    });
                  }}
                  defaultValue={categoryPaymentDetails?.promissoryNoteReminderFee}
              />
              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.reminderPaymentPeriod")}
                  name={"promissoryNoteReminderPaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      promissoryNoteReminderPaymentPeriod: e.target.value,
                    });
                  }}
                  defaultValue={
                    categoryPaymentDetails?.promissoryNoteReminderPaymentPeriod
                  }
              />
            </div>
          </div>

          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("GasStations.debtNotePaymentInfoTitle")}
            </div>
            <div className={Style.rowContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={t("GasStations.additionalFee")}
                  name={"debtNoteAdditionalFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      debtNoteAdditionalFee: e.target.value,
                    });
                  }}
                  defaultValue={categoryPaymentDetails?.debtNoteAdditionalFee}
              />

              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.paymentPeriod")}
                  name={"debtNotePaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      debtNotePaymentPeriod: e.target.value,
                    });
                  }}
                  defaultValue={categoryPaymentDetails?.debtNotePaymentPeriod}
              />
            </div>

            <div className={Style.rowContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={t("GasStations.reminderFee")}
                  name={"debtNoteReminderFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      debtNoteReminderFee: e.target.value,
                    });
                  }}
                  defaultValue={categoryPaymentDetails?.debtNoteReminderFee}
              />

              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.reminderPaymentPeriod")}
                  name={"debtNoteReminderPaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      debtNoteReminderPaymentPeriod: e.target.value,
                    });
                  }}
                  defaultValue={
                    categoryPaymentDetails?.debtNoteReminderPaymentPeriod
                  }
              />
            </div>
          </div>

          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("GasStations.pumpSwappedPaymentInfoTitle")}
            </div>
            <div className={Style.rowContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={t("GasStations.additionalFee")}
                  name={"pumpSwappedAdditionalFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      pumpSwappedAdditionalFee: e.target.value,
                    });
                  }}
                  defaultValue={categoryPaymentDetails?.pumpSwappedAdditionalFee}
              />

              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.paymentPeriod")}
                  name={"pumpSwappedPaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      pumpSwappedPaymentPeriod: e.target.value,
                    });
                  }}
                  defaultValue={categoryPaymentDetails?.pumpSwappedPaymentPeriod}
              />
            </div>

            <div className={Style.rowContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={t("GasStations.reminderFee")}
                  name={"pumpSwappedReminderFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      pumpSwappedReminderFee: e.target.value,
                    });
                  }}
                  defaultValue={categoryPaymentDetails?.pumpSwappedReminderFee}
              />

              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.reminderPaymentPeriod")}
                  name={"pumpSwappedReminderPaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      pumpSwappedReminderPaymentPeriod: e.target.value,
                    });
                  }}
                  defaultValue={
                    categoryPaymentDetails?.pumpSwappedReminderPaymentPeriod
                  }
              />
            </div>
          </div>

          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("GasStations.otherReasonPaymentInfoTitle")}
            </div>
            <div className={Style.rowContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={t("GasStations.additionalFee")}
                  name={"otherReasonAdditionalFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      otherReasonAdditionalFee: e.target.value,
                    });
                  }}
                  defaultValue={categoryPaymentDetails?.otherReasonAdditionalFee}
              />

              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.paymentPeriod")}
                  name={"otherReasonPaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      otherReasonPaymentPeriod: e.target.value,
                    });
                  }}
                  defaultValue={categoryPaymentDetails?.otherReasonPaymentPeriod}
              />
            </div>

            <div className={Style.rowContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={t("GasStations.reminderFee")}
                  name={"otherReasonReminderFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      otherReasonReminderFee: e.target.value,
                    });
                  }}
                  defaultValue={categoryPaymentDetails?.otherReasonReminderFee}
              />

              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.reminderPaymentPeriod")}
                  name={"otherReasonReminderPaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      otherReasonReminderPaymentPeriod: e.target.value,
                    });
                  }}
                  defaultValue={
                    categoryPaymentDetails?.otherReasonReminderPaymentPeriod
                  }
              />
            </div>
          </div>

          <div className={Style.section}>
            <AddressesComponent
                onAddressAdded={(newAddress) => {
                  setModifyGasStation({
                    ...modifyGasStation,
                    address: newAddress,
                  })
                }}
                initialAddress={modifyGasStation?.address}
                watch={watch}
                register={register}
            />
          </div>

          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("ParkingZones.paymentInfo")}
            </div>
            <div className={[Style.rowContainer]}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )} label={t("EditParkingZoneModal.iban")}
                  name={"iban"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"text"}
                  onChange={(e) => {
                    setModifyGasStation({
                      ...modifyGasStation,
                      iban: e.target.value,
                    });
                  }}
                  defaultValue={
                    modifyGasStation?.iban
                  }
              />
              <InputController
                  containerStyles={cn(
                      Style.container
                  )}
                  label={t("EditParkingZoneModal.bic")}
                  name={"bic"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"text"}
                  onChange={(e) => {
                    setModifyGasStation({
                      ...modifyGasStation,
                      bic: e.target.value,
                    });
                  }}
                  defaultValue={
                    modifyGasStation?.bic
                  }
              />
            </div>
            <div className={[Style.rowContainer]}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={t("EditParkingZoneModal.payee")}
                  name={"payee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"text"}
                  onChange={(e) => {
                    setModifyGasStation({
                      ...modifyGasStation,
                      payee: e.target.value,
                    });
                  }}
                  defaultValue={
                    modifyGasStation?.payee
                  }
              />
              <InputController
                  containerStyles={cn(
                      Style.container,
                  )}
                  label={t("EditParkingZoneModal.financialInstitution")}
                  name={"financialInstitution"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"text"}
                  onChange={(e) => {
                    setModifyGasStation({
                      ...modifyGasStation,
                      financialInstitution: e.target.value,
                    });
                  }}
                  defaultValue={
                    modifyGasStation?.financialInstitution
                  }
              />
            </div>

            <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  justifyContent: "space-between",
                }}
            >
              <div style={{zIndex: 3, flex: 1}}>
                <LabelWithHR label={t("ParkingZones.contractDate")}/>
                <Controller
                    name="contractDate"
                    control={control}
                    render={() => (
                        <DatePicker
                            selected={modifyGasStation?.contractDate}
                            popperPlacement="top"
                            dateFormat="dd.MM.yyyy"
                            className={Style.textStyleInputEditModal}
                            locale="de"
                            onChange={(date) => {
                              setModifyGasStation({
                                ...modifyGasStation,
                                contractDate: date,
                              });
                            }}
                        />
                    )}
                />
              </div>
              <div style={{flex: 1, marginLeft: "40px"}}>
                <LabelWithHR label={t("ParkingZones.salesArea")}/>
                <Controller
                    name="salesArea"
                    control={control}
                    render={() => (
                        <input
                            type="text"
                            className={Style.textStyleInputEditModal}
                            name="salesArea"
                            onChange={(e) => {
                              setModifyGasStation({
                                ...modifyGasStation,
                                salesArea: e.target.value,
                              });
                            }}
                            defaultValue={modifyGasStation?.salesArea}
                        />
                    )}
                />
              </div>
            </div>

            <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  justifyContent: "space-between",
                }}
            >
              <div style={{zIndex: 3, flex: 1}}>
                <LabelWithHR label={t("GasStations.Others")}/>
                <Controller
                    name="others"
                    control={control}
                    render={() => (
                        <textarea
                            type="text"
                            className={Style.textArea}
                            name="others"
                            onChange={(e) => {
                              setModifyGasStation({
                                ...modifyGasStation,
                                others: e.target.value,
                              });
                            }}
                            defaultValue={modifyGasStation?.others}
                        />
                    )}
                />
              </div>
            </div>
            <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  justifyContent: "space-between",
                }}
            >
              <div style={{zIndex: 3, flex: 1}}>
                <LabelWithHR label={t("GasStations.RepresentedBy")}/>
                <Controller
                    name="representedBy"
                    control={control}
                    render={() => (
                        <input
                            type="text"
                            className={Style.textStyleInputEditModal}
                            name="representedBy"
                            onChange={(e) => {
                              setModifyGasStation({
                                ...modifyGasStation,
                                representedBy: e.target.value,
                              });
                            }}
                            defaultValue={modifyGasStation?.representedBy}
                        />
                    )}
                />
              </div>
              <div style={{flex: 1, marginLeft: "40px"}}>
                <LabelWithHR label={t("GasStations.RelationToProperty")}/>
                <Controller
                    name="relationToProperty"
                    control={control}
                    render={() => (
                        <input
                            type="text"
                            className={Style.textStyleInputEditModal}
                            name="relationToProperty"
                            onChange={(e) => {
                              setModifyGasStation({
                                ...modifyGasStation,
                                relationToProperty: e.target.value,
                              });
                            }}
                            defaultValue={modifyGasStation?.relationToProperty}
                        />
                    )}
                />
              </div>
            </div>

            <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  justifyContent: "space-between",
                }}
            >
              <div style={{zIndex: 3, flex: 1}}>
                <LabelWithHR label={t("GasStations.NotedBy")}/>
                <Controller
                    name="notedBy"
                    control={control}
                    render={() => (
                        <textarea
                            type="text"
                            className={Style.textArea}
                            name="notedBy"
                            onChange={(e) => {
                              setModifyGasStation({
                                ...modifyGasStation,
                                notedBy: e.target.value,
                              });
                            }}
                            defaultValue={modifyGasStation?.notedBy}
                        />
                    )}
                />
              </div>
            </div>

            <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  justifyContent: "space-between",
                }}
            >
              <div style={{zIndex: 3, flex: 1}}>
                <LabelWithHR label={t("GasStations.InjuredPerson")}/>
                <Controller
                    name="injuredPerson"
                    control={control}
                    render={() => (
                        <textarea
                            type="text"
                            className={Style.textArea}
                            name="injuredPerson"
                            onChange={(e) => {
                              setModifyGasStation({
                                ...modifyGasStation,
                                injuredPerson: e.target.value,
                              });
                            }}
                            defaultValue={modifyGasStation?.injuredPerson}
                        />
                    )}
                />
              </div>
            </div>

            <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  justifyContent: "space-between",
                }}
            >
              <div style={{zIndex: 3, flex: 1}}>
                <LabelWithHR label={t("GasStations.AdditionalInfo")}/>
                <Controller
                    name="additionalInfo"
                    control={control}
                    render={() => (
                        <textarea
                            type="text"
                            className={Style.textArea}
                            name="additionalInfo"
                            onChange={(e) => {
                              setModifyGasStation({
                                ...modifyGasStation,
                                additionalInfo: e.target.value,
                              });
                            }}
                            defaultValue={modifyGasStation?.additionalInfo}
                        />
                    )}
                />
              </div>
            </div>
          </div>

          <div className={Style.section}>
              <div className={Style.section__title}>
                {t("ParkingZones.documents")}
              </div>
              <div className={Style.container}>
                <>
                  <label htmlFor="file-upload" className={Style.fileUpload}>
                    {t("GasStations.PowerOfAttorney")}
                  </label>
                  {powerOfAttorneyName !== "" && (
                      <label>
                        {powerOfAttorneyName === "" ? null : (
                            <div>
                              {showDocumentsNames(
                                  powerOfAttorney,
                                  setPowerOfAttorney,
                                  powerOfAttorneyName,
                                  setPowerOfAttorneyName
                              )}
                            </div>
                        )}
                      </label>
                  )}

                  <input
                      id="file-upload"
                      type="file"
                      accept="image/jpg, image/png, image/jpeg"
                      className="powerOfAttorney"
                      onChange={(e) => {
                        documentUpload(
                            e,
                            powerOfAttorney,
                            setPowerOfAttorney,
                            powerOfAttorneyName,
                            setPowerOfAttorneyName,
                            true
                        );
                      }}
                      multiple={false}
                  />
                  <label htmlFor="documentation" className={Style.fileUpload}>
                    {t("GasStations.GSDocumentation")}
                  </label>
                  {documentationName !== "" && (
                      <label>
                        {documentationName === "" ? null : (
                            <div>
                              {showDocumentsNames(
                                  documentation,
                                  setDocumentation,
                                  documentationName,
                                  setDocumentationName
                              )}
                            </div>
                        )}
                      </label>
                  )}
                  <input
                      id="documentation"
                      type="file"
                      accept=".pdf, .docx"
                      className="documentation"
                      multiple
                      onChange={(e) => {
                        documentUpload(
                            e,
                            documentation,
                            setDocumentation,
                            documentationName,
                            setDocumentationName,
                            false
                        );
                      }}
                  />
                </>
              </div>
            </div>

          <div className={Style.section}>
            <div className={cn(Style.section__title, Style.paddingTop)}>
              {t("Clients.Client")}
            </div>
            {isLoggedUserSysadmin ? (
                <div>
                  <div className={Style.managerListContainer}>
                    <div className={Style.selectManager}>
                      <Controller
                          name="clientId"
                          control={control}
                          render={() => (
                              <Autocomplete
                                  className={Style.textStyleSelectEditModal}
                                  id={"clientId"}
                                  onChange={(e, value) => {
                                    if (value) {
                                      setClientId(value.id);
                                      setValue("customer", `${value.name}`);
                                    }
                                  }}
                                  disableClearable
                                  value={clients.find(x => x.id === modifyGasStation?.clientId) || null}
                                  renderInput={(params) => <TextField {...params} placeholder={t("Clients.Choose")}/>}
                                  options={clients}
                                  getOptionSelected={(option, value) => option.id === value.id}
                                  getOptionLabel={(x) => `${x.name} | ${x.representativeName}`}
                              />
                          )}
                      />
                    </div>
                    <div className={Style.removeManager} onClick={() => {
                      setClientId(null);
                      setUseClientDocs(false);
                      setValue("customer", "");
                    }}>
                      <img
                          alt=""
                          src={XIcon}
                          height="25px"
                          width="25px"
                      />
                    </div>
                  </div>
                  <div className="objectionEnabledCheckbox">
                    <input
                        type="checkBox"
                        className="checkboxObjectionEnabled"
                        disabled={!modifyGasStation?.clientId}
                        onChange={() => {
                          setUseClientDocs(
                              !useClientDocs
                          );
                        }}
                        checked={useClientDocs}
                    />
                    <div
                        className="objectionEnabled"
                        style={{fontSize: 14}}
                    >
                      {t("Clients.UseClientDocs")}
                    </div>
                  </div>
                </div>
            ) : (
                <div className={Style.text}>
                  {clients.find(client => client.id === modifyGasStation.client.id)?.name || ""}
                </div>
            )}

            {viewersIds && renderEditViewers(viewersIds)}
            <button
                type="button"
                className={
                  viewersIds?.includes("0")
                      ? Style.addManagerButtonDisabled
                      :
                      Style.addManagerButton
                }
                onClick={() => {
                  setViewersIds([...viewersIds, "0"]);
                  setViewersComboBoxNames([
                    ...viewersComboBoxNames,
                    viewerCounter + 1,
                  ]);
                  setViewerCounter(viewerCounter + 1);
                }}
                disabled={viewersIds && viewersIds?.includes("0")}
            >
              {"+" + t("Viewer.addViewer")}
            </button>
          </div>

          <div className={Style.section}>
            <div className={cn(Style.section__title, Style.paddingTop)}>
              {t("ParkingZones.chooseAdmin")}
            </div>
            {isLoggedUserSysadmin ? (
                <Controller
                    name="adminKeycloakId"
                    control={control}
                    render={() => (
                        <Autocomplete
                            className={Style.textStyleSelectEditModal}
                            id={"keycloakAdminId"}
                            onChange={(e, value) => {
                              if (value) {
                                setGasStationAdmin(value.id);
                              }
                            }}
                            disableClearable
                            value={admins.find(x => x.id === modifyGasStation?.adminKeycloakId) || null}
                            renderInput={(params) => <TextField {...params} placeholder={"auswählen"}/>}
                            options={admins}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(x) => `${x.firstName} ${x.lastName} | ${x.emailAddress} ${x.companyName ? "| " + x.companyName : ""}`}
                        />
                    )}
                />
            ) : (
                <div className={Style.text}>
                  {loggedUser?.firstName + " " + loggedUser?.lastName}
                </div>
            )}

            {managersIds && renderEditManagers(managersIds)}
            <button
                type="button"
                className={
                  managersIds?.includes("0")
                      ? Style.addManagerButtonDisabled
                      : Style.addManagerButton
                }
                onClick={() => {
                  setManagersIds([...managersIds, "0"]);
                  setManagersComboBoxNames([
                    ...managersComboBoxNames,
                    counter + 1,
                  ]);
                  setCounter(counter + 1);
                }}
                disabled={managersIds && managersIds?.includes("0")}
            >
              {"+ Neue Manager"}
            </button>

            <div className={Style.buttonContainer}>
              <Button
                  style={{
                    width: "25%",
                  }}
                  buttonStyle={{
                    width: "100%",
                  }}
                  disabled={isSubmitDisabled}
                  text={t("Save")}
              />
            </div>
          </div>
        </div>

      </form>
    </Modal>
);
};
export default EditGasStationModal;
