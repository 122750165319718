import {
  ADD_CLIENT,
  CLEAR_CLIENT_FOR_DETAILS,
  CLEAR_CLIENT_FOR_UPDATE,
  GET_ALL_CLIENTS,
  GET_CLIENT_FOR_UPDATE,
  UPDATE_CLIENT,
} from "../actions/types";

const initialState = {
  clients: [],
  client : null,
  clientForUpdate: {},
  clientForDetails: {},
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CLIENTS:
      return {
        ...state,
        clients: action.payload,
        client: null,
      };

    case GET_CLIENT_FOR_UPDATE:
      return {
        ...state,
        clientForUpdate: action.payload,
        clientForDetails: action.payload,
      };
     case CLEAR_CLIENT_FOR_UPDATE:
       return {
         ...state,
         clientForUpdate: {},
       };
     case CLEAR_CLIENT_FOR_DETAILS:
       return {
         ...state,
         clientForDetails: {},
       };
    case ADD_CLIENT:
      return {
        ...state,
        clients: state.clients.reverse().concat(action.payload).reverse(),
      };
    case UPDATE_CLIENT:
      return {
        ...state,
        clients: state.clients.map((c) =>
          c.id !== action.payload.id ? c : action.payload
        ),
      };

    default:
      return state;
  }
};

export default clientReducer;
