import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Sidebar from "../../sidebar/sidebar";
import MaterialTable from "@material-table/core";
import {
  useStyles,
  useStylesForMaterial,
  toolbarStyles,
  materialTableStyle,
  paginationStyle,
} from "../../reusable/styles";
import { useTranslation } from "react-i18next";
import Pagination from "../../reusable/pagination";
import {
  getAllAdmins,
  disableAdmin,
  closeDuplicateModal,
  checkAdminEmail,
  clearAdminData,
} from "../../actions/admin";
import ModalAddAdmin from "./modalAddAdmin";
import ModalEditAdmin from "./modalEditAdmin";
import { useDispatch, useSelector } from "react-redux";
import { optionsGeneralEnableDisable } from "../../reusable/optionsForTables";
import LoadingIndicator from "../../reusable/loadingIndicator";
import Style from "./admins.module.css";
import ModalAddAdminForm from "./modalAddAdminForm";
import { changeTabInSidebar } from "../../actions/sidebar";
import ErrorModal from "../../common/modal/errorModal";
import MagnifyingGlass from "../../common/assets/magnifying-glass.svg";
import { AdminColumns } from "../../columns/adminColumns";

export default function Admins() {
  const { t } = useTranslation();
  const [openAddAdminModal, setOpenAddAdminModal] = useState(false);
  const [openAddAdminModalForm, setOpenAddAdminModalForm] = useState(false);
  const [adminsEmail, setAdminsEmail] = useState("");
  const [openEditAdminModal, setOpenEditAdminModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState({});
  const classes = useStyles();
  const classesMaterial = useStylesForMaterial();
  const classesForm = toolbarStyles();
  const dispatch = useDispatch();
  const { admins, duplicateAdmin, admin } = useSelector((state) => state.admin);
  const { isLoading } = useSelector((state) => state.loader);

  const [height, setHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  const closeModalDuplicatedAdmin = () => {
    dispatch(closeDuplicateModal());
  };

  const closeModal = () => {
    setOpenAddAdminModal(false);
    setOpenEditAdminModal(false);
    setSelectedAdmin({});
  };

  const closeModalForm = () => {
    dispatch(clearAdminData());
    setOpenAddAdminModalForm(false);
  };

  useEffect(() => {
    dispatch(changeTabInSidebar(3));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllAdmins());
  }, [dispatch]);

  function toolbar(props) {
    return (
      <div className={classesForm.root}>
        <div className={Style.header}>
          <div className={Style.header__text}>{t("Sidebar.Admins")}</div>
          <button
            className="addEntityButton"
            onClick={() => {
              setOpenAddAdminModal(true);
            }}
          >
            <p className={Style.header__addButtonText}>
              {t("Admins.CreateAdmin")}
            </p>
          </button>
        </div>
      </div>
    );
  }

  const disableAdminBySysAdmin = (email) => {
    dispatch(disableAdmin(email));
  };

  function pagination(props) {
    return (
      <td style={paginationStyle}>
        <Pagination translate={t} {...props} classes={{ ...classesMaterial }} />
      </td>
    );
  }

  const checkAdmin = (email) => {
    dispatch(checkAdminEmail(email));
    setAdminsEmail(email);
    setOpenAddAdminModal(false);
    setOpenAddAdminModalForm(true);
  };

  return (
    <div className={classes.root}>
      <>
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth={false} className={classes.container}>
            {isLoading && <LoadingIndicator />}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className="text-center">
                  <div className="row content">
                    <div className="col-sm-12">
                      <div className={classes.paper}>
                        <MaterialTable
                          icons={{
                            Filter: () => (
                              <img src={MagnifyingGlass} alt="search" />
                            ),
                          }}
                          title="Parking zones"
                          style={materialTableStyle}
                          data={admins}
                          columns={AdminColumns(
                            setOpenEditAdminModal,
                            setSelectedAdmin,
                            t,
                            disableAdminBySysAdmin
                          )}
                          options={optionsGeneralEnableDisable(
                            height - 240,
                            44
                          )}
                          components={{
                            Toolbar: (props) => toolbar(props),
                            Pagination: (props) => pagination(props),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </main>
        {openAddAdminModal && (
          <ModalAddAdmin
            open={openAddAdminModal}
            close={closeModal}
            submit={checkAdmin}
          />
        )}
        {openAddAdminModalForm && (
          <ModalAddAdminForm
            open={openAddAdminModalForm}
            close={closeModalForm}
            adminsEmail={adminsEmail}
            admin={admin}
          />
        )}

        {openEditAdminModal && (
          <ModalEditAdmin
            open={openEditAdminModal}
            close={closeModal}
            selectedAdmin={selectedAdmin}
          />
        )}

        {duplicateAdmin && (
          <ErrorModal
            title={t("DulicateAdministrator.title")}
            errorMessage={t("DulicateAdministrator.message")}
            open={duplicateAdmin}
            onClose={() => closeModalDuplicatedAdmin()}
            width="350px"
          />
        )}
      </>
    </div>
  );
}
