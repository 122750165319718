import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { newModalStyle } from "../../reusable/styles";
import LabelWithHR from "../../common/labels/labelWithHR";
import Style from "./modalEditTicket.module.css";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import Button from "../../common/button/button";

registerLocale("de", de);

const ModalManualPayment = (props) => {
  const { handleSubmit, control } = useForm();
  const [manualPayment, setManualPayment] = useState({
    ticketId: props.ticketForEdit.id,
    paidAmount: "",
  });

  const isNumeric = (str) => {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  };

  const payManually = () => {
    props.manuallyPayTicket(manualPayment);
    props.close();
  };

  const { t } = useTranslation();
  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={props.close}
      center
      styles={newModalStyle({ width: "450px" })}
    >
      <form onSubmit={handleSubmit(payManually)}>
        <div className="modal__title">
          {t("ModalManualPayment.ManualPayment")}
        </div>

        <div>
          <LabelWithHR label={t("ModalManualPayment.paidAmount")} />
          <Controller
            name="paidAmount"
            control={control}
            render={() => (
              <input
                className={Style.textStyleInputEditModal}
                name="paidAmount"
                type="text"
                onChange={(e) => {
                  setManualPayment({
                    ...manualPayment,
                    paidAmount: e.target.value,
                  });
                }}
                defaultValue={manualPayment.paidAmount}
              />
            )}
          />
        </div>

        <div className={Style.buttonContainer}>
          <Button
            style={{
              width: "25%",
              marginTop: 30,
            }}
            disabled={!isNumeric(manualPayment.paidAmount)}
            buttonStyle={{
              width: "100%",
            }}
            text={t("ModalManualPayment.Save")}
          />
        </div>
      </form>
    </Modal>
  );
};
export default ModalManualPayment;
