import {Controller} from "react-hook-form";
import LabelWithHR from "../labels/labelWithHR";

const InputController = ({
                             containerStyles,
                             label,
                             name,
                             control,
                             type,
                             controllerClassName,
                             onChange,
                             defaultValue,
                             disabled,
                             required,
                             min,
                         }) => {
    return (
        <div className={containerStyles}>
            <LabelWithHR label={label} required={required}/>
            <Controller
                name={name}
                control={control}
                render={() => (
                    <input
                        type={type ? type : "text"}
                        className={controllerClassName}
                        name={name}
                        onChange={onChange}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        required={required}
                        min={min}
                    />
                )}
            />
        </div>
    );
};

export default InputController;
