import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useTranslation } from "react-i18next";
import Style from "./ownerDetailsModal.module.css";
import { newModalStyle } from "../../reusable/styles";
import EntityDetail from "../../common/entityDetail/entityDetail";

const OwnerDetailsModal = (props) => {
  const { owner } = props;
  const { t } = useTranslation();

  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={props.onClose}
      center
      styles={newModalStyle({ width: "539px" })}
    >
      <div className="modal__headerLine" />
      <div className="modal__title">{t("OwnerDetailsModal.title")}</div>

      <div className={Style.body}>
        <EntityDetail
          label={t("OwnerDetailsModal.firstName")}
          value={owner.firstName}
        />
        <EntityDetail
          label={t("OwnerDetailsModal.lastName")}
          value={owner.lastName}
        />
        <EntityDetail
          label={t("OwnerDetailsModal.email")}
          value={owner.email}
        />
        <EntityDetail
          label={t("OwnerDetailsModal.phoneNumber")}
          value={owner.phoneNumber}
        />
      </div>
    </Modal>
  );
};
export default OwnerDetailsModal;
