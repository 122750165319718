import Style from "./entityDetail.module.css";

const EntityDetail = (props) => {
  const { label, value } = props || {};
  return (
    <div className={Style.entityDetail}>
      <div className={Style.entityDetail__label}>{label}</div>
      <div className={Style.entityDetail__line} />
      {props.pressableValue ? (
        <div
          className={Style.entityDetail__pressableValue}
          onClick={() => {
            props.openModal(true);
            props.setSelectedTicket();
          }}
        >
          {value}
        </div>
      ) : (
        <div className={Style.entityDetail__value}>{value}</div>
      )}
    </div>
  );
};
export default EntityDetail;
